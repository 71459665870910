import React, { useEffect, useState } from "react";
import "./deals.scss";
import Button from "../../../../components/button/button";
import TabCollection from "./tabCollection/tabCollection";
import CollectionList from "./collectionList/CollectionList";

function Collection() {
    window.$addDeal = false;
    const [deal, setDeal] = useState(window.$addDeal);
    const [collectionDetail, setCollectionDetail] = useState("");
    const [show, setShow] = useState(false);
   

    const collectionPage = () => {
        setDeal(true);
        window.$addDeal = true;
        setShow(false);
        setCollectionDetail("");
    };

    const collectionDetails = (val) => {
        setCollectionDetail(val);
    }

    const showView = (val) => {
        setShow(val);
    }

    return (
        <div className="w-100 screenLeftMargin">

            {!deal &&
                <>
                    <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                        <div className="col-12 heading-title">Deals</div>
                    </div>
                    <div className="row ml-3 mr-3 mt-2 mobileResponsive">
                        <div className="col-lg-9 col-md-8">
                            <div className="muiDescripationInfo">
                                {/* Collection can be used to manage your listings and Discounts.This information will not be seen by customers. */}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 d-flex justify-content-end btn-mobile-margin-none" style={{ top: "-25px" }}>
                            <Button onClick={() => collectionPage()} className="btn btn-cancle btn-lg" name="Create Deals"></Button>
                        </div>
                    </div>
                </>
            }

            {deal ?
                <div className="row ml-3 mr-3 mobileResponsive">
                    <div className="col-12">
                        < TabCollection show={show} showListing={collectionDetail} setShowListing={(val) => setDeal(val)} />
                    </div>
                </div>
                : ""}

            {!deal &&
                <div className="row ml-3 mr-3 mobileResponsive">
                    <div className="col-12">
                        < CollectionList show={(val) => showView(val)} setShowListing={(val) => setDeal(val)} collectionDetails={(val) => collectionDetails(val)} />
                    </div>
                </div>
            }

        </div>
    )
}
export default Collection;