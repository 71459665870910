import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import ListSubheader from "@material-ui/core/ListSubheader";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import JoditEditor from "jodit-react";
import { useDropzone } from "react-dropzone";
import Tooltip from "rc-tooltip";
import { toast } from "react-toastify";
import Button from "../../../../../components/button/button";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import SpinnerLoader from "../../../../../components/loader/spinnerLoader";
import SearchIcon from "@material-ui/icons/Search";
import { getMethod, getProductDetails, postMethodWithTokenProgress, postMethodWithToken, putMethodWithToken } from "../../../../../utils/services/api";
import urls from "../../../../../utils/services/apiUrl.json";
import { getProductId, getStoreId, setProductId, getProductEditableFlag } from "../../../../../utils/common";
import { makeStyles } from "@material-ui/core/styles";
import info from "../../../../../assets/images/info.png";
import ProgressBar from "../../../../../components/progressBar/progressBar";
import "./general.scss";

const checkGeneralInfo = generalData => {

    const emptyKeys = [
        'product_name',
        'brand',
        'product_model',
        'category_slug',
        'subcategory_slug',
        'description',
        /*'img_ids',*/
    ];

    const emptyKeysErrors = [
        'Name',
        'Brand',
        'Model',
        'Type',
        'Category',
        'Description',
        /*'Image',*/
    ];
    
    for (let i = 0; i < emptyKeys.length; i++) {
        if (!generalData.general[emptyKeys[i]]) {
            return `Please fill in ${emptyKeysErrors[i]}`;
        }

        /*if(emptyKeys[i] === 'img_ids' && generalData.general[emptyKeys[i]].length === 0) {
            return `Please fill in ${emptyKeysErrors[i]}`;
        }*/
    }

    return '';
};

function GeneralSection({ generalData, prevGeneralData, setSwipeableFlag, specificationProps, descriptionProps }) {
    function getSpecificationsConfig(props) {
        let config = {
            readonly: false,
            placeholder: "Product Specifications",
        }
        return config;
    }

    const specificationsConfig = useMemo(() => getSpecificationsConfig(specificationProps), [specificationProps]); 

    function getDescriptionConfig(props) {
        let config = {
            readonly: false,
            placeholder: "Product Description",
        }
        return config;
    }

    const descriptionConfig = useMemo(() => getDescriptionConfig(descriptionProps), [descriptionProps]); 

    function useForceUpdate() {
        const [value, setValue] = useState(0); // integer state
        return () => setValue((value) => value + 1); // update the state to force render
    }

    const forceUpdate = useForceUpdate();

    const editorRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState('');
    const [state, setState] = useState({
        product_name: "",
        brand: "",
        product_model: "",
        category_slug: "",
        subcategory_slug: "",
        third_category_slug:"",
        fourth_category_slug:"",
        description: "",
        specification: "",
        shippingFlag: false,
        inventoryFlag: false
        /*img_ids: [],
        general_image: [],*/
    });

    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [subSubCategoryOptions, setSubSubCategoryOptions] = useState([]);
    const [fourthCategoryOptions, setFourthCategoryOptions] = useState([]);
    const [categoryOptionsData, setCategoryOptionsData] = useState([]);
    const [subCategoryOptionsData, setSubCategoryOptionsData] = useState([]);
    const [subSubCategoryOptionsData, setSubSubCategoryOptionsData] = useState([]);
    const [fourthCategoryOptionsData, setFourthCategoryOptionsData] = useState([]);
    const [category, setCategory] = useState("");
    const [subCategory, setSubCategory] = useState("");
    const [subSubCategory, setSubSubCategory] = useState("");
    const [fourthCategory, setFourthCategory] = useState("");

    /*const [uploadPercentage, setStateUploadPercentage] = useState(0);
    const [productImgWithFile, setProductImgWithFile] = useState([]);
    const [productImgWithId, setProductImgWithId] = useState([]);*/

    const dragItem = useRef();
    const dragOverItem = useRef();

    useEffect(() => {
        productCategory();
        
        if(prevGeneralData) {
            setCategory(prevGeneralData.category_slug);
            productSubCategory(prevGeneralData.category_slug);
            setSubCategory(prevGeneralData.subcategory_slug);
        
            productSubSubCategory(prevGeneralData.category_slug, prevGeneralData.subcategory_slug)
            setSubSubCategory(prevGeneralData.third_category_slug);

            productFourthCategory(prevGeneralData.category_slug, prevGeneralData.subcategory_slug, prevGeneralData.third_category_slug)

            setFourthCategory(prevGeneralData.fourth_category_slug)

            setState(prevGeneralData)

            setLoading(true)
        } else {
            listedDetails();
        }
        
    }, []);

    useEffect(() => {
        let backData = {
            data : state,
            is_submit: false,
        }
        generalData(backData)
    }, [state]);

    const productCategory = async () => {
        if (categoryOptions.length <= 0) {
            await getMethod(urls.productCategory).then((response) => {
                if (response.status === 200) {
                    setCategoryOptions(response.data);
                    setCategoryOptionsData(response.data);
                }
            }).catch((error) => {

            });
        }
    };

    const productSubCategory = async (slug) => {
        if (slug !== "" && slug !== undefined) {
            await getMethod(urls.productSubCategory + slug + "/").then((response) => {
                if (response.status === 200) {
                    setSubCategoryOptions(response.data);
                    setSubCategoryOptionsData(response.data);
                }
            }).catch((error) => {
                console.log("API error", error);
            });
        }
    };

    const productSubSubCategory = async (categoryslug, slug) => {
        if (slug !== "" && slug !== undefined) {
            await getMethod(urls.productSubCategory + categoryslug + "/"+ slug + "/").then((response) => {
                if (response.status === 200) {
                    setSubSubCategoryOptions(response.data);
                    setSubSubCategoryOptionsData(response.data);
                }
            }).catch((error) => {
                console.log("API error", error);
            });
        }
    };

    const productFourthCategory = async (categoryslug, subCategoryslug ,slug) => {
        if (slug !== "" && slug !== undefined) {
            await getMethod(urls.productSubCategory + categoryslug + "/" + subCategoryslug + "/" + slug + "/" ).then((response) => {
                if (response.status === 200) {
                    setFourthCategoryOptions(response.data);
                    setFourthCategoryOptionsData(response.data);
                }
            }).catch((error) => {
                console.log("API error", error);
            });
        }
    };

    const listedDetails = async () => {
        if (getProductId() !== null && getProductEditableFlag() !== 'added') {
            let storeId = getStoreId();
            let productId = getProductId();
            let moduleName = "general";

            await getProductDetails(storeId, productId, moduleName).then((productDetails) => {

                if (productDetails.status === 200) {
                    setLoading(true);

                    /*productDetails.data.images.forEach((item, index) => {
                        let imageObject = {
                            image_id: item.id,
                            order_image: item.display_order,
                        };
                        productImgWithId.push(imageObject);

                        let productImageObject = {
                            file: item.original,
                            id: item.id,
                            order_image: item.display_order,
                        };
                        productImgWithFile.push(productImageObject);
                    });

                    setProductImgWithFile(productImgWithFile);
                    setProductImgWithId(productImgWithId)*/

                    setState((prevState) => ({
                        ...prevState,
                        ["product_name"] : productDetails.data.product_name,
                        ["brand"] : productDetails.data.brand,
                        ["product_model"] : productDetails.data.product_model,
                        ["category_slug"] : productDetails.data.categories !== null && productDetails.data.categories.length !== 0 ? productDetails.data.categories[0].slug : "",
                        ["subcategory_slug"] : productDetails.data.product_subcategory !== null && productDetails.data.product_subcategory.length !== 0 ? productDetails.data.product_subcategory[0].slug : "",
                        ["third_category_slug"] : productDetails.data.productThirdCategory !== null && productDetails.data.productThirdCategory.length !== 0 ? productDetails.data.productThirdCategory[0].slug : "",
                        ["fourth_category_slug"] : productDetails.data.productFourthCategory !== null && productDetails.data.productFourthCategory.length !== 0 ? productDetails.data.productFourthCategory[0].slug : "",
                        ["description"] : productDetails.data.description,
                        ["specification"]: productDetails.data.specification,
                        ["shippingFlag"]: productDetails.data.shippingFlag,
                        ["inventoryFlag"]: productDetails.data.inventoryFlag,
                        /*["img_ids"]: productImgWithId,
                        ["general_image"]: productImgWithFile,*/
                    }));

                    setCategory(productDetails.data.categories !== null && productDetails.data.categories.length !== 0 ? productDetails.data.categories[0].slug : "");
                    productSubCategory(productDetails.data.categories !== null && productDetails.data.categories.length !== 0 ? productDetails.data.categories[0].slug : "");
                    setSubCategory(productDetails.data.product_subcategory !== null && productDetails.data.product_subcategory.length !== 0 ? productDetails.data.product_subcategory[0].slug : "");
                
                    productSubSubCategory(
                        productDetails.data.categories !== null && productDetails.data.categories.length !== 0 ? productDetails.data.categories[0].slug : "",
                        productDetails.data.product_subcategory !== null && productDetails.data.product_subcategory.length !== 0 ? productDetails.data.product_subcategory[0].slug : ""
                    )
                    setSubSubCategory(productDetails.data.productThirdCategory !== null && productDetails.data.productThirdCategory.length !== 0 ? productDetails.data.productThirdCategory[0].slug : "");

                    productFourthCategory(
                        productDetails.data.categories !== null && productDetails.data.categories.length !== 0 ? productDetails.data.categories[0].slug : "",
                        productDetails.data.product_subcategory !== null && productDetails.data.product_subcategory.length !== 0 ? productDetails.data.product_subcategory[0].slug : "",
                        productDetails.data.productThirdCategory !== null && productDetails.data.productThirdCategory.length !== 0 ? productDetails.data.productThirdCategory[0].slug : ""
                    )

                    setFourthCategory(productDetails.data.productFourthCategory !== null && productDetails.data.productFourthCategory.length !== 0 ? productDetails.data.productFourthCategory[0].slug : "")
                }

            }).catch((error) => {
                console.log("API error", error);
            });
        }
        setLoading(true);
    };

    /*const useStyles = makeStyles((theme) => ({
        categoryControl: {
            width: "100%",
            backgroundColor: "#fff",
        },
        subCategoryControl: {
            width: "100%",
            backgroundColor: "#fff",
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }));

    const classes = useStyles();*/

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSpcificationChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            ['specification']: e,
        }));
    };

    const handleDescriptionChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            ['description']: e,
        }));
    };

    const categoryChange = (event) => {
        if(event.target.value !== undefined) {
            setSubCategory('')
            setSubSubCategory('')
            setFourthCategory('')

            setState((prevState) => ({
                ...prevState,
                ["subcategory_slug"]: '',
                ["third_category_slug"]: '',
                ["fourth_category_slug"]: '',
            }));

            setCategory(event.target.value);
            
            setState((prevState) => ({
                ...prevState,
                ["category_slug"]: event.target.value,
            }));

            productSubCategory(event.target.value);
        }
    };

    const subCategoryChange = (event) => {
        if(event.target.value !== undefined) {
            setSubSubCategory('')
            setFourthCategory('')
            setState((prevState) => ({
                ...prevState,
                ["third_category_slug"]: '',
                ["fourth_category_slug"]: '',
            }));

            setSubCategory(event.target.value);
            
            setState((prevState) => ({
                ...prevState,
                ["subcategory_slug"]: event.target.value,
            }));
            
            productSubSubCategory(category, event.target.value)  
        }
    };

    const subSubCategoryChange = (event) => {
        if(event.target.value !== undefined) {
            setFourthCategory('')
            setState((prevState) => ({
                ...prevState,
                ["fourth_category_slug"]: '',
            }));

            setSubSubCategory(event.target.value);
            
            setState((prevState) => ({
                ...prevState,
                ["third_category_slug"]: event.target.value,
            }));
            
            productFourthCategory(category, subCategory, event.target.value)
        }
    };

    const fourthCategoryChange = (event) => {
        if(event.target.value !== undefined) {
            setFourthCategory(event.target.value);
            setState((prevState) => ({
                ...prevState,
                ["fourth_category_slug"]: event.target.value,
            }));
        }
    };

    /*const onUploadProgress = (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setStateUploadPercentage(percent);
    };*/

    /*const onDrop = useCallback((acceptedFiles) => {
        let formData = new FormData();
        acceptedFiles.map((file, index) => {
            formData.append("file", file);
        });

        postMethodWithTokenProgress(urls.fileUpload, formData, onUploadProgress).then((response) => {
            if(response.status === 200 || response.status === 201) {
                
                response.data.map((item, index) => {
                    let imageObject = {
                        image_id: item.id,
                        order_image: productImgWithId.length > 0 ? productImgWithId.length + 1 : index + 1,
                    };
                    productImgWithId.push(imageObject);

                    let productImageObject = {
                        file: item.file,
                        id: item.id,
                        order_image: productImgWithFile.length > 0 ? productImgWithFile.length + 1 : index + 1,
                    };
                    productImgWithFile.push(productImageObject);
                });

                setProductImgWithFile(productImgWithFile);
                setProductImgWithId(productImgWithId)

                setState((prevState) => ({
                    ...prevState,
                    ["img_ids"]: productImgWithId,
                    ["general_image"]: productImgWithFile,
                }));

                setStateUploadPercentage(0);
            }
        });
    },[]);*/
    
    /*const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: "image/jpeg, image/jpg, image/png",
        onDrop,
    });*/

    /*const handleGeneralDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        let imageWithFileArray = [];
        const imageWithFile = [...productImgWithFile];
        const itemsWithFile = Array.from(imageWithFile);
        const [reorderedFile] = itemsWithFile.splice(result.source.index, 1);
        itemsWithFile.splice(result.destination.index, 0, reorderedFile);

        for (let k = 0; k < itemsWithFile.length; k++) {
            let changeOrder = {file: "", id: "", order: ""};
            changeOrder.file = itemsWithFile[k].file !== undefined ? itemsWithFile[k].file : itemsWithFile[k].original;
            changeOrder.id = itemsWithFile[k].id;
            changeOrder.order_image = k + 1;
            imageWithFileArray.push(changeOrder);
        }
        setProductImgWithFile(imageWithFileArray);

        let imageWithIdArray = [];
        const imageWithId = [...productImgWithId];
        const itemsWithId = Array.from(imageWithId);
        const [reorderedId] = itemsWithId.splice(result.source.index, 1);
        itemsWithId.splice(result.destination.index, 0, reorderedId);

        for (let k = 0; k < itemsWithId.length; k++) {
            let changeOrderId = {id: "", order: ""};
            changeOrderId.id = itemsWithId[k].id;
            changeOrderId.order_image = k + 1;
            imageWithIdArray.push(changeOrderId);
        }
        setProductImgWithId(imageWithIdArray);

        setState((prevState) => ({
            ...prevState,
            ["img_ids"]: imageWithIdArray,
            ["general_image"]: imageWithFileArray,
        }));
    };*/

    /*const dragUploadStart = (e, position) => {
        setSwipeableFlag(true)
        dragItem.current = position;
    }*/

    /*const dragUploadEnter = (e, position) => {
        dragOverItem.current = position;
    }*/

    /*const dropUploadEnd = (e) => {
        const imageWithFile = [...productImgWithFile];
        const imageWithId = [...productImgWithId];

        const dragItemContentWithFile = imageWithFile[dragItem.current];
        const dragOverItemContentWithFile = imageWithFile[dragOverItem.current];

        const dragItemContentWithId = imageWithId[dragItem.current];
        const dragOverItemContentWithId = imageWithId[dragOverItem.current];

        imageWithFile.splice(dragItem.current, 1);
        imageWithFile.splice(dragOverItem.current, 0, dragItemContentWithFile);

        imageWithId.splice(dragItem.current, 1);
        imageWithId.splice(dragOverItem.current, 0, dragItemContentWithId);

        dragItem.current = null;
        dragOverItem.current = null;

        imageWithFile.forEach((x, i) => {
            imageWithFile[i]['order_image'] = i + 1;
        })

        imageWithId.forEach((x, i) => {
            imageWithId[i]['order_image'] = i + 1;
        })

        setProductImgWithFile(imageWithFile);
        setProductImgWithId(imageWithId)

        setState((prevState) => ({
            ...prevState,
            ["img_ids"]: imageWithId,
            ["general_image"]: imageWithFile,
        }));

        setSwipeableFlag(false)
    }*/

    /*const deleteGeneralImg = (item, index) => {
        productImgWithFile.splice(index, 1);

        productImgWithId.splice(index, 1);

        if(productImgWithFile.length > 0) {
            productImgWithFile.forEach((x, i) => {
                if(productImgWithFile[i].order_image !== 1) {
                    productImgWithFile[i].order_image = productImgWithFile[i].order_image - 1;
                }
            });

            productImgWithId.forEach((x, i) => {
                if(productImgWithId[i].order_image !== 1) {
                    productImgWithId[i].order_image = productImgWithId[i].order_image - 1;
                }
            });
        }

        setProductImgWithFile(productImgWithFile);
        setProductImgWithId(productImgWithId)

        setState((prevState) => ({
            ...prevState,
            ["img_ids"]: productImgWithId,
            ["general_image"]: productImgWithFile,
        }));

        forceUpdate()
    };*/

    const onSubmitGeneral = async (value) => {
        let storeId = getStoreId();
        let product_id = getProductId();

        let generalBody = {
            store_id: storeId,
            general: state,
        }

        const generalError = checkGeneralInfo(generalBody);

        if(generalError) {
            toast.error(generalError)
        } else {
            if(product_id && product_id !== null) {
                await putMethodWithToken(urls.aboutStore + storeId + "/product/" + product_id + "/?module=" + value, generalBody).then((response) => {
                    if ((response.status === 200 || response.status === 201) && value === "general") {
                        toast.success(response.data.success)

                        let backData = {
                            data : state,
                            is_submit: true,
                        }

                        generalData(backData)
                    }
                }).catch((error) => {
                    console.log("error", error);
                });
            } else {
                await postMethodWithToken(urls.addProduct + value, generalBody).then((response) => {
                    if ((response.status === 200 || response.status === 201) && value === "general") {
                        toast.success(response.data.success)

                        let backData = {
                            product_id : response.data.product_id,
                            data : state,
                            is_submit: true,
                        }
                        
                        setProductId(response.data.product_id)
                        generalData(backData)
                    }
                }).catch((error) => {
                    console.log("error", error);
                });
            }
        }
    };

    const firstCategoryFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = categoryOptionsData.filter((item) => {
                return item.name.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setCategoryOptions(results);
        } else {
            setCategoryOptions(categoryOptionsData);
        }
    };

    const secondCategoryFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = subCategoryOptionsData.filter((item) => {
                return item.name.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setSubCategoryOptions(results);
        } else {
            setSubCategoryOptions(subCategoryOptionsData);
        }
    };

    const thirdCategoryFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = subSubCategoryOptionsData.filter((item) => {
                return item.name.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setSubSubCategoryOptions(results);
        } else {
            setSubSubCategoryOptions(subSubCategoryOptionsData);
        }
    };

    const fourthCategoryFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = fourthCategoryOptionsData.filter((item) => {
                return item.name.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setFourthCategoryOptions(results);
        } else {
            setFourthCategoryOptions(fourthCategoryOptionsData);
        }
    };

    return (
        <div>
            {!loading ? (
                <SpinnerLoader />
            ) : (
                <ValidatorForm>
                    {/*<div className="row pt-3">
                        <div className="col-12 d-flex" style={{cursor: "pointer" }}>
                            <div className="uploadImages" {...getRootProps()}>
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p>Drop here..</p>
                                ) : (
                                    <div>
                                        Upload images
                                        <Tooltip
                                            placement="bottom"
                                            trigger={["hover"]}
                                            overlay={
                                                <span>
                                                    Upload images (at least 300 px x 300 px) of a
                                                    product with different angles and details. You
                                                    can upload up to maximum 10 images. Drag and
                                                    drop to change the order of your images.
                                                </span>
                                            }
                                            >
                                            <a href="#">
                                                <img src={info} alt="" />
                                            </a>
                                        </Tooltip>

                                        <div className={uploadPercentage === 0 ? "hideper" : "showper"}>
                                            <ProgressBar bgcolor="#233949" progress={uploadPercentage} height={6}/>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="d-flex">
                                {state.general_image && state.general_image.map((item, index) => {
                                    return (
                                        <div className="d-flex" 
                                            data-key={index}
                                            onDragStart={(e) => dragUploadStart(e, index)}
                                            onDragEnter={(e) => dragUploadEnter(e, index)}
                                            onDragEnd={dropUploadEnd}
                                            key={index}
                                            draggable>
                                            <div className="ml-2 mr-2">
                                                <div className="deleteUploadedImage text-center" onClick={() => deleteGeneralImg(item, index)}>
                                                    x
                                                </div>
                                                <img className="uploadedImages" src={item.file ? item.file : ''} alt=""/>
                                            </div>
                                        </div>
                                    );
                                })} 
                            </div>
                        </div>
                    </div>*/}

                    <div className="row pt-3">
                        <div className="col-4">
                            <TextValidator
                                errorMessages={"Please fill in Name"}
                                inputProps={{ maxLength: 80 }}
                                validators={[
                                    "minStringLength:1",
                                    "maxStringLength:80",
                                    "required",
                                ]}
                                onChange={handleChange}
                                value={state.product_name}
                                name="product_name"
                                label="Product Name"
                                variant="outlined"
                                required
                            />
                        </div>
                        <div className="col-4">
                            <TextValidator
                                errorMessages={"Please fill in Brand"}
                                inputProps={{ maxLength: 30 }}
                                validators={[
                                    "minStringLength:1",
                                    "maxStringLength:30",
                                    "required",
                                ]}
                                onChange={handleChange}
                                value={state.brand}
                                name="brand"
                                label="Brand"
                                variant="outlined"
                                required
                            />
                        </div>
                        <div className="col-4">
                            <TextValidator
                                required
                                errorMessages={"Please fill in Model"}
                                inputProps={{ maxLength: 20 }}
                                validators={[
                                    "minStringLength:1",
                                    "maxStringLength:30",
                                    "required",
                                ]}
                                onChange={handleChange}
                                value={state.product_model}
                                name="product_model"
                                label="Model"
                                variant="outlined"
                            />
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-3">
                            <FormControl
                                variant="outlined"
                                >
                                <InputLabel required classid="category-select">
                                    Product Type
                                </InputLabel>
                                <Select
                                    MenuProps={{ autoFocus: false }}
                                    labelId="Product-category"
                                    id="Product-category-dropdown"
                                    value={category}
                                    defaultValue={category}
                                    label="Product Type"
                                    onChange={(e) => categoryChange(e)}
                                    onClose={() => setCategoryOptions(categoryOptionsData)}
                                    >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            placeholder="Type to search..."
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={firstCategoryFilter}
                                            onKeyDown={(e) => {
                                                if (e.key !== "Escape") {
                                                    e.stopPropagation();
                                                }
                                            }}
                                        />
                                    </ListSubheader>
                                    {categoryOptions.length > 0 && categoryOptions.map((item) => {
                                        return (
                                            <MenuItem
                                                key={item.slug}
                                                value={item.slug}
                                                disabled={item.slug === "all"}
                                                >
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col-3">
                            <FormControl variant="outlined">
                                <InputLabel required id="subcategory-select">
                                    Product Category
                                </InputLabel>
                                <Select
                                    MenuProps={{ autoFocus: false }}
                                    labelId="Product-subcategory"
                                    id="Product-subcategory-dropdown"
                                    value={subCategory}
                                    defaultValue={subCategory}
                                    label="Product Category"
                                    onChange={(e) => subCategoryChange(e)}
                                    onClose={() => setSubCategoryOptions(subCategoryOptionsData)}
                                    >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            placeholder="Type to search..."
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={secondCategoryFilter}
                                            onKeyDown={(e) => {
                                                if (e.key !== "Escape") {
                                                    e.stopPropagation();
                                                }
                                            }}
                                        />
                                    </ListSubheader>
                                    {subCategoryOptions.length > 0 && subCategoryOptions.map((item) => {
                                        return (
                                            <MenuItem key={item.slug} value={item.slug}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col-3">
                            <FormControl variant="outlined">
                                <InputLabel id="subcategory-select">
                                    Product Sub Category
                                </InputLabel>
                                <Select
                                    MenuProps={{ autoFocus: false }}
                                    labelId="Product-subcategory"
                                    id="Product-subcategory-dropdown"
                                    value={subSubCategory}
                                    defaultValue={subSubCategory}
                                    label="Product Sub Category"
                                    onChange={(e) => subSubCategoryChange(e)}
                                    onClose={() => setSubSubCategoryOptions(subSubCategoryOptionsData)}
                                    >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            placeholder="Type to search..."
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={thirdCategoryFilter}
                                            onKeyDown={(e) => {
                                                if (e.key !== "Escape") {
                                                    e.stopPropagation();
                                                }
                                            }}
                                        />
                                    </ListSubheader>
                                    {subSubCategoryOptions.length > 0 && subSubCategoryOptions.map((item) => {
                                        return (
                                            <MenuItem key={item.slug} value={item.slug}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col-3">
                            <FormControl variant="outlined">
                                <InputLabel id="subcategory-select">
                                    Product Sub Sub Category
                                </InputLabel>
                                <Select
                                    MenuProps={{ autoFocus: false }}
                                    labelId="Product-subcategory"
                                    id="Product-subcategory-dropdown"
                                    value={fourthCategory}
                                    defaultValue={fourthCategory}
                                    label="Product Sub Sub Category"
                                    onChange={(e) => fourthCategoryChange(e)}
                                    onClose={() => setFourthCategoryOptions(fourthCategoryOptionsData)}
                                    >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            placeholder="Type to search..."
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={fourthCategoryFilter}
                                            onKeyDown={(e) => {
                                                if (e.key !== "Escape") {
                                                    e.stopPropagation();
                                                }
                                            }}
                                        />
                                    </ListSubheader>
                                    {fourthCategoryOptions.length > 0 && fourthCategoryOptions.map((item) => {
                                        return (
                                            <MenuItem key={item.slug} value={item.slug}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-6">
                            <div className={"joditEdit"}>
                                <JoditEditor
                                    ref={editorRef}
                                    value={state.description}
                                    config={descriptionConfig}
                                    onBlur={newContent => handleDescriptionChange(newContent)}
                                    onChange={newContent => {}}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className={"joditEdit"}>
                                <JoditEditor
                                    ref={editorRef}
                                    value={state.specification}
                                    config={specificationsConfig}
                                    onBlur={newContent => handleSpcificationChange(newContent)}
                                    onChange={newContent => {}}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-r">
                        <div className="col-12 d-flex justify-content-end mt-3">
                            <Button onClick={() => onSubmitGeneral("general")} name="Save" className="btn btn-primary mb-5"/>
                        </div>
                    </div>
                </ValidatorForm>
            )}
        </div>
    );
}
export default GeneralSection;
