import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { AreaChart, Area, LineChart, Line, ResponsiveContainer, CartesianGrid, Label, XAxis, Tooltip } from "recharts";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import "./home.scss";
import { getMethod } from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";

function Home() {
    const data = [
        {
            name: 'Page A',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Page D',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];
    const [allDashboardData, setAllDashboardData] = useState("");
    const [averageSalesofItems, setAverageSalesofItems] = useState("");
    const [averageSalesofItemsGraphData, setAverageSalesofItemsGraphData] = useState([]);
    const [returns, setReturns] = useState("");
    const [returnsGraphData, setReturnsGraphData] = useState([]);
    const [salesTax, setSalesTax] = useState("");
    const [salesGraphData, setSalesGraphData] = useState([]);
    const [transaction, setTransaction] = useState("");
    const [transactionGraphData, setTransactionGraphData] = useState([]);
    const [grossSales, setGrossSales] = useState("");
    const [grossSalesGraphData, setGrossSalesGraphData] = useState([]);
    const [netSales, setNetSales] = useState("");
    const [netSalesGraphData, setNetSalesGraphData] = useState([]);
    const [averageSalesValue, setAverageSalesValue] = useState("");
    const [averageSalesValueGraphData, setAverageSalesValueGraphData] = useState([]);
    const [grossprofit, setGrossprofit] = useState("");
    const [grossprofitGraphData, setGrossprofitGraphData] = useState([]);
    const [grossMargin, setGrossMargin] = useState("");
    const [grossMarginGraphData, setGrossMarginGraphData] = useState([]);
    const [grossMarkup, setGrossMarkup] = useState("");
    const [grossMarkupGraphData, setGrossMarkupGraphData] = useState([]);
    const [costOfGood, setCostOfGood] = useState("");
    const [costOfGoodGraphData, setCostOfGoodGraphData] = useState([]);

    useEffect(() => {
        GraphData();
    }, []);

    const GraphData = async () => {
        await getMethod(urls.getDashboardDetail).then((response) => {
            setAllDashboardData(response.data.response);
            setAverageSalesofItems(response.data.response.averageSalesofItems);
            setAverageSalesofItemsGraphData(response.data.response.averageSalesofItems.graphData.length > 0 && response.data.response.averageSalesofItems.graphData.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));
            setReturns(response.data.response.returns);
            setReturnsGraphData(response.data.response.returns.graphData.length > 0 && response.data.response.returns.graphData.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));
            setSalesTax(response.data.response.salesTax);
            setSalesGraphData(response.data.response.salesTax.graphData.length > 0 && response.data.response.salesTax.graphData.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));
            setTransaction(response.data.response.transaction);
            setTransactionGraphData(response.data.response.transaction.graphData.length > 0 && response.data.response.transaction.graphData.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));
            setGrossSales(response.data.response.grossSales);
            setGrossSalesGraphData(response.data.response.grossSales.graphData.length > 0 && response.data.response.grossSales.graphData.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));
            setNetSales(response.data.response.netSales);
            setNetSalesGraphData(response.data.response.netSales.graphData.length > 0 && response.data.response.netSales.graphData.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));
            setAverageSalesValue(response.data.response.averageSalesValue);
            setAverageSalesValueGraphData(response.data.response.averageSalesValue.graphData.length > 0 && response.data.response.averageSalesValue.graphData.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));
            setGrossprofit(response.data.response.grossprofit);
            setGrossprofitGraphData(response.data.response.grossprofit.graphData.length > 0 && response.data.response.grossprofit.graphData.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));
            setGrossMargin(response.data.response.grossMargin);
            setGrossMarginGraphData(response.data.response.grossMargin.graphData.length > 0 && response.data.response.grossMargin.graphData.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));
            setGrossMarkup(response.data.response.grossMarkup);
            setGrossMarkupGraphData(response.data.response.grossMarkup.graphData.length > 0 && response.data.response.grossMarkup.graphData.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));
            setCostOfGood(response.data.response.costOfGood);
            setCostOfGoodGraphData(response.data.response.costOfGood.graphData.length > 0 && response.data.response.costOfGood.graphData.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));

        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <div className="w-100 screenLeftMargin mt-3">

            <div className="row ml-3 mr-3 mobileResponsive">
                <div className="col-12">
                    <h1 className="heading-title">Dashboard</h1>
                </div>
            </div>

            <div className="row ml-3 mr-3 pl-3 pr-3 mt-3 mobileResponsive">

                <div className="col-3">
                    <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                        <div className="box-title">Gross Sales</div>
                        <div className="box-total mt-4">${grossSales && grossSales.todayGrossSales !== null && grossSales.todayGrossSales.toFixed(2)} <span className={grossSales.deviation === "negative" ? "box-down-percentage" : "box-up-percentage"}>{grossSales && grossSales.deviation === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{grossSales && grossSales.checkDeviationAmount !== null && grossSales.checkDeviationAmount.toFixed(2)}%</span></div>
                        <div className="box-chart mt-4">
                            <AreaChart width={230} height={100} data={grossSalesGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="date" height={0} tick={false} />
                                <Tooltip />
                                <Area type="linear" dataKey="grossSales" stroke={grossSales && grossSales.deviation === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={grossSales && grossSales.deviation === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                            </AreaChart>
                        </div>
                    </div>
                </div>

                <div className="col-2">
                    <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                        <div className="box-title">Returns</div>
                        <div className="box-total mt-4">${returns && returns.todayReturnAmount !== null && returns.todayReturnAmount.toFixed(2)} <span className={returns.deviation === "negative" ? "box-down-percentage" : "box-up-percentage"}>{returns.deviation === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{returns && returns.checkDeviationAmount !== null && returns.checkDeviationAmount.toFixed(2)}%</span></div>
                        <div className="box-chart mt-4">
                            <AreaChart width={230} height={100} data={returnsGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="date" height={0} tick={false} />
                                <Tooltip />
                                <Area type="linear" dataKey="return" stroke={returns.deviation === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={returns.deviation === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                            </AreaChart>
                        </div>
                    </div>
                </div>

                <div className="col-2">
                    <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                        <div className="box-title">Discounts</div>
                        <div className="box-total mt-4">$10.00 <span className="box-up-percentage"><ArrowDropUpIcon /> 100%</span></div>
                        <div className="box-chart mt-4">
                            <AreaChart width={230} height={100} data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="name" height={0} tick={false}>
                                </XAxis>
                                <Tooltip />
                                <Area type="linear" dataKey="pv" stroke="#12872C" fillOpacity={1} fill="url(#Pv)" />
                            </AreaChart>
                        </div>
                    </div>
                </div>

                <div className="col-2">
                    <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                        <div className="box-title">Sales Tax</div>
                        <div className="box-total mt-4">${salesTax && salesTax.todaySalesTax !== null && salesTax.todaySalesTax.toFixed(2)} <span className={salesTax.deviation === "negative" ? "box-down-percentage" : "box-up-percentage"}>{salesTax.deviation === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{salesTax && salesTax.checkDeviationAmount !== null && salesTax.checkDeviationAmount.toFixed(2)}%</span></div>
                        <div className="box-chart mt-4">
                            <AreaChart width={230} height={100} data={salesGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="date" height={0} tick={false} />
                                <Tooltip />
                                <Area type="linear" dataKey="sales" stroke={salesTax.deviation === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={salesTax.deviation === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                            </AreaChart>
                        </div>
                    </div>
                </div>

                <div className="col-3">
                    <div className="dsDarkBox pl-3 pr-3 pt-3 pb-3">
                        <div className="box-title">Net Sales</div>
                        <div className="box-total mt-4">${netSales && netSales.todayNetSales !== null && netSales.todayNetSales.toFixed(2)} <span className={netSales.deviation === "negative" ? "box-down-percentage" : "box-up-percentage"}>{netSales.deviation === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{netSales && netSales.checkDeviationAmount !== null && netSales.checkDeviationAmount.toFixed(2)}%</span></div>
                        <div className="box-chart mt-4">
                            <AreaChart width={230} height={100} data={netSalesGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="date" height={0} tick={false} />
                                <Tooltip />
                                <Area type="linear" dataKey="netSales" stroke={netSales.deviation === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={netSales.deviation === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                            </AreaChart>
                        </div>
                    </div>
                </div>

            </div>

            <div className="row ml-3 mr-3 pl-3 pr-3 mt-4 mobileResponsive">
                <div className="col-4">
                    <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                        <div className="box-title">Transactions</div>
                        <div className="box-total mt-4">${transaction && transaction.todayTotalTranscation !== null && transaction.todayTotalTranscation.toFixed(2)} <span className={transaction.deviation === "negative" ? "box-down-percentage" : "box-up-percentage"}>{transaction.deviation === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{transaction && transaction.checkDeviationAmount !== null && transaction.checkDeviationAmount.toFixed(2)}%</span></div>
                        <div className="box-chart mt-4">
                            <AreaChart width={490} height={100} data={transactionGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="date" height={0} tick={false} />
                                <Tooltip />
                                <Area type="linear" dataKey="transcation" stroke={transaction.deviation === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={transaction.deviation === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                            </AreaChart>
                        </div>
                    </div>
                </div>

                <div className="col-4">
                    <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                        <div className="box-title">Average Sale Value</div>
                        <div className="box-total mt-4">${averageSalesValue && averageSalesValue.todayNetSales !== null && averageSalesValue.todayNetSales.toFixed(2)} <span className={averageSalesValue.deviation === "negative" ? "box-down-percentage" : "box-up-percentage"}>{averageSalesValue.deviation === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{averageSalesValue && averageSalesValue.checkDeviationAmount !== null && averageSalesValue.checkDeviationAmount.toFixed(2)}%</span></div>
                        <div className="box-chart mt-4">
                            <AreaChart width={490} height={100} data={averageSalesValueGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="date" height={0} tick={false} />
                                <Tooltip />
                                <Area type="linear" dataKey="averageSaleValue" stroke={averageSalesValue.deviation === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={averageSalesValue.deviation === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                            </AreaChart>
                        </div>
                    </div>
                </div>

                <div className="col-4">
                    <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                        <div className="box-title">Average Item per Sale</div>
                        <div className="box-total mt-4">${averageSalesofItems && averageSalesofItems.todayaverageSales !== null && averageSalesofItems.todayaverageSales.toFixed(2)} <span className={averageSalesofItems.deviation === "negative" ? "box-down-percentage" : "box-up-percentage"}>{averageSalesofItems.deviation === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{averageSalesofItems && averageSalesofItems.checkDeviationAmount !== null && averageSalesofItems.checkDeviationAmount.toFixed(2)}%</span></div>
                        <div className="box-chart mt-4">
                            <AreaChart width={490} height={100} data={averageSalesofItemsGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="date" height={0} tick={false} />
                                <Tooltip />
                                <Area type="linear" dataKey="averageItems" stroke={averageSalesofItems.deviation === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={averageSalesofItems.deviation === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                            </AreaChart>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row ml-3 mr-3 pl-3 pr-3 mt-4 mobileResponsive">
                <div className="col-3">
                    <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                        <div className="box-title">Net COGs</div>
                        <div className="box-total mt-4">${costOfGood && costOfGood.todayGrossMargin !== null && costOfGood.todayGrossMargin.toFixed(2)} <span className={costOfGood.checkDeviationSign === "negative" ? "box-down-percentage" : "box-up-percentage"}>{costOfGood.checkDeviationSign === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{costOfGood && costOfGood.checkDeviationAmount !== null && costOfGood.checkDeviationAmount.toFixed(2)}%</span></div>
                        <div className="box-chart mt-4">
                            <AreaChart width={230} height={100} data={costOfGoodGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="date" height={0} tick={false} />
                                <Tooltip />
                                <Area type="linear" dataKey="costofGood" stroke={costOfGood.checkDeviationSign === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={costOfGood.checkDeviationSign === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                            </AreaChart>
                        </div>
                    </div>
                </div>

                <div className="col-3">
                    <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                        <div className="box-title">Gross Profite</div>
                        <div className="box-total mt-4">${grossprofit && grossprofit.todayGrossProfit !== null && grossprofit.todayGrossProfit.toFixed(2)} <span className={grossprofit.checkDeviationSign === "negative" ? "box-down-percentage" : "box-up-percentage"}>{grossprofit.checkDeviationSign === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{grossprofit && grossprofit.checkDeviationAmount !== null && grossprofit.checkDeviationAmount.toFixed(2)}%</span></div>
                        <div className="box-chart mt-4">
                            <AreaChart width={230} height={100} data={grossprofitGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="date" height={0} tick={false} />
                                <Tooltip />
                                <Area type="linear" dataKey="grossProfit" stroke={grossprofit.checkDeviationSign === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={grossprofit.checkDeviationSign === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                            </AreaChart>
                        </div>
                    </div>
                </div>

                <div className="col-3">
                    <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                        <div className="box-title">Gross Markup</div>
                        <div className="box-total mt-4">${grossMarkup && grossMarkup.todayGrossMargin !== null && grossMarkup.todayGrossMargin.toFixed(2)} <span className={grossMarkup.checkDeviationSign === "negative" ? "box-down-percentage" : "box-up-percentage"}>{grossMarkup.checkDeviationSign === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{grossMarkup && grossMarkup.checkDeviationAmount !== null && grossMarkup.checkDeviationAmount.toFixed(2)}%</span></div>
                        <div className="box-chart mt-4">
                            <AreaChart width={230} height={100} data={grossMarkupGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="date" height={0} tick={false} />
                                <Tooltip />
                                <Area type="linear" dataKey="grossMarkup" stroke={grossMarkup.checkDeviationSign === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={grossMarkup.checkDeviationSign === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                            </AreaChart>
                        </div>
                    </div>
                </div>

                <div className="col-3">
                    <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                        <div className="box-title">Gross Margin</div>
                        <div className="box-total mt-4">${grossMargin && grossMargin.todayGrossMargin !== null && grossMargin.todayGrossMargin.toFixed(2)} <span className={grossMargin.checkDeviationSign === "negative" ? "box-down-percentage" : "box-up-percentage"}>{grossMargin.checkDeviationSign === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{grossMargin && grossMargin.checkDeviationAmount !== null && grossMargin.checkDeviationAmount.toFixed(2)}%</span></div>
                        <div className="box-chart mt-4">
                            <AreaChart width={230} height={100} data={grossMarginGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="date" height={0} tick={false} />
                                <Tooltip />
                                <Area type="linear" dataKey="grossMargin" stroke={grossMargin.checkDeviationSign === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={grossMargin.checkDeviationSign === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                            </AreaChart>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}
export default Home;