import React, { useState, useCallback, useRef, useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import GeneralSection from "../general/general";
import AttributesSection from "../attributes/attributes";
import InventorySection from "../inventory/inventory";
import ShippingSection from "../shipping/shipping";
import {getProductId, setMethod, setProductId, setProductEditableFlag, setInventoryEditedFlag, setShippingEditedFlag, setAddonsEditedFlag} from "../../../../../utils/common";
import Button from "../../../../../components/button/button";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import "./tabs.scss";

export default function TabBar(backHandle, generalDetails) {
    const [pickerOptions, setPickerOptions] = useState([
        { value: "color", label: "Color" },
        { value: "image", label: "Image" },
    ]);
    var panelOpen = window.$panelActive;
    const [currTab, setCurrTab] = React.useState(0);
    const [prevGeneralData, setPrevGeneralData] = useState("");
    const [prevShippingData, setPrevShippingData] = useState("");
    const [prevAttributesData, setPrevAttributesData] = useState("");
    const [prevInventoryData, setPrevInventoryData] = useState("");
    const [invAttributesData, setInvAttributesData] = useState("");
    const [curProductName, setCurProductName] = useState("");
    const [touchEventFlag , setTouchEventFlag] = useState(false);
    const [generalInfoStatus , setGeneralInfoStatus] = useState(false);
    const [shippingInfoStatus , setShippingInfoStatus] = useState(false);
    const [inventoryInfoStatus , setInventoryInfoStatus] = useState(false);
    
    const [listingsDescription, setListingsDescription] = useState(
        "Stock your online store with as many products as you can. Let your perspective customers discover what you have in store"
    );

    const history = useHistory();
    
    const config = {
        readonly: false,
        placeholder: "Product Description",
    };

    const ProductSpecificationsconfig = {
        readonly: false,
        placeholder: "Product Specifications",
    };

    const ProductCancellationPolicy = {
        readonly: false,
        placeholder: "Product Cancellation policy",
    };

    function handleChange(event, newValue) {
        if (newValue === 0) {
            setListingsDescription("Provide detailed information about this product general information to inform customers");
        } else if (newValue === 1) {
            setListingsDescription("Provide detailed information about this product attributes information to inform customers");
        } else if (newValue === 2) {
            setListingsDescription("Provide detailed information about this product inventory information to inform customers");
        } else if (newValue === 3) {
            setListingsDescription("Provide detailed information about this product Shipping & other charges information to inform customers");
        } else {
            setListingsDescription("Provide detailed information about this product to inform customers");
        }
        setCurrTab(newValue);
    }

    function handleChangeIndex(index) {
        setCurrTab(index);
    }
    
    const getGeneralData = (items) => {
        setCurProductName(items.data.product_name)
        setPrevGeneralData(items.data)

        if(items.data.product_name && items.data.brand && items.data.product_model && items.data.category_slug && items.data.subcategory_slug && items.data.description) {
            setGeneralInfoStatus(true)
        }

        if(items.data.shippingFlag) {
            setShippingInfoStatus(true)
        }

        if(items.data.inventoryFlag) {
            setInventoryInfoStatus(true)
        }

        if(items.is_submit === true) {
            setCurrTab(1);
        }
    };

    const getAttributesData = (items) => {
        let prevData = {
            colorFields: items.colorFields,
            fields: items.fields,
            addColor: items.addColor,
            editableFlag: items.editableFlag,
            addOns: items.addOns,
            is_submit: items.is_submit
        }

        setPrevAttributesData(prevData)

        if(items.is_submit === true) {
            if(items.data) {
                setInvAttributesData(items.data)
            }
            
            setCurrTab(2);
        }
    };

    const getInventoryData = (items) => {
        setPrevInventoryData(items)

        if(items.inventoryState.length > 0) {
            let inventoryStatus = false;
            items.inventoryState.forEach((value, index) => {
                if(value.tax_detail && value.upc && value.partner_sku && value.stock_qty && value.low_stock_threshold && value.invoice_cost && value.price && value.compare_at_price && value.img_ids.length > 0) {
                    inventoryStatus = true;
                } else {
                    inventoryStatus = false;
                }
            });
            setInventoryInfoStatus(inventoryStatus)
        }

        if(items.is_added === true) {
            setInvAttributesData("")
        }

        if(items.is_submit === true) {
            setCurrTab(3);
        } 
    };

    const getShippingData = (items) => {
        setPrevShippingData(items)

        if(items.state.product_height && items.state.product_length && items.state.product_weight && items.state.product_width) {
            setShippingInfoStatus(true)
        }

        if(items.is_submit === true) {
            setProductId("");
            setProductEditableFlag('added');
            setInventoryEditedFlag('added');
            setShippingEditedFlag('added');
            setAddonsEditedFlag('added')
            //history.push('/catalogue');
            window.location.reload();
        }
    };

    const gotoBack = async () => {
        setMethod("");
        setProductId("");
        setProductEditableFlag('added');
        setInventoryEditedFlag('added');
        setShippingEditedFlag('added');
        setAddonsEditedFlag('added')
        window.$addProduct = false;
        window.location.reload();
    };
    
    return (
        <React.Fragment>
            <div className="col-12 d-flex mt-3">
                <div  onClick={() => gotoBack()} style={{cursor: "pointer"}}>
                    <ArrowBackIcon /> <a style={{color: "#4472C4"}} className="ml-2">Listings</a>
                </div>
            </div>

            <div className="col-12 d-flex mt-2">
                {getProductId() ?
                    <h1 className="heading-title">{curProductName ? curProductName+' Product' : 'Update Product'}</h1>
                :
                    <h1 className="heading-title">{curProductName ? curProductName+' Product' : 'Add New Product'}</h1>
                }
            </div>

            <div className="col-12 mt-2">
                <div className="muiDescripationInfo">{listingsDescription}</div>
            </div>

            <div className="col-12 muiProductsTab pt-3">
                <Tabs
                    value={currTab}
                    onChange={handleChange}
                    textColor="inherit"
                    variant="standard"
                    >
                    {generalInfoStatus === true ?
                        <Tab label="General" icon={<CheckCircleIcon fontSize="medium" style={{color:"#00FF00"}} />}/>
                    :
                        <Tab label="General" icon={<InfoIcon fontSize="medium" style={{color:"#FF0000"}} />}/>
                    }
                    
                    <Tab label="Attributes" icon={<InfoIcon fontSize="medium" style={{color:"#FF8C00"}}  />} />
                    
                    {inventoryInfoStatus === true ?
                        <Tab label="Inventory" icon={<CheckCircleIcon fontSize="medium" style={{color:"#00FF00"}} />}/>
                    :
                        <Tab label="Inventory" icon={<InfoIcon fontSize="medium" style={{color:"#FF0000"}} />}/>
                    }
                    
                    {shippingInfoStatus === true ?
                        <Tab label="Shipping & Other Charges" icon={<CheckCircleIcon fontSize="medium" style={{color:"#00FF00"}} />}/>
                    :
                        <Tab label="Shipping & Other Charges" icon={<InfoIcon fontSize="medium" style={{color:"#FF0000"}} />}/>
                    }
                </Tabs>
            </div>
            
            <div className="col-12">
                <div style={{ display: "flex" }}>
                    <div className="w-100">
                        <SwipeableViews disabled={true} index={currTab} onChangeIndex={handleChangeIndex} enableMouseEvents={true} >
                            {currTab === 0 &&
                                <div>
                                    <div>
                                        <GeneralSection 
                                            generalData={(value) => getGeneralData(value)}
                                            prevGeneralData={prevGeneralData}
                                            setSwipeableFlag={(val) => setTouchEventFlag(val)} 
                                        />
                                    </div>
                                </div>
                            }

                            {currTab === 1 &&
                                <div>
                                    <AttributesSection 
                                        attributesData={(value) => getAttributesData(value)}
                                        prevAttributesData={prevAttributesData}
                                    />
                                </div>
                            }

                            {currTab === 2 &&
                                <div>
                                    <InventorySection 
                                        inventoryData={getInventoryData}
                                        invAttributesData={invAttributesData}
                                        setInvAttributesData={(val) => setInvAttributesData(val)} 
                                        prevInventoryData={prevInventoryData}
                                        setSwipeableFlag={(val) => setTouchEventFlag(val)} 
                                    />
                                </div>
                            }

                            {currTab === 3 &&
                                <div>
                                    <ShippingSection
                                        shippingData={(value) => getShippingData(value)}
                                        prevShippingData={prevShippingData}
                                    />
                                </div>
                            }
                        </SwipeableViews>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
