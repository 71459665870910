import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import LoadingOverlay from "react-loading-overlay";
import Geosuggest from "react-geosuggest";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import ListSubheader from "@material-ui/core/ListSubheader";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputMask from "react-input-mask";
import SearchIcon from "@material-ui/icons/Search";
import { toast } from "react-toastify";
import { Elements, useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Tooltip from "rc-tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import StoreSeviceTypeList from "../../../components/store/store";
import CardDetails from "./cardDetails";
import Button from "../../../components/button/button";
import { getMethod, postMethodWithToken, postMethodWithStripe, getMethodWithStripe, putMethodWithToken, putMethodWithStripe, deleteMethodWithStripe } from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";
import { getStoreId, setFinancialId } from "../../../utils/common";
import { stripePaymentKey } from "../../../utils/env.json";
import "./financials.scss";
import USA from "../../../assets/images/USA.svg";
import CAN from "../../../assets/images/canada.png";
import canada_cheque from "../../../assets/images/canada_cheque.png";
import US_cheque from "../../../assets/images/US_cheque.png";
import info from "../../../assets/images/info.png";

const checkFinicials = data => {
    const emptyKeys = [
        'first_name',
        'last_name',
        'address',
        'country',
        'state',
        'city',
        'postal_code',
        'contact_number',
        'paymentMode'
    ];

    const emptyKeysErrors = [
        'First Name',
        'Last Name',
        'Address',
        'Country',
        'State',
        'City',
        'Postal/Zip Code',
        'Phone Number',
        'Payment Mode'
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!data[emptyKeys[i]]) {
            return `Please fill in ${emptyKeysErrors[i]}`;
        }
    }

    return '';
};

const checkBankDetails = (data, country) => {
    if (country === 'USA') {
        const emptyKeys = [
            'bank_full_name',
            'bank_account_type',
            'bank_routing_number',
            'bank_account_number',
        ];

        const emptyKeysErrors = [
            'Full name on account',
            'Account Type',
            'Routing Number',
            'Account Number',
        ];

        for (let i = 0; i < emptyKeys.length; i++) {
            if (!data[emptyKeys[i]]) {
                return `Please fill in ${emptyKeysErrors[i]}`;
            }
        }
    } else {
        const emptyKeys = [
            'bank_full_name',
            'bank_account_type',
            'bank_routing_number',
            'bank_institution_number',
            'bank_account_number',
        ];

        const emptyKeysErrors = [
            'Full name on account',
            'Account Type',
            'Routing Number',
            'Institution Number',
            'Account Number',
        ];

        for (let i = 0; i < emptyKeys.length; i++) {
            if (!data[emptyKeys[i]]) {
                return `Please fill in ${emptyKeysErrors[i]}`;
            }
        }
    }

    return '';
};


function Financials() {
    const [stripePromise, setStripePromise] = useState(() =>
        loadStripe(stripePaymentKey)
    );

    const bankTypeList = [
        { value: "individual", name: "Individual" },
        { value: "company", name: "Company" },
    ]

    const geosuggestEl = useRef("");
    const google = window.google;
    const history = useHistory();

    const [loader, setLoader] = useState(false);

    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);

    const [getMode, setGetMode] = useState([]);
    // const [paymentMode, setPaymentMode] = useState("");
    const [stripeCardData, setStripeCardData] = useState();
    const [stripeCustomerId, setStripeCustomerId] = useState("");
    const [bankAccountId, setBankAccountId] = useState("");

    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [stateDataList, setStateDataList] = useState([]);
    const [cityDataList, setCityDataList] = useState([]);
    const [countrySelected, setCountrySelected] = useState("");
    const [stateSelected, setStateSelected] = useState("");
    const [citySelected, setCitySelected] = useState("");
    const [stateName, setStateName] = useState("");
    const [cityName, setCityName] = useState("");

    const [errorAccountNumber, setErrorAccountNumber] = useState(false);
    const [monthlyCheck, setMonthlyCheck] = useState(true);
    const [bankDetailsEditable, setBankDetailsEditable] = useState(false);
    const [cardDetailsEditable, setCardDetailsEditable] = useState(false);
    const [cardCustomerId, setCardCustomerId] = useState("");
    const [bankDetailId, setBankDetailId] = useState("");
    const [bankDetailChange, setBankDetailChange] = useState(false);
    const [cardDetailUpdateCheck, setCardDetailUpdateCheck] = useState(false);
    const [cardDetailUpdateToken, setCardDetailUpdateToken] = useState(false);
    const [isBoardingStatus, setIsBoardingStatus] = useState(false);

    const [state, setState] = useState({
        first_name: "",
        last_name: "",
        address: "",
        unit: "",
        country: "",
        state: "",
        city: "",
        postal_code: "",
        contact_number: "",
        website: "",
        paymentMode: ""
    });

    const [bankState, setBankState] = useState({
        bank_country: state.country,
        bank_full_name: '',
        bank_account_type: '',
        bank_routing_number: '',
        bank_institution_number: '',
        bank_account_number: '',
    });

    const [cardState, setCardState] = useState({
        card_number: '',
        card_month: '',
        card_year: '',
        card_cvv: '',
        expiry: '',
    });

    const getExpiryMonth = (month) => {
        let Length = String(month);
        if (Length.length == 1) {
            return "0" + month;
        } else {
            return month;
        }
    };

    const getExpiryYear = (year) => {
        let Year = String(year);
        return Year.substring(2);
    };

    useEffect(() => {
        onBoardingStatus();
        getCountryList();
        getPaymentMethodInfo();
        getFinancialDetails();
    }, []);

    const onBoardingStatus = async () => {
        await getMethod(urls.verifyOnboardStatus).then((response) => {
            if (response.status === 200) {
                setIsBoardingStatus(response.data.status);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const getPaymentMethodInfo = async () => {
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200) {
                setStripeCustomerId(response.data.customer_stripe_ID);
            }
        }).catch((error) => {
            console.log("error", error);
        });
    };

    const getCountryList = async () => {
        await getMethod(urls.getCountry).then((response) => {
            if (response.status === 200) {
                setCountryList(response.data);
            }
        }).catch((error) => {
            console.log("error", error);
        });
    };

    const getStateList = async (value) => {
        await getMethod(urls.getStates + '?country_name=' + value).then((response) => {
            if (response.status === 200) {
                setStateList(response.data.response[0].data.states);
                setStateDataList(response.data.response[0].data.states);
            }
        }).catch((error) => {
            console.log("error", error);
        });
    };

    const getCityList = async (value) => {
        await getMethod(urls.getStates + '?country_name=' + state.country + '&state_id=' + value).then((response) => {
            if (response.status === 200) {
                setCityList(response.data.response[0].data.cities);
                setCityDataList(response.data.response[0].data.cities);
            }
        }).catch((error) => {
            console.log("error", error);
        });
    };

    const getCityListWithName = async (countryId, stateId, name) => {
        await getMethod(urls.getStates + '?country_name=' + countryId + '&state_id=' + stateId).then((response) => {
            if (response.status === 200) {
                let results = response.data.response[0].data.cities;
                setCityList(results);
                setCityDataList(results);

                if (name && results.length > 0) {
                    let indexCityID = '';

                    results.map((item) => {
                        if (item.cityName === name) {
                            indexCityID = item.id;
                        }
                    });

                    if (indexCityID) {
                        setCitySelected(indexCityID)

                        setState((prevState) => ({
                            ...prevState,
                            city: indexCityID,
                        }));
                    }
                }

            }
        }).catch((error) => {
            console.log("error", error);
        });
    }

    const getFinancialDetails = async () => {
        let storeId = getStoreId();

        await getMethod(urls.aboutStore + storeId + "/financialinfo/").then(async (finicialResponse) => {
            if (finicialResponse.status === 200) {
                setCardCustomerId(finicialResponse.data.customerCardId);
                if (Object.keys(finicialResponse.data).length === 0) {
                    await getMethod(urls.aboutStore + storeId).then((aboutResponse) => {
                        let countryValue = aboutResponse.data.business_address.country;
                        let stateValue = aboutResponse.data.business_address.state;
                        let cityValue = aboutResponse.data.business_address.city;
                        let state_id = '';

                        setCountrySelected(countryValue)

                        if (countryValue) {
                            getMethod(urls.getStates + '?country_name=' + countryValue).then((response) => {
                                if (response.status === 200) {
                                    let results = response.data.response[0].data.states

                                    setStateList(results);
                                    setStateDataList(results);

                                    if (results.length > 0 && stateValue) {
                                        let indexStateID = '';

                                        results.map((item) => {
                                            if (item.stateName === stateValue) {
                                                indexStateID = item.id;
                                            }
                                        });

                                        if (indexStateID) {
                                            state_id = indexStateID;
                                            setStateSelected(indexStateID);

                                            setState((prevState) => ({
                                                ...prevState,
                                                state: indexStateID,
                                            }));

                                            if (countryValue && state_id) {
                                                getCityListWithName(countryValue, state_id, cityValue)
                                            }
                                        }
                                    }
                                }
                            }).catch((error) => {
                                console.log("error", error);
                            });
                        }

                        setState((prevState) => ({
                            ...prevState,
                            ["first_name"]: aboutResponse.data.business_address.first_name,
                            ["last_name"]: aboutResponse.data.business_address.last_name,
                            ["address"]: aboutResponse.data.business_address.address,
                            ["unit"]: aboutResponse.data.business_address.unit,
                            ["country"]: aboutResponse.data.business_address.country,
                            ["postal_code"]: aboutResponse.data.business_address.postal_code,
                            ["contact_number"]: aboutResponse.data.business_address.contact_number,
                            ["website"]: aboutResponse.data.business_address.website,
                        }));
                    });
                } else {
                    let countryValue = finicialResponse.data.billing_address.country;
                    let stateValue = finicialResponse.data.billing_address.state;
                    let cityValue = finicialResponse.data.billing_address.city;
                    let state_id = '';

                    setGetMode([finicialResponse.data.payment_mode]);
                    // setPaymentMode(finicialResponse.data.payment_mode.slug)
                    setCountrySelected(countryValue)
                    setBankAccountId(finicialResponse.data.bankAccountID)

                    if (finicialResponse.data.bankAccountID) {
                        getMethodWithStripe(urls.postStripeCustomers + '/' + finicialResponse.data.customerStripeID + '/sources/' + finicialResponse.data.bankAccountID).then((response) => {
                            setBankState((prevState) => ({
                                ...prevState,
                                ["bank_full_name"]: response.data.account_holder_name,
                                ["bank_account_type"]: response.data.account_holder_type,
                                ["bank_routing_number"]: response.data.routing_number,
                                ["bank_institution_number"]: response.data.metadata.institution_number,
                                ["bank_account_number"]: '********' + response.data.last4,
                            }));
                        }).catch((error) => {
                            console.log("error", error);
                        });

                        setBankDetailsEditable(true)

                    } else {
                        setBankState((prevState) => ({
                            ...prevState,
                            ["bank_full_name"]: finicialResponse.data.canada_bank_detail.full_name,
                            ["bank_account_type"]: finicialResponse.data.canada_bank_detail.account_type,
                            ["bank_routing_number"]: finicialResponse.data.canada_bank_detail.routing_number,
                            ["bank_institution_number"]: finicialResponse.data.canada_bank_detail.institution_number,
                            ["bank_account_number"]: '********' + finicialResponse.data.canada_bank_detail.account_number.substr(-4),
                        }));
                        setBankDetailsEditable(true);
                        setBankDetailId(finicialResponse.data.canada_bank_detail.id);
                    }


                    if (finicialResponse.data.customerCardId) {
                        getMethodWithStripe(urls.postStripeCustomers + '/' + finicialResponse.data.customerStripeID + '/sources/' + finicialResponse.data.customerCardId).then((response) => {
                            setCardState((prevState) => ({
                                ...prevState,
                                ["card_number"]: '**** **** **** ' + response.data.last4,
                                ["card_month"]: response.data.exp_month,
                                ["card_year"]: response.data.exp_year,
                                ['expiry']: getExpiryMonth(response.data.exp_month) + '/' + getExpiryYear(response.data.exp_year),
                                ["card_cvv"]: '***',
                            }));
                        }).catch((error) => {
                            console.log("error", error);
                        });

                        setCardDetailsEditable(true)
                    }

                    if (countryValue) {
                        getMethod(urls.getStates + '?country_name=' + countryValue).then((response) => {
                            if (response.status === 200) {
                                let results = response.data.response[0].data.states

                                setStateList(results);
                                setStateDataList(results);

                                if (results.length > 0 && stateValue) {
                                    let indexStateID = '';

                                    results.map((item) => {
                                        if (item.stateName === stateValue) {
                                            indexStateID = item.id;
                                        }
                                    });

                                    if (indexStateID) {
                                        state_id = indexStateID;
                                        setStateSelected(indexStateID);

                                        setState((prevState) => ({
                                            ...prevState,
                                            state: indexStateID,
                                        }));

                                        if (countryValue && state_id) {
                                            getCityListWithName(countryValue, state_id, cityValue)
                                        }
                                    }
                                }
                            }
                        }).catch((error) => {
                            console.log("error", error);
                        });
                    }

                    setState((prevState) => ({
                        ...prevState,
                        ["first_name"]: finicialResponse.data.billing_address.first_name,
                        ["last_name"]: finicialResponse.data.billing_address.last_name,
                        ["address"]: finicialResponse.data.billing_address.address,
                        ["unit"]: finicialResponse.data.billing_address.unit,
                        ["country"]: finicialResponse.data.billing_address.country,
                        ["postal_code"]: finicialResponse.data.billing_address.postcode,
                        ["contact_number"]: finicialResponse.data.billing_address.contact_number,
                        ["website"]: finicialResponse.data.billing_address.website,
                        ["paymentMode"]: finicialResponse.data.payment_mode.slug,
                        ["id"]: finicialResponse.data.billing_address.id
                    }));
                }
            } else {
                toast.error(finicialResponse.error)
            }
        }).catch((error) => {
            console.log("error", error);
        });
    }

    const sendDataToFinancial = (item) => {
        setGetMode([item]);

        setState((prevState) => ({
            ...prevState,
            ["paymentMode"]: item.slug,
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleBankChange = (e) => {
        const { name, value } = e.target;
        setBankState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setBankDetailChange(true);
    };

    const handleCountryChange = (event) => {
        setCountrySelected(event.target.value);
        setState({ ...state, [event.target.name]: event.target.value });

        setState((prevState) => ({
            ...prevState,
            state: '',
            city: '',
            postal_code: '',
        }));
        setStateSelected('')
        setCitySelected('');

        getStateList(event.target.value);
    };

    const handleStateChange = (event) => {
        setStateSelected(event.target.value);
        setState({ ...state, [event.target.name]: event.target.value });

        setState((prevState) => ({
            ...prevState,
            city: '',
        }));

        setCitySelected('');

        getCityList(event.target.value);
    };

    const handleCityChange = (event) => {
        setCitySelected(event.target.value);
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const checkAddress = (e) => {
        setMonthlyCheck(!monthlyCheck);
    };

    const onSuggestSelect = (suggest) => {

        if (suggest) {
            setCountrySelected('');
            setStateSelected('');
            setCitySelected('');

            setState((prevState) => ({
                ...prevState,
                postal_code: "",
                country: "",
                state: "",
                city: ""
            }));

            const components = suggest.gmaps.address_components;

            let state_id = '';
            let streetNumberValue = '';
            let routeValue = '';
            let cityValue = '';
            let postCodeValue = '';
            let stateValue = '';
            let stateAddressValue = '';
            let countryValue = '';
            let addressValue = '';

            for (let key of components) {
                if (key.types[0] === "locality" || key.types[1] === "sublocality") {
                    setCityName(key.long_name)
                    cityValue = key.long_name;
                }

                if (key.types[0] === "administrative_area_level_1") {
                    stateValue = key.long_name;
                    stateAddressValue = key.short_name
                    setStateName(key.long_name)
                }

                if (key.types[0] === "country") {
                    if (key.short_name === 'US') {
                        setCountrySelected('USA');
                        countryValue = 'USA';

                        setState((prevState) => ({
                            ...prevState,
                            country: 'USA',
                        }));
                    } else if (key.short_name === 'CA') {
                        setCountrySelected('CAN');
                        countryValue = 'CAN';

                        setState((prevState) => ({
                            ...prevState,
                            country: 'CAN',
                        }));
                    } else {
                        setCountrySelected(key.short_name)
                        countryValue = key.short_name;

                        setState((prevState) => ({
                            ...prevState,
                            country: key.short_name,
                        }));
                    }
                }

                if (key.types[0] === "postal_code") {
                    postCodeValue = key.long_name;
                    setState((prevState) => ({
                        ...prevState,
                        postal_code: key.long_name,
                    }));
                }
                if (key.types[0] === "route") {
                    routeValue = key.short_name;
                }

                if (key.types[0] === "street_number") {
                    streetNumberValue = key.long_name;
                }
            }

            if (stateValue && countryValue) {
                getMethod(urls.getStates + '?country_name=' + countryValue).then((response) => {
                    if (response.status === 200) {
                        let results = response.data.response[0].data.states

                        setStateList(results);
                        setStateDataList(results);

                        if (results.length > 0) {
                            let indexStateID = '';

                            results.map((item) => {
                                if (item.stateName === stateValue) {
                                    indexStateID = item.id;
                                }
                            });

                            if (indexStateID) {
                                state_id = indexStateID;
                                setStateSelected(indexStateID);

                                setState((prevState) => ({
                                    ...prevState,
                                    state: indexStateID,
                                }));

                                if (countryValue && state_id) {
                                    getCityListWithName(countryValue, state_id, cityValue)
                                }
                            }
                        }
                    }
                });
            }

            if (streetNumberValue !== "") {
                addressValue += streetNumberValue;
            }

            if (routeValue !== "" && streetNumberValue !== "") {
                addressValue += ", " + routeValue;
            } else if (routeValue !== "") {
                addressValue += routeValue;
            }

            /*if(cityValue !== "") {
                addressValue += ", " + cityValue;
            }

            if(stateValue !== "") {
                addressValue += ", " + stateAddressValue;
            }

            if(countryValue !== "") {
                addressValue += ", " + countryValue;
            }

            if(postCodeValue !== "") {
                addressValue += ", " + postCodeValue;
            }*/

            let latitude = suggest.location.lat;
            let longitude = suggest.location.lng;

            setState((prevState) => ({
                ...prevState,
                ["address"]: addressValue,
            }));

            geosuggestEl.current.update(addressValue);
        }
    };

    const stateFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = stateDataList.filter((item) => {
                return item.stateName.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setStateList(results);
        } else {
            setStateList(stateDataList);
        }
    };

    const cityFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = cityDataList.filter((item) => {
                return item.cityName.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setCityList(results);
        } else {
            setCityList(cityDataList);
        }
    };

    const JSON_to_URLEncoded = (element, key, list) => {
        var list = list || [];
        if (typeof (element) == 'object') {
            for (var idx in element) {
                JSON_to_URLEncoded(element[idx], key ? key + '[' + idx + ']' : idx, list);
            }
        } else {
            list.push(key + '=' + encodeURIComponent(element));
        }
        return list.join('&');
    }

    const submitHandle = async (cardToken) => {
        if (cardToken) {
            const bankError = checkBankDetails(bankState, state.country);

            if (bankError) {
                toast.error(bankError)
            } else {
                const error = checkFinicials(state);

                if (error) {
                    toast.error(error);
                } else {

                    let formvalue = {
                        bank_account: {
                            country: state.country === "USA" ? 'US' : 'CA',
                            currency: state.country === "USA" ? 'USD' : 'USD',
                            account_holder_name: bankState.bank_full_name,
                            routing_number: bankState.bank_routing_number,
                            account_number: bankState.bank_account_number,
                            account_holder_type: bankState.bank_account_type,
                        },
                    }

                    if (state.country === "USA") {

                        await postMethodWithStripe(urls.postStripeToken, JSON_to_URLEncoded(formvalue)).then(async (response) => {
                            if (response.status === 200) {
                                let bankTokenId = response.data.id;

                                let formDataStrip = {
                                    source: bankTokenId,
                                    metadata: {
                                        institution_number: bankState.bank_institution_number
                                    }
                                }

                                await postMethodWithStripe(urls.postStripeCustomers + '/' + stripeCustomerId + '/sources', JSON_to_URLEncoded(formDataStrip)).then(async (response) => {
                                    if (response.status === 200) {
                                        let bankStripeId = response.data.id;
                                        setBankAccountId(bankStripeId);
                                        let formBody = {
                                            store_id: getStoreId(),
                                            payment_mode_slug: state.paymentMode,
                                            "country_flag": "USA",
                                            billing_info: state,
                                            bank_details: {
                                                bankStripeId: bankStripeId,
                                            },
                                            card_detail: {
                                                card_token: cardToken,
                                            }
                                        }

                                        await postMethodWithToken(urls.financial, formBody).then((response) => {
                                            if (response.status === 200) {
                                                setLoader(false);
                                                setFinancialId(response.data.financial_id);
                                                toast.success(response.data.success);
                                                history.push("/plans");
                                            } else {
                                                setLoader(false);
                                                toast.error(response.data.error);
                                            }
                                        }).catch((error) => {
                                            console.log("error", error);
                                        });

                                    } else {
                                        toast.error(response.data.error.message);
                                    }
                                }).catch((error) => {
                                    setLoader(false);
                                    console.log("error", error);
                                });
                            } else {
                                toast.error(response.data.error.message);
                            }
                        }).catch((error) => {
                            setLoader(false);
                            console.log("error", error);
                        });

                    } else {

                        let formBody = {
                            store_id: getStoreId(),
                            payment_mode_slug: state.paymentMode,
                            "country_flag": "CAN",
                            billing_info: state,
                            bank_details: {
                                "bank_account_country": "CAN",
                                "bank_account_number": btoa(bankState.bank_account_number),
                                "bank_institution_number": bankState.bank_institution_number,
                                "bank_account_type": bankState.bank_account_type,
                                "bank_routing_number": bankState.bank_routing_number,
                                "bank_full_name": bankState.bank_full_name
                            },
                            card_detail: {
                                card_token: cardToken,
                            }
                        }

                        await postMethodWithToken(urls.financial, formBody).then((response) => {
                            if (response.status === 200) {
                                setLoader(false);
                                setFinancialId(response.data.financial_id);
                                toast.success(response.data.success);
                                history.push("/plans");
                            } else {
                                setLoader(false);
                                toast.error(response.data.error);
                            }
                        }).catch((error) => {
                            console.log("error", error);
                        });
                    }
                }
            }
        } else {
            setLoader(false);
        }
    }

    const handleUpdateFInancials = async (cardToken) => {
        if (cardToken) {
            const bankError = checkBankDetails(bankState, state.country);
            if (bankError) {
                toast.error(bankError)
            } else {
                const error = checkFinicials(state);
                if (error) {
                    toast.error(error);
                } else {
                    let formvalue = {
                        bank_account: {
                            country: state.country === "USA" ? 'US' : 'CA',
                            currency: state.country === "USA" ? 'USD' : 'USD',
                            account_holder_name: bankState.bank_full_name,
                            routing_number: bankState.bank_routing_number,
                            account_number: bankState.bank_account_number,
                            account_holder_type: bankState.bank_account_type,
                        },
                    }

                    if (state.country === "USA") {

                        if (bankDetailChange) {

                            await postMethodWithStripe(urls.postStripeToken, JSON_to_URLEncoded(formvalue)).then(async (response) => {
                                if (response.status === 200) {
                                    let bankTokenId = response.data.id;

                                    let formDataStrip = {
                                        source: bankTokenId,
                                        metadata: {
                                            institution_number: bankState.bank_institution_number
                                        }
                                    }

                                    await postMethodWithStripe(urls.postStripeCustomers + '/' + stripeCustomerId + '/sources', JSON_to_URLEncoded(formDataStrip)).then(async (response) => {
                                        if (response.status === 200) {
                                            let bankStripeId = response.data.id;
                                            setBankAccountId(bankStripeId);
                                            let formBody = {
                                                store_id: getStoreId(),
                                                payment_mode_slug: state.paymentMode,
                                                billing_info: state,
                                                "country_flag": "USA",
                                                bank_details: {
                                                    bankStripeId: bankStripeId,
                                                },
                                                card_detail: {
                                                    card_token: "",
                                                }
                                            }

                                            await putMethodWithToken(urls.updateFinacialsDetails + getStoreId() + "/financialinfo/", formBody).then((response) => {
                                                if (response.status === 200) {
                                                    setLoader(false);
                                                    setFinancialId(response.data.financial_id);
                                                    toast.success(response.data.message);
                                                } else {
                                                    setLoader(false);
                                                    toast.error(response.data.message);
                                                }
                                            }).catch((error) => {
                                                console.log("error", error);
                                            });

                                        } else {
                                            toast.error(response.data.error.message);
                                        }
                                    }).catch((error) => {
                                        setLoader(false);
                                        console.log("error", error);
                                    });
                                } else {
                                    toast.error(response.data.error.message);
                                }
                            }).catch((error) => {
                                setLoader(false);
                                console.log("error", error);
                            });

                        } else {
                            let formBody = {
                                store_id: getStoreId(),
                                payment_mode_slug: state.paymentMode,
                                billing_info: state,
                                "country_flag": "USA",
                                bank_details: {
                                    bankStripeId: "",
                                },
                                card_detail: {
                                    card_token: cardDetailUpdateToken ? cardToken : "",
                                }
                            }

                            await putMethodWithToken(urls.updateFinacialsDetails + getStoreId() + "/financialinfo/", formBody).then((response) => {
                                if (response.status === 200) {
                                    setLoader(false);
                                    setFinancialId(response.data.financial_id);
                                    toast.success(response.data.message);
                                } else {
                                    setLoader(false);
                                    toast.error(response.data.error);
                                }
                            }).catch((error) => {
                                console.log("error", error);
                            });
                        }

                    } else {

                        let formBody = {
                            store_id: getStoreId(),
                            payment_mode_slug: state.paymentMode,
                            billing_info: state,
                            "country_flag": "CAN",
                            bank_details: {
                                "bank_account_country": "CAN",
                                "bank_account_number": btoa(bankState.bank_account_number),
                                "bank_institution_number": bankState.bank_institution_number,
                                "bank_account_type": bankState.bank_account_type,
                                "bank_routing_number": bankState.bank_routing_number,
                                "bank_full_name": bankState.bank_full_name,
                                "id": bankDetailId
                            },
                            card_detail: {
                                card_token: cardDetailUpdateToken ? cardToken : "",
                            }
                        }

                        await putMethodWithToken(urls.updateFinacialsDetails + getStoreId() + "/financialinfo/", formBody).then((response) => {
                            if (response.status === 200) {
                                setLoader(false);
                                setFinancialId(response.data.financial_id);
                                toast.success(response.data.message);
                            } else {
                                setLoader(false);
                                toast.error(response.data.error);
                            }
                        }).catch((error) => {
                            console.log("error", error);
                        });
                    }
                }
            }
        } else {
            setLoader(false);
        }
    }

    const handleCardDetailChange = (e) => {
        setCardState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }))
    }

    const CardHandleDetailUpdateCheck = (e) => {
        setCardDetailUpdateCheck(!cardDetailUpdateCheck);
        if (cardDetailUpdateCheck) {
            setCardDetailsEditable(true);
        } else {
            setCardDetailsEditable(false);
        }
        setCardDetailUpdateToken(true);
    }

    const handleUpdateCreateToken = async (val) => {
        await deleteMethodWithStripe(urls.postStripeCustomers + '/' + stripeCustomerId + '/sources' + '/' + cardCustomerId).then(async (response) => {
            if (response.status === 200) {
                handleUpdateFInancials(val);
            } else {
                toast.error(response.data.error.message);
            }
        })
    }

    const handleRedirect = () => {
        if(!isBoardingStatus) {
            history.push("/plans");
        } else {
            history.push("/settings/plans");
        }
    }

    return (
        <LoadingOverlay active={loader} spinner text="Processing...">
            <div className="screenLeftMargin mt-3">
                <div className="row ml-3 mr-3 mobileResponsive">
                    <div className="col-12">
                        <h1 className="heading-title">Financial Information</h1>
                        <div className="muiDescripationInfo">
                            Shopez allows buyers to choose from variety of payment options
                            protected by SSL encryption and security.
                        </div>
                    </div>
                </div>

                <div className="row ml-3 mr-3 mobileResponsive">
                    <div className="col-lg-6 col-md-12">
                        <div className="row">
                            <div className="col-12">
                                <h5 className="h5-label mt-3">Accepted mode of payment</h5>

                                <div className="muiDescripationInfo">
                                    Based on the store preference, Shopez customers will have an
                                    option to pay online and/or in-store, refer to{" "}
                                    <span className="blueText" style={{cursor:"pointer"}} onClick={() => handleRedirect()}>Shopez Payment Processing.</span>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-12">
                                <StoreSeviceTypeList
                                    sendDataToFinancial={(item) => {
                                        sendDataToFinancial(item);
                                    }}
                                    activeStore={getMode}
                                    type="payment"
                                    isFullDataShow="no"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <h5 className="h5-label mt-3">Payment Information</h5>
                                <div className="muiDescripationInfo">
                                    Your bank account to deposit funds from the sale proceeds (if
                                    applicable). All your banking information is protected via SSL
                                    encryption and security.
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-12">
                                <h6 className="h6-label mb-2">Bank Details</h6>
                            </div>
                        </div>

                        <ValidatorForm>
                            <div className="row">
                                <div className="col-12">
                                    <div className="bank">
                                        <img src={state.country === 'USA' ? USA : CAN} />
                                        {state.country}
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-12">
                                    <TextValidator
                                        required
                                        errorMessages={[
                                            "Please enter full name on account"
                                        ]}
                                        validators={[
                                            "required",
                                        ]}
                                        id="standard required"
                                        onChange={handleBankChange}
                                        name="bank_full_name"
                                        label="Full name on account"
                                        variant="outlined"
                                        value={bankState.bank_full_name}
                                    />
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-12">
                                    <FormControl variant="outlined">
                                        <InputLabel required id="bank-select">
                                            Account Type
                                        </InputLabel>
                                        <Select
                                            labelId="bank-select"
                                            id="bank-select-dropdown"
                                            value={bankState.bank_account_type}
                                            onChange={handleBankChange}
                                            label="Account Type"
                                            name="bank_account_type"
                                        >
                                            {bankTypeList.map((item) => {
                                                return (
                                                    <MenuItem value={item.value}> {item.name} </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row mt-3">
                                {state.country === 'USA' ?
                                    <div className="col-12">
                                        <InputMask
                                            mask="999999999"
                                            onChange={handleBankChange}
                                            maskChar="_"
                                            value={bankState.bank_routing_number}
                                        >
                                            {() => (
                                                <TextField
                                                    required
                                                    errorMessages={[
                                                        "Routing number must be atleast 9 characters long!",
                                                        "Routing number exceeds maximum limit 9",
                                                        "please enter routing number"
                                                    ]}
                                                    validators={[
                                                        "minStringLength:9",
                                                        "maxStringLength:9",
                                                        "required",
                                                    ]}
                                                    onChange={handleBankChange}
                                                    name="bank_routing_number"
                                                    label="Routing No."
                                                    variant="outlined"
                                                    value={bankState.bank_routing_number}
                                                />
                                            )}
                                        </InputMask>
                                    </div>
                                    :
                                    <>
                                        <div className="col-6">
                                            <InputMask
                                                mask="999"
                                                onChange={handleBankChange}
                                                maskChar="_"
                                                value={bankState.bank_institution_number}
                                            >
                                                {() => (
                                                    <TextField
                                                        required
                                                        errorMessages={[
                                                            "Institution number must be atleast 3 characters long!",
                                                            "Institution number exceeds maximum limit 3",
                                                            "Please enter institution number"
                                                        ]}
                                                        validators={[
                                                            "minStringLength:3",
                                                            "maxStringLength:3",
                                                            "required",
                                                        ]}
                                                        name="bank_institution_number"
                                                        onChange={handleBankChange}
                                                        label="Institution No."
                                                        variant="outlined"
                                                        value={bankState.bank_institution_number}
                                                    />
                                                )}
                                            </InputMask>
                                        </div>

                                        <div className="col-6">
                                            <InputMask
                                                mask="99999"
                                                onChange={handleBankChange}
                                                disabled={false}
                                                maskChar="_"
                                                value={bankState.bank_routing_number}
                                            >
                                                {() => (
                                                    <TextField
                                                        required
                                                        errorMessages={[
                                                            "Transit number must be atleast 5 characters long!",
                                                            "Transit number exceeds maximum limit 5",
                                                            "Please enter transit number"
                                                        ]}
                                                        validators={[
                                                            "minStringLength:5",
                                                            "maxStringLength:5",
                                                            "required",
                                                        ]}
                                                        name="bank_routing_number"
                                                        onChange={handleBankChange}
                                                        label="Transit No."
                                                        variant="outlined"
                                                        value={bankState.bank_routing_number}
                                                    />
                                                )}
                                            </InputMask>
                                        </div>
                                    </>
                                }
                            </div>

                            <div className="row mt-3">
                                <div className="col-12 d-flex">
                                    {bankDetailsEditable === true ?
                                        <TextField
                                            required
                                            errorMessages={[
                                                "Account number must be atleast 12 characters long!",
                                                "Account number exceeds maximum limit 12",
                                                "please enter account number"
                                            ]}
                                            validators={[
                                                "minStringLength:12",
                                                "maxStringLength:17",
                                                "required",
                                            ]}
                                            inputProps={{ maxLength: 12 }}
                                            onChange={handleBankChange}
                                            name="bank_account_number"
                                            label="Account No."
                                            variant="outlined"
                                            value={bankState.bank_account_number}
                                        />
                                        :
                                        <InputMask
                                            mask={"999999999999"}
                                            onChange={handleBankChange}
                                            maskChar="_"
                                            value={bankState.bank_account_number}
                                        >
                                            {() => (
                                                <TextField
                                                    required
                                                    errorMessages={[
                                                        "Account number must be atleast 12 characters long!",
                                                        "Account number exceeds maximum limit 12",
                                                        "please enter account number"
                                                    ]}
                                                    validators={[
                                                        "minStringLength:12",
                                                        "maxStringLength:17",
                                                        "required",
                                                    ]}
                                                    onChange={handleBankChange}
                                                    name="bank_account_number"
                                                    label="Account No."
                                                    variant="outlined"
                                                    value={bankState.bank_account_number}
                                                />
                                            )}
                                        </InputMask>
                                    }

                                    <Tooltip
                                        placement="top"
                                        trigger={["hover"]}
                                        destroyTooltipOnHide={true}
                                        overlay={
                                            state.country === "USA" ? (
                                                <img src={US_cheque} alt="" />
                                            ) : (
                                                <img src={canada_cheque} alt="" />
                                            )
                                        }
                                    >
                                        <div className="accountInfo br-right-top-bottom">
                                            <a href="#">
                                                <img src={info} alt="" />
                                            </a>
                                        </div>
                                    </Tooltip>

                                    {state.country === "USA" && errorAccountNumber && state.account_number.length < 6 ? (
                                        <div style={{ color: "red", fontSize: "11px", fontFamily: "Roboto-Regular" }}>
                                            Account number must be atleast 6 digits long!
                                        </div>
                                    ) : state.country === "USA" && errorAccountNumber && state.account_number.length > 17 ? (
                                        <div style={{ color: "red", fontSize: "11px", fontFamily: "Roboto-Regular" }}>
                                            Account number exceeds maximum limit 17
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-12">
                                    <Checkbox
                                        defaultChecked
                                        checked={monthlyCheck}
                                        onChange={(e) => checkAddress(e)}
                                        color="primary"
                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                    />
                                    <span className="muiDescripationInfoCheckbox">I would rather like to receive a monthly check sent to my default address</span>
                                </div>
                            </div>
                        </ValidatorForm>

                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="row">
                            <div className="col-12">
                                <h5 className="h5-label mt-3">Billing Information</h5>
                            </div>
                            <div className="col-12">
                                <div className="muiDescripationInfo">
                                    Your credit/debit card will not be charged to launch and manage
                                    your store. Only <span className="blueText">Premium Services</span> will be charged
                                    to this card after your consent.
                                </div>
                            </div>
                        </div>

                        <ValidatorForm>
                            <h6 className="h6-label mt-3 mb-2">Billing Address</h6>

                            <div className="row d-flex">
                                <div className="col-6">
                                    <TextValidator
                                        required
                                        errorMessages={[
                                            "Please enter first name"
                                        ]}
                                        validators={[
                                            "required",
                                        ]}
                                        id="filled-required"
                                        name="first_name"
                                        onChange={handleChange}
                                        label="First Name"
                                        variant="outlined"
                                        value={state.first_name}
                                    />
                                </div>
                                <div className="col-6">
                                    <TextValidator
                                        required
                                        errorMessages={[
                                            "Please enter last name"
                                        ]}
                                        validators={[
                                            "required",
                                        ]}
                                        name="last_name"
                                        onChange={handleChange}
                                        label="Last Name"
                                        variant="outlined"
                                        value={state.last_name}
                                    />
                                </div>
                            </div>

                            <div className="row d-flex mt-3">
                                <div className="col-8">
                                    <div>
                                        <Geosuggest
                                            id="financial"
                                            ref={geosuggestEl}
                                            required
                                            placeholder="Address"
                                            name="address"
                                            fields={["address_components", "geometry"]}
                                            types={["address"]}
                                            country={countrySelected === "USA" ? ["US"] : ["CA"]}
                                            initialValue={state.address}
                                            onSuggestSelect={onSuggestSelect}
                                            location={new google.maps.LatLng(lat, lng)}
                                            radius="100"
                                        />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <TextValidator
                                        type="number"
                                        name="unit"
                                        onChange={handleChange}
                                        label="Unit"
                                        variant="outlined"
                                        value={state.unit}
                                    />
                                </div>
                            </div>

                            <div className="row d-flex mt-3">
                                <div className="col-6">
                                    <FormControl variant="outlined" >
                                        <InputLabel required id="country-select">
                                            Country
                                        </InputLabel>
                                        <Select
                                            labelId="country-select"
                                            id="country-select-dropdown"
                                            value={state.country}
                                            onChange={handleCountryChange}
                                            label="Country"
                                            name="country"
                                            errorMessages={["Please select country"]}
                                            validators={[
                                                "required",
                                            ]}
                                        >
                                            {countryList.map((item) => {
                                                return (
                                                    <MenuItem value={item.iso_3166_1_a3}> {item.printable_name} </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-6">
                                    <FormControl variant="outlined" >
                                        <InputLabel required id="country-select">
                                            {countrySelected === 'USA' ? 'State' : 'Province'}
                                        </InputLabel>
                                        <Select
                                            MenuProps={{ autoFocus: false }}
                                            labelId="country-select"
                                            id="country-select-dropdown"
                                            value={state.state}
                                            defaultValue={state.state}
                                            label={countrySelected === 'USA' ? 'State' : 'Province'}
                                            name="state"
                                            errorMessages={["Please select state"]}
                                            validators={[
                                                "required",
                                            ]}
                                            onChange={(e) => handleStateChange(e)}
                                            onClose={() => setStateList(stateDataList)}
                                        >
                                            <ListSubheader>
                                                <TextField
                                                    size="small"
                                                    autoFocus
                                                    placeholder="Type to search..."
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={stateFilter}
                                                    onKeyDown={(e) => {
                                                        if (e.key !== "Escape") {
                                                            e.stopPropagation();
                                                        }
                                                    }}
                                                />
                                            </ListSubheader>
                                            {stateList.length > 0 && stateList.map((item) => {
                                                return (
                                                    <MenuItem value={item.id}> {item.stateName} </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row d-flex mt-3">
                                <div className="col-6">
                                    <FormControl variant="outlined" >
                                        <InputLabel required id="country-select">
                                            City
                                        </InputLabel>
                                        <Select
                                            MenuProps={{ autoFocus: false }}
                                            labelId="country-select"
                                            id="country-select-dropdown"
                                            value={state.city}
                                            defaultValue={state.city}
                                            label="City"
                                            name="city"
                                            errorMessages={["Please select city"]}
                                            validators={[
                                                "required",
                                            ]}
                                            onChange={(e) => handleCityChange(e)}
                                            onClose={() => setCityList(cityDataList)}
                                        >
                                            <ListSubheader>
                                                <TextField
                                                    size="small"
                                                    autoFocus
                                                    placeholder="Type to search..."
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={cityFilter}
                                                    onKeyDown={(e) => {
                                                        if (e.key !== "Escape") {
                                                            e.stopPropagation();
                                                        }
                                                    }}
                                                />
                                            </ListSubheader>
                                            {cityList.length > 0 && cityList.map((item) => {
                                                return (
                                                    <MenuItem value={item.id}> {item.cityName} </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-6">
                                    {countrySelected === "USA" ?
                                        <InputMask
                                            mask={"99999"}
                                            value={state.postal_code}
                                            disabled={false}
                                            onChange={handleChange}
                                        >
                                            {() => (
                                                <TextValidator
                                                    required
                                                    errorMessages={[
                                                        "Please enter postal code/zip code",
                                                    ]}
                                                    validators={[
                                                        "required",
                                                    ]}
                                                    name="postal_code"
                                                    label="Postal Code"
                                                    variant="outlined"
                                                />
                                            )}
                                        </InputMask>
                                        :
                                        <InputMask
                                            mask={"a9a 9a9"}
                                            value={state.postal_code}
                                            disabled={false}
                                            onChange={handleChange}
                                        >
                                            {() => (
                                                <TextValidator
                                                    required
                                                    errorMessages={[
                                                        "Please enter postal code/zip code",
                                                    ]}
                                                    validators={[
                                                        "required",
                                                    ]}
                                                    name="postal_code"
                                                    label="ZIP Code"
                                                    variant="outlined"
                                                />
                                            )}
                                        </InputMask>
                                    }
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-6">
                                    <InputMask
                                        mask={"+1 (999) 999-9999"}
                                        maskChar="_"
                                        value={state.contact_number}
                                        disabled={false}
                                        onChange={handleChange}
                                    >
                                        {() => (
                                            <TextValidator
                                                required
                                                errorMessages={[
                                                    "Please enter valid phone number"
                                                ]}
                                                validators={[
                                                    "required",
                                                ]}
                                                name="contact_number"
                                                label="Phone Number"
                                                variant="outlined"
                                            />
                                        )}
                                    </InputMask>
                                </div>
                                <div className="col-6">
                                    <TextField
                                        onChange={handleChange}
                                        value={state.website}
                                        name="website"
                                        label="Website"
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        </ValidatorForm>

                        <div className="row mt-3" >
                            <div className="col-12" style={{ display: "flex", justifyContent: "space-between" }}>
                                <h6 className="h6-label mb-2">Add a credit card</h6>
                                <div>
                                    <Checkbox
                                        defaultChecked
                                        checked={cardDetailUpdateCheck}
                                        onChange={(e) => CardHandleDetailUpdateCheck(e)}
                                        color="primary"
                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                    />
                                    <span className="muiDescripationInfoCheckbox">update</span>
                                </div>

                            </div>
                        </div>

                        {
                            cardDetailsEditable === true ?
                                <div>
                                    <div className="row">
                                        <div className="col-12">
                                            <TextField
                                                value={cardState.card_number}
                                                name="card_number"
                                                label="Number"
                                                variant="outlined"
                                                onChange={(e) => handleCardDetailChange(e)}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-6">
                                            <TextField
                                                value={cardState.expiry}
                                                name="expiry"
                                                label="Expiry (MM/YY)"
                                                variant="outlined"
                                                onChange={(e) => handleCardDetailChange(e)}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <TextField
                                                value={cardState.card_cvv}
                                                name="card_cvv"
                                                label="CVC"
                                                variant="outlined"
                                                onChange={(e) => handleCardDetailChange(e)}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-5 mt-3">
                                        <div className="col-12 d-flex justify-content-end">
                                            <Button
                                                onClick={() => handleUpdateFInancials(cardCustomerId)}
                                                name="Update"
                                                className="btn btn-primary"
                                            />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                    <CardDetails
                                        stripeCardData={stripeCardData}
                                        setStripeCardData={(elements) => setStripeCardData(elements)}
                                        finish={(val) => cardDetailUpdateToken ? handleUpdateCreateToken(val) : submitHandle(val)}
                                    />
                                </div>
                        }
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    );
}
export default Financials;