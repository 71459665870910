import React, {useState,useEffect} from 'react';
import { useHistory } from "react-router-dom";
import Button from '../../../../../../components/button/button';
import { useLocation,useParams } from "react-router-dom";
import urls from "../../../../../../utils/services/apiUrl.json";
import { postMethodWithToken } from "../../../../../../utils/services/api";
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import StorefrontIcon from '@material-ui/icons/Storefront';
import { getMethod } from '../../../../../../utils/services/api';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import BussinessCred from '../../inReview/components/inReviewTabs/components/bussinessCredential/BussinessCred';
import FinancialInfo from '../../inReview/components/inReviewTabs/components/fanancialInformation/FinancialInfo';
import OpeningDays from '../../inReview/components/inReviewTabs/components/openingDays/OpeningDays';
import Products from '../../inReview/components/inReviewTabs/components/Products/Products';

const ApproveStoreDetails = (props) => {
    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    const [currTab, setCurrTab] = useState(0);
    const [approvedList,setApprovedLsit] = useState([])

    function handleTabChange(event, newValue) {
        setCurrTab(newValue);
    }

    function handleChangeIndex(index) {
        setCurrTab(index);
    }


    const gotoBack = async () => {
        history.goBack()
    };

    const onHold = async () => {
        let body ={
            shop_id : approvedList.id
        };
        await postMethodWithToken(urls.approve + "on_hold", body).then((response) => {
            if (response.status === 200) {
                history.push("/stores");
            }
        });
    };
    useEffect(()=>{
        getApprovedList()
    },[])

    const getApprovedList = async() =>{
        await getMethod(urls.getDetails + params.id + "/?query_for=approved").then((response=>{
            console.log(response)
            if(response.status === 200 || response.status === 201){
                setApprovedLsit(response.data.response)
            }
            
        }))
    }

    return (
        <div className={props.menu ?  "w-100 screenLeftMargin" : "screenLeftMarginResponse w-100"}>
            <div className="row mt-3 pl-3 ml-3 mr-3 mobileResponsive">
                <div  onClick={() => gotoBack()} style={{cursor: "pointer"}}>
                    <ArrowBackIcon /> <a style={{color: "#4472C4"}} className="ml-2">Listings</a>
                </div>
            </div>
            <div className="row mt-2 ml-3 mr-3 mobileResponsive">
                <div className="col-7 d-flex">
                    <h1 className="heading-title">
                        <StorefrontIcon fontSize="large"/> Approved Store
                    </h1>
                </div>
                <div className="col-5 d-flex justify-content-end">
                    <Button onClick={() => onHold()} className="btn btn-primary ml-md-2" name="On Hold"></Button>
                </div>
            </div>
            
            <div className="row mt-3 ml-3 mr-3 mobileResponsive">
                <div className="col-12">
                <div className="reviewBox">
                        <div className="row ">
                            <div className="col-4 mt-3 pl-5">
                                <h3 className="h6-label">Shop Details</h3>
                                <div>
                                    <p className="h2-label mt-3">
                                        {approvedList?.store_company_name}
                                    </p>
                                    <p className="h2-label mt-2">
                                        ID: <span>{approvedList?.id}</span>
                                    </p>
                                </div>
                            </div>

                            <div className="col-4 mt-3">
                                <h3 className="h6-label">Owner Details</h3>
                                <p className="h2-label mt-3">
                                    {approvedList?.owner_name}
                                </p>
                            </div>

                            <div className="col-4 mt-3 pr-5">
                                <h3 className="h6-label">Contact</h3>
                                <p className="mb-1 mt-3">
                                    <EmailIcon fontSize="small" style={{color: "#808080", marginRight:"5Px"}}/> {approvedList?.email}
                                </p>
                                <p>
                                    <PhoneIcon fontSize="small" style={{color: "#808080", marginRight:"5Px"}}/>
                                    {approvedList?.country_code}
                                    {approvedList?.phone_number}
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4 mt-3">
                                <div className="ml-3 pl-3">
                                    <span>
                                        <p className="h5-label">Store Details</p>
                                    </span>
                                    <p className="h6-label mb-0">
                                        <b>Store Type</b>
                                        <span className="leadBox-details pl-4 ml-3">
                                            : {approvedList?.store_types?.[0]?.name}
                                        </span>
                                    </p>
                                    <p className="h6-label mb-0">
                                        <b>Store Category</b>
                                        <span className="leadBox-details pl-2 ml-1">
                                            : {approvedList?.strore_category?.[0]?.name}
                                        </span>
                                    </p>
                                        {approvedList?.store_revenue?.slug && 
                                        <p className="h6-label mb-0">
                                            <b>Revenue</b>
                                            <span className="leadBox-details pl-5 ml-2">
                                                : {approvedList?.store_revenue?.slug}
                                            </span>
                                        </p>
                                        }
                                </div>
                            </div>

                            <div className="col-4 mt-3">
                                <p className="h5-label mt-4">Address</p>
                                <p className="leadBox-details">
                                    {approvedList?.store_address?.location}
                                </p>
                            </div>

                            <div className="col-4 mt-3">
                                <p className="h5-label mt-4">Manager Details</p>
                                <p className="mb-1 mt-3">
                                    {/* <b>{inReviewDetail.manager_detail[0].related_manager.name}</b> */}
                                </p>
                                <p className="mb-1">
                                    <EmailIcon fontSize="small" style={{color: "#808080", marginRight:"5Px"}}/> 
                                    {approvedList?.manager_detail?.[0]?.related_manager?.email}
                                </p>
                                <p>
                                    <PhoneIcon fontSize="small" style={{color: "#808080", marginRight:"5Px"}}/>
                                    {approvedList?.manager_detail?.[0]?.related_manager?.phone_number}
                                </p>
                            </div>
                        </div>

                        
                        <div className="w-100">
                            <Tabs
                                value={currTab}
                                onChange={handleTabChange}
                                textColor="inherit"
                                variant="standard"
                                >
                                <Tab
                                    label="Business Credentials"
                                />
                                <Tab
                                    label="Financial Information"
                                />
                                {approvedList?.opening_days?.length !== 0 && 
                                <Tab
                                    label="Opening Days"
                                />
                                }
                                <Tab
                                    label="Products"
                                />
                            </Tabs>
                        </div>
                    </div>
                    <div>
                         <div className="w-100">
                            <SwipeableViews index={currTab} onChangeIndex={handleChangeIndex} enableMouseEvents={true}>
                                <div>
                                    <BussinessCred data={approvedList} />
                                </div>
                                <div>
                                    <FinancialInfo data={approvedList} />
                                </div>
                                {approvedList?.opening_days?.length !== 0 ?
                                <div>
                                    <OpeningDays data={approvedList} />
                                </div>
                                :
                                <div>
                                    <Products data={approvedList} />
                                </div>
                                }
                                <div>
                                    <Products data={approvedList} />
                                </div>
                            </SwipeableViews>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApproveStoreDetails;