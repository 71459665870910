import React, { useState, useCallback, useEffect } from 'react';
import './role.scss';
import RoleDB from "../../../../assets/images/Role_R.svg";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/button/button";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import SearchDB from "../../../../assets/images/Search_S.svg";
import AdminBoxDb from "../../../../assets/images/AdminBox.svg";
import { useDropzone } from "react-dropzone";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Modal from "react-modal";
import NoDoc from "../../../../assets/images/emptyCamera.svg";
import urls from "../../../../utils/services/apiUrl.json";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Camera from "../../../../assets/images/camera.svg";
import UploadPhotoIcon from "../../../../assets/images/add_a_photo.png";
import { getMethod, postMethodWithTokenProgress, postMethodWithToken, putMethodWithToken, deleteMethodWithToken } from "../../../../utils/services/api";
import { toast } from 'react-toastify';
import moment from "moment";
import InputMask from "react-input-mask";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Pagination from '@mui/material/Pagination';
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const Role = (props) => {
    const itemsPerPage = 20;
    let timeOutId;

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const [searchInput, setSearchInput] = useState("");
    const history = useHistory();
    const [addAdmin, setAddAdmin] = useState(false);
    const [uploadedDoc, setUploadedDoc] = useState("");
    const [uploadPercentage, setStateUploadPercentage] = useState(0);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const initialValue = [];
    const [docId, setDocID] = useState(initialValue);
    const [roleDetails, setRoleDetails] = useState("");
    const [roleSelect, setRoleSelect] = useState("")
    const [loader, setLoader] = useState(false);
    const [roleOptions, setRoleOptions] = useState([]);
    const [idClicked, setIdClicked] = useState();
    const [noMatch, setNoMatch] = useState(false);
    const [action, setAction] = useState("");

    const [indexIdToDelete, setIndexIdToDelete] = useState("");
    const [confirmationModal, setConfirmationModal] = useState(false);

    const [state, setState] = useState({
        name: "",
        email: "",
        phone: ""
    });

    const gotoBack = async () => {
        history.push('/masterdata')
    };
    const openAdmin = (Action) => {
        setAction(Action);
        setAddAdmin(true);

    };
    const closeAdmin = () => {
        setAddAdmin(false);
        clearFields();
    };
   
    const createDate = (date) => {
        let now = new Date(date)
        var dateString = moment(now).format('DD MMM YYYY');
        return dateString

    };
    useEffect(() => {
        getRoleList();
        // getRoleSelect();
    }, [roleSelect]);
    
    useEffect(() => {
        getRoleDetails();
    }, []);


    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleEmailChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value.trim(),
        }));
    };

    const getRoleDetails = async () => {
        setLoader(true);
        await getMethod(urls.getRoleList).then((response) => {
            setCount(Math.ceil(response.data.count/itemsPerPage))
            setRoleDetails(response.data.results.data)
            setLoader(false);
        }).catch((error) => {
            console.log(error);
        });
        setLoader(false);
    }

    const handlePageChange = async (event, value) => {
        setLoader(true);
        setPage(value)

        await getMethod(urls.getRoleList + "?page="+value).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count/itemsPerPage))
                setRoleDetails(response.data.results.data);
                setLoader(false);
            }
        });

        setLoader(false);
    }

    const createRole = async () => {

        if(!state.name){
            toast.error("Please fill in name");
        } else if(!state.email) {
            toast.error("Please fill in email");
        } else if(!state.phone) {
            toast.error("Please fill in phone");
        } else if(!roleSelect) {
            toast.error("Please fill in role");
        } else if(uploadedDoc.length === 0) {
            toast.error("Please fill in image");
        } else {
            let body = {
                name: state.name,
                email: state.email.toLowerCase(),
                phone_number: state.phone,
                role: roleSelect,
                profile_pic: uploadedDoc[0].id.toString(),
            };

            if (action === "create") {
                await postMethodWithToken(urls.createRole, body).then((response) => {
                    if (response.status === 201) {
                        toast.success(response.data.response)
                        setAddAdmin(false);
                        getRoleDetails();
                        clearFields();
                    } else {
                        toast.error("Shopez Admins with this email already exists..")
                        setAddAdmin(true);
                    }
                });
            } else if (action === "update") {
                await putMethodWithToken(urls.updateAdmin + idClicked + "/", body).then((response) => {
                    if (response.status === 200) {
                        setAddAdmin(false);
                        getRoleDetails();
                        clearFields();
                        toast.success(" Details is Updated Successfully !!")
                    }

                });
            }
        }
    }

    const getRoleList = async () => {
        await getMethod(urls.getListRole).then((response) => {
            setRoleOptions(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }
    const fillRoleDetails = async (Action, id) => {
        setAction(Action);
        setIdClicked(id);
        await getMethod(urls.getRoleDetails + id + "/").then(
            (response) => {
                if (response.status === 200) {
                    setState((prevState) => ({
                        ...prevState,
                        ["name"]: response.data.name,
                        ["email"]: response.data.email,
                        ["phone"]: response.data.phone_number,
                    }));

                    setRoleSelect(response.data.role.slug);
                    setUploadedDoc([response.data.profile_pic !== undefined || response.data.profile_pic !== "" ? response.data.profile_pic : ""]);
                }
            }
        );
        setAddAdmin(true);
    };

    const onUploadProgress = (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setStateUploadPercentage(percent);
    };
    const onDrop = useCallback(
        (acceptedFiles) => {
            let formData = new FormData();
            acceptedFiles.map((file, index) => {
                formData.append("file", file);
            });
            postMethodWithTokenProgress(
                urls.fileUpload,
                formData,
                onUploadProgress
            ).then((response) => {
                setUploadedDoc(response.data);
                response.data.map((item) => {
                    initialValue.push(item.id);
                });
            }).catch((error) => {
                console.log("error", error);
            });
        },
        [uploadedDoc]
    );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: "image/jpeg, image/jpg, image/png",
        onDrop,
    });

    const handleRoleChange = (event) => {
        setRoleSelect(event.target.value);
    };

    const clearFields = () => {
        setRoleSelect("");
        setState("");
        setDocID([]);
        setUploadedDoc([]);
    };

    const notification = async () => {
        history.push("/notifications");

    }

    const handleSearchInputChange = (e) => {
        setSearchInput(e.target.value);
        debounceCall(e.target.value);

    };
    const debounce = (func, delay) => {
        return (...args) => {
            if (timeOutId) clearTimeout(timeOutId);
            timeOutId = setTimeout(() => {
                func.apply(null, args);
            }, delay);
        };
    };

    const roleSearch = async (text) => {
        await getMethod(urls.searchRole + text).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count/itemsPerPage))
                setRoleDetails(response.data.results.data);
                setPage(1)
                setLoader(false);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const debounceCall = debounce(roleSearch, 500);

    const allStyless = {
        content: {
            top: '50%',
            left: '50%',
            transform: screenSize.width <= 800 ? 'translate(-32%, -50%)' : screenSize.width <= 1100 ? 'translate(-35%, -42%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            width: "70vw",
            height: screenSize.width <= 800 ? "40vh" : screenSize.width <= 1100 ? "55vh" : "45vh",
            outline: "none",
            padding: "15px",
        },
    };

    const customStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 800 ? "40vw" : screenSize.width <= 1100 ? "28vw" : "25vw",
            height: screenSize.width <= 800 ? "15vh" : "18vh",
        },
    };

    const ToDelete = (id) => {
        setConfirmationModal(true);
        setIndexIdToDelete(id);
    };

    const roleDelete = async (id) => {
        await deleteMethodWithToken(urls.deleteRole + id + '/').then((response) => {
            if (response.status === 201 || response.status === 200) {
                toast.success(response.data.response)
                setConfirmationModal(false);
            } else {
                toast.error(response.data.response);
            }
        });

        getRoleDetails()
    }

    return (
        <div className={props.menu ? 'w-100 screenLeftMargin' : 'w-100 screenLeftMarginResponse'}>
            <div className="row ml-3 pl-3 mr-3 mt-3 mobileResponsive">
                <div  onClick={() => gotoBack()} style={{cursor: "pointer"}}>
                    <ArrowBackIcon /> <a style={{color: "#4472C4"}} className="ml-2">Master Data</a>
                </div>
            </div>
            <div className="row ml-3 mr-3 mt-2 mobileResponsive">
                <div className="col-7 d-flex">
                    <div className="d-flex mr-3">
                        <img width="25" src={RoleDB} />
                    </div>
                    <div className="d-flex" style={{alignItems: "center"}}>
                        <h1 className="heading-title d-flex">Role</h1>
                    </div>
                </div>
                <div className='col-5 d-flex justify-content-end'>
                    <div className='order-notifications-icon' onClick={() => notification()}>
                        <NotificationsIcon style={{color: "#fff", fontSize: "30px"}}/>
                    </div>
                    <div className='order-notifications-icon ml-3'>
                        <EmailIcon style={{color: "#fff", fontSize: "30px"}}/>
                    </div>
                </div>
            </div>

            <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                <div className='col-xl-4 col-lg-6 col-md-7 d-flex' style={{alignItems: "center"}}>
                    <TextField
                        onChange={(text) => console.log(text)}
                        name="search"
                        label="Search"
                        variant="outlined"
                        onChange={(event) => handleSearchInputChange(event)}
                        value={searchInput}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div className='col-xl-8 col-lg-6 col-md-5 d-flex justify-content-end pr-4'>
                    <Button onClick={() => openAdmin("create")} className="btn btn-cancle" name="Add Admin">
                    </Button>
                </div>
            </div>

            {count > 1 && 
                <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                    <div className="col-12">
                        <div className="shopPagination">
                            <Pagination 
                                count={count}
                                page={page}
                                showFirstButton
                                showLastButton 
                                onChange={(event, value) => handlePageChange(event, value)}
                            />
                        </div>
                    </div>
                </div>
            }

            <div className="row ml-3 mr-3 pl-3 pr-3 mt-3 mobileResponsive">
                <div className='col-12'>
                    <div className="row pt-3 pb-3">
                        <div className="col-4 f-600">Admin</div>
                        <div className="col-4 f-600">info</div>
                        <div className="col-2 f-600">Joined</div>
                        <div className="col-2 f-600">Actions</div>
                    </div>

                    {roleDetails.length > 0 ?  roleDetails.map((item, index) => {
                        return (
                            <div className="row mt-3 pt-3 pb-3" style={{background: "#ECECEC"}}>
                                <div className="col-4" style={{alignItems: "center", cursor: "pointer"}} onClick={() => fillRoleDetails("update", item.id)}>
                                    <img className="listedRadiusImg" src={item.profile_pic.file} alt=""/>
                                    <img alt="" className="pr-2 pl-2 listedImgIcon" src={AdminBoxDb} />
                                    {item.name}
                                </div>
                                <div className="col-4" style={{alignItems: "center", cursor: "pointer"}} onClick={() => fillRoleDetails("update", item.id)}>
                                    <div>
                                        <EmailIcon fontSize="small" style={{color: "#808080", marginRight:"5Px", marginTop: "-1px"}}/>
                                        {item.email}
                                    </div>
                                    <div>
                                        <PhoneIcon fontSize="small" style={{color: "#808080", marginRight:"5Px", marginTop: "-1px"}}/>
                                        {item.phone_number}
                                    </div>
                                </div>
                                <div className="col-2 d-flex" style={{alignItems: "center", cursor: "pointer"}} onClick={() => fillRoleDetails("update", item.id)}>
                                    {item.created_at !== undefined ? createDate(item.created_at) : ""}
                                </div>
                                <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                    <div className="d-flex">
                                        <EditIcon fontSize="small" onClick={() => fillRoleDetails("update", item.id)} style={{ cursor: "pointer" }} />
                                    </div>
                                    <div className="d-flex ml-2">
                                        <DeleteIcon fontSize="small" style={{ cursor: "pointer",color:"#eb2813" }} onClick={() => ToDelete(item.id)} />
                                    </div>
                                </div>
                            </div>
                        )
                    }) : (
                        <div className="row mt-3 pt-3 pb-3" style={{background: "#ECECEC", textAlign: "center"}}>
                            <div className="col-12" style={{textAlign: "center"}}>
                                No results match found.
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {count > 1 && 
                <div className="row ml-3 mr-3 mt-3 mb-5 mobileResponsive">
                    <div className="col-12">
                        <div className="shopPagination">
                            <Pagination 
                                count={count}
                                page={page}
                                showFirstButton
                                showLastButton 
                                onChange={(event, value) => handlePageChange(event, value)}
                            />
                        </div>
                    </div>
                </div>
            }

            <Modal isOpen={addAdmin} style={allStyless} overlayClassName="myoverlay" closeTimeoutMS={500} >
                <div className="row">
                    <div className="col-10 offset-1">
                        <div className="row mt-3">
                            <div className="h4-label mt-3 mb-3 col-12">
                                Add Admin
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="container storeImageBlock d-flex justify-content-center">
                                    <div className="mt-3" {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <DragDropContext>
                                            <Droppable droppableId="uploadedImages" direction="horizontal">
                                                {(provided) => (
                                                    <div
                                                        style={{ display: "flex", alignItems: "center" }}
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {uploadedDoc.length !== 0 ? (
                                                            <>
                                                                {uploadedDoc &&
                                                                    uploadedDoc.map((item, index) => {
                                                                        return (
                                                                            <Draggable
                                                                                key={item.id.toString()}
                                                                                draggableId={item.id.toString()}
                                                                                index={0}
                                                                            >
                                                                                {(provided) => (
                                                                                    <div
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                    >
                                                                                        <img
                                                                                            src={item.file !== undefined ? item.file : item.url}
                                                                                            alt=""
                                                                                            className="imgTagBlock"
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        );
                                                                    })}
                                                            </>
                                                        ) : (
                                                            <div className="uploadPhoto">
                                                                <div style={{cursor: "pointer"}} className="uploadInfo">
                                                                    <div className="uploadImage"><img width="22" src={UploadPhotoIcon} /></div>
                                                                    <div className="uploadText">Profile Photo</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <ValidatorForm>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className="">
                                            <ValidatorForm>
                                                <TextValidator
                                                    validators={[
                                                        "required",
                                                    ]}
                                                    required
                                                    errorMessages={[
                                                        "Please fill in Name"
                                                    ]}
                                                    className="mt-3"
                                                    inputProps={{ maxLength: 20 }}
                                                    name="name"
                                                    label="Name"
                                                    variant="outlined"
                                                    onChange={handleFieldChange}
                                                    value={state.name}

                                                />
                                                <InputMask
                                                    mask={"+1 (999) 999-9999"}
                                                    maskChar="_"
                                                    value={state.phone}
                                                    disabled={false}
                                                    onChange={handleFieldChange}
                                                    >
                                                    {() => (
                                                        <TextValidator
                                                            required
                                                            errorMessages={[
                                                                "Please fill in phone number"
                                                            ]}
                                                            validators={[
                                                                "required",
                                                            ]}
                                                            className="mt-3"
                                                            name="phone"
                                                            label="phone"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                </InputMask>
                                            </ValidatorForm>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="">
                                            <ValidatorForm>
                                                <TextValidator
                                                    validators={[
                                                        "required",
                                                    ]}
                                                    required
                                                    errorMessages={[
                                                        "Please fill in Email"
                                                    ]}
                                                    className="mt-3"
                                                    inputProps={{ maxLength: 35 }}
                                                    name="email"
                                                    label="Email"
                                                    onChange={handleEmailChange}
                                                    value={state.email}
                                                    variant="outlined"
                                                    type='email'
                                                />
                                                <FormControl variant="outlined" className="mt-3">
                                                    <InputLabel required id="role-select">Role</InputLabel>
                                                    <Select
                                                        onChange={handleRoleChange}
                                                        value={roleSelect}
                                                        labelId="role-select"
                                                        id="role-select-dropdown"
                                                        label="Role"
                                                    >
                                                        {roleOptions.map((item, index) => {
                                                            return (
                                                                <MenuItem value={item.slug}> {item.name} </MenuItem>
                                                            );
                                                        })}

                                                    </Select>
                                                </FormControl>
                                            </ValidatorForm>
                                        </div>
                                    </div>
                                </div>
                            </ValidatorForm>
                        </div>

                        <div className="row pb-5">
                            <div className="col-12 mt-3">
                                <Button type="onSubmit" name="Save" onClick={() => {createRole()}} className="btn btn-primary ml-3 float-right"/>
                                <Button type="onSubmit" onClick={() => closeAdmin()} name="Cancel" className="btn btn-cancle float-right"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={confirmationModal} style={customStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="mt-4 mb-4">
                    <div className="row">
                        <div className="col-12 text-center">
                            Are you sure you want to delete this user?
                        </div>
                        <div className="col-12 mt-4  d-flex justify-content-center">
                            <Button type="onSubmit" onClick={() => setConfirmationModal(false)} name="Cancel" className="btn btn-cancle btn-sm mr-3"/>
                            <Button type="onSubmit" onClick={() => {roleDelete(indexIdToDelete);}} name="Delete" className="btn btn-primary btn-sm"/>
                        </div>
                    </div>
                </div>
            </Modal>
        </div >
    )
}

export default Role;
