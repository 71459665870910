import React, { useEffect, useState } from "react";
import "./FinancialInfo.scss";
import { getYear } from "date-fns";
import { getMethodWithStripe, postMethodWithStripe } from "../../../../../../../../../utils/services/api";
import urls from "../../../../../../../../../utils/services/apiUrl.json";

const FinancialInfo = ({data}) => {
    const [bankState, setBankState] = useState({
        bank_country: data?.store_address?.country,
        full_name: '',
        bank_name: '',
        bank_routing_number: '',
        bank_account_number: '',
    });
    const [cardState, setCardState] = useState({
        card_number: '',
        card_month: '',
        card_year: '',
        card_cvv: '',
        expiry: '',
    });
    
    const [bankVerificationStatus, setBankVerificationStatus] = useState("");

    const getExpiryMonth = (month) => {
        let Length = String(month);
        if (Length.length == 1) {
            return "0" + month;
        } else {
            return month;
        }
    };

    const getExpiryYear = (year) => {
        let Year = String(year);
        return Year.substring(2);
    };

    const getAddress = () => {
            let address = data?.billing_address?.line1 + " " + data?.billing_address?.state + " " + data?.billing_address?.country + " " + data?.billing_address?.postcode;
            return address;

    };
    useEffect(()=>{
        if (data.bank_account_id) {
            getMethodWithStripe(urls.postStripeCustomers + '/' + data.customer_stripe_id + '/sources/' + data.bank_account_id, "USA").then((response) => {
                if(response.status === 200 || response.status === 201) {
                    setBankState((prevState) => ({
                        ...prevState,
                        ["full_name"]: response?.data?.account_holder_name,
                        ["bank_name"]: response?.data?.bank_name,
                        ["bank_routing_number"]: response?.data?.routing_number,
                        ["bank_account_number"]: '********' + response?.data?.last4,
                        // ["bank_institution_number"]: response.data.metadata.institution_number,
                    }));

                    setBankVerificationStatus(response?.data?.status);
                }
            }).catch((error) => {
                console.log("error", error);
            });
            
        } else {
            setBankState((prevState) => ({
                ...prevState,
                ["full_name"]: data?.canada_bank_detail?.full_name,
                ["bank_name"]: data?.canada_bank_detail?.bank_name,
                ["bank_routing_number"]: data?.canada_bank_detail?.routing_number,
                // ["bank_institution_number"]: data.canada_bank_detail.institution_number,
                ["bank_account_number"]: '********' + data?.canada_bank_detail?.account_number,
            }));
        }
    },[data.bank_account_id])
    useEffect(()=>{
        if (data.customer_card_id) {
            var countryValue;
            if(data.store_address.country === "United States"){
                 countryValue = "USA";
            }else{
                 countryValue = data.store_address.country;
            }
            getMethodWithStripe(urls.postStripeCustomers + '/' + data?.customer_stripe_id + '/sources/' + data?.customer_card_id, countryValue).then((response) => {
                setCardState((prevState) => ({
                    ...prevState,
                    ["card_number"]: '**** **** **** ' + response?.data?.last4,
                    ["card_month"]: response?.data?.exp_month,
                    ["card_year"]: response?.data?.exp_year,
                    ['expiry']: getExpiryMonth(response?.data?.exp_month) + '/' + getExpiryYear(response?.data?.exp_year),
                    ["card_cvv"]: '***',
                }));
            }).catch((error) => {
                console.log("error", error);
            });
        }
    },[data.customer_card_id])

    return (
        <div className="">
            <div className="FinancialBox mt-3 mb-5">
                <div className="d-flex">
                    <div className="col-6">
                        <div className="h5-label mb-2 pt-3 pl-4">
                            Bank Details
                            {data.bank_account_id &&
                                <>
                                    {bankVerificationStatus === 'verified' ?
                                        <span className="bank_verified">Verified</span>
                                    :
                                        <span className="bank_unverified">Unverified</span>
                                    }
                                </>
                            }
                        </div>
                        <div className="h6-label pl-4">
                            Full Name
                            <span className="leadBox-details pl-4 ml-2">
                                : {bankState?.full_name}
                            </span>
                        </div>
                        {bankState?.bank_name && 
                        <div className="h6-label pl-4">
                            Bank Name
                            <span className="leadBox-details pl-3 ml-1">
                                : {bankState?.bank_name}
                            </span>
                        </div>
                        }
                        <div className="h6-label pl-4">
                            {/* {data?.canada_bank_detail?.transit_number !== "" ? "Transit No." : "Routing No."} */}
                            Routing No
                            <span className="leadBox-details pl-3 ml-2">
                                {/* : {data?.canada_bank_detail?.routing_number !== "" ? data?.canada_bank_detail?.transit_number : data?.canada_bank_detail?.routing_number} */}
                                : {bankState?.bank_routing_number}
                            </span>
                        </div>
                        <div className="h6-label pl-4">
                            Account No.
                            <span className="leadBox-details pl-3">
                                : {bankState?.bank_account_number}
                            </span>
                        </div>
                        <div className="h6-label pl-4">
                            Country
                            <span className="leadBox-details pl-5">
                                : {data?.store_address?.country}
                            </span>
                        </div>
                        <div className="h5-label mt-3 pl-4">Accepted Mode of Payment</div>
                        <div className="pl-4 pt-2 leadBox-details">
                            {data?.mode_of_payment !== null ? (
                                <span>
                                    <img src={data?.mode_of_payment?.active_image} alt="" />
                                    <span> </span>
                                    {data?.mode_of_payment?.name}
                                </span>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="h5-label mb-2 pt-3">Billing Information</div>
                        <div className="h6-label">
                            Card No.
                            <span className="leadBox-details pl-5 ml-3">
                                : {cardState?.card_number}
                            </span>
                        </div>
                        <div className="h6-label">
                            MM\YY
                            <span className="leadBox-details pl-5 ml-4">
                                :  {cardState?.card_month}/{cardState?.card_year}
                            </span>
                        </div>
                        <div className="h6-label ">
                            CVV <span className="leadBox-details pl-5 ml-5">: *** </span>
                        </div>
                        <div className="h5-label mt-3 pt-4"> Billing Address</div>
                        <p className="leadBox-details w-50">{getAddress()}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FinancialInfo;
