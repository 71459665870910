import React, { useState, useEffect } from "react";
import "./navBar.scss";
import haderLogo from "../../assets/images/haderLogo.png";
import vector from "../../assets/images/Vector.png";
import { getMethod } from "../../utils/services/api";
import urls from "../../utils/services/apiUrl.json";
import { getUserRole } from "../../utils/common";
import { useHistory } from "react-router-dom";

function Navbar(props) {
    const history = useHistory("");
    const [value, setValue] = useState("");
    const [storeList, setStoreList] = useState("");
    const [displayStore, setDisplayStore] = useState(false);

    useEffect(() => {
        getCurrentStatus();
        if (getUserRole() !== "super-admin") {
            getStoresListing();
        }
    }, [value]);

    const getCurrentStatus = async () => {
        await getMethod(urls.onboardingProgress).then((response) => {
            window.$progessPercentage = response.data.status;
            setValue(response.data.status);
        }).catch((error) => {
            console.log(error);
        });
    };

    const logOut = async () => {
        sessionStorage.clear();
        history.push("/");
        window.location.reload();
    };

    const getStoresListing = async () => {
        await getMethod(urls.getStore).then((response) => {
            if (response.status === 200) {
                setStoreList(response.data);
            }
        });
    };

    const changeDisplay = () => {
        setDisplayStore(!displayStore);
    };

    return (
        <div className="stickNav">
            <div className="onboardingNavbar container-fluid pt-3 pb-3">
                <div className="row">
                    <div className="col-4">
                        <div className="pl-3" style={{cursor: "pointer"}}>
                            <img src={vector} onClick={() => props.setMenu()} alt=""/>
                            <img className=" mt-1 pl-2" src={haderLogo} alt="" />
                        </div>
                    </div>

                    <div className="col-8 d-flex justify-content-end">
                        <div className="row">
                            {/* <div className="mr-4">
                                <img src="assets/images/Vector (5).svg" alt="" />
                            </div>
                            <div className="mr-4">
                                <img src="assets/images/Vector (3).svg" alt="" />
                            </div>
                            <div className="mr-4">
                                <img src="assets/images/Vector (4).svg" alt="" />
                            </div> */}

                            {/* <div className="row">
                                <div className="pl-3 pr-2"
                                    style={{
                                        paddingRight: "3px",
                                        paddingTop: "2px",
                                        color: "#fff",
                                        height: "32px",
                                        borderRight: "2px solid #FFFFFF",
                                    }}
                                    >
                                    Shopeez
                                </div>
                                <div>
                                    <div className="storeHover option-heading d-inline-block arrow pl-2"
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        style={{
                                            paddingRight: "3vw",
                                            paddingLeft: "3px",
                                            paddingTop: "3px",
                                            color: "#fff",
                                        }}
                                        onClick={() =>
                                            getUserRole() !== "super-admin" ? changeDisplay() : ""
                                        }
                                        >
                                        Store
                                    </div>
                                    <div class={displayStore ? "dropdown-menu" : "hide-dropdown"}
                                        aria-labelledby="dropdownMenuButton"
                                        >
                                        {storeList && storeList.map((item) => {
                                            return (
                                                <li class="depli">
                                                    <h6 class="lineHt pt-2">
                                                        {item.first_name + " " + item.last_name}
                                                    </h6>
                                                    <hr></hr>
                                                </li>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div> */}
                            <div className="mr-0">
                                <button className="logout" onClick={() => logOut()}> Logout </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
