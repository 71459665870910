import React, {useState, useRef, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import Termsdb from "../../../../assets/images/Terms.svg";
import JoditEditor from "jodit-react";
import Button from "../../../../components/button/button";
import {getMethod,postMethod, putMethod} from "../../../../utils/services/api";
import urls from "../../../../utils/services/apiUrl.json";
import { toast } from 'react-toastify';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EmailIcon from '@material-ui/icons/Email';

const Terms = (props) => {
    const editor = useRef(null);
    const [state, setState] = useState("");
    const [termsOfUse, setTermsOfUse] = useState("");
       

    const history = useHistory();
    const config = {
        readonly: false,
        placeholder: "Terms Of Use",
    };

    const gotoBack = async () => {
        history.push('/masterdata')
    };

    const notification = async () => {
        history.push("/notifications");

    }

    const postTermOfUse = async (method, id) =>{
        if(state.terms !==""){
            let body={
                term_and_conditions_text : state
            };

            if(method==="POST"){
                await postMethod(urls.termOfUse, body).then((response) => {
                    if (response.status === 201) {
                        toast.success(response.data.response)
                    } else {
                        toast.error("Something went wrong")
                    }
                });
            } else {
                await putMethod(urls.termOfUse + termsOfUse.data[0].id + "/", body).then((response) => {
                    if (response.status === 202) {
                        toast.success(response.data.response)
                    } else {
                        toast.error("Something went wrong")
                    }
                });
            }
        }
    }

    useEffect(() => {
        getTermsOfUse();
    }, []);

    const getTermsOfUse = async () => {
        await getMethod(urls.termOfUse).then((response) => {
            setTermsOfUse(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <div className={props.menu ? 'w-100 screenLeftMargin' : 'w-100 screenLeftMarginResponse'}>
            <div className="row ml-3 pl-3 mr-3 mt-3 mobileResponsive">
                <div  onClick={() => gotoBack()} style={{cursor: "pointer"}}>
                    <ArrowBackIcon /> <a style={{color: "#4472C4"}} className="ml-2">Master Data</a>
                </div>
            </div>
            <div className="row ml-3 mr-3 mt-2 mobileResponsive">
                <div className="col-7 d-flex">
                    <div className="d-flex mr-3">
                        <img width="25" src={Termsdb} />
                    </div>
                    <div className="d-flex" style={{alignItems: "center"}}>
                        <h1 className="heading-title d-flex">Terms of Use</h1>
                    </div>
                </div>
                <div className='col-5 d-flex justify-content-end w-100'>
                    <div className='order-notifications-icon' onClick={() => notification()}>
                        <NotificationsIcon style={{color: "#fff", fontSize: "30px"}}/>
                    </div>
                    <div className='order-notifications-icon ml-3'>
                        <EmailIcon style={{color: "#fff", fontSize: "30px"}}/>
                    </div>
                </div>
            </div>

            <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                <div className="col-12">
                    <JoditEditor
                        style={{minHeight: "500px"}}
                        ref={editor}
                        value={termsOfUse!=="" && termsOfUse.data[0]!=="undefined" ? termsOfUse.data[0].term_and_conditions_text : ""}
                        config={config}
                        onBlur={(newContent) => setState(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={(newContent) => {
                            console.log("entered input", newContent);
                        }}
                    />
                </div>

                <div className="col-12 mt-3">
                    <div className='d-flex justify-content-end'>
                        <Button
                            onClick={() =>termsOfUse==="" ? postTermOfUse("POST") : postTermOfUse("UPDATE")}
                            className="btn btn-primary"
                            name="Save"
                            >
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Terms;