import React, { useState, useRef, useEffect } from "react";
import "./createStore.scss";
import ShopezLogo from "../../assets/images/shopez_logo.png";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import InputMask from "react-input-mask";
import Button from "../../components/button/button";
import { useHistory } from "react-router-dom";
import Geosuggest from "react-geosuggest";
import { postMethod } from "../../utils/services/api";
import urls from "../../utils/services/apiUrl.json";
import { toast } from "react-toastify";
import USADB from "../../assets/images/usa.png";
import canadaDB from "../../assets/images/canada.png";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import LocationDb from "../../assets/images/location_icon.png";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import OtpInput from 'react-otp-input';

const CreateStore = () => {
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [storeName, setStoreName] = useState("");
    const [recaptchaVerification, setRecaptchaVerification] = useState("");
    const [otpVerification, setOtpVerification] = useState("");
    const [loginScreenVisible, setLoginScreenVisible] = useState(true);

    const [otpValue, setOtpValue] = useState("");
    const [countInvalid, setCountInvalid] = useState(0);

    const [storeDetails, setStoreDetails] = useState({
        owner_name: "",
        email: "",
        country_code: "+1",
        phone_number: "",
        store_company_name: "",
        location: "",
    });
    const countriesFlag = [
        {
            value: "USA",
            flag: USADB,
            code: "+1",
        },
        {
            value: "Canada",
            flag: canadaDB,
            code: "+1",
        },
    ];

    const [countryCodeSelect, setCountryCodeSelect] = useState("Canada");
    const history = useHistory();
    const geosuggestEl = useRef("");
    const initialValue = [];
    const google = window.google;

    const gotoBack = async () => {
        history.push("/");
    };

    const promptPermission = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            if (position.coords.longitude) {
                alert("Location permission is already allowed");
                codeLatLng(position.coords.latitude, position.coords.longitude);
            }
        });
    };

    const codeLatLng = (lat, lng) => {
        let geocoder = new google.maps.Geocoder();
        var latlng = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
        let country = "";
        let sublocality = "";
        let locality = "";
        let postalCode = "";
        let Address = "";
        geocoder.geocode({ location: latlng }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                if (results[1]) {
                    for (var i = 0; i < results[0].address_components.length; i++) {
                        for (var j = 0; j < results[0].address_components[i].types.length; j++) {
                            if (results[0].address_components[i].types[j] === "country") {
                                country = results[0].address_components[i].short_name.toString();
                            }
                            if (results[0].address_components[i].types[j] === "locality") {
                                locality = results[0].address_components[i].short_name.toString();
                            }
                            if (results[0].address_components[i].types[j] === "postal_code") {
                                postalCode = results[0].address_components[i].short_name.toString();
                            }
                            if (results[0].address_components[i].types[j] === "sublocality_level_2") {
                                sublocality = results[0].address_components[i].short_name.toString();
                            }
                        }
                    }
                }

                Address = sublocality + " " + country + " " + locality + " " + postalCode;
                setStoreDetails((prevState) => ({
                    ...prevState,
                    ["location"]: Address,
                }));
            }
        });
    };

    const handleOtpChange = (e) => {
        setOtpValue(e)
    }

    const gotoWelcome = async () => {
        if (
            storeDetails.owner_name !== "" &&
            storeDetails.email !== "" &&
            storeDetails.phone_number !== "" &&
            storeDetails.phone_number.substring(0).replace(/[^0-9]/g, "").length === 10 &&
            storeDetails.store_company_name !== "" &&
            storeDetails.location !== "" &&
            storeName
        ) {
            let phoneString = storeDetails.phone_number;
            let requiredPhoneFormat = phoneString.substring(0);

            const auth = getAuth();
            const phoneNumber = '+1'+requiredPhoneFormat.replace('(','').replace(')','').replace('-','').replace(' ','');

            let body = {
                email : storeDetails.email
            }

            await postMethod(urls.postEmailValidation, body).then((response) => {
                if (response.status === 201 || response.status === 200) {
                    const appVerifier = new RecaptchaVerifier('recaptcha-container', {
                        'size': 'invisible',
                        'callback': (response) => {
                            setRecaptchaVerification(response)
                        }
                    }, auth);

                    signInWithPhoneNumber(auth, phoneNumber, appVerifier).then((confirmationResult) => {
                        setLoginScreenVisible(false)
                        setOtpVerification(confirmationResult)
                    }).catch((error) => {
                        toast.error("Please enter valid phone number")
                    });
                } else {
                    toast.error('this email already exists !!');
                    setLoginScreenVisible(true)
                }
            });

        } else {

            if (storeDetails.owner_name == "") {
                toast.error("Please enter owner name");
            } else if (storeDetails.email == "") {
                toast.error("Please enter email address");
            } else if (storeDetails.location == "") {
                toast.error("Please choose location");
            } else if (storeDetails.phone_number == "") {
                toast.error("Please enter phone number");
            } else if (storeDetails.store_company_name == "") {
                toast.error("Please enter store name");
            } else if(!storeName) {
                toast.error("Please enter valid store name");
            } else {
                toast.error("Please enter valid details");
            }
        }
    };

    const verifiedOtp = async () => {
        if(otpValue !== '') {
            otpVerification.confirm(otpValue).then((result) => {
                
                createStore()

            }).catch((error) => {
                toast.error('please enter valid otp')
            });
        } else {
            toast.error('please enter valid otp')
            let countNumber = countInvalid + 1;
            setCountInvalid(countNumber);

            if(countNumber >= 3) {
                sessionStorage.clear();
                history.push("/createstore");
            }
        }
    }

    const sendResendOtp = () => {
        if(storeDetails.phone_number !== '') {
            let phoneString = storeDetails.phone_number;
            let requiredPhoneFormat = phoneString.substring(0);

            const authInfo = getAuth();
            const phoneNumber = '+1'+requiredPhoneFormat.replace('(','').replace(')','').replace('-','').replace(' ','');
            

            const appVerifier = new RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible',
                'callback': (response) => {
                    setRecaptchaVerification(response)
                }
            }, authInfo);
            
            signInWithPhoneNumber(authInfo, phoneNumber, appVerifier).then((confirmationResult) => {
                setLoginScreenVisible(false)
                setOtpVerification(confirmationResult)
            }).catch((error) => {
                setLoginScreenVisible(true)
                toast.error('Phone number is not valid. please fill up details again')
            });
        } else {
            setLoginScreenVisible(true)
            toast.error('Phone number is not valid. please fill up details again')
        }
    }

    const createStore = async () => {
        let phoneString = storeDetails.phone_number;
        let requiredPhoneFormat = phoneString.substring(0)

        let body = {
            owner_name: storeDetails.owner_name,
            email: storeDetails.email.toLowerCase(),
            country_code: "+1",
            phone_number: requiredPhoneFormat,
            store_company_name: storeDetails.store_company_name,
            location: storeDetails.location,
        };

        await postMethod(urls.applyForStore, body).then((response) => {
            if (response.status === 201) {
                toast.success(response.data.response);
                history.push("/welcome");
            } else {
                if(response.data.errors && response.data.errors.email.length > 0) {
                    toast.error(response.data.errors.email[0]);
                } else {
                    toast.error('Please fill up valid details');
                }

                setLoginScreenVisible(true)
            }
        });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStoreDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleEmailChange = (e) => {
        const { name, value } = e.target;
        setStoreDetails((prevState) => ({
            ...prevState,
            [name]: value.trim(),
        }));
    };

    const onSuggestSelect = (suggest) => {
        var streetNumber = "", route = "", locality = "", stateAddress = "", state = "", country = "", postal_code = "",  addressValue = "";
        console.log(suggest)       
        suggest && suggest.gmaps && suggest.gmaps.address_components.map((item, index) => {
            if (item.types[0] === "street_number") {
                streetNumber = item.long_name;
            }

            if(item.types[0] === "route") {
                route = item.short_name;
            }

            if(item.types[0] === "locality") {
                locality = item.long_name;
            }

            if(item.types[0] === "administrative_area_level_1") {
                state = item.long_name;
                stateAddress = item.short_name;
            }

            if(item.types[0] === "country") {
                country = item.short_name;
            }

            if(item.types[0] === "postal_code") {
                postal_code = item.long_name;
            }
        });

        if(streetNumber !== "") {
            addressValue += streetNumber;
        } 

        if(route !== "" && streetNumber !== "") {
            addressValue += ", " + route;
        } else if(route !== "") {
            addressValue += route;
        }

        if(locality !== "") {
            addressValue += ", " + locality;
        }

        if(state !== "") {
            addressValue += ", " + stateAddress;
        }

        if(country !== "") {
            addressValue += ", " + country;
        }

        /*if(postal_code !== "") {
            addressValue += ", " + postal_code;
        }*/

        setStoreDetails((prevState) => ({
            ...prevState,
            ["location"]: addressValue,
        }));
        geosuggestEl.current.update(addressValue);
    };

    const handleCountryCodeChange = (event) => {
        setCountryCodeSelect(event.target.value);
    };

    useEffect(() => {
        checkStoreName();
    }, [storeDetails.store_company_name, storeName]);

    useEffect(() => {}, [storeName, storeDetails.location]);

    const checkStoreName = async () => {
        if (storeDetails.store_company_name && storeDetails.store_company_name.length > 0 ) {
            let body = {
                storename: storeDetails.store_company_name,
            };
            await postMethod(urls.verifyStoreName, body).then((response) => {
                if (response.status === 200) {
                    setStoreName(true);
                } else {
                    setStoreName(false);
                }
            }).catch((error) => {
                console.log("Error in APi calling", error);
            });
        }
    };

    return (
        <React.Fragment>
            <div className="container-fluid p-0 m-0">
                <div className="row p-0 m-0">
                    <div className="col-6 p-0 m-0">
                        <div className="createBusinessDeal container h-100">
                            <div className="back" onClick={() => gotoBack()}>
                                <ArrowBackIcon style={{color: "#fff", fontSize: "3em"}} />
                            </div>
                            <div className="logo">
                                <img className=" logosize" src={ShopezLogo} />
                            </div>
                            <div className="logo logo_text fontsize mt-3">
                                <div>“Increase your Traffic”</div>
                                <div>“More Revenue”</div>
                                <div>“Higher Exposure”</div>
                            </div>
                        </div>
                    </div>

                    {loginScreenVisible ?
                        <div className="col-6 p-0 m-0">
                            <div className="loginForm">
                                <div id="create-card" className="create_card_container">
                                    <div className="container col-12 mt-5 mb-5">
                                        <h1 className="heading-title mt-5 text-center">Tell us about your store</h1>
                                        <p className="h6-label text-center" style={{color: "#808080"}}>
                                            Enter your personal details and start journey with us.
                                        </p>
                                        <div>
                                            <ValidatorForm>
                                                <div className="row mt-5">
                                                    <div className="col-8 offset-2">
                                                        <TextValidator
                                                            errorMessages={[
                                                                "Please enter a valid owner name",
                                                                "Please enter a valid owner name",
                                                                "Please enter a owner name",
                                                            ]}
                                                            validators={[
                                                                "minStringLength:2",
                                                                "maxStringLength:30",
                                                                "required",
                                                            ]}
                                                            type="text"
                                                            onChange={handleChange}
                                                            inputProps={{ maxLength: 20 }}
                                                            name="owner_name"
                                                            label="Owner name"
                                                            variant="outlined"
                                                            value={storeDetails.owner_name}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-8 offset-2">
                                                        <TextValidator
                                                            errorMessages={[
                                                                "Please enter email",
                                                                "Please enter valid email",
                                                            ]}
                                                            validators={["required", "isEmail"]}
                                                            onChange={handleEmailChange}
                                                            name="email"
                                                            label="Email"
                                                            variant="outlined"
                                                            value={storeDetails.email}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-8 offset-2">
                                                        <div className="row">
                                                            <div className="col-11 pr-0 geosuggest-left-top-bottom">
                                                                <Geosuggest
                                                                    id="createstore"
                                                                    ref={geosuggestEl}
                                                                    placeholder="Business Address"
                                                                    name="location"
                                                                    fields={["address_components", "geometry"]}
                                                                    types={["address"]}
                                                                    country={["US", "CA"]}
                                                                    initialValue={storeDetails.location}
                                                                    value={storeDetails.location}
                                                                    onChange={(value) =>
                                                                        setStoreDetails((prevState) => ({
                                                                            ...prevState,
                                                                            ["location"]: value,
                                                                        }))
                                                                    }
                                                                    onSuggestSelect={onSuggestSelect}
                                                                    location={new google.maps.LatLng(lat, lng)}
                                                                    radius="100"
                                                                />
                                                            </div>
                                                            <div className="col-1 pl-0">
                                                                <div className="inputLocationIcon">
                                                                    <img onClick={() => promptPermission()} src={LocationDb}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col-8 offset-2">
                                                        <div className="row">
                                                            <div className="col-3 pr-0 br-left-top-bottom">
                                                                <FormControl variant="outlined">
                                                                    <InputLabel id="cCode-select"></InputLabel>
                                                                    <Select
                                                                        onChange={handleCountryCodeChange}
                                                                        value={countryCodeSelect}
                                                                        labelId="cCode-select"
                                                                        id="cCode-dropdown"
                                                                        >
                                                                        {countriesFlag.map((item) => {
                                                                            return (
                                                                                <MenuItem value={item.value}>
                                                                                    <img className="flagSize" src={item.flag} alt="" />
                                                                                    <span className="pl-1 h6-label">
                                                                                        {" "}{item.code}
                                                                                    </span>
                                                                                </MenuItem>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-9 pl-0 br-right-top-bottom">
                                                                <div className="flag_select">
                                                                    <InputMask
                                                                        mask={"(999) 999-9999"}
                                                                        maskChar="_"
                                                                        onChange={handleChange}
                                                                        value={storeDetails.phone_number}
                                                                        >
                                                                        {() => (
                                                                            <TextValidator
                                                                                required
                                                                                errorMessages={[
                                                                                    "Please enter phone number",
                                                                                ]}
                                                                                validators={[
                                                                                    "required",
                                                                                ]}
                                                                                name="phone_number"
                                                                                label="Phone Number"
                                                                                value={storeDetails.phone_number}
                                                                                variant="outlined"
                                                                                onChange={handleChange}
                                                                            />
                                                                        )}
                                                                    </InputMask>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col-8 offset-2">
                                                        <TextValidator
                                                            required
                                                            errorMessages={[
                                                                "Please enter valid store name",
                                                                "Please enter valid store name",
                                                                "Please enter store name",
                                                            ]}
                                                            validators={[
                                                                "minStringLength:2",
                                                                "maxStringLength:30",
                                                                "required",
                                                            ]}
                                                            name="store_company_name"
                                                            onChange={handleChange}
                                                            label="Company / Store Name"
                                                            variant="outlined"
                                                            value={storeDetails.store_company_name}
                                                        />
                                                        <div>
                                                            {storeName && storeDetails.store_company_name && storeDetails.store_company_name.length > 0 ? (
                                                                <div style={{ color: "green", paddingTop: "10px" }}>
                                                                    Store name is available
                                                                </div>
                                                            ) : !storeName && storeDetails.store_company_name && storeDetails.store_company_name.length > 0 ? (
                                                                <div style={{ color: "Red", paddingTop: "10px" }}>
                                                                    Store name is not available
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-8 offset-2">
                                                        <div id="recaptcha-container"></div>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col-8 offset-2">
                                                        <div className="saveAdmin-btn-center">
                                                            <Button id="sign-in-button" onClick={() => gotoWelcome()} className="btn btn-primary w-100 text-truncate mb-5" name="Continue"></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ValidatorForm>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                        <div className="col-6 p-0 m-0">
                            <div className="loginForm">
                                <div id="create-card" className="create_otp_card_container">
                                    <div className="container col-12">
                                        <h1 className="heading-title mb-3 mt-5 text-center">OTP verification</h1>
                                        <p className="h6-label text-center" style={{color: "#808080"}}>
                                            Please verify you phone number
                                        </p>

                                        <div>
                                            <ValidatorForm>
                                                <div className="row mt-4">
                                                    <div className="col-8 offset-2 d-flex justify-content-center">
                                                        <div className="forgot_password mt-4 otp-input">
                                                            <OtpInput
                                                                value={otpValue}
                                                                onChange={(e) => handleOtpChange(e)}
                                                                numInputs={6}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-6 offset-3">
                                                        <div style={{color: "#4472C4", textAlign: "end"}} className="h6-label mr-5" onClick={sendResendOtp}>
                                                            Resend OTP?
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="recaptcha-container"></div>
                                                <div className="row mt-4">
                                                    <div className="col-8 offset-2">
                                                        <div className="d-flex justify-content-center">
                                                            <Button id="sign-in-button" onClick={() => verifiedOtp()} className="mw-100 text-truncate btn btn-primary mb-5" name="Verify"></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ValidatorForm>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

export default CreateStore;
