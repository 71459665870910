import React, { useState,useEffect } from "react";
import "./onHoldStore.scss";
import { useHistory, useLocation,useParams } from "react-router-dom";
import Button from "../../../../../../components/button/button";
import urls from "../../../../../../utils/services/apiUrl.json";
import { postMethodWithToken, deleteMethodWithToken} from "../../../../../../utils/services/api";
import Modal from "react-modal";
import { toast } from "react-toastify";
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import StorefrontIcon from '@material-ui/icons/Storefront';
import { getMethod } from "../../../../../../utils/services/api";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import BussinessCred from '../../inReview/components/inReviewTabs/components/bussinessCredential/BussinessCred';
import FinancialInfo from '../../inReview/components/inReviewTabs/components/fanancialInformation/FinancialInfo';
import OpeningDays from '../../inReview/components/inReviewTabs/components/openingDays/OpeningDays';
import Products from '../../inReview/components/inReviewTabs/components/Products/Products';



const OnHoldStore = (props) => {
    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    const [currTab, setCurrTab] = useState(0);
    const [confirmation, setConfirmation] = useState(false);
    const [onHoldDetail,setOnHoldDetail] = useState([])

    function handleTabChange(event, newValue) {
        setCurrTab(newValue);
    }

    function handleChangeIndex(index) {
        setCurrTab(index);
    }

    const gotoBack = async () => {
        history.goBack()
    };

    const customStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: "25vw",
            height: "18vh",
        },
    };

    const backToApproved = async () => {
        let body = {
            shop_id: onHoldDetail.id,
        };
        await postMethodWithToken(urls.approve + "in_approved", body).then((response) => {
            if (response.status === 200) {
                history.push("/stores");
            }
        });
    };

    const cancelRequest = async () => {
        setConfirmation(false);
        await deleteMethodWithToken(urls.deleteStore + onHoldDetail.id + "/").then((response) => {
            if (response.status === 200) {
                history.push("/stores");
                toast.success(response.data.response);
            } else {
                toast.error("Something Went Wrong");
            }
        });
    };
    useEffect(()=>{
        handleOnHoldDetail()
    },[])
    const handleOnHoldDetail = async() =>{
        await getMethod(urls.getDetails + params.id + "/?query_for=onhold").then((response)=>{
            if(response.status === 200 || response.status === 201) {
                setOnHoldDetail(response.data.response)
            }
        })

    }

    return (
        <div className={props.menu ? "w-100 screenLeftMargin" : "w-100 screenLeftMarginResponse"}>
            <div className="row mt-3 pl-3 ml-3 mr-3 mobileResponsive">
                <div  onClick={() => gotoBack()} style={{cursor: "pointer"}}>
                    <ArrowBackIcon /> <a style={{color: "#4472C4"}} className="ml-2">Listings</a>
                </div>
            </div>
            <div className="row mt-2 ml-3 mr-3 mobileResponsive">
                <div className="col-7 d-flex">
                    <h1 className="heading-title">
                        <StorefrontIcon fontSize="large"/> On Hold Stores
                    </h1>
                </div>
                <div className="col-5 d-flex justify-content-end">
                    <Button onClick={() => backToApproved()} className="btn btn-primary mr-3" name="Approve"></Button>
                    <Button onClick={() => setConfirmation(true)} className="btn btn-cancle" name="Delete"></Button>
                </div>
            </div>

            <div className="row mt-3 ml-3 mr-3 mobileResponsive">
                <div className="col-12">
                <div className="reviewBox">
                        <div className="row ">
                            <div className="col-4 mt-3 pl-5">
                                <h3 className="h6-label">Shop Details</h3>
                                <div>
                                    <p className="h2-label mt-3">
                                        {onHoldDetail?.store_company_name}
                                    </p>
                                    <p className="h2-label mt-2">
                                        ID: <span>{onHoldDetail?.id}</span>
                                    </p>
                                </div>
                            </div>

                            <div className="col-4 mt-3">
                                <h3 className="h6-label">Owner Details</h3>
                                <p className="h2-label mt-3">
                                    {onHoldDetail?.owner_name}
                                </p>
                            </div>

                            <div className="col-4 mt-3 pr-5">
                                <h3 className="h6-label">Contact</h3>
                                <p className="mb-1 mt-3">
                                    <EmailIcon fontSize="small" style={{color: "#808080", marginRight:"5Px"}}/> {onHoldDetail?.email}
                                </p>
                                <p>
                                    <PhoneIcon fontSize="small" style={{color: "#808080", marginRight:"5Px"}}/>
                                    {onHoldDetail?.country_code}
                                    {onHoldDetail?.phone_number}
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4 mt-3">
                                <div className="ml-3 pl-3">
                                    <span>
                                        <p className="h5-label">Store Details</p>
                                    </span>
                                    <p className="h6-label mb-0">
                                        <b>Store Type</b>
                                        <span className="leadBox-details pl-4 ml-3">
                                            : {onHoldDetail?.store_types?.[0]?.name}
                                        </span>
                                    </p>
                                    <p className="h6-label mb-0">
                                        <b>Store Category</b>
                                        <span className="leadBox-details pl-2 ml-1">
                                            : {onHoldDetail?.strore_category?.[0]?.name}
                                        </span>
                                    </p>
                                        {onHoldDetail?.store_revenue?.slug && 
                                        <p className="h6-label mb-0">
                                            <b>Revenue</b>
                                            <span className="leadBox-details pl-5 ml-2">
                                                : {onHoldDetail?.store_revenue?.slug}
                                            </span>
                                        </p>
                                        }
                                </div>
                            </div>

                            <div className="col-4 mt-3">
                                <p className="h5-label mt-4">Address</p>
                                <p className="leadBox-details">
                                    {onHoldDetail?.store_address?.location}
                                </p>
                            </div>

                            <div className="col-4 mt-3">
                                <p className="h5-label mt-4">Manager Details</p>
                                <p className="mb-1 mt-3">
                                    {/* <b>{inReviewDetail.manager_detail[0].related_manager.name}</b> */}
                                </p>
                                <p className="mb-1">
                                    <EmailIcon fontSize="small" style={{color: "#808080", marginRight:"5Px"}}/> 
                                    {onHoldDetail?.manager_detail?.[0]?.related_manager?.email}
                                </p>
                                <p>
                                    <PhoneIcon fontSize="small" style={{color: "#808080", marginRight:"5Px"}}/>
                                    {onHoldDetail?.manager_detail?.[0]?.related_manager?.phone_number}
                                </p>
                            </div>
                        </div>

                        
                        <div className="w-100">
                            <Tabs
                                value={currTab}
                                onChange={handleTabChange}
                                textColor="inherit"
                                variant="standard"
                                >
                                <Tab
                                    label="Business Credentials"
                                />
                                <Tab
                                    label="Financial Information"
                                />
                                {onHoldDetail?.opening_days?.length !== 0 && 
                                <Tab
                                    label="Opening Days"
                                />
                                }
                                <Tab
                                    label="Products"
                                />
                            </Tabs>
                        </div>
                    </div>
                    <div>
                         <div className="w-100">
                            <SwipeableViews index={currTab} onChangeIndex={handleChangeIndex} enableMouseEvents={true}>
                                <div>
                                    <BussinessCred data={onHoldDetail} />
                                </div>
                                <div>
                                    <FinancialInfo data={onHoldDetail} />
                                </div>
                                {onHoldDetail?.opening_days?.length !== 0 ?
                                <div>
                                    <OpeningDays data={onHoldDetail} />
                                </div>
                                :
                                <div>
                                <Products data={onHoldDetail} />
                                </div>
                                }
                                <div>
                                    <Products data={onHoldDetail} />
                                </div>
                            </SwipeableViews>
                        </div> 
                    </div>
                </div>
            </div>

            <Modal isOpen={confirmation} style={customStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="mt-4 mb-4">
                    <div className="row">
                        <div className="col-12 text-center">
                            Are you sure you want to delete this store?
                        </div>
                        <div className="col-12 mt-4  d-flex justify-content-center">
                            <Button type="onSubmit" onClick={() => setConfirmation(false)} name="Cancel" className="btn btn-cancle btn-sm mr-3"/>
                            <Button type="onSubmit" onClick={() => {cancelRequest();}} name="Delete" className="btn btn-primary btn-sm"/>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default OnHoldStore;
