import React, { useState } from "react";
import "./Products.scss";
import NoImage from "../../../../../../../../../assets/images/storelisting.png";

const Products = ({data}) => {
    const [expandIndex, setExpandIndex] = useState("");

    const expandDropdown = (index) => {
        console.log(index)
        if (expandIndex === index) {
          setExpandIndex("");
        } else {
          setExpandIndex(index);
        }
    }



    // console.log(data)

    
    return (
        <div>
             
            <div className="row mt-3 pt-3 pb-3 mr-0 ml-0">
                <div className="col-2 f-600"></div>
                <div className="col-3 f-600">Product Name</div>
                <div className="col-2 f-600">SKU</div>
                <div className="col-1 f-600">Qty.</div>
                <div className="col-1 f-600">Cost</div>
                <div className="col-2 f-600">Price</div>
                <div className="col-1 f-600">Inv Alert</div>
            </div>
            
            
            {data.product_listing && data.product_listing.length> 0 ?
              data.product_listing.map((item, index) => {
                return (
                    <>
                        <div key={index + 1} className="row ft-14 mt-3 pt-3 pb-3 mr-0 ml-0" style={{background: "#ECECEC"}}>
                            <div className="col-2 d-flex" style={{alignItems: "center", cursor: "pointer"}}>
                                {item.children.length > 0 && expandIndex !== index ? (
                                    <i
                                        style={{marginRight: "10px", fontSize: "20px", verticalAlign: "middle"}}
                                        className="fa fa-angle-down iconColorArrow"
                                        onClick={() => expandDropdown(index)}
                                        aria-hidden="true"
                                    ></i>
                                ) : item.children.length > 0 && expandIndex === index ? (
                                    <i
                                        style={{marginRight: "10px", fontSize: "20px", verticalAlign: "middle"}}
                                        className="fa fa-angle-up iconColorArrow"
                                        onClick={() => expandDropdown(index)}
                                        aria-hidden="true"
                                    ></i>
                                ) : null} 
                                {item.images && item.images.slice(0, 1).map((item, i) => {
                                    return (
                                        <img className="listedImg" src={item.original} alt=""/>
                                    );
                                })} 

                                {typeof json_data !== "undefined" && item.images.length === 0 &&
                                    <img className="listedImg" src={NoImage} alt="" />
                                }
                            </div>
                            <div className="col-3 d-flex" style={{alignItems: "center"}}>
                                {item?.product_name}
                            </div>
                            <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                {item?.partner_sku}
                            </div>
                            <div className="col-1 d-flex" style={{alignItems: "center"}}>
                                {item?.num_in_stock}
                            </div>
                            <div className="col-1 d-flex" style={{alignItems: "center"}}>
                                {item?.invoice_cost}
                            </div>
                            <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                {item?.price}
                            </div>
                            <div className="col-1 d-flex" style={{alignItems: "center"}}>
                                {item?.low_stock_threshold}
                            </div>
                        </div>

                        {item?.children.length > 0 && expandIndex === index && item?.children.map((items, indexs) => {
                            return (
                                <div key={indexs + 1} className="row ft-14 mt-3 pt-3 pb-3 mr-0 ml-0" style={{background: "#ECECEC"}}>
                                    <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                        {items.images && items.images.slice(0, 1).map((items, i) => {
                                            return (
                                                <img className="listedImg" src={items.original} alt="" />
                                            );
                                        })}
                                        {typeof json_data !== "undefined" && items.images.length === 0 &&
                                            <img className="listedImg" src={NoImage} alt="" />
                                        }
                                    </div>
                                    <div className="col-3 d-flex" style={{alignItems: "center"}}>
                                        {items?.product_name}
                                    </div>
                                    <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                        {items?.partner_sku}
                                    </div>
                                    <div className="col-1 d-flex" style={{alignItems: "center"}}>
                                        {items?.num_in_stock}
                                    </div>
                                    <div className="col-1 d-flex" style={{alignItems: "center"}}>
                                        {items?.invoice_cost}
                                    </div>
                                    <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                        {items?.price}
                                    </div>
                                    <div className="col-1 d-flex" style={{alignItems: "center"}}>
                                        {items?.low_stock_threshold}
                                    </div>
                                </div>
                            );
                        })}
                     </>
                );
            })
            :
            <div className="mt-3 pt-3 pb-3 mr-0 ml-0 noMatch">No matching product found.</div> 
            }
        </div>
    );
};

export default Products;
