import React, { useState, useEffect } from "react";
import "./StoreDetails.scss";
import Button from "../../../../components/button/button";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Allocate from "../../../../assets/images/Allocate_A.svg";
import WebIcon from "../../../../assets/images/Web_W.svg";
import Phone from "../../../../assets/images/Call_C.svg";
import NoImage from "../../../../assets/images/storelisting.png";
import Email from "../../../../assets/images/Email_E.svg";
import Bell from "../../../../assets/images/Bell_B.svg";
import { useHistory } from "react-router-dom";
import OnlineEnq from "../../../../assets/images/onlineEnqM.svg";
import { useLocation } from "react-router-dom";
import {getMethod, putMethodWithToken, putMethod, postMethod} from "../../../../utils/services/api";
import urls from "../../../../utils/services/apiUrl.json";
import Modal from "react-modal";
import { toast } from "react-toastify";
import RoleMan from "../../../../assets/images/man.png";
import Edit from "../../../../assets/images/Edit_E.svg";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import EditIcon from '@material-ui/icons/Edit';

const StoreDetails = (props) => {
    const history = useHistory();
    const location = useLocation();

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    
    const [storeInfo, setStoreInfo] = useState(location.state.item);
    const [checked, setChecked] = React.useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [managerList, setMangerList] = useState("");
    const [edit, setEdit] = useState(false);
    const [managerInfo, setManagerInfo] = useState("");
    const [allocate, setAllocate] = useState(true);
    const [state, setState] = useState({
        email: "",
    });

    let allocateManagerDetails = storeInfo.manager_detail;
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const emailChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value.trim(),
        }));
    };

    const customStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 800 ? "40vw" : screenSize.width <= 1100 ? "28vw" : "25vw",
            height: screenSize.width <= 800 ? "15vh" : "18vh",
        },
    };

    const notification = async () => {
        history.push("/notifications");
    };
    
    const gotoBack = async () => {
        history.goBack()
    };

    const promoteRequest = async () => {
        if (managerInfo !== "" && managerInfo.manager_detail.length !== 0) {
            await putMethodWithToken(urls.promote + storeInfo.id + "/", "").then((response) => {
                if (response.status == 200) {
                    toast.success(response.data.response);
                    history.push("/stores");
                }
            });
        } else {
            toast.error("Please Allocate Manager");
        }
    };

    const cancelRequest = async () => {
        setConfirmation(false);
        await putMethodWithToken(urls.cancelStoreRequest + storeInfo.id + "/", "").then((response) => {
            if (response.status == 200) {
                toast.success(response.data.response)
                history.push("/onlineenquiry");
            }
        });
    };

    const updateEmail = async () => {
        let body = {
            email: state.email,
        };
        await putMethod(urls.emailUpdate + storeInfo.id + "/", body).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.response);
            } else {
                toast.error(response.data.response);
            }
        });
    };

    useEffect(() => {
        getAllocateManager();
        getMangerList();
    }, []);

    const getMangerList = async () => {
        await getMethod(urls.getManagerList + "account-manager").then((response) => {
            setMangerList(response.data.results.data);
        }).catch((error) => {
            console.log(error);
        });
    };

    const allocateManager = async (managerId) => {
        let body = {
            manager_id: managerId,
            shop_meta_id: storeInfo.id,
        };
        if ((managerInfo && managerInfo.manager_detail.length <= 0) || !edit) {
            await postMethod(urls.allocateManager, body).then((response) => {
                if (response.status === 201) {
                    toast.success(response.data.response);
                    setAllocate(true);
                    setEdit(false);
                    getAllocateManager();
                } else {
                    toast.error("Something went Wrong");
                }
            }).catch((error) => {
                console.log(error);
            });
        } else {
            await putMethod(urls.allocateManager + storeInfo.id + "/", body).then((response) => {
                if (response.status === 201) {
                    toast.success(response.data.response);
                    setAllocate(true);
                    setEdit(false);
                    getAllocateManager();
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    const getAllocateManager = async () => {
        await getMethod(urls.allocateManagerDetails + storeInfo.id + "/").then((response) => {
            // console.log(response)
            if (response.status === 200) {
                setManagerInfo(response.data);
            }
        });
    };

    return (
        <div className={ props.menu ? "w-100 screenLeftMargin" : "w-100 screenLeftMarginResponse"}>
            <div className="row mt-3 ml-3 mr-3 mobileResponsive">
                <div className="col-6">
                    <div  onClick={() => gotoBack()} style={{cursor: "pointer"}}>
                        <ArrowBackIcon /> <a style={{color: "#4472C4"}} className="ml-2">Listings</a>
                    </div>
                </div>

                {/*<div className="col-6 d-flex justify-content-end">
                    <div className="muiNotificationsIcon d-flex" onClick={() => notification()}>
                        <NotificationsIcon style={{color: "#fff", fontSize: "30px"}}/>
                    </div>
                    <div className="muiNotificationsIcon d-flex ml-3">
                        <EmailIcon style={{color: "#fff", fontSize: "30px"}}/>
                    </div>
                </div>*/}
            </div>

            <div className="row mt-3 ml-3 mr-3 mobileResponsive">
                <div className="col-6">
                    <div className="enquiry-text">
                        <img className="headIcon" src={OnlineEnq} />
                        <span className="heading-title pl-2">Online Enquiry</span>
                    </div>
                </div>

                <div className="col-6" style={{textAlign: "right"}}>
                    <Button onClick={() => promoteRequest()} className="btn btn-primary mr-3" name="Promote"></Button>
                    <Button onClick={() => setConfirmation(true)} className="btn btn-cancle" name="Delete"></Button>
                </div>
            </div>

            <div className="row mt-3 ml-3 mr-3 mobileResponsive">
                <div className="col-12">
                    <div className="reviewBox_promote">
                        <div className="row p-0 m-0">
                            <div className="col-sm-4">
                                <div className="h6-label mt-4 ml-4">Store Details</div>
                                <div className="row mt-3">
                                    <div className="col-sm-12 ml-4">
                                        <div className="h2-label mb-2">
                                            {storeInfo?.store_company_name}
                                        </div>
                                        <div className="h2-label mt-2">
                                            ID: <span>{storeInfo?.id}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-4 mt-4">
                                <div className="h6-label">Owner Details</div>
                                <div className="h2-label mt-3">{storeInfo?.owner_name}</div>
                            </div>

                            <div className="col-sm-4 p-0 m-0 mt-4">
                                <div className="h6-label">Contact</div>
                                <p className="mb-1 mt-3">
                                    <EmailIcon fontSize="small" style={{color: "#808080", marginRight: "5px", marginTop: "-1px"}}/>
                                    {storeInfo?.email}
                                </p>
                                <p>
                                    <PhoneIcon fontSize="small" style={{color: "#808080", marginRight: "5px", marginTop: "-1px"}}/>
                                    {storeInfo?.country_code + " " + storeInfo?.phone_number}
                                </p>
                            </div>
                        </div>

                        <div className="ml-4 mt-5">
                            <span>
                                <Checkbox color="primary" />
                                <span className="h6-label">
                                    Use <b>{storeInfo?.email}</b> as the store’s login credentials.
                                </span>
                            </span>

                            <div className="h6-label mt-2 ">
                                <Checkbox
                                    color="primary"
                                    checked={checked}
                                    onChange={handleChange}
                                />
                                Add other email
                                <span className={!checked ? "discheckbox" : "ml-3"}>
                                    <TextField
                                        label="Email"
                                        name="email"
                                        onChange={emailChange}
                                        className="checkbox br-left-top-bottom"
                                        placeholder="Enter your email here"
                                        value={state.email}
                                        variant="outlined"
                                    />
                                    <button onClick={() => updateEmail()} className="btn-checkbox">
                                        <i className="fa fa-angle-right"></i>
                                    </button>
                                </span>
                            </div>
                        </div>

                         {managerInfo !== "" && managerInfo.manager_detail.length !== 0 ? (
                            <div>
                                <div>
                                    <div className="h5-label ml-4 mt-5">
                                        Allocated Account Manager for the Store
                                    </div>
                                </div>

                                <div className="row mt-3 pt-3 pb-3 ml-3 mr-3 mb-3" style={{background: "#FFFFFF"}}>
                                    <div className="col-4 d-flex" style={{alignItems: "center"}}>
                                        <img
                                            className="listedRadiusImg"
                                            src={managerInfo !== "" && managerInfo.manager_detail.length !== 0 ? managerInfo.manager_detail[0].related_manager.profile_pic.file : ""}
                                        />
                                        <span className="ml-3">{managerInfo !== "" && managerInfo.manager_detail.length !== 0 ? managerInfo.manager_detail[0].related_manager.name : ""}</span>
                                    </div>

                                    <div className="col-4">
                                        <div>
                                            <PhoneIcon fontSize="small" style={{color: "#808080", marginRight:"5Px", marginTop: "-1px"}}/>
                                            {managerInfo !== "" && managerInfo.manager_detail.length !== 0 ? managerInfo.manager_detail[0].related_manager.phone_number : ""}
                                        </div>
                                        <div>
                                            <EmailIcon fontSize="small" style={{color: "#808080", marginRight:"5Px", marginTop: "-1px"}}/>
                                            {managerInfo !== "" && managerInfo.manager_detail.length !== 0 ? managerInfo.manager_detail[0].related_manager.email : ""}
                                        </div>
                                    </div>

                                    <div className="col-4 d-flex" style={{alignItems: "center", justifyContent: "end"}}>
                                        <div className="rounded-circle bg-light edit-small" onClick={() => setEdit(true)}>
                                            <EditIcon fontSize="medium" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div>
                                    <div className="h5-label ml-4 mt-4">
                                        Allocate Key Account Manager for the Store
                                    </div>
                                </div>
                            </div>
                        )} 
                    </div>

                    <div className="show mr-3 ml-3">
                         {managerList.length > 0 && ((managerInfo && managerInfo.manager_detail.length <= 0) || edit) ? (
                            <>
                                {managerList.map((item) => {
                                    return (
                                        <div className="row mt-3 pt-3 pb-3" style={{background: "#ECECEC"}}>
                                            <div className="col-4 d-flex" style={{alignItems: "center"}}>
                                                <img
                                                    className="listedRadiusImg"
                                                    src={item?.profile_pic.file}
                                                />
                                                <span className="ml-3">{item?.name}</span>
                                            </div>
                                            <div className="col-4">
                                                <div>
                                                    <PhoneIcon fontSize="small" style={{color: "#808080", marginRight:"5Px", marginTop: "-1px"}}/>
                                                    {item?.phone_number}
                                                </div>
                                                <div>
                                                    <EmailIcon fontSize="small" style={{color: "#808080", marginRight:"5Px", marginTop: "-1px"}}/>
                                                    {item?.email}
                                                </div>
                                            </div>
                                            <div className="col-4 d-flex" style={{alignItems: "center", justifyContent: "end"}}>
                                                <Button onClick={() => allocateManager(item.id)} className="btn btn-cancle ml-3" name="Allocate"></Button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        ) : null} 
                    </div>
                </div>
            </div>

            <Modal isOpen={confirmation} style={customStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="mt-4 mb-4">
                    <div className="row">
                        <div className="col-12 text-center">
                            Are you sure you want to delete this store?
                        </div>
                        <div className="col-12 mt-4  d-flex justify-content-center">
                            <Button type="onSubmit" onClick={() => setConfirmation(false)} name="Cancel" className="btn btn-sm btn-cancle mr-3" />
                            <Button type="onSubmit" onClick={() => {cancelRequest()}} name="Delete" className="btn btn-sm btn-primary"/>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default StoreDetails;
