import React, { useEffect, useState, useRef } from "react";
import "./ordersTab.scss";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import PrintDB from "../../../assets/images/Print_P.svg";
import ShareDB from "../../../assets/images/Share_S.svg";
import ProductInfo from "../orders/productInfo/productInfo";
import PackingSlip from "./packingSlip/packingSlip";
import Invoice from "./Invoice/Invoice";
import PackiReturnLabelSlip from "./returnLabel/ReturnLabel";
import CancleLabelSlip from "./cancle/cancle";
import GiftReciept from "./giftReciept/giftReciept";
import Button from "../../../components/button/button";
import Save from "../../../assets/images/Save_S.svg";
import Edit from "../../../assets/images/Edit_Add.svg";
import Box from "@material-ui/core/Box";
import EmailDB from "../../../assets/images/email.png";
import CallDB from "../../../assets/images/Call_C.svg";
import LocationDB from "../../../assets/images/location.png";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getMethodWithPlatfrom, getMethod, postMethodWithToken, deleteMethodWithToken, getPostMethodWithToken, postMethodWithPlatform } from "../../../utils/services/api";
import { getUserRole, getStoreId } from "../../../utils/common";
import urls from "../../../utils/services/apiUrl.json";
import moment from "moment";
import Modal from "react-modal";
import { useReactToPrint } from "react-to-print";
import { RWebShare } from "react-web-share";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PrintIcon from '@material-ui/icons/Print';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import DeleteIcon from '@material-ui/icons/Delete';
import PinDropIcon from '@material-ui/icons/PinDrop';
import { toast } from 'react-toastify';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import OrderReceivedIcon from "../../../assets/images/order/orderReceived.png";
import OrderinProcessIcon from "../../../assets/images/order/orderinProcess.png";
import OrderReadyIcon from "../../../assets/images/order/orderReady.png";
import OrderShippedIcon from "../../../assets/images/order/orderShipped.png";
import SpinnerLoader from "../../../components/loader/spinnerLoader";
import { Checkbox, FormControl, InputAdornment, makeStyles, MenuItem, TextField } from "@material-ui/core";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const checkCancleProcess = data => {
    const emptyKeys = [
        'productId',
        'message',
    ];

    const emptyKeysErrors = [
        'Product',
        'Message',
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!data[emptyKeys[i]]) {
            return `Please fill in ${emptyKeysErrors[i]}`;
        }

        if (data[i] === 'productId' && data[emptyKeys[i]].length === 0) {
            return `Please fill in ${emptyKeysErrors[i]}`;
        }
    }

    return '';
};

const checkReturnProcess = data => {
    const emptyKeys = [
        'productId',
        'message',
    ];

    const emptyKeysErrors = [
        'Product',
        'Message',
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!data[emptyKeys[i]]) {
            return `Please fill in ${emptyKeysErrors[i]}`;
        }

        if (data[i] === 'productId' && data[emptyKeys[i]].length === 0) {
            return `Please fill in ${emptyKeysErrors[i]}`;
        }
    }

    return '';
};

const checkRefundProcess = data => {
    const emptyKeys = [
        'amount',
    ];

    const emptyKeysErrors = [
        'Amount',
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!data[emptyKeys[i]]) {
            return `Please fill in ${emptyKeysErrors[i]}`;
        }
    }

    return '';
};

function OrdersTab(props) {
    const orderStatusColor = {
        OrderReceived: "#C00000",
        OrderinProcess: "#C00000",
        OrderReady: "#FFC000",
        OrderShipped: "#00AF50",
        OrderCancelled: "#C55B11",
        OrderReturned: "#C55B11"
    };

    const OrderIdParam = useParams();

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const componentRefSlip = useRef();
    const componentRefInvoice = useRef();
    const componentRefReturn = useRef();
    const componentRefCancle = useRef();
    const componentRefProductList = useRef();
    const labelClickSlip = useRef();
    const history = useHistory();
    var panelOpen = window.$panelActive;
    const [currTab, setCurrTab] = React.useState(0);
    const [loading, setLoading] = useState(false);
    const [customerDetails, setCustomerDetails] = useState({});
    const [orderHistoryList, setOrderHistoryList] = useState([]);
    const [orderStatus, setOrderStatus] = useState("");
    const [orderOldStatus, setOrderOldStatus] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    const [labelSlipLink, setLabelSlipLink] = useState("");
    const [orderProductList, setOrderProductList] = useState([]);
    const [orderNotesList, setOrderNotesList] = useState([]);
    const [orderNoteMsg, setOrderNoteMsg] = useState("");
    const [isNotesOpen, setIsNotesOpen] = useState(false);
    const [buttonLabel, setButtonLabel] = useState('Start Processing');
    const [isCancleListModalFlag, setIsCancleListModalFlag] = useState(false);
    const [isRefundModalFlag, setIsRefundModalFlag] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [productInfoData, setProductInfoData] = useState([]);
    const [cancleProductInfoData, setCancleProductInfoData] = useState([]);
    const [returnProductInfoData, setReturnProductInfoData] = useState([]);
    const [orderProductInfoData, setOrderProductInfoData] = useState([]);
    const [orderCancellationFlag, setOrderCancellationFlag] = useState("");
    const [orderReturnedFlag, setOrderReturnedFlag] = useState("");
    const [cancleProductIds, setCancleProductIds] = useState([]);
    const [returnProductIds, setReturnProductIds] = useState([]);
    const [isSelectedAll, setIsSelectedAll] = useState(false);
    const [processTypeModal, setProcessTypeModal] = useState('cancel');
    const [refundTypeModal, setRefundTypeModal] = useState('cancel');
    const [refundTypeData, setRefundTypeData] = useState([]);
    const [refundStatus, setRefundStatus] = useState("");
    const [refundAmount, setRefundAmount] = useState("");
    const [visible, setVisible] = useState(true)
    const [isRefundModalAdmin, setIsRefundModalAdmin] = useState(false)
    const [refundValue,setRefundValue] = useState("")

    const [cancleStateValue, setCancleStateValue] = useState({
        storeInfo_id: getUserRole() === "super-admin" ? OrderIdParam.store_id : getStoreId(),
        order_id: getUserRole() === "super-admin" ? OrderIdParam.order_id : OrderIdParam.id,
        productId: "",
        message: "",
        isCancelled: true,
    });

    const [returnStateValue, setReturnStateValue] = useState({
        storeInfo_id: getUserRole() === "super-admin" ? OrderIdParam.store_id : getStoreId(),
        order_id: getUserRole() === "super-admin" ? OrderIdParam.order_id : OrderIdParam.id,
        productId: "",
        message: "",
        isReturned: true,
    });

    const [refundStateValue, setRefundStateValue] = useState({
        orderId: getUserRole() === "super-admin" ? parseInt(OrderIdParam.order_id) : parseInt(OrderIdParam.id),
        amount: "",
    });

    function handleTabChange(event, newValue) {
        setCurrTab(newValue);
    }

    function handleChangeIndex(index) {
        setCurrTab(index);
    }

    const getCustomerDetails = async (url, type) => {
        setLoading(true);
        await getMethodWithPlatfrom(url, type).then((response) => {
            if (response.status === 200) {
                setCustomerDetails(response.data);
                setLoading(false);
                setOrderProductList(response.data.productInfo)
            }
        });
        setLoading(false);
    };

    const orderHistoryProcess = async () => {
        if (getUserRole() === "super-admin") {
            await getMethodWithPlatfrom(urls.orderStatusHistory + '?order_id=' + OrderIdParam.order_id + '&store_id=' + OrderIdParam.store_id, "super-admin").then((response) => {
                if (response.data.latestStatus === 'Order Shipped') {
                    setButtonLabel('Return Order');
                } else if (response.data.latestStatus === 'Order Ready' || (response.data.latestStatus === 'Order Cancelled' && response.data.oldStatus === 'Order Ready')) {
                    setButtonLabel('Complete Order');
                } else if (response.data.latestStatus === 'Order in Process' || (response.data.latestStatus === 'Order Cancelled' && response.data.oldStatus === 'Order in Process')) {
                    setButtonLabel('Finish Processing');
                } else {
                    setButtonLabel('Start Processing');
                }

                setOrderStatus(response.data.latestStatus)
                setOrderOldStatus(response.data.oldStatus)
                setPaymentStatus(response.data.paymentStatus)
                setOrderHistoryList(response.data.history)
            });
        } else {

            await getMethod(urls.orderStatusHistory + '?order_id=' + OrderIdParam.id).then((response) => {
                if (response.data.latestStatus === 'Order Shipped') {
                    setButtonLabel('Return Order');
                } else if (response.data.latestStatus === 'Order Ready' || (response.data.latestStatus === 'Order Cancelled' && response.data.oldStatus === 'Order Ready')) {
                    setButtonLabel('Complete Order');
                } else if (response.data.latestStatus === 'Order in Process' || (response.data.latestStatus === 'Order Cancelled' && response.data.oldStatus === 'Order in Process')) {
                    setButtonLabel('Finish Processing');
                } else {
                    setButtonLabel('Start Processing');
                }

                setOrderStatus(response.data.latestStatus)
                setOrderOldStatus(response.data.oldStatus)
                setPaymentStatus(response.data.paymentStatus)
                setOrderHistoryList(response.data.history)
            });
        }
    }

    const getOrderDetails = async (url, type) => {
        await getMethodWithPlatfrom(url, type).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setProductInfoData(response.data.results.data);
                setCancleProductInfoData(response.data.results.cancellation);
                setReturnProductInfoData(response.data.results.returned);
                setOrderProductInfoData(response.data);
                setOrderCancellationFlag(response.data.results.cancellationflag);
                setOrderReturnedFlag(response.data.results.returnedflag);
                setRefundAmount(response.data.results.RefundAmount)
                setRefundStatus(response.data.results.refundStatus)
            }
        });
    };

    const getOrderNotes = async () => {
        if (getUserRole() === "super-admin") {
            getMethod(urls.getOrderNotes + '?order_id=' + OrderIdParam.order_id).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setOrderNotesList(response.data.response)
                }
            });
        } else {
            getMethod(urls.getOrderNotes + '?order_id=' + OrderIdParam.id).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setOrderNotesList(response.data.response)
                }
            });
        }
    }

    const getRefundTypeData = async () => {
        if (getUserRole() === "super-admin") {
            await getMethod(urls.getRefundProcess + '?order_id=' + OrderIdParam.order_id ).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setRefundTypeData(response.data)
                }
            });
        } else {
            await getMethod(urls.getRefundProcess + '?order_id=' + OrderIdParam.id).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setRefundTypeData(response.data)

                    response.data.data.forEach((item, index) => {
                        if (response.data.orderStatus === 'Complete' && item.label === 'complete refund') {
                            setRefundStateValue((prevState) => ({
                                ...prevState,
                                ['amount']: parseInt(item.price) * 100,
                            }));
                        } else {
                            setRefundStateValue((prevState) => ({
                                ...prevState,
                                ['amount']: parseInt(item.price) * 100,
                            }));
                        }
                       
                    })
                }
            });
        }

    }

    useEffect(() => {
        if (getUserRole() === "super-admin") {
            getCustomerDetails(urls.ordersAdmin + "?order_id_details=" + OrderIdParam.order_id + "&store_id_details=" + OrderIdParam.store_id , "super-admin");
            getOrderDetails(urls.ordersAdmin + "?stores_id=" + OrderIdParam.store_id + "&order_id=" + OrderIdParam.order_id, "super-admin");
            refundValueAdmin()
        } else {
            getCustomerDetails(urls.ordersMerchant + "?order_id_details=" + OrderIdParam.id, "merchant");
            getOrderNotes();
            getOrderDetails(urls.ordersMerchant + "?order_id=" + OrderIdParam.id, "merchant");
        }

        orderHistoryProcess();
        getRefundTypeData();

    }, []);

    const refundValueAdmin = async () => {
        if (getUserRole() === "super-admin") {
            await getMethodWithPlatfrom(urls.ordersAdmin + "?stores_id=" + OrderIdParam.store_id + "&order_id_refund=" + OrderIdParam.order_id ,"super-admin").then((response) => {
                if(response.status === 200 || response.status === 201) {
                    setRefundValue(response.data)
                }
            })
        }

    }

    const handleProductList = useReactToPrint({
        content: () => componentRefProductList.current,
    });

    const handlePrintSlip = useReactToPrint({
        content: () => componentRefSlip.current,
        onBeforePrint: () => {
            setConfirmationModal(false)
        }
    });

    const handlePrintInvoice = useReactToPrint({
        content: () => componentRefInvoice.current,
    });

    const handlePrintReturn = useReactToPrint({
        content: () => componentRefReturn.current,
    });

    const handlePrintCancle = useReactToPrint({
        content: () => componentRefCancle.current,
    });

    const gotoBack = async () => {
        /*if (getUserRole() === "super-admin") {
            history.push(`/orders/listing/${OrderIdParam.store_id}`);
        } else {
            history.push("/orders/listing");
        }*/
        history.goBack()
    };

    const timeFormat = (date) => {
        let requiredFormat = moment(date).format("lll");
        return requiredFormat;
    };

    const orderStartProcessing = async () => {
        setOrderHistoryList([])

        await getMethod(urls.orderStatusHistory + '?order_id=' + OrderIdParam.id + '&start_processing=1').then((response) => {
            if (response.status === 200 || response.status === 201) {
                setOrderStatus(response.data.latestStatus);
                setOrderOldStatus(response.data.oldStatus);
                setPaymentStatus(response.data.paymentStatus);
                setOrderHistoryList(response.data.history);
                setButtonLabel('Finish Processing');
                setCurrTab(1);
                setConfirmationModal(true);
            } else {
                toast.error(response.data.response);
                orderHistoryProcess();
            }
        });
    }

    const orderFinishProcessing = async () => {
        setOrderHistoryList([])

        await getMethod(urls.orderStatusHistory + '?order_id=' + OrderIdParam.id + '&finish_processing=1').then((response) => {
            if (response.status === 200 || response.status === 201) {
                setOrderStatus(response.data.latestStatus);
                setOrderOldStatus(response.data.oldStatus);
                setPaymentStatus(response.data.paymentStatus);
                setOrderHistoryList(response.data.history);
                setButtonLabel('Complete Order');
            } else {
                toast.error(response.data.response);
                orderHistoryProcess();
            }
        });
    }

    const orderCompleteProcessing = async () => {
        setOrderHistoryList([])

        await getMethod(urls.orderStatusHistory + '?order_id=' + OrderIdParam.id + '&complete_order=1').then((response) => {
            if (response.status === 200 || response.status === 201) {
                setOrderStatus(response.data.latestStatus);
                setOrderOldStatus(response.data.oldStatus);
                setPaymentStatus(response.data.paymentStatus);
                setOrderHistoryList(response.data.history);
                setButtonLabel('Return Order');

                window.open(response.data.shippoData.label_url, '_blank');
            } else {
                toast.error(response.data.response);
                orderHistoryProcess();
            }
        });
    }

    const handleOrderNotes = (e) => {
        setOrderNoteMsg(e.target.value)
    }

    const handleOrderCancleNotes = (e, type) => {
        const { name, value } = e.target;

        if (type === 'cancel' || type === 'cancelProcess') {
            setCancleStateValue((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else {
            setReturnStateValue((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }

    }

    const handleOrderCancleCheckbox = (e, id, index, type) => {
        if (type === 'cancel' || type === 'cancelProcess') {
            const list = [...cancleProductIds];

            if (e.target.checked) {
                if (!list.includes(id)) {
                    list.push(id)
                }
            } else {
                list.splice(index, 1);
            }

            setCancleProductIds(list)
            setCancleStateValue((prevState) => ({
                ...prevState,
                ['productId']: list,
            }));
        } else {
            const list = [...returnProductIds];

            if (e.target.checked) {
                if (!list.includes(id)) {
                    list.push(id)
                }
            } else {
                list.splice(index, 1);
            }

            setReturnProductIds(list)
            setReturnStateValue((prevState) => ({
                ...prevState,
                ['productId']: list,
            }));
        }
    }

    const handleSelectAll = (type) => {
        if (isSelectedAll !== true) {
            if (type === 'cancel' || type === 'cancelProcess') {
                const list = [...cancleProductIds];

                setIsSelectedAll(true)
                productInfoData.forEach((x, i) => {
                    if (!list.includes(x.product_id)) {
                        list.push(x.product_id)
                    }
                });

                setCancleProductIds(list)
                setCancleStateValue((prevState) => ({
                    ...prevState,
                    ['productId']: list,
                }));
            } else {
                const list = [...returnProductIds];

                setIsSelectedAll(true)
                productInfoData.forEach((x, i) => {
                    if (!list.includes(x.product_id)) {
                        list.push(x.product_id)
                    }
                });

                setReturnProductIds(list)
                setReturnStateValue((prevState) => ({
                    ...prevState,
                    ['productId']: list,
                }));
            }

        } else {
            if (type === 'cancel' || type === 'cancelProcess') {
                setCancleProductIds([])
                setCancleStateValue((prevState) => ({
                    ...prevState,
                    ['productId']: [],
                }));
                setIsSelectedAll(false)
            } else {
                setReturnProductIds([])
                setReturnStateValue((prevState) => ({
                    ...prevState,
                    ['productId']: [],
                }));
                setIsSelectedAll(false)
            }
        }
    }

    const handleAmountChange = (e) => {
        const { name, value } = e.target;

        if (value) {
            setRefundStateValue((prevState) => ({
                ...prevState,
                [name]: parseInt(value) * 100,
            }));
        } else {
            setRefundStateValue((prevState) => ({
                ...prevState,
                [name]: 0,
            }));
        }
    };

    const onOrderSaveNotes = async () => {
        let body = {
            user: getUserRole() === "super-admin" ? OrderIdParam.store_id : getStoreId(),
            order_id: getUserRole() === "super-admin" ? OrderIdParam.order_id : OrderIdParam.id,
            message: orderNoteMsg,
        }

        await postMethodWithToken(urls.postOrderNotes, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(response.data.response)
                setIsNotesOpen(false)
                getOrderNotes()
            } else {
                toast.error(response.data.response)
            }
        });
    }

    const onDeleteNotes = async (noteId) => {
        await deleteMethodWithToken(urls.deleteOrderNote + noteId + '/').then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(response.data.response)
                getOrderNotes()
            } else {
                toast.error(response.data.response)
            }
        });
    }

    const onOpenNotesModal = () => {
        setIsNotesOpen(true)
    }

    const onCloseNotesModal = () => {
        setIsNotesOpen(false)
    }

    const onOpenCancleListModal = (value) => {
        setIsCancleListModalFlag(true)

        if (value === 'cancel') {
            setProcessTypeModal('cancel')
        } else if (value === 'cancelProcess') {
            setProcessTypeModal('cancelProcess')
        } else {
            setProcessTypeModal('return')
        }
    }

    const onCloseCancleListModal = () => {
        setIsCancleListModalFlag(false)
    }

    const onOpenRefundModal = async (value) => {
        setIsRefundModalFlag(true)

        if (value === 'cancel') {
            setRefundTypeModal('cancel')
        } else {
            setRefundTypeModal('return')
        }
    }

    const onCloseRefundModal = () => {
        setIsRefundModalFlag(false)
    }

    const onOpenRefundModalAdmin = () => {
        setIsRefundModalAdmin(true)
    }


    const customStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 800 ? "40vw" : screenSize.width <= 1100 ? "28vw" : "25vw",
            height: screenSize.width <= 800 ? "15vh" : "18vh",
        },
    };

    const notesModalStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 1100 ? "35vw" : "25vw",
            height: "20vh",
            zIndex: "99",
        },
    };

    const cancleModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            transform: screenSize.width <= 800 ? 'translate(-32%, -55%)' : screenSize.width <= 1100 ? 'translate(-36%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            width: "60vw",
            height: screenSize.width <= 800 ? "65vh" : screenSize.width <= 1100 ? "75vh" : "62vh",
            outline: "none",
            padding: "15px",
            zIndex: "99",
        },
    };

    const refundProcessModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            transform: screenSize.width <= 800 ? 'translate(-32%, -55%)' : screenSize.width <= 1100 ? 'translate(-36%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            width: "50vw",
            height: screenSize.width <= 800 ? "45vh" : screenSize.width <= 1100 ? "45vh" : "40vh",
            outline: "none",
            padding: "15px",
            zIndex: "99",
        },
    };
    const refundProcessAdminStyles = {
        content: {
            top: '50%',
            left: '50%',
            transform: screenSize.width <= 800 ? 'translate(-32%, -55%)' : screenSize.width <= 1100 ? 'translate(-36%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            width: "35vw",
            height: screenSize.width <= 800 ? "45vh" : screenSize.width <= 1100 ? "40vh" : "29vh",
            outline: "none",
            padding: "15px",
            zIndex: "99",
        },
    };

    /*const getProductInfoData = (items) => {
        setProductInfoData(items)
    }*/

    const handleCancleReview = async (type) => {
        let isRedirectPage = false;

        if (type === 'cancelProcess' && cancleStateValue.productId && cancleStateValue.message) {

            const error = checkCancleProcess(cancleStateValue);

            if (error) {
                toast.error(error);
            } else {
                await postMethodWithPlatform(urls.postOrderCancellation, cancleStateValue, "merchant").then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        if (getUserRole() === "super-admin") {
                            getOrderDetails(urls.ordersAdmin + "?stores_id=" + OrderIdParam.store_id + "&order_id=" + OrderIdParam.order_id, "super-admin");
                        } else {
                            getOrderDetails(urls.ordersMerchant + "?order_id=" + OrderIdParam.id, "merchant");
                        }

                        toast.success(response.data.response);
                        setOrderStatus(response.data.status);
                        orderHistoryProcess();

                        isRedirectPage = true;
                    }
                }).catch((error) => {
                    console.log(error)
                });

                setIsCancleListModalFlag(false)
            }
        } else if (type !== 'cancelProcess') {
            const error = checkCancleProcess(cancleStateValue);

            if (error) {
                toast.error(error);
            } else {
                await postMethodWithPlatform(urls.postOrderCancellation, cancleStateValue, "merchant").then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        if (getUserRole() === "super-admin") {
                            getOrderDetails(urls.ordersAdmin + "?stores_id=" + OrderIdParam.store_id + "&order_id=" + OrderIdParam.order_id, "super-admin");
                        } else {
                            getOrderDetails(urls.ordersMerchant + "?order_id=" + OrderIdParam.id, "merchant");
                        }

                        toast.success(response.data.response);
                        setOrderStatus(response.data.status);
                        orderHistoryProcess();

                        window.location.reload(false);
                    }
                }).catch((error) => {
                    console.log(error)
                });

                setIsCancleListModalFlag(false)
            }
        }

        if (type === 'cancelProcess') {
            orderFinishProcessing()
            setIsCancleListModalFlag(false)

            if (isRedirectPage === true) {
                window.location.reload(false);
            }
        }
    }

    const handleReturnReview = async () => {
        const error = checkReturnProcess(returnStateValue);

        if (error) {
            toast.error(error);
        } else {
            await postMethodWithToken(urls.postOrderReturned, returnStateValue).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (getUserRole() === "super-admin") {
                        getOrderDetails(urls.ordersAdmin + "?stores_id=" + OrderIdParam.store_id + "&order_id=" + OrderIdParam.order_id, "super-admin");
                    } else {
                        getOrderDetails(urls.ordersMerchant + "?order_id=" + OrderIdParam.id, "merchant");
                    }

                    toast.success(response.data.response);
                    setOrderStatus(response.data.status);
                    orderHistoryProcess();

                    window.location.reload(false);
                }
            }).catch((error) => {
                console.log(error)
            });
        }

        setIsCancleListModalFlag(false)
    }

    const handleProcessRefund = async () => {
        const error = checkRefundProcess(refundStateValue);

        if (error) {
            toast.error(error);
        } else {
            await postMethodWithToken(urls.postRefundProcess, refundStateValue).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    toast.success(response.data.response);
                    setRefundStatus(response.data.refundStatus)
                } else {
                    toast.error(response.data.response);
                }
            }).catch((error) => {
                console.log(error)
            });
        }

        setIsRefundModalFlag(false)
    }

    const handleRefundAmount = async() =>{
        await getPostMethodWithToken(urls.ordersAdmin + "?orders_id_refund=" + OrderIdParam.order_id + "&amount_refund=" + (refundValue.amount_refund * 100) + "&stores_id=" + OrderIdParam.store_id,"super-admin").then((response)=>{
            if(response.status === 200 || response.status === 201){
                toast.success(response.data.response)
            }
        })
        setIsRefundModalAdmin(false)
        window.location.reload(false);
    }

    const toggleVisible = () => {
        let heightToTop = 200;
        const scrolled = document.body.scrollTop || document.documentElement.scrollTop;
        if (scrolled > 0) {
            setVisible(false)
        }
        else if (scrolled <= 0) {
            setVisible(true)
        }
    };

    const scrollToBottom = () => {
        window.scrollTo({
            top: 960,
            behavior: 'smooth'
        });
    };
    useEffect(() => {
        window.addEventListener('scroll', toggleVisible);
    }, [])
    const scrollToBottomIcon = () => {
        window.scrollTo({
            top: 990,
            behavior: 'smooth'
        });
    };
    const iconBottom = () => {
        window.scrollTo({
            top: 840,
            behavior: 'smooth'
        });
    }

    return (
        <React.Fragment>
            <div className={props.menu ? "w-100 screenLeftMargin" : "w-100 screenLeftMarginResponse"}>
                <div className="col-12 mr-3 d-flex mt-3">
                    <div onClick={() => gotoBack()} style={{ cursor: "pointer" }}>
                        <ArrowBackIcon /> <a style={{ color: "#4472C4" }} className="ml-2">Listings</a>
                    </div>
                </div>

                <div className="row mr-3 mt-2">
                    <div className="col-lg-12 col-xl-8">
                        <div className="row">
                            <div className="col-lg-11 col-xl-11 col-sm-10 col-md-10">
                                <div className="mt-3 orderTabs">
                                    <Tabs
                                        value={currTab}
                                        onChange={handleTabChange}
                                        textColor="inherit"
                                        variant="standard"
                                    >
                                        <Tab
                                            label="Product Information"
                                        />

                                        <Tab
                                            label="Packing Slip"
                                        />

                                        {orderStatus !== 'Order Received' &&
                                            <Tab
                                                label="Invoice"
                                            />
                                        }

                                        {orderStatus === 'Order Returned' &&
                                            <Tab
                                                label="Return Label"
                                            />
                                        }

                                    </Tabs>
                                </div>
                            </div>
                            <div className="col-lg-1 col-xl-1 col-sm-2 col-md-2 mt-3 printIcon">
                                <PrintIcon style={{ cursor: "pointer", width: "25px", height: "25px" }} onClick={currTab === 0 ? handleProductList : (currTab === 1 ? handlePrintSlip : (currTab === 2 ? handlePrintInvoice : handlePrintReturn))} />
                                <div>Print</div>
                            </div>
                        </div>
                        {/* <div>
                            <PrintIcon fontSize="medium" style={{ cursor: "pointer" }} onClick={currTab === 0 ? handleProductList : (currTab === 1 ? handlePrintSlip : (currTab === 2 ? handlePrintInvoice : handlePrintReturn))} />

                        </div> */}

                        <div className="container-fluid  p-0 m-0">
                            <div style={{ display: "flex" }}>
                                <div className="productInformation">
                                    <SwipeableViews index={currTab} onChangeIndex={handleChangeIndex} enableMouseEvents={true}>
                                        <div className="w-100" ref={componentRefProductList}>
                                            {orderProductInfoData.results && (orderProductInfoData?.results?.data?.length > 0 || orderProductInfoData?.results?.cancellation?.length > 0 || orderProductInfoData?.results?.returned?.length > 0) &&
                                                <ProductInfo
                                                    ref={componentRefProductList}
                                                    orderId={getUserRole() === "super-admin" ? OrderIdParam.order_id : OrderIdParam.id}
                                                    storeId={getUserRole() === "super-admin" ? OrderIdParam.store_id : getStoreId()}
                                                    refundValue={getUserRole() === "super-admin" && refundValue }
                                                    orderProductInfoData={orderProductInfoData}
                                                    customerDetails={customerDetails}
                                                />
                                            }
                                        </div>

                                        <div className="w-100" ref={componentRefSlip}>
                                            <PackingSlip
                                                ref={componentRefSlip}
                                                orderId={getUserRole() === "super-admin" ? OrderIdParam.order_id : OrderIdParam.id}
                                                storeId={getUserRole() === "super-admin" ? OrderIdParam.store_id : getStoreId()}
                                                customerDetails={customerDetails}
                                            />
                                        </div>

                                        {orderStatus !== 'Order Received' &&
                                            <div className="w-100" ref={componentRefInvoice}>
                                                <Invoice
                                                    ref={componentRefInvoice}
                                                    orderId={getUserRole() === "super-admin" ? OrderIdParam.order_id : OrderIdParam.id}
                                                    storeId={getUserRole() === "super-admin" ? OrderIdParam.store_id : getStoreId()}
                                                    customerDetails={customerDetails}
                                                />
                                            </div>
                                        }

                                        {orderStatus === 'Order Returned' &&
                                            <div className="w-100" ref={componentRefReturn}>
                                                <PackiReturnLabelSlip
                                                    ref={componentRefReturn}
                                                    orderId={getUserRole() === "super-admin" ? OrderIdParam.order_id : OrderIdParam.id}
                                                    storeId={getUserRole() === "super-admin" ? OrderIdParam.store_id : getStoreId()}
                                                    customerDetails={customerDetails}
                                                />
                                            </div>
                                        }

                                    </SwipeableViews>
                                </div>
                                <div className="downIcon" >
                                    <ArrowDownwardIcon onClick={scrollToBottom} style={{ width: "35px", height: "30px" }} />
                                </div>

                                <div className="arrowDown" >
                                    <ArrowDownwardIcon onClick={iconBottom} style={{ width: "35px", height: "30px" }} />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-4 col-xl-4 col-md-11 col-sm-11">
                        {getUserRole() !== "super-admin" ? (
                            <>
                                {refundStatus !== 'Partial Refunded' && refundStatus !== 'Complete Refunded' &&
                                    <p className="h5-label" style={{ textAlign: "center" }}>Ready to Process the Order?</p>
                                }

                                <div className="d-flex p-2 ml-lg-3 justify-content-center">
                                    {(orderStatus === 'Order Received' && orderStatus !== 'Order Cancelled') || (orderStatus === 'Order Cancelled' && orderOldStatus === 'Order Received' && orderCancellationFlag && orderCancellationFlag === 'Partially Cancellation') ?
                                        <Button className="btn btn-primary btn-lg mr-3" onClick={orderStartProcessing} name={buttonLabel}></Button>
                                        : (orderStatus === 'Order in Process' && orderStatus !== 'Order Cancelled') || (orderStatus === 'Order Cancelled' && orderOldStatus === 'Order in Process' && orderCancellationFlag && orderCancellationFlag === 'Partially Cancellation') ?
                                            <Button className="btn btn-primary btn-lg mr-3" onClick={() => onOpenCancleListModal('cancelProcess')} name={buttonLabel}></Button>
                                            : (orderStatus === 'Order Ready' && orderStatus !== 'Order Cancelled') || (orderStatus === 'Order Cancelled' && orderOldStatus === 'Order Ready' && orderCancellationFlag && orderCancellationFlag === 'Partially Cancellation') ?
                                                <Button className="btn btn-primary btn-lg mr-3" onClick={orderCompleteProcessing} name={buttonLabel}></Button>
                                                : orderStatus !== 'Order Returned' && orderStatus !== 'Order Cancelled' ?
                                                    <Button className="btn btn-primary btn-lg mr-3" onClick={() => onOpenCancleListModal('return')} name={buttonLabel}></Button>
                                                    : orderStatus !== 'Order Cancelled' && refundStatus !== 'Partial Refunded' && refundStatus !== 'Complete Refunded' ?
                                                        <Button className="btn btn-primary btn-lg mr-3" onClick={() => onOpenRefundModal('return')} name="Process Refund"></Button>
                                                        :
                                                        null
                                    }

                                    {orderStatus === 'Order Cancelled' && refundStatus !== 'Partial Refunded' && refundStatus !== 'Complete Refunded' ?
                                        <Button className="btn btn-cancle btn-lg" onClick={() => onOpenRefundModal('cancel')} name="Process Refund"></Button>
                                        : orderStatus === 'Order Received' || orderStatus === 'Order in Process' || orderStatus === 'Order Ready' ?
                                            <Button className="btn btn-cancle btn-lg" onClick={() => onOpenCancleListModal('cancel')} name="Cancel Order"></Button>
                                            :
                                            null
                                    }
                                </div>
                            </>
                        ) : getUserRole() === "super-admin" && customerDetails.amount_refunded_flag !== true && customerDetails.order_refund_flag !== false ? (
                            <>
                                <p className="h5-lable-heading" style={{ textAlign: "center" }}>Ready to Refund the Order?</p>
                                <div className="d-flex p-2 ml-lg-3 justify-content-center">
                                    <Button className="btn btn-primary btn-lg mr-3" onClick={() => onOpenRefundModalAdmin('return')} name="Process Refund"></Button>
                                </div>
                            </>
                        )
                    :
                    ""
                    }

                        {getUserRole() !== "super-admin" &&
                            <div className="section1 pt-2 pb-2 pr-3 pl-3">
                                <div className="row mt-2 mb-2">
                                    <div className="col-12">
                                        <h5 className="h5-label">Fulfillment Timeline</h5>
                                    </div>
                                </div>
                                {orderHistoryList && orderHistoryList.length > 0 ?
                                    <>
                                        <div className="row mb-2">
                                            <div className="col-4 d-flex" style={{ alignItems: "center" }}>
                                                Status
                                            </div>
                                            <div className="col-8">
                                                {(orderCancellationFlag && orderCancellationFlag === 'Partially Cancellation') || (orderReturnedFlag && orderReturnedFlag === 'Partially Returned') ?
                                                    <a className="btn btn-order-status" style={{ backgroundColor: '#92D14F' }}>{orderStatus}</a>
                                                    :
                                                    <a className="btn btn-order-status" style={{ backgroundColor: orderStatusColor[orderStatus.replace(" ", "").replace(" ", "")] }}>{orderStatus}</a>
                                                }
                                            </div>
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col-12 history-tl-container">

                                                {orderHistoryList && orderHistoryList.length === 3 &&
                                                    <>
                                                        <div className="row history-item">
                                                            <div className="col-3">&nbsp;</div>
                                                            <div className="col-6 history-border">
                                                                <div className="d-flex history-icon">
                                                                    <img width="25" height="25" src={OrderShippedIcon} />
                                                                </div>
                                                                <div className="history-step d-flex"></div>
                                                            </div>
                                                            <div className="col-3">&nbsp;</div>
                                                        </div>
                                                    </>
                                                }

                                                {orderHistoryList && orderHistoryList.length === 2 &&
                                                    <>
                                                        <div className="row history-item">
                                                            <div className="col-3">&nbsp;</div>
                                                            <div className="col-6 history-border">
                                                                <div className="d-flex history-icon">
                                                                    <img width="25" height="25" src={OrderShippedIcon} />
                                                                </div>
                                                                <div className="history-step d-flex"></div>
                                                            </div>
                                                            <div className="col-3">&nbsp;</div>
                                                        </div>
                                                        <div className="row history-item">
                                                            <div className="col-3">&nbsp;</div>
                                                            <div className="col-6 history-border">
                                                                <div className="d-flex history-icon">
                                                                    <img width="25" height="25" src={OrderReadyIcon} />
                                                                </div>
                                                                <div className="history-step d-flex"></div>
                                                            </div>
                                                            <div className="col-3">&nbsp;</div>
                                                        </div>
                                                    </>
                                                }

                                                {orderHistoryList && orderHistoryList.length === 1 &&
                                                    <>
                                                        <div className="row history-item">
                                                            <div className="col-3">&nbsp;</div>
                                                            <div className="col-6 history-border">
                                                                <div className="d-flex history-icon">
                                                                    <img width="25" height="25" src={OrderShippedIcon} />
                                                                </div>
                                                                <div className="history-step d-flex"></div>
                                                            </div>
                                                            <div className="col-3">&nbsp;</div>
                                                        </div>
                                                        <div className="row history-item">
                                                            <div className="col-3">&nbsp;</div>
                                                            <div className="col-6 history-border">
                                                                <div className="d-flex history-icon">
                                                                    <img width="25" height="25" src={OrderReadyIcon} />
                                                                </div>
                                                                <div className="history-step d-flex"></div>
                                                            </div>
                                                            <div className="col-3">&nbsp;</div>
                                                        </div>
                                                        <div className="row history-item">
                                                            <div className="col-3">&nbsp;</div>
                                                            <div className="col-6 history-border">
                                                                <div className="d-flex history-icon">
                                                                    <img width="25" height="25" src={OrderinProcessIcon} />
                                                                </div>
                                                                <div className="history-step d-flex"></div>
                                                            </div>
                                                            <div className="col-3">&nbsp;</div>
                                                        </div>
                                                    </>
                                                }

                                                {orderHistoryList && orderHistoryList.length > 0 && orderHistoryList.map((item, index) => {
                                                    return (
                                                        <>
                                                            {index <= 3 &&
                                                                <div className="row history-item">
                                                                    <div className="col-3 orderhistoryDate">
                                                                        {moment(item.date_created).format("MMM DD HH:mm")}
                                                                    </div>
                                                                    <div className={item.new_status.replace(" ", "").replace(" ", "") + "History col-6 history-border " + (orderCancellationFlag && orderCancellationFlag.replace(" ", "")) + " " + (orderReturnedFlag && orderReturnedFlag.replace(" ", ""))}>
                                                                        <div className="d-flex history-icon">
                                                                            {item.new_status === 'Order Received' ?
                                                                                <img width="25" height="25" src={OrderReceivedIcon} />
                                                                                : item.new_status === 'Order in Process' ?
                                                                                    <img width="25" height="25" src={OrderinProcessIcon} />
                                                                                    : item.new_status === 'Order Ready' ?
                                                                                        <img width="25" height="25" src={OrderReadyIcon} />
                                                                                        :
                                                                                        <img width="25" height="25" src={OrderShippedIcon} />
                                                                            }
                                                                        </div>
                                                                        <div className="history-step d-flex"></div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        {item.new_status}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className="row">
                                        <div className="col-12 historyLoader">
                                            <SpinnerLoader />
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {/* {getUserRole() === "super-admin" ? (
                            <>
                            <p className="h5-label" style={{ textAlign: "center" }}>Ready to Process the Order?</p>
                            </>
                        )
                        :
                        ""
                        } */}

                        <div className="section2 pt-2 pb-2 pr-3 pl-3">
                            <div className="row mb-2">
                                <div className="col-12">
                                    <h5 className="h5-label">Customer Information</h5>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <div className="h6-label">
                                        {customerDetails && customerDetails.first_name + " " + customerDetails.last_name}
                                    </div>
                                </div>
                                <div className="col-12 mt-2">
                                    <div style={{ display: "flex" }}>
                                        <EmailIcon fontSize="small" style={{ color: "#808080" }} />

                                        <div className="SlipCusEmail pl-2">
                                            {customerDetails && customerDetails.email}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-2">
                                    <div style={{ display: "flex" }}>
                                        {customerDetails && customerDetails.mobile_number !== "" && customerDetails.mobile_number !== null &&
                                            <PhoneIcon fontSize="small" style={{ color: "#808080" }} />
                                        }
                                        <div className="SlipCusCall pl-2">
                                            {customerDetails && customerDetails.mobile_number}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-2">
                                    <div style={{ display: "flex" }}>
                                        <PinDropIcon fontSize="small" style={{ color: "#808080" }} />
                                        <div className="SlipCusLocation pl-2">
                                            {customerDetails && customerDetails.shipping_address && customerDetails.shipping_address.line1 + " " + customerDetails.shipping_address.line2 + " " + customerDetails.shipping_address.line3 + " " + customerDetails.shipping_address.line4 + " " + customerDetails.shipping_address.state + " " + customerDetails.shipping_address.postcode}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="section2 pt-2 pb-2 pr-3 pl-3">
                            <div className="row mt-2 mb-2">
                                <div className="col-12">
                                    <h5 className="h5-label">Customer Information</h5>
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col-12">
                                    <div className="h6-label">
                                        {customerDetails && customerDetails.first_name + " " + customerDetails.last_name}
                                    </div>
                                </div>
                                <div className="col-12 mt-2">
                                    <div style={{ display: "flex" }}>
                                        <EmailIcon fontSize="small" style={{ color: "#808080" }} />

                                        <div className="SlipCusEmail pl-2">
                                            {customerDetails && customerDetails.email}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-2">
                                    <div style={{ display: "flex" }}>
                                        {customerDetails && customerDetails.mobile_number !== "" && customerDetails.mobile_number !== null &&
                                            <PhoneIcon fontSize="small" style={{ color: "#808080" }} />
                                        }
                                        <div className="SlipCusCall pl-2">
                                            {customerDetails && customerDetails.mobile_number}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-2">
                                    <div style={{ display: "flex" }}>
                                        <PinDropIcon fontSize="small" style={{ color: "#808080" }} />
                                        <div className="SlipCusLocation pl-2">
                                            {customerDetails && customerDetails.shipping_address && customerDetails.shipping_address.line1 + " " + customerDetails.shipping_address.line2 + " " + customerDetails.shipping_address.line3 + " " + customerDetails.shipping_address.line4 + " " + customerDetails.shipping_address.state + " " + customerDetails.shipping_address.postcode}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {getUserRole() !== "super-admin" &&
                            <div className="section3 pt-2 pb-2 pr-3 pl-3">
                                <div>
                                    <div className="row d-flex mt-2 mb-2">
                                        <div className="col-6">
                                            <h5 className="h5-label">Notes</h5>
                                        </div>
                                        <div className="col-6 d-flex justify-content-end">
                                            <a className="btn btn-cancle btn-sm" onClick={onOpenNotesModal}>Add Note</a>
                                        </div>
                                    </div>

                                    <div className="row mb-2">
                                        <div className="col-9 f-600">
                                            Note
                                        </div>
                                        <div className="col-3 f-600">
                                            Action
                                        </div>
                                    </div>

                                    {orderNotesList.length > 0 ? orderNotesList.map((item, index) => {
                                        return (
                                            <div className="row mb-2 pt-2 pb-2" style={{ background: "#ECECEC" }}>
                                                <div className="col-9">
                                                    {item.message}
                                                    <div style={{ fontSize: "14px" }}>
                                                        {moment(item.date_created).format("MMM DD, YYYY HH:mm")}
                                                    </div>
                                                </div>
                                                <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                                    <DeleteIcon fontSize="small" style={{ cursor: "pointer", color: "#eb2813" }} onClick={() => onDeleteNotes(item.id)} />
                                                </div>
                                            </div>
                                        )
                                    }) : (
                                        <div className="row mb-2 pt-2 pb-2" style={{ background: "#ECECEC", textAlign: "center" }}>
                                            <div className="col-12">
                                                No Notes Found!
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <Modal isOpen={isNotesOpen} style={notesModalStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="row">
                    <div className="col-12">
                        <TextareaAutosize onChange={(e) => handleOrderNotes(e)} className="input-textarea w-100" aria-label="minimum height" minRows={4} placeholder="Notes Descripation" />
                    </div>
                </div>
                <div className="row pb-5">
                    <div className="col-12 mt-3 d-flex justify-content-end">
                        <div className="d-flex">
                            <Button type="onSubmit" onClick={() => onOrderSaveNotes()} name="Save" className="btn btn-primary btn-sm mr-3" />
                        </div>
                        <div className="d-flex">
                            <Button type="onSubmit" onClick={() => onCloseNotesModal()} name="Cancel" className="btn btn-cancle btn-sm" />
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={isCancleListModalFlag} style={cancleModalStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="row mt-3 mb-3">
                    <div className="h4-label col-12">
                        {processTypeModal === 'cancelProcess' ?
                            'All order are fully fulfilled or partially fulfilled?'
                            : processTypeModal === 'cancel' ?
                                'Select items to be cancelled'
                                :
                                'Select items to be returned'
                        }
                    </div>

                    {processTypeModal === 'cancelProcess' &&
                        <div className="h6-label col-12 mt-3">
                            Select items to be not available in stock
                        </div>
                    }
                </div>

                <div className="row mb-3">
                    <div className="col-12">
                        <div className="row pb-2" style={{ borderBottom: "1px solid #CECECE" }}>
                            <div className="col-2 viewAll f-600" onClick={() => handleSelectAll(processTypeModal)}>{isSelectedAll === true ? "Unselect All" : "Select All"}</div>
                            <div className="col-1 f-600"></div>
                            <div className="col-3 f-600">Product</div>
                            <div className="col-2 f-600">Price</div>
                            <div className="col-2 f-600">Qty.</div>
                            <div className="col-2 f-600">Total</div>
                        </div>

                        {productInfoData.length > 0 && productInfoData.map((item, index) => {
                            return (
                                <div className="row mt-3">
                                    <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                        <Checkbox
                                            color="primary"
                                            onChange={(e) => handleOrderCancleCheckbox(e, item.product_id, index, processTypeModal)}
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                            checked={cancleProductIds.includes(item.product_id) ? true : (returnProductIds.includes(item.product_id) ? true : false)}
                                        />
                                    </div>
                                    <div className="col-1">
                                        <img width="75" height="75" src={item.product_images[0].original} />
                                    </div>
                                    <div className="col-3">
                                        <div className="h6-label f-600">{item.product_name}</div>
                                        <div><b>sku:</b> {item.sku}</div>
                                        <div><b>brand:</b> {item.brand}</div>
                                    </div>
                                    <div className="col-2">
                                        {item.price}
                                    </div>
                                    <div className="col-2">
                                        {item.quantity}
                                    </div>
                                    <div className="col-2">
                                        {item.quantity * item.price}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-12 mb-1">
                        <div className="h5-label">
                            {processTypeModal === 'cancelProcess' ?
                                'Reason'
                                : processTypeModal === 'cancle' ?
                                    'Reason for Cancel'
                                    :
                                    'Reason for Return'
                            }
                        </div>
                    </div>
                    <div className="col-12">
                        <TextareaAutosize onChange={(e) => handleOrderCancleNotes(e, processTypeModal)} name="message" className="input-textarea w-100" aria-label="minimum height" minRows={4} placeholder={processTypeModal === 'cancelProcess' ? "Reason for Cancellation(Partial)" : "Reason for Return"} />
                    </div>
                </div>

                <div className="row pb-5">
                    <div className="col-12 mt-3 d-flex justify-content-end">
                        <div className="d-flex">
                            {processTypeModal === 'cancel' ?
                                <Button type="onSubmit" onClick={() => handleCancleReview('cancel')} name="Review Cancel" className="btn btn-primary mr-3" />
                                : processTypeModal === 'cancelProcess' ?
                                    <Button type="onSubmit" onClick={() => handleCancleReview('cancelProcess')} name="Proceed" className="btn btn-primary mr-3" />
                                    :
                                    <Button type="onSubmit" onClick={() => handleReturnReview()} name="Review Return" className="btn btn-primary mr-3" />
                            }

                        </div>
                        <div className="d-flex">
                            <Button type="onSubmit" onClick={() => onCloseCancleListModal()} name="Cancel" className="btn btn-cancle" />
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={isRefundModalFlag} style={refundProcessModalStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="row mt-3 mb-3">
                    <div className="h4-label col-12">
                        Amount to be refunded
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {refundTypeData && refundTypeData.data && refundTypeData.data.length > 0 && refundTypeData.data.map((item, index) => {
                            return (
                                <>
                                    <div className="row mt-4">
                                        <div className="col-1">
                                            {refundTypeData.orderStatus === 'Complete' && item.lable === 'complete refund' ?
                                                <Checkbox
                                                    color="primary"
                                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                                    checked={true}
                                                />
                                                : refundTypeData.orderStatus === 'Partial' && item.lable !== 'complete refund' ?
                                                    <Checkbox
                                                        color="primary"
                                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                                        checked={true}
                                                    />
                                                    :
                                                    <Checkbox
                                                        color="primary"
                                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                                    />
                                            }
                                        </div>
                                        <div className="col-8">
                                            {item.lable === 'complete refund' ? 'Issue full refund' : 'Issue partial refund'}

                                            {item.lable !== 'complete refund' &&
                                                <div className="pl-3" style={{ color: "#888888" }}>Enter amount to be refunded</div>
                                            }
                                        </div>
                                        <div className="col-3">
                                            {item.lable === 'complete refund' ?
                                                <TextField
                                                    className="amount"
                                                    label="Amount"
                                                    name="amount"
                                                    variant="outlined"
                                                    value={item.price}
                                                    disabled={true}
                                                    InputProps={{
                                                        inputProps: { min: 0 },
                                                        startAdornment: (
                                                            <InputAdornment position="start">$</InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                :
                                                <TextField
                                                    className="amount"
                                                    label="Amount"
                                                    name="amount"
                                                    variant="outlined"
                                                    value={refundStateValue.amount / 100}
                                                    onChange={handleAmountChange}
                                                    InputProps={{
                                                        inputProps: { min: 0 },
                                                        startAdornment: (
                                                            <InputAdornment position="start">$</InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-12">
                        <div className="totalrefundLabel h5-label" style={{ color: "#888888" }}>Refund Amount:</div>
                        <div className="totalRefundAmount h2-label">${refundStateValue.amount / 100}</div>
                    </div>
                </div>

                <div className="row pb-5">
                    <div className="col-12 mt-3 d-flex justify-content-end">
                        <div className="d-flex">
                            <Button type="onSubmit" onClick={() => handleProcessRefund()} name="Process Refund" className="btn btn-primary mr-3" />
                        </div>
                        <div className="d-flex">
                            <Button type="onSubmit" onClick={() => onCloseRefundModal()} name="Cancel" className="btn btn-cancle" />
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={isRefundModalAdmin} style={refundProcessAdminStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="row mt-3 mb-3">
                    <div className="h4-label col-12">
                        Amount to be refunded
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <hr />
                    </div>
                </div>

                <div className="row mt-1">
                    <div className="h4-lable-amount col-5 d-flex" style={{ alignItems: "center" }}>
                        Refundable Amount
                    </div>
                    <div className="col-7">
                        <TextField
                            className="amount"
                            label="Amount"
                            name="amount"
                            variant="outlined"
                            value={refundValue.amount_refund}
                            // onChange={handleAmountChange}
                            disabled={true}
                            InputProps={{
                                inputProps: { min: 0 },
                                startAdornment: (
                                    <InputAdornment position="start">$</InputAdornment>
                                ),
                            }}
                        />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12">
                        <div className="totalrefundLabel h5-label" style={{ color: "#888888" }}>Refund Amount:</div>
                        <div className="totalRefundAmount h2-label">${refundValue.amount_refund}</div>
                    </div>
                </div>
                <div className="row pb-4">
                    <div className="col-12 d-flex justify-content-end">
                        <div className="d-flex">
                            <Button type="onSubmit" name="Process Refund" onClick={()=>handleRefundAmount()} className="btn btn-primary mr-3" />
                        </div>
                        <div className="d-flex">
                            <Button type="onSubmit" onClick={() => setIsRefundModalAdmin(false)} name="Cancel" className="btn btn-cancle" />
                        </div>
                    </div>
                </div>

            </Modal>

            <Modal isOpen={confirmationModal} style={customStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="mt-4 mb-4">
                    <div className="row">
                        <div className="col-12 text-center">
                            Do you want the print packing slip?
                        </div>
                        <div className="col-12 mt-4  d-flex justify-content-center">
                            <Button type="onSubmit" onClick={() => setConfirmationModal(false)} name="No" className="btn btn-sm btn-cancle mr-3" />
                            <Button type="onSubmit" onClick={handlePrintSlip} name="Yes" className="btn btn-sm btn-primary" />
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
}
export default OrdersTab;
