import React, { useState, useEffect } from "react";
import CollectionDB from "../../../../../../assets/images/collection.png";
import { getMethod, deleteMethodWithToken } from "../../../../../../utils/services/api";
import urls from "../../../../../../utils/services/apiUrl.json";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { toast } from "react-toastify";
import SpinnerLoader from "../../../../../../components/loader/spinnerLoader";
import Button from "../../../../../../components/button/button";
import Modal from "react-modal";
import Pagination from '@mui/material/Pagination';

function CollectionList({ show, setShowListing, collectionDetails }) {
    const itemsPerPage = 20;

    const [loading, setLoading] = useState(false);
    const [collectionListing, setCollectionListing] = useState([]);
    const [confirmation, setConfirmation] = useState(false);
    const [indexIdToDelete, setIndexIdToDelete] = useState("");
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    const customStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 800 ? "40vw" : screenSize.width <= 1100 ? "28vw" : "25vw",
            height: screenSize.width <= 800 ? "15vh" : "18vh",
        },
    };

    useEffect(() => {
        getCollectionListing();
    }, []);

    const getCollectionListing = async () => {
        setLoading(true);
        await getMethod(urls.getMerchantCollectionList).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count/itemsPerPage))
                setCollectionListing(response.data.results.data);
                setLoading(false);
            }
        }).catch((error) => {
            console.log("error", error);
        });
        setLoading(false);
    };

    const handlePageChange = async (event, value) => {
        setLoading(true);
        setPage(value)

        await getMethod(urls.getMerchantCollectionList + "?page="+value).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count/itemsPerPage))
                setCollectionListing(response.data.results.data);
                setLoading(false);
            }
        });

        setLoading(false);
    }

    const deleteProduct = async (id) => {
        setConfirmation(false);
        setLoading(true);
        await deleteMethodWithToken(
            urls.deleteMerchantCollectionList + id + "/"
        ).then((response) => {
            toast.success("Product Deleted Successfully.");
            getCollectionListing();
            setLoading(false);
        });
        setLoading(false);
    };

    const ToDelete = (id) => {
        setConfirmation(true);
        setIndexIdToDelete(id);
    };

    const goToDetails = async (productClicked, index) => {
        show(true);
        setShowListing(true);
        window.$addCollection = true;
        collectionDetails(productClicked);
    };

    if (!loading) {
        return (
            <>
                {count > 1 && 
                    <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                        <div className="col-12">
                            <div className="shopPagination">
                                <Pagination 
                                    count={count}
                                    page={page}
                                    showFirstButton
                                    showLastButton 
                                    onChange={(event, value) => handlePageChange(event, value)}
                                />
                            </div>
                        </div>
                    </div>
                }

                <div className="row listSectionAddproduct ft-14 mt-3" style={{ textAlign: "center", alignItems: "center" }}>
                    <div className="col-3 f-600">S.No.</div>
                    <div className="col-3 f-600">Name</div>
                    <div className="col-3 f-600">No. of Products</div>
                    <div className="col-3 f-600">Action</div>
                </div>

                {collectionListing && collectionListing.length > 0 ? collectionListing.map((item, index) => {
                    return (
                        <>
                            <div key={index + 1} className="row ft-14 mt-3 pt-3 pb-3" style={{ background: "#ECECEC", textAlign: "center", alignItems: "center", height: "75px" }}>
                                <div className="col-3 " onClick={() => goToDetails(item)}>
                                    {index + 1}.
                                </div>

                                <div className="col-3 " onClick={() => goToDetails(item)}>
                                    {item.collectionName}
                                </div>

                                <div className="col-3 " onClick={() => goToDetails(item)}>
                                    {item.productCount}
                                </div>

                                <div className="col-3 " >
                                    <EditIcon fontSize="small" onClick={() => goToDetails(item)} style={{ cursor: "pointer", color: "#000000", marginRight: "5px" }} />
                                    <DeleteIcon fontSize="small" onClick={() => ToDelete(item.id)} style={{ cursor: "pointer", color: "#eb2813", marginLeft: "5px" }} />
                                </div>
                            </div>
                        </>
                    );
                }) :
                    <div className="row ft-14 mt-3 pt-3 pb-3" style={{ background: "#ECECEC", textAlign: "center", alignItems: "center", height: "75px" }}>
                        <div className="col-12 " >No collections found.</div>
                    </div>
                }

                {count > 1 && 
                    <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                        <div className="col-12">
                            <div className="shopPagination">
                                <Pagination 
                                    count={count}
                                    page={page}
                                    showFirstButton
                                    showLastButton 
                                    onChange={(event, value) => handlePageChange(event, value)}
                                />
                            </div>
                        </div>
                    </div>
                }

                <div className="row ml-3 mr-3 mt-3">
                    <div className="col-12">
                        <Modal isOpen={confirmation} style={customStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                            <div className="mt-4 mb-4">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        Are you sure you want to delete this product?
                                    </div>
                                    <div className="col-12 mt-4  d-flex justify-content-center">
                                        <Button type="onSubmit" onClick={() => setConfirmation(false)} name="Cancel" className="btn btn-sm btn-cancle mr-3" />
                                        <Button type="onSubmit" onClick={() => { deleteProduct(indexIdToDelete); }} name="Delete" className="btn btn-sm btn-primary" />
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </>
        );
    } else {
        return <SpinnerLoader />
    }

}
export default CollectionList;
