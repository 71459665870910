import React,{useEffect, useState} from "react";
import "./shortlistedDetails.scss";
import Button from "../../../../../../components/button/button";
import { useHistory,useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { putMethodWithToken } from "../../../../../../utils/services/api";
import Modal from 'react-modal';
import urls from "../../../../../../utils/services/apiUrl.json";
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import StorefrontIcon from '@material-ui/icons/Storefront';
import { getMethod } from "../../../../../../utils/services/api";


const ShortlistedDetails = (props) => {
    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    const [confirmation,setConfirmation]=useState(false);
    const [shortDetail,setShortDetail] = useState([])

    const gotoBack = async () => {
        history.goBack()
    };

    const customStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: "25vw",
            height: "18vh",
        },
    };

    const cancelRequest=async()=>{
        setConfirmation(false);
        await putMethodWithToken(urls.cancelStoreRequest + shortDetail.id + "/", "").then((response) => {
            if (response.status === 200) {
                history.push("/stores");
            }
        });
    }
    useEffect(()=>{
        getShortlistedDetailList()
    },[])

    const getShortlistedDetailList = async()=>{
        await getMethod(urls.getDetails + params.id + "/?query_for=promoted").then((response)=>{
            if(response.status === 200 || response.status === 201){
                setShortDetail(response.data.response)
            }
        })
    }

    return (
        <div className={props.menu ? "w-100 screenLeftMargin" : "w-100 screenLeftMarginResponse"}>
            <div className="row mt-3 pl-3 ml-3 mr-3 mobileResponsive">
                <div  onClick={() => gotoBack()} style={{cursor: "pointer"}}>
                    <ArrowBackIcon /> <a style={{color: "#4472C4"}} className="ml-2">Listings</a>
                </div>
            </div>
            <div className="row mt-2 ml-3 mr-3 mobileResponsive">
                <div className="col-7 d-flex">
                    <h1 className="heading-title">
                        <StorefrontIcon fontSize="large"/> Stores
                    </h1>
                </div>
                <div className="col-5 d-flex justify-content-end">
                    <Button onClick={() => setConfirmation(true)} className="btn btn-cancle ml-3" name="Delete"></Button>
                </div>
            </div>

            <div className="row mt-3 ml-3 mr-3 mobileResponsive">
                <div className="col-12">
                    <h2 className="h6-label mb-2">In Shortlisted</h2>

                    <div className="shortBox">
                        <div className="row " style={{justifyContent:"space-between"}}>
                            <div className="col-4 mt-3 pl-5">
                                <h3 className="h6-label">Shop Details</h3>
                                <div>
                                    
                                    <p className="h2-label mt-3">{shortDetail?.store_company_name}</p>
                                </div>
                            </div>

                            <div className="col-4 mt-3">
                                <h3 className="h6-label">Owner Details</h3>
                                <h1 className="h2-label mt-3">{shortDetail?.owner_name}</h1>
                            </div>

                            <div className="col-4 mt-3">
                                <h3 className="h6-label">Contact</h3>
                                <p className="mb-1 mt-3">
                                    <EmailIcon fontSize="small" style={{color: "#808080", marginRight:"5Px"}}/> {shortDetail?.email}
                                </p>
                                <p>
                                    <PhoneIcon fontSize="small" style={{color: "#808080", marginRight:"5Px"}}/> {shortDetail?.country_code+" "+shortDetail?.phone_number}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={confirmation} style={customStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="mt-4 mb-4">
                    <div className="row">
                        <div className="col-12 text-center">
                            Are you sure you want to delete this store?
                        </div>
                        <div className="col-12 mt-4  d-flex justify-content-center">
                            <Button type="onSubmit" onClick={() => setConfirmation(false)} name="Cancel" className="btn btn-cancle btn-sm mr-3" />
                            <Button type="onSubmit" onClick={() => {cancelRequest()}} name="Delete" className="btn btn-primary btn-sm"/>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ShortlistedDetails;
