import React, { useEffect, useState } from "react";
import "./setPassword.scss";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import ShopezLogo from "../../../assets/images/shopez_logo.png";
import Button from "../../../components/button/button";
import { useHistory } from "react-router-dom";
import { postMethod } from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";
import { toast } from "react-toastify";
import { setStoreId, setUserSession, setUserRole } from "../../../utils/common";
import Eye from "../../../assets/images/eye.png";
import HideEye from "../../../assets/images/hideEye.png";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from "@material-ui/core/IconButton";

const SetPassword = () => {
    let regex = new RegExp(
        "^(?!.*([A-Za-z0-9])\\1{2})(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$@$!%*?&])([A-Za-z0-9$@$!%*?&]).{7,15}$"
    );

    const [getPassword, setPassword] = useState({
        password: "",
        confirm_password: "",
    });

    const [urlTokenId, setUrlTokenId] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const history = useHistory();
    let url_string = window.location.href;
    let url = new URL(url_string);

    let token = url.searchParams.get("token");
    let email = url.searchParams.get("email").replace(' ', '+');
    let userName = url.searchParams.get("owner_name");
    
    useEffect(() => {
        validateToken();
    }, []);

    const validateToken = async () => {
        let body = {
            signup_token: token,
        };
        await postMethod(urls.validateSetPassword, body).then((response) => {
            if (response.status === 200) {
                setUrlTokenId(response.data.store_meta_id);
            } else if (response.status === 400) {
                toast.error(response.data.response);
            } else {
                history.push("/invalidToken");
            }
        }).catch((error) => {
            console.log(error)
        });
    };

    const continueToAboutStore = async () => {
        let body = {
            email: email,
            store_meta_id: urlTokenId,
            username: email,
            password: btoa(getPassword.password),
            role: "vendor",
        };

        if (
            getPassword.password !== "" &&
            getPassword.confirm_password !== "" &&
            getPassword.password === getPassword.confirm_password &&
            checkPassword() === true
        ) {
            await postMethod(urls.createProfile, body).then((response) => {
                if (response.status === 200) {
                    setUserSession(response.data.key);
                    setStoreId(response.data.store_id);
                    setUserRole("vendor");
                    window.$store_name = response.data.store_name;
                    history.push("/aboutStore");
                } else {
                    toast.error(response.data.response);
                }
            }).catch((error) => {
                console.log(error)
            });
        }
    };

    const checkPassword = () => {
        return regex.test(getPassword.password);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPassword((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <React.Fragment>
            <div className="container-fluid p-0 m-0">
                <div className="row">
                    <div className="col-6">
                        <div className="createBusinessDeal_setPassword ">
                            <img className="logo_setPass img-fluid" src={ShopezLogo} />
                            <div className="logo_text_frgot">
                                <div>Welcome Back</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="loginForm">
                            <div id="create-card" className="create_welcome_card_container pt-5 pb-5" >
                                <div className="container col-12">
                                    <h2 className="heading-title text-center mb-3">Welcome to Shopez Family</h2>
                                    <p className="h6-label text-center">Set your password</p>

                                    <ValidatorForm>

                                        <div className="row mt-4">
                                            <div className="col-8 offset-2">
                                                <TextValidator
                                                    required
                                                    errorMessages={"Please enter a valid email"}
                                                    validators={[
                                                        "isEmail",
                                                        "required",
                                                    ]}
                                                    value={email}
                                                    name="User Name"
                                                    label="User Name"
                                                    variant="outlined"
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-8 offset-2">
                                                <TextValidator
                                                    required
                                                    errorMessages={"Please enter the password"}
                                                    validators={["minStringLength:1", "required"]}
                                                    onChange={handleChange}
                                                    inputProps={{ maxLength: 20 }}
                                                    type={showPassword ? "text" : "password"}
                                                    name="password"
                                                    label="Password"
                                                    variant="outlined"
                                                    value={getPassword.password}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton>
                                                                    {showPassword ?
                                                                        <VisibilityIcon onClick={() => setShowPassword(!showPassword)} />
                                                                        :
                                                                        <VisibilityOffIcon onClick={() => setShowPassword(!showPassword)} />
                                                                    }
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <div>{checkPassword()}</div>
                                            {checkPassword() === false && getPassword.password !== "" ? (
                                                <div style={{ marginLeft: "10vw", fontSize: "14px", color: "Red", paddingTop: "5px" }}>
                                                    Password length must be greater than 8 characters and
                                                    it should contain a capital letter,
                                                    <br />a special character, a small character and a
                                                    number.
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-8 offset-2">
                                                <TextValidator
                                                    required
                                                    errorMessages={"Please enter the confirm password"}
                                                    validators={[
                                                        "minStringLength:1",
                                                        "maxStringLength:30",
                                                        "required",
                                                    ]}
                                                    onChange={handleChange}
                                                    inputProps={{ maxLength: 20 }}
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    name="confirm_password"
                                                    label="Confirm Password"
                                                    variant="outlined"
                                                    value={getPassword.confirm_password}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton>
                                                                    {showConfirmPassword ?
                                                                        <VisibilityIcon onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                                                                        :
                                                                        <VisibilityOffIcon onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                                                                    }
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            {getPassword.password !== getPassword.confirm_password && getPassword.confirm_password.length > 0 ? (
                                                <div style={{ marginLeft: "10vw", fontSize: "14px", color: "Red", paddingTop: "10px" }}>
                                                    Password and confirm password doesn't match!
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-8 offset-2">
                                                <Button onClick={() => continueToAboutStore()} className="btn btn-primary w-100 text-truncate" name="Continue"></Button>
                                            </div>
                                        </div>

                                    </ValidatorForm>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SetPassword;
