import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Email from "../../../assets/images/Email_E.svg";
import Bell from "../../../assets/images/Bell_B.svg";
import AppUsersDb from "../../../assets/images/Appusers_A.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import SearchDB from "../../../assets/images/Search_S.svg";
import WebIcon from "../../../assets/images/Web_W.svg";
import Phone from "../../../assets/images/Call_C.svg";
import NoImage from "../../../assets/images/storelisting.png";
import LanguageIcon from '@material-ui/icons/Language';
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import PhoneIcon from '@material-ui/icons/Phone';
import SearchIcon from '@material-ui/icons/Search';
import {getMethod} from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";
import Pagination from '@mui/material/Pagination';
import moment from "moment";
import EmailIcon from '@material-ui/icons/Email';
import NotificationsIcon from '@material-ui/icons/Notifications';
import './AppUsers.scss';

let timeOutId;

const AppUsers = (props) => {
    const history = useHistory();
    const itemsPerPage = 20;

    const [appUserList, setAppUserList] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [loader, setLoader] = useState(false);
    const [searchInput,setSearchInput] = useState("")
    const [noMatch,setNoMatch] = useState(false)

    const userStatus = [
        { color: "#60A511", status: "Active User" },
        { color: "#E73D3B", status: "Blocked User" },
        { color: "#ADADAD", status: "Partial User" },
    ]

    const notification = async () => {
        history.push("/notifications");
    }

    const userDetails = async () => {
        history.push("/appusers/userdetails");
    }

    useEffect(() => {
        getAppUsersList();
    }, []);

    const getAppUsersList = async () => {
        await getMethod(urls.getAppUsersList ).then((response) => {
            if (response.status === 200) {
                setAppUserList(response.data.results.data)
                setCount(Math.ceil(response.data.count/itemsPerPage))
            }
        });
    };

    const handlePageChange = async (event, value) => {
        setLoader(true);
        setPage(value)

        await getMethod(urls.getAppUsersList + "?page="+value).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count/itemsPerPage))
                setAppUserList(response.data.results.data);
                setLoader(false);
            }
        });

        setLoader(false);
    }
    const debounce = (func, delay) => {
        return (...args) => {
            if (timeOutId) clearTimeout(timeOutId);
            timeOutId = setTimeout(() => {
                func.apply(null, args);
            }, delay);
        };
    };

    const searchAppUser = async(text) =>{
        if(text === ""){
            setNoMatch(false)
            getAppUsersList()
        }else{
            await getMethod(urls.getAppUserSearch + "?user_name=" + text).then((response)=>{
                if(response.status === 200 || response.status === 201){
                    if(response.data.results.data.length > 0){
                        setNoMatch(false)
                        setCount(Math.ceil(response.data.count / itemsPerPage))
                        setAppUserList(response.data.results.data);
                        setPage(1)
                    }else{
                        setNoMatch(true)
                    }
                }
            })
        }

    }

    const handleSearchInputChange = (e)=>{
        setSearchInput(e.target.value)
        debounceCall(e.target.value)
    }
    const debounceCall = debounce(searchAppUser,500)

    return (
        <div className={props.menu ? 'w-100 screenLeftMargin' : 'w-100 screenLeftMarginResponse'}>
            <div className='row ml-3 mr-3 mt-3 mobileResponsive'>
                <div className='col-7'>
                    <div className="d-flex" style={{alignItems: "center"}}>
                        <img width="20" className='mr-2' src={AppUsersDb} />
                        <span className="heading-title"> App Users</span>
                    </div>
                </div> 
                {/* <div className='col-5 d-flex justify-content-end'>
                    <div className='muiNotificationsIcon d-flex' onClick={() => notification()}>
                        <NotificationsIcon style={{color: "#fff", fontSize: "30px"}}/>
                    </div>
                    <div className='muiNotificationsIcon d-flex ml-3'>
                        <EmailIcon style={{color: "#fff", fontSize: "30px"}}/>
                    </div>
                </div> */}
            </div>

            <div className='row ml-3 mr-3 mt-3 mobileResponsive'>
                <div className='col-xl-4 col-lg-6'>
                    <TextField
                        onChange={(event) => handleSearchInputChange(event)}
                        name="search"
                        label="Search"
                        value={searchInput}
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            </div>

            {/*<div className='row ml-3 mr-3 mt-3 pl-3 pr-3 mobileResponsive'>
                <div className='col-12 status-box-AppUsers'>
                    <div className='status-box-text ml-3 pb-2 pt-2'>App user status legend</div>
                    <div style={{ display: "flex" }}>
                        {userStatus.map(statusInfo => {
                            return (
                                <div >
                                    <div style={{
                                        backgroundColor: statusInfo.color, 
                                        width: "23.33vw", height: "0.7vw", paddingLeft: "0.2rem"
                                        }}>
                                    </div>
                                    <div className='status-text mt-2 pb-2'>{statusInfo.status}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>*/}

            {count > 1 && !noMatch && 
                <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                    <div className="col-12">
                        <div className="shopPagination">
                            <Pagination 
                                count={count}
                                page={page}
                                showFirstButton
                                showLastButton 
                                onChange={(event, value) => handlePageChange(event, value)}
                            />
                        </div>
                    </div>
                </div>
            }

            {!noMatch ? 
            <div className='row ml-3 mr-3 pl-3 pr-3 mt-3 mobileResponsive'>
                <div className="col-12">
                    <div className="row">
                        <div className="col-3 f-600">User Name</div>
                        <div className="col-3 f-600">Info</div>
                        <div className="col-3 f-600">Joined</div>
                        <div className="col-3 f-600">Last login</div>
                    </div>

                    {appUserList.length > 0 && appUserList.map((item, index) => {
                        return (
                            <div className="row mt-3 pt-3 pb-3" style={{background: "#ECECEC"}}>
                                <div className="col-3 d-flex" style={{alignItems: "center"}}>
                                    <div className="d-flex">
                                        <img className="listedRadiusImg" src={item.profile_pic ? item.profile_pic.file : NoImage}/>
                                    </div>
                                    <div className="d-flex ml-2" style={{alignItems: "center"}}>
                                        {item.first_name} {item.last_name}
                                    </div>
                                </div>

                                <div className="col-3 d-flex" style={{alignItems: "center"}}>
                                    <div>
                                        <PhoneIcon style={{color: "#808080", marginRight:"5Px", fontSize: "18px", marginTop: "-1px"}}/>
                                        {item.contact_number}
                                    </div>
                                    {/*<div>
                                        <LanguageIcon style={{color: "#808080", marginRight:"5Px", fontSize: "18px", marginTop: "-1px"}}/>
                                        www.google.com
                                    </div>*/}
                                </div>

                                <div className="col-3 d-flex" style={{alignItems: "center"}}>
                                    {moment(item.joined).format('YYYY MMM DD')}
                                </div>
                                <div className="col-3 d-flex" style={{alignItems: "center"}}>
                                    {item.last_login ? moment(item.last_login).format('YYYY MMM DD HH:mm') : ''}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            :
            <div className="noMatchUser row pt-3 pb-3" style={{marginLeft:"30px",marginTop:"20px",fontSize:"18px",fontWeight:500}}>No matching product found.</div>
            }

            {count > 1 && !noMatch && 
                <div className="row ml-3 mr-3 mt-3 mb-5 mobileResponsive">
                    <div className="col-12">
                        <div className="shopPagination">
                            <Pagination 
                                count={count}
                                page={page}
                                showFirstButton
                                showLastButton 
                                onChange={(event, value) => handlePageChange(event, value)}
                            />
                        </div>
                    </div>
                </div>
            }

            <div className="row ml-3 mr-3 mb-5 mobileResponsive">
            </div>
        </div>
    )
}

export default AppUsers;
