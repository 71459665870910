import React, { useState, useCallback, useEffect } from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Modal from "react-modal";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Pagination from '@mui/material/Pagination';
import Button from "../../../components/button/button";
import UploadPhotoIcon from "../../../assets/images/add_a_photo.png";
import { getMethod, postMethodWithTokenProgress, postMethodWithToken, putMethodWithToken, deleteMethodWithTokenAndData } from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";
import DefaultImage from "../../../assets/images/ShopezDefult.svg"

const checkBrandInfo= data => {
    const emptyKeys = [
        'brand_image_id',
        'brand_name'
    ];

    const emptyKeysErrors = [
        'Brand Icon',
        'Brand Name'
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!data[emptyKeys[i]]) {
            return `Please fill in ${emptyKeysErrors[i]}`;
        }
    }
}

function Brands(props) {
    const itemsPerPage = 20;

	const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const customStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 800 ? "40vw" : screenSize.width <= 1100 ? "28vw" : "25vw",
            height: screenSize.width <= 800 ? "15vh" : "18vh",
        },
    };

    const allStyles = {
        content: {
            top: '50%',
            left: '50%',
            transform: screenSize.width <= 800 ? 'translate(-32%, -55%)' : screenSize.width <= 1100 ? 'translate(-36%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            width: "30vw",
            height: screenSize.width <= 800 ? "35vh" : screenSize.width <= 1100 ? "35vh" : "35vh",
            outline: "none",
            padding: "15px",
        },
    };

    const [loading, setLoading] = useState(false);
    const [indexIdToDelete, setIndexIdToDelete] = useState("");
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [brandDetailsFlag, setBrandDetailsFlag] = useState(false);
    const [brandsList, setBrandsList] = useState("");
    const [uploadPercentage, setStateUploadPercentage] = useState(0);
    const [brandImage, setBrandImage] = useState();
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [brandCreateUpdate, setBrandCreateUpdate] = useState('Create');

    const [formValue, setFormValue] = useState({
        brand_image_id: "",
        brand_name: "",
        is_admin: true,
        is_merchant: false,
    });

    useEffect(() => {
        getBrandsList();
    }, []);

    const getBrandsList = async () => {
        setLoading(true);

        await getMethod(urls.getBrandsList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setCount(Math.ceil(response.data.count/itemsPerPage))
                setBrandsList(response.data.results.data);
            }
        });
        
        setLoading(false);
    };

    const fillBrandDetails = async (Action, id) => {
        await getMethod(urls.getBrandDetails + id).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setIndexIdToDelete(response?.data?.response.id);

                setFormValue((prevState) => ({
                    ...prevState,
                    ['brand_image_id']: response?.data?.response.brand_image?.id,
                    ['brand_name']: response?.data?.response.brand_name,
                    ['is_admin']: response?.data?.response.is_admin,
                    ['is_merchant']: response?.data?.response.is_merchant
                }));

                setBrandImage(response.data&&response.data.response.brand_image !== null&&response.data.response.brand_image.file);
                setBrandCreateUpdate('Update');
                setBrandDetailsFlag(true);
            }
        });
    }

    const handlePageChange = async (event, value) => {
        setLoading(true);
        setPage(value)

        await getMethod(urls.getBrandsList + "?page="+value).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count/itemsPerPage))
                setBrandsList(response.data.results.data);
            }
        });

        setLoading(false);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValue((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const onUploadProgress = (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setStateUploadPercentage(percent);
    };

    const onDrop = useCallback((acceptedFiles) => {
        let formData = new FormData();

        acceptedFiles.map((file, index) => {
            formData.append("file", file);
        });

        postMethodWithTokenProgress(urls.fileUpload, formData, onUploadProgress).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setFormValue((prevState) => ({
                    ...prevState,
                    ['brand_image_id']: response.data[0].id
                }));

                setBrandImage(response.data[0].file);
            } else {
                toast.error(response.error)
            }
        }).catch((error) => {
            console.log("error", error);
        });

    });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const ToDelete = (id) => {
        setConfirmationModal(true);
        setIndexIdToDelete(id);
    };

    const deleteBrand = async (id) => {
        setLoading(true);
        setConfirmationModal(false);

        let body = {
            "is_deleted" : true
        }

        await deleteMethodWithTokenAndData(urls.deleteBrand + id, body).then((response) => {
            if(response.status === 200 || response.status === 201) {
                toast.success(response.data.response);

                getBrandsList();
            } else {
                toast.error(response.data.response);
            }
        });

        setLoading(false);
    }

    const openBrandModal = () => {
        setIndexIdToDelete('');

        setFormValue((prevState) => ({
            ...prevState,
            ['brand_image_id']: '',
            ['brand_name']: '',
            ['is_admin']: true,
            ['is_merchant']: false
        }));

        setBrandImage();
        setBrandCreateUpdate('Create');
        setBrandDetailsFlag(true);
    }

    const submitBrandData = async () => {
        const error = checkBrandInfo(formValue);

        if(error) {
            toast.error(error);
        } else {
            if(brandCreateUpdate === 'Update') {
                await putMethodWithToken(urls.putBrand + indexIdToDelete + '/', formValue).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        toast.success(response.data.response);
                        setBrandDetailsFlag(false);
                        getBrandsList();
                        setBrandCreateUpdate('Create');
                        setIndexIdToDelete('');
                    } else {
                        toast.error(response.data.response);
                    }
                }).catch((error) => {
                    setLoading(false);
                });
            } else {
                await postMethodWithToken(urls.postBrand, formValue).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        toast.success(response.data.response);
                        setBrandDetailsFlag(false);
                        getBrandsList();
                    } else {
                        toast.error(response.data.response);
                    }
                }).catch((error) => {
                    setLoading(false);
                });
            }
        }
    }

	return (
		<div className={props.menu ? "w-100 screenLeftMargin mt-3" : "screenLeftMarginResponse w-100 mt-3"}>
			<div className="row ml-3 mr-3 mobileResponsive">
				<h1 className="heading-title col-6">Brands</h1>
				<div className="col-6" style={{ textAlign: "right" }}>
                    {/* <Button onClick={() => openBrandModal()} className="btn btn-cancle" name="Add Brand"></Button> */}
                </div>
			</div>

            {count > 1 && 
                <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                    <div className="col-12">
                        <div className="shopPagination">
                            <Pagination 
                                count={count}
                                page={page}
                                showFirstButton
                                showLastButton 
                                onChange={(event, value) => handlePageChange(event, value)}
                            />
                        </div>
                    </div>
                </div>
            }

			<div className="row ml-3 mr-3 mt-3 mobileResponsive">
                <div className="col-12">
                	<div className="row">
                        <div className="col-1 f-600">S.No</div>
                        <div className="col-2 f-600">Icon</div>
                        <div className="col-7 f-600">Name</div>
                        <div className="col-2 f-600">Action</div>
                    </div>

                    {brandsList.length > 0 && brandsList.map((item, index) => {
                        return (
                            <>
                                <div key={index + 1} className="row mt-3 pt-3 pb-3" style={{background: "#ECECEC"}}>
                                    <div className="col-1 d-flex" style={{alignItems: "center"}}>
                                        {(page - 1) * itemsPerPage + index + 1}
                                    </div>
                                    <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                        <img className="listedImg" src={item.brand_image!== null ? item.brand_image && item.brand_image.file : DefaultImage} alt="" />
                                    </div>
                                    <div className="col-7 d-flex" style={{alignItems: "center"}}>
                                        {item.brand_name}
                                    </div>
                                    <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                        {/* <div className="d-flex">
                                            <EditIcon fontSize="small" style={{ cursor: "pointer" }} onClick={() => fillBrandDetails("update", item.id)} />
                                        </div>
                                        <div className="d-flex ml-2">
                                            <DeleteIcon fontSize="small" style={{ cursor: "pointer",color:"#eb2813" }} onClick={() => ToDelete(item.id)} />
                                        </div> */}
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </div>

            {count > 1 && 
                <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                    <div className="col-12">
                        <div className="shopPagination">
                            <Pagination 
                                count={count}
                                page={page}
                                showFirstButton
                                showLastButton 
                                onChange={(event, value) => handlePageChange(event, value)}
                            />
                        </div>
                    </div>
                </div>
            }

            <Modal isOpen={brandDetailsFlag} style={allStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
            	<div className="row">
            		<div className="col-10 offset-1">
            			<div className="row mt-3">
                            <div className="h4-label mt-3 mb-3 col-12">
                                Enter Brand Details
                            </div>
                        </div>

                        <div className="row">
                        	<div className="col-12 mt-3">
                                <div className="container storeImageBlock d-flex justify-content-center">
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        {isDragActive ? (
                                            <p>Drop the files here ...</p>
                                        ) : (
                                            <>
                                                {brandImage ? (
                                                    <div>
                                                        <img
                                                            className="imgTagBlock"
                                                            src={brandImage}
                                                            alt=""
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="uploadPhoto">
                                                        <div style={{ cursor: "pointer" }} className="uploadInfo">
                                                            <div className="uploadImage"><img width="22" src={UploadPhotoIcon} /></div>
                                                            <div className="uploadText">Brand Icon</div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                        	</div>
                        </div>

                        <div>
                        	<ValidatorForm>
                        		<div className="row mt-3">
                        			<div className="col-12">
                                        <TextValidator
                                            validators={[
                                                "minStringLength:1",
                                                "maxStringLength:30",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter brand name"
                                            ]}
                                            name="brand_name"
                                            label="Brand Name"
                                            variant="outlined"
                                            onChange={handleInputChange}
                                            value={formValue.brand_name}
                                        />
                                    </div>
                        		</div>
                        	</ValidatorForm>
                        </div>

                        <div className="row pb-5">
                            <div className="col-12 mt-3">
                                <Button type="onSubmit" name="Cancel" onClick={() => setBrandDetailsFlag(false) } className="btn btn-sm btn-cancle ml-3 float-right" />
                                {formValue.is_admin === true &&  <Button type="onSubmit" name="Save" onClick={() => submitBrandData() } className="btn btn-sm btn-primary float-right" /> }
                            </div>
                        </div>
            		</div>
            	</div>
            </Modal>

            <Modal isOpen={confirmationModal} style={customStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="mt-4 mb-4">
                    <div className="row">
                        <div className="col-12 text-center">
                            Are you sure you want to delete this store?
                        </div>
                        <div className="col-12 mt-4  d-flex justify-content-center">
                            <Button type="onSubmit" onClick={() => setConfirmationModal(false)} name="Cancel" className="btn btn-sm btn-cancle mr-3" />
                            <Button type="onSubmit" onClick={() => { deleteBrand(indexIdToDelete); }} name="Delete" className="btn btn-sm btn-primary" />
                        </div>
                    </div>
                </div>
            </Modal>
		</div>
	);
}
export default Brands;
