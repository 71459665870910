import React, { useState, useEffect } from "react";
import { Switch, BrowserRouter as Router, useHistory, Route, Redirect, useLocation } from "react-router-dom";
import login from "../screens/login/login";
import home from "../screens/home/home";
import Dashboard from "../screens/panel/dashboard/dashboard";
import signup from "../screens/signup/signup";
import CreateStore from "../screens/create_store/createStore";
import WelcomeScreen from "../screens/create_store/welcomeScreen/welcomeScreen";
import ForgotPassword from "../screens/login/forgotPassword/forgotPassword";
import SetPassword from "../screens/panel/setPassword/setPassword";
import InvalidToken from "../screens/panel/setPassword/invalidToken/invalidToken";
import { getToken, getUserRole, getUserOnBoarding } from "../utils/common";


export default function Routes() {

    const history = useHistory();
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const Role = getUserRole();
    const OnBoardStatus = getUserOnBoarding();

    useEffect(() => {
        setIsLoggedIn(Boolean(getToken()));
    }, []);


    const PrivateRoute = (props) => {

        const { children } = props;
        setIsLoggedIn(Boolean(getToken()));
        const location = useLocation();

        return isLoggedIn ? (
            <>{children}</>
        ) : (
            <Redirect
                replace={true}
                to="/login"
                state={{ from: `${location.pathname}${location.search}` }}
            />
        );
    };

    return (

        isLoggedIn !== null &&
        <Router history={history}>
            <Switch>
                <Route exact path="/" component={() => isLoggedIn ? Role === "vendor" ? OnBoardStatus === "true" ? <Redirect to="/home" /> : <Redirect to="/aboutStore" /> : <Redirect to="/onlineenquiry" /> : home()} />
                <Route exact path="/login" component={() => isLoggedIn ? Role === "vendor" ? OnBoardStatus === "true" ? <Redirect to="/home" /> : <Redirect to="/aboutStore" /> : <Redirect to="/onlineenquiry" /> : login()} />
                <Route exact path="/forgotpassword" component={() => isLoggedIn ? Role === "vendor" ? OnBoardStatus === "true" ? <Redirect to="/home" /> : <Redirect to="/aboutStore" /> : <Redirect to="/onlineenquiry" /> : ForgotPassword()} />
                <Route exact path="/signup" component={() => isLoggedIn ? Role === "vendor" ? OnBoardStatus === "true" ? <Redirect to="/home" /> : <Redirect to="/aboutStore" /> : <Redirect to="/onlineenquiry" /> : signup()} />
                <Route exact path="/setpassword" component={() => isLoggedIn ? Role === "vendor" ? OnBoardStatus === "true" ? <Redirect to="/home" /> : <Redirect to="/aboutStore" /> : <Redirect to="/onlineenquiry" /> : SetPassword()} />
                <Route exact path="/createstore" component={() => isLoggedIn ? Role === "vendor" ? OnBoardStatus === "true" ? <Redirect to="/home" /> : <Redirect to="/aboutStore" /> : <Redirect to="/onlineenquiry" /> : CreateStore()} />
                <Route exact path="/welcome" component={() => isLoggedIn ? Role === "vendor" ? OnBoardStatus === "true" ? <Redirect to="/home" /> : <Redirect to="/aboutStore" /> : <Redirect to="/onlineenquiry" /> : WelcomeScreen()} />
                <Route exact path="/invalidToken" component={InvalidToken} />
                <Route exact path="/home" component={() => <PrivateRoute><Dashboard path={"home"} /></PrivateRoute>} />
                <Route exact path="/dashboard" component={() => <PrivateRoute><Dashboard /> </PrivateRoute>} />
                <Route exact path="/aboutStore" component={() => <PrivateRoute><Dashboard path={"aboutStore"} /></PrivateRoute>} />
                <Route exact path="/addProduct" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/plans" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/financials" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/review" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/stores" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/stores/inreview/:id" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/stores/approved/:id" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/stores/onHold/:id" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/stores/inshortlisted/:id" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/onlineenquiry" component={() => <Dashboard path={"onlineenquiry"} />} />
                <Route exact path="/onlineenquiry/storedetails" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/brands" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/notifications" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/appusers" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/appusers/userdetails" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/orders" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/orders/listing" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/orders/listing/:id" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/orders/details/:id" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/orders/details/:store_id/:order_id" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/masterdata" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/masterdata/role" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/masterdata/territories" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/masterdata/privacy_policies" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/masterdata/terms" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/campaign" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/campaign/details" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/catalogue" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/catalogue/listing" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/catalogue/collection" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/contacts" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/contacts/suppliers" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/contacts/suppliers/:id" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/contacts/customers" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/transaction" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/promotions" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/promotions/banners" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/promotions/deals" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/promotions/discounts" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/promotions/discount/:id" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/settings" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/settings/generalSettings" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/settings/faqs" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/settings/faqs/create" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/settings/plans" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/settings/financials" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/settings/shippingDelivery" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="/helpCenter" component={() => <PrivateRoute><Dashboard /></PrivateRoute>} />
            </Switch>
        </Router>
    );
}