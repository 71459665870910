import React from "react";
import "./home.scss";
import ShopezLogo from "../../assets/images/shopez_logo.png";
import { useHistory } from "react-router-dom";
import Steps1DB from "../../assets/images/Step1_S.svg";
import Steps2DB from "../../assets/images/Step2_S.svg";
import Steps3DB from "../../assets/images/Step3_S.svg";
import Steps4DB from "../../assets/images/Step4_S.svg";

const Home = () => {
    const history = useHistory();
    
    const login = async () => {
        history.push("/login");
    };
    const createstore = async () => {
        history.push("/createstore");
    };

    return (
        <div>
            <div className="container-fluid p-0 m-0">
                <div className=" header">
                    <div className="row p-0 m-0">
                        <div className="col-4 mt-4 logoc">
                            <img className=" shopezzLogo ml-3" src={ShopezLogo} />
                        </div>
                        <div className="col-8 logoc text-right">
                            <div className="row">
                                {/*<div className="mt-4 pt-4 ml-5" onClick={() => createstore()}>
                                    <h2 className="header-text ">BECOME A PARTNER</h2>
                                </div>*/}
                                <div className="mt-4 pt-4 ml-3" onClick={() => login()}>
                                    <h2 className="header-text">LOGIN</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="main-img" className="jumbotron">
                <div>
                    <h2 className="img-title">Get your store online</h2>
                </div>
            </div>

            <div className="container-fluid">
                <div>
                    <h2 className="steps-text">Steps to Partner With Shopez</h2>
                </div>
                <div className="container d-flex mt-5">
                    <div className="">
                        <img className="steps1 img-fluid" src={Steps1DB} />
                        <h2 className="stepss-text mt-4">Request</h2>
                        <p className="steps-disc">
                            Learn how to go further and faster with valuable customer{" "}
                        </p>
                    </div>
                    <div className="stepshr">
                        <hr style={{ border: "1px solid #1A2934", width: "8vw" }} />
                    </div>

                    <div className="">
                        <img className="steps1 img-fluid" src={Steps2DB} />
                        <h2 className="stepss-text mt-4">Fill a Form</h2>
                        <p className="steps-disc">
                            Learn how to go further and faster with valuable customer{" "}
                        </p>
                    </div>
                    <div className="stepshr">
                        <hr style={{ border: "1px solid #1A2934", width: "8vw" }} />
                    </div>

                    <div>
                        <img className="steps1 img-fluid " src={Steps3DB} />
                        <h2 className="stepss-text mt-4 ">Get Approved</h2>
                        <p className="steps-disc">
                            Learn how to go further and faster with valuable customer{" "}
                        </p>
                    </div>
                    <div className="stepshr">
                        <hr style={{ border: "1px solid #1A2934", width: "8vw" }} />
                    </div>

                    <div>
                        <img className="steps1 img-fluid" src={Steps4DB} />
                        <h2 className="stepss-text mt-4 ">Earn</h2>
                        <p className="steps-disc">
                            Learn how to go further and faster with valuable customer{" "}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
