import React from "react";
import "./welcomeScreen.scss";
import Welcome from "../../../assets/images/welcome.png";
import WelcomeLogo from "../../../assets/images/shopez logo_wel.png";
import Button from "../../../components/button/button";
import { useHistory } from "react-router-dom";
const WelcomeScreen = () => {
    const history = useHistory();
    const gotoMainScreen = () => {
        history.push("/");
    };

    return (
        <div className="container-fluid p-0 m-0">
            <div className="row p-0 m-0">
                <div className="col-6 p-0 m-0">
                    <div className="createWelcome">
                        <img className="welcome_img img-fluid w-100" src={Welcome} />
                    </div>
                </div>

                <div className="col-6">
                    <div className="loginForm">
                        <div id="create-card" className="create_welcome_card_cont m-5 p-5 w-100">
                            <div className="container">
                                <div className="welcomelogo">
                                    <img src={WelcomeLogo} />
                                </div>
                                <div className="welcome-text">
                                    <p>Thank you for your application !!</p>
                                    <p> You will hear back from us within 2 to 3 days.</p>
                                </div>
                                <div className="container text-center mt-5 pt-5">
                                    <Button onClick={() => gotoMainScreen()} className="btn btn-primary" name="Home"></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WelcomeScreen;
