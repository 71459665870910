import React  from "react";
import "./banner.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function Banner() {
    return(
        <div className="screenLeftMargin mt-3">
            <div className="row ml-3 mr-3 mobileResponsive">
                <h1 className="col-12 heading-title">Banner(0/2)</h1>
            </div>
        </div>
    )
}
export default  Banner;