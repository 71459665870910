import React, { useState, useEffect } from "react";
import { getMethod } from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";
import CollectionDB from "../../../assets/images/collection.png";
import SpinnerLoader from "../../../components/loader/spinnerLoader";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Pagination from '@mui/material/Pagination';

function Transaction() {

    const itemsPerPage = 20;
    const history = useHistory();
    const [listOfSuppliers, setListOfSuppliers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    useEffect(() => {
        supplierList();
    }, []);

    const supplierList = async () => {
        setLoading(true);
        await getMethod(urls.getBankHistory).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setListOfSuppliers(response.data.results.data);
                setLoading(false);
            }
        });
        setLoading(false);
    };

    const handleClick = (id) => {
        history.push(`/orders/details/${id}`);
    }

    const handlePageChange = async (event, value) => {
        setLoading(true);
        setPage(value)
        await getMethod(urls.getBankHistory + "?&page=" + value).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setListOfSuppliers(response.data.results.data);
                setLoading(false);
            }
        });
        setLoading(false);
    }

    const showSupplierList = () => {
        if (loading) {
            return <SpinnerLoader />;
        } else {
            return (
                <>
                    <div className="col-12">
                        {count > 1 &&
                            <div className="shopPagination mt-4">
                                <Pagination
                                    count={count}
                                    page={page}
                                    showFirstButton
                                    showLastButton
                                    onChange={(event, value) => handlePageChange(event, value)}
                                />
                            </div>
                        }
                    </div>

                    {listOfSuppliers.length !== 0 ? (
                        <div className="row ml-3 mr-3 pl-3 pr-3 mt-4">
                            <div className="col-12">
                                <div className="row" style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                                    <div className="col-1 f-600">S.No</div>
                                    <div className="col-2 f-600">Name/Email</div>
                                    <div className="col-2 f-600">shippingCharge</div>
                                    <div className="col-2 f-600">TotalTax</div>
                                    <div className="col-2 f-600">OrderTotal</div>
                                    <div className="col-2 f-600">OrderNumber</div>
                                    <div className="col-1 f-600">Date</div>
                                </div>
                            </div>
                            <div className="col-12">
                                {listOfSuppliers && listOfSuppliers.length > 0 ? listOfSuppliers.map((item, index) => {
                                    return (
                                        <>
                                            <div className="row mt-3 pt-3 pb-3" style={{ background: "#ECECEC", cursor: "pointer", display: "flex", justifyContent: "center", textAlign: "center" }} onClick={() => handleClick(item.id)} >

                                                <div className="col-1" style={{ alignItems: "center" }}>
                                                    {index + 1 + "."}
                                                </div>

                                                <div className="col-2 d" style={{ alignItems: "center" }}>
                                                    {item.user_detail.first_name + " " + item.user_detail.last_name}{","} {item.user_detail.email}
                                                </div>

                                                <div className="col-2" style={{ alignItems: "center" }}>
                                                    ${item.orderSummary[0].shippingCharge}
                                                </div>

                                                <div className="col-2" style={{ alignItems: "center" }}>
                                                    ${item.orderSummary[0].totalTax}
                                                </div>

                                                <div className="col-2" style={{ alignItems: "center" }}>
                                                    ${item.orderSummary[1].orderTotal}
                                                </div>

                                                <div className="col-2" style={{ alignItems: "center" }}>
                                                    {item.number}
                                                </div>

                                                <div className="col-1" style={{ alignItems: "center" }}>
                                                    {moment(item.date_placed).format("YYYY/MM/DD")}
                                                </div>
                                            </div>
                                        </>
                                    );
                                }) :
                                    <div className="row ft-14 mt-3 pt-3 pb-3" style={{ background: "#ECECEC", textAlign: "center", alignItems: "center", height: "75px" }}>
                                        <div className="col-12 " >No transaction data found.</div>
                                    </div>
                                }
                            </div>
                        </div>
                    ) : (
                        <div className="row ml-3 mr-3 mt-5">
                            <div className="col-12" style={{display:"flex",justifyContent:"center"}}>
                                <img className="collectionImage" src={CollectionDB} alt="" />
                            </div>
                        </div>
                    )}

                    <div className="col-12">
                        {count > 1 &&
                            <div className="shopPagination mt-4 mb-5">
                                <Pagination
                                    count={count}
                                    page={page}
                                    showFirstButton
                                    showLastButton
                                    onChange={(event, value) => handlePageChange(event, value)}
                                />
                            </div>
                        }
                    </div>
                </>
            )
        }

    };


    return (
        <div className="w-100 screenLeftMargin">
            <div className="row ml-3 mr-3 mt-4">
                <div className="col-6">
                    <h1 className="heading-title">Transaction Details</h1>
                </div>
            </div>
            {showSupplierList()}
        </div>
    );
}
export default Transaction;
