import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMove } from "react-sortable-hoc";
import "./thumbNailview.scss";
import { setProductId, setInnerProductId, setMethod} from "../../../../../utils/common";
import TabBar from "../../components/tabs/tabs";

const useStyles = makeStyles({
    root: {
        maxWidth: 500,
        marginRight: "30px",
        height: "300px",
    },
});

const styles = {
    cardAction: {
        display: "block",
        textAlign: "initial",
    },
    multiLineEllipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
    },
};

export default function ThumbNailView({
    cardViewData,
    goToProductDetails,
    myFun,
}) {
    window.$addProduct = false;
    const [addProduct, setAddProduct] = useState(window.$addProduct);
    const classes = useStyles();
    const [cardData, setCardData] = useState(cardViewData);
    const [show, setShow] = useState(true);

    const goToDetails = async (productClicked, index) => {
      myFun(!show);
        setMethod("PUT");
        setProductId(productClicked.value.id);
        setInnerProductId(productClicked.value.id);
        setAddProduct(true);
        window.$productDetails = productClicked.value;
    };

    const SortableItem = SortableElement((item, index) => {
        return (
            <Card className={classes.root} style={{ cursor: "pointer" }}>
                {item.value.images.length !== 0 ? (item.value.images && item.value.images.slice(0, 1).map((items, i) => {
                    return (
                        <CardMedia
                            component="img"
                            alt=""
                            height="60%"
                            image={items.original}
                            onClick={() => goToDetails(item, index)}
                        />
                    );
                })) : (
                    <CardMedia
                        component="img"
                        alt=""
                        height="60%"
                        image="assets/images/storelisting.png"
                    />
                )}

                <CardContent className="footer" style={{ textAlign: "center" }} onClick={() => goToDetails(item, index)}>
                    <Typography className={classes.multiLineEllipsis} id="cardName">
                        {item.value.product_name}
                    </Typography>
                    <Typography id="cardModel" className="fontStylesModel mt-2">
                        {item.value.product_model}
                    </Typography>
                    {item.value.children.length === 0 ? (
                        <Typography className="mt-2">
                            <span className="priceLabel">
                                {item.value.price > 0 ? "$" + item.value.price : ""}
                            </span>
                            <span className="inv_price ml-3">
                                {item.value.invoice_cost > 0 ? "$" + item.value.invoice_cost : ""}
                            </span>
                        </Typography>
                    ) : (
                        <Typography className=" mt-2">
                            <span className="priceLabel">
                                {item.value.children[0].price > 0 ? "$" + item.value.children[0].price : ""}
                            </span>
                            <span className="inv_price ml-3">
                                {item.value.children[0].invoice_cost > 0 ? "$" + item.value.children[0].invoice_cost : ""}
                            </span>
                      </Typography>
                    )}
                </CardContent>
            </Card>
        );
    });

    const SortableGridView = SortableContainer(({ items }) => {
        return (
            <div className="row">
                <div className="thumbNailContainer">
                    {items.map((value, index) => (
                        <SortableItem key={`item-${value}`} index={index} value={value} />
                    ))}
                </div>
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setCardData(arrayMove(cardData, oldIndex, newIndex));
    };

    return addProduct ? (
        <div>
            <TabBar />
        </div>
    ) : (
        <SortableGridView
            items={cardData}
            onSortEnd={onSortEnd}
            axis={"xy"}
            distance={1}
        />
    );
}
