import React, { useState, useEffect } from "react";
import "./plans.scss";
import { useHistory, useLocation } from "react-router-dom";
import { getStoreId } from "../../../utils/common";
import { getMethod, postMethodWithPlatform } from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";
import { baseUrl } from "../../../utils/env.json";
import parse from "html-react-parser";
import { toast } from "react-toastify";

const Plans = () => {
    const [select, setSelect] = useState(true);
    const [planIndex, setPlanIndex] = useState("");
    const [planDetails, setPlanDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState("");
    const [onBoarding, setOnBoarding] = useState(false);
    const [reviewStoreData, setReviewStoreData] = useState("");
    const [selectedProductName, setSelectedProductName] = useState("");
    const [planName, setPlanName] = useState("");
    const [basketReceivedId, setBasketReceivedId] = useState("");
    const [planFlag, setPlanFlag] = useState(false);
    const [selectedPlanFlag, setSelectedPlanFlag] = useState("");

    const [price, setPrice] = useState("");
    let location = useLocation();
    const history = useHistory();

    let plans = [
        {
            money: "CA$0.00",
            planName: "Bronze",
            bannerLimit: 2,
            deals: "25 Deals",
            store: "1 Store",
        },
        {
            money: "CA$29.00",
            planName: "Silver",
            bannerLimit: 5,
            deals: "100 Deals",
            store: "3 Store",
        },
        {
            money: "CA$49.00",
            planName: "Gold",
            bannerLimit: 10,
            deals: "Unlimited",
            store: "Unlimited",
        },
    ];

    const getPlanIndex = (ind, item) => {
        setSelectedPlan(item.id);
        setSelect(true);
        setPlanIndex(ind);
        setPlanName(item.title);
        setPrice(item.price);
    };

    const onBoardStatus = async () => {
        await getMethod(urls.verifyOnboardStatus).then((response) => {
            setOnBoarding(response.data.status);
            if (response.data.status) {
                getReviewData();
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const goToReview = () => {
        history.push("/review");
    };

    useEffect(() => {
        onBoardStatus();
    }, []);

    const getPlansDetails = async (orderDetails) => {
        setLoading(true);
        let storeId = getStoreId();

        await getMethod(
            urls.plans + storeId + "/product/subscriptionproduct/?slug_value=subscription"
        ).then((response) => {
            if (response.status === 200) {
                if (response.data.planFlag.length > 0) {
                    setSelectedPlanFlag(response.data.planFlag[0].productId);
                    setPlanFlag(true);
                    setSelectedPlan(response.data.planFlag[0].productId);
                    let indexToBeSelected = response.data.response.findIndex((obj) => obj.id === response.data.planFlag[0].productId);
                    setPlanIndex(indexToBeSelected);
                }
                if (response.data.response.length > 0) {
                    setPlanDetails(response.data.response);
                    if (orderDetails !== null && orderDetails.length > 0) {
                        let indexToBeSelected = response.data.response.findIndex((obj) => obj.title === orderDetails[0].product_name);
                        setSelectedPlan(response.data.response[indexToBeSelected].id);
                        getPlanIndex(indexToBeSelected, response.data.response[indexToBeSelected]);
                    } else {
                        if (response.data.response[planIndex] !== undefined) {
                            setSelectedPlan(response.data.response[planIndex].id);
                            getPlanIndex(planIndex, response.data.response[planIndex].id);
                        }
                    }
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }
        });
    };

    const getPaymentMethodInfo = async () => {
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200) {
                getPlansDetails(response.data.order_detail);
                if (response.data.order_detail !== null && response.data.order_detail.length > 0) {
                    setSelectedProductName(response.data.order_detail[0].product_name);
                    setPlanName(response.data.order_detail[0].product_name);
                    setPrice(response.data.order_detail[0].price);
                }
            }
        });
    };

    useEffect(() => {
        getPaymentMethodInfo();
    }, []);


    const addToCart = async () => {
        if (planIndex!==""&&planIndex!==undefined) {
            if (planFlag) {
                let body = {
                    url: baseUrl + urls.postPlan + selectedPlanFlag + "/",
                    quantity: -1,
                };
                await postMethodWithPlatform(urls.addInBasket, body, "merchant").then((response) => {
                    if (response.status === 200) {
                        addTocarts();
                    }
                }).catch((error) => {
                    console.log("error", error);
                });
                if (!onBoarding) {
                    goToReview();
                }
            } else {
                addTocarts();
            }
        }else{
            toast.error("Please Select One Of The Plan");
        }

    };

    const addTocarts = async () => {
        let body = {
            url: baseUrl + urls.postPlan + selectedPlan + "/",
            quantity: 1,
        };

        await postMethodWithPlatform(urls.addInBasket, body, "merchant").then((response) => {
            if (response.status === 200) {
                console.log(response);
            }
        }).catch((error) => {
            console.log("error", error);
        });
        if (!onBoarding) {
            goToReview();
        }
    };

    const getReviewData = async () => {
        let storeid = getStoreId();
        let response_data = {};
        await getMethod(urls.vendorOnboarding + storeid).then((response) => {
            if (response.status === 200) {
                setReviewStoreData(response.data);
            }
        }).catch((error) => {
            console.log("error", error);
        });
    };

    const getPhoneFormat = (phone) => {
        const str = phone;
        const format = str.replace(/[^a-z\d\s]+/gi, "");
        const removeSpace = format.split(" ").join("");
        return "+" + removeSpace;
    };

    const getBaskets = async () => {
        await getMethod(urls.getBasket).then(async (response) => {
            if (response.status === 200) {
                setBasketReceivedId(response.data[0].id);

                if (reviewStoreData.financial_info.billing_address !== undefined) {
                    let body = {
                        basket: baseUrl + urls.getBasket + response.data[0].id + "/",
                        guest_email: reviewStoreData.user.email,
                        total: price,
                        shipping_charge: {
                            currency: "USD",
                            excl_tax: "0.0",
                            tax: "0.0",
                        },
                        shipping_method_code: "no-shipping-required",
                        shipping_address: {
                            country: baseUrl + urls.country + reviewStoreData.business_address.country + "/",
                            first_name: reviewStoreData.financial_info.billing_address.first_name !== undefined ? reviewStoreData.financial_info.billing_address.first_name : "",
                            last_name: reviewStoreData.financial_info.billing_address.last_name !== undefined ? reviewStoreData.financial_info.billing_address.last_name : "",
                            line1: reviewStoreData.financial_info.billing_address.line1 !== undefined ? reviewStoreData.financial_info.billing_address.line1 : "",
                            line2: reviewStoreData.financial_info.billing_address.unit !== undefined ? reviewStoreData.financial_info.billing_address.unit.toString() : "",
                            line3: reviewStoreData.financial_info.billing_address.province !== undefined && reviewStoreData.financial_info.billing_address.province !== "" ? reviewStoreData.financial_info.billing_address.province : "",
                            line4: reviewStoreData.financial_info.billing_address.city !== undefined ? reviewStoreData.financial_info.billing_address.city : "",
                            notes: "",
                            phone_number: reviewStoreData.financial_info.billing_address.phone_number !== undefined ? getPhoneFormat(reviewStoreData.financial_info.billing_address.phone_number) : "",
                            postcode: reviewStoreData.financial_info.billing_address.postcode !== undefined ? reviewStoreData.financial_info.billing_address.postcode : "",
                            state: reviewStoreData.financial_info.billing_address.province !== undefined && reviewStoreData.financial_info.billing_address.province !== "" ? reviewStoreData.financial_info.billing_address.province : reviewStoreData.financial_info.billing_address.state !== undefined && reviewStoreData.financial_info.billing_address.state !== "" ? reviewStoreData.financial_info.billing_address.state : "",
                            title: "",
                        },
                        billing_address: {
                            country: baseUrl + urls.country + reviewStoreData.business_address.country + "/",
                            first_name: reviewStoreData.financial_info.billing_address.first_name !== undefined ? reviewStoreData.financial_info.billing_address.first_name : "",
                            last_name: reviewStoreData.financial_info.billing_address.last_name !== undefined ? reviewStoreData.financial_info.billing_address.last_name : "",
                            line1: reviewStoreData.financial_info.billing_address.line1 !== undefined ? reviewStoreData.financial_info.billing_address.line1 : "",
                            line2: reviewStoreData.financial_info.billing_address.unit !== undefined ? reviewStoreData.financial_info.billing_address.unit.toString() : "",
                            line3: reviewStoreData.financial_info.billing_address.province !== undefined && reviewStoreData.financial_info.billing_address.province !== "" ? reviewStoreData.financial_info.billing_address.province : "",
                            line4: reviewStoreData.financial_info.billing_address.city !== undefined ? reviewStoreData.financial_info.billing_address.city : "",
                            notes: "",
                            phone_number: reviewStoreData.financial_info.billing_address.phone_number !== undefined ? getPhoneFormat(reviewStoreData.financial_info.billing_address.phone_number) : "",
                            postcode: reviewStoreData.financial_info.billing_address.postcode !== undefined ? reviewStoreData.financial_info.billing_address.postcode : "",
                            state: reviewStoreData.financial_info.billing_address.province !== undefined && reviewStoreData.financial_info.billing_address.province !== "" ? reviewStoreData.financial_info.billing_address.province : reviewStoreData.financial_info.billing_address.state !== undefined && reviewStoreData.financial_info.billing_address.state !== "" ? reviewStoreData.financial_info.billing_address.state : "",
                            title: "",
                        },
                    };

                    await postMethodWithPlatform(urls.checkout, body, "merchant").then((response) => {
                        if (response.status === 200) {
                            history.push("/settings/shippingDelivery");
                        } else {
                            toast.error("Something went wrong");
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                }
            }
        });
    };

    const checkoutToChangePlan = async () => {
        if (planName !== selectedProductName) {
            await addToCart();
            await getBaskets();
        } else {
            toast.error("Selected plan is already active");
        }
    };

    return (
        <div className="w-100 screenLeftMargin">
            <div className="row ml-3 mr-3 mt-3">
                <h1 className="col-12 heading-title">Plans</h1>
            </div>

            <div className="row ml-3 mr-3 mt-3 mainView">
                {planDetails && planDetails.map((item, index) => {
                    return (
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="card cardDesign" data-ribbon={item.title}>
                                <div className="card-header cardHeading text-center">
                                    <div className="plan_heading">
                                        CA${planDetails[index].price}
                                    </div>
                                    <div class="cardText">Per Month</div>
                                </div>

                                <div class="card-body cardBody text-center">
                                    {parse(item.description)}

                                    {planDetails[index].attributes !== null && planDetails[index].attributes.length > 0 ? (
                                        <>
                                            <p class="cardText pb-2">
                                                This plan includes the following benefits:
                                            </p>
                                            <p className="smallHeadings">Banner limit</p>
                                            <p className="cardText">
                                                {planDetails[index].attributes[0].value} Banner
                                            </p>
                                            <p className="smallHeadings">Products limit</p>
                                            <p className="cardText">
                                                {planDetails[index].attributes[3].value ? "Unlimited" : "Limited"}
                                            </p>
                                            <p className="smallHeadings">Deals/Promotions Limit</p>
                                            <p className="cardText">
                                                {planDetails[index].attributes[1].value}
                                            </p>
                                            <p className="smallHeadings">Stores/Accounts</p>
                                            <p className="cardText">
                                                {planDetails[index].attributes[2].value}
                                            </p>
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    {planIndex === index ? (
                                        <div class="btn btn-primary w-100 pt-3 pb-3 mt-4 mb-4" onClick={() => getPlanIndex(index, item)}>
                                            Selected
                                        </div>
                                    ) : (
                                        <div class="btn btn-cancle w-100 pt-3 pb-3 mt-4 mb-4" onClick={() => getPlanIndex(index, item)}>
                                            Choose this plan
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className="row ml-5 mr-5 pl-2 pr-2 mt-3 mainView">
                {planDetails.length > 0 ? (
                    <div className="col-12 d-flex justify-content-end">
                        {location.pathname === "/settings/plans" ? (
                            <div class="btn btn-primary" onClick={() => checkoutToChangePlan()} style={{ marginBottom: "40px", paddingBottom: "15px" }}>
                                Save
                            </div>
                        ) : (
                            <div class="btn btn-primary" onClick={() => addToCart()} style={{ marginBottom: "40px", paddingBottom: "15px" }}>
                                Finish & Review
                            </div>
                        )}
                    </div>
                ) : null}
            </div>
        </div>
    );
};
export default Plans;
