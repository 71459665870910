import React, { useEffect, useState } from "react";
import "./addProduct.scss";
import Button from "../../../components/button/button";
import importIcon from "../../../assets/images/home.png";
import StoreListing from "../../../components/storeEmpty/storeListingEmpty";
import TabBar from "./components/tabs/tabs";
import SpinnerLoader from "../../../components/loader/spinnerLoader";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchDB from "../../../assets/images/search.png";
import Filter from "../../../assets/images/filter.svg";
import Listing from "../../../assets/images/listing.svg";
import ListViewOption from "../../../assets/images/listViewIcon.svg";
import GridIcon from "../../../assets/images/gridIcon.svg";
import Input from "@material-ui/core/Input";
import Modal from "react-modal";
import { toast } from "react-toastify";
import TablePagination from "@mui/material/TablePagination";
import { deleteMethodWithToken, getMethod, getMethodWithoutBaseUrl, putMethodWithToken } from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";
import { getStoreId, setProductId, setProductEditableFlag, setInventoryEditedFlag, setShippingEditedFlag, setAddonsEditedFlag } from "../../../utils/common";
import Switch from "react-switch";
import ThumbNailView from "./components/thumbNailView/thumbNailView";
import Edit from "../../../assets/images/edit.png";
import Delete from "../../../assets/images/delete.png";
import NoImage from "../../../assets/images/storelisting.png";
import { useHistory } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Pagination from '@mui/material/Pagination';
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

let timeOutId;
window.$productDetails = [];
window.$inventoryDetails = [];
window.$spec = "";
window.$desc = "";
window.$ColorToggle = false;

function AddProduct() {
    window.$addProduct = false;
    const itemsPerPage = 20;

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const history = useHistory();
    const [addProduct, setAddProduct] = useState(window.$addProduct);
    const [listviewData, setListviewData] = useState([]);
    const [cardViewList, setCardViewList] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [indexIdToDelete, setIndexIdToDelete] = useState("");
    const [noMatch, setNoMatch] = useState(false);
    const [expandIndex, setExpandIndex] = useState("");
    const [hide, setHide] = useState(true);
    const [loader, setLoader] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [onBoarding, setOnBoarding] = useState(false);

    const productPage = () => {
        setAddProduct(true);
        setProductId("");
        setProductEditableFlag('added');
        setInventoryEditedFlag('added');
        setAddonsEditedFlag('added');
        setShippingEditedFlag('added');
        window.$addProduct = true;
        window.$spec = "";
        window.$desc = "";
    };

    const customStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 800 ? "40vw" : screenSize.width <= 1100 ? "28vw" : "25vw",
            height: screenSize.width <= 800 ? "15vh" : "18vh",
        },
    };

    const useStyles = makeStyles((theme) => ({
        draftToolTip: {
            backgroundColor: "rgb(255, 127, 0)",
        },
        draftToolTipArrow: {
            color: "rgb(255, 127, 0)",
        },
        activeToolTip: {
            backgroundColor: "rgb(0, 180, 0)",
        },
        activeToolTipArrow: {
            color: "rgb(0, 180, 0)",
        },
        deactiveToolTip: {
            backgroundColor: "rgb(255, 0, 0)",
        },
        deactiveToolTipArrow: {
            color: "rgb(255, 0, 0)",
        },
    }));

    const classes = useStyles();

    const cardView = () => {
        setCardViewList(true);
    };

    const listViewData = () => {
        setCardViewList(false);
    };

    const updateDataOfListing = async (url) => {
        setLoader(true);
        await getMethodWithoutBaseUrl(url).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setListviewData(response.data.results);
                setLoader(false);
            }
        }).catch((error) => {
            console.log("error", error);
        });
        setLoader(false);
    };

    useEffect(() => {
        getCurrentStatus();
        listedItem();
        onBoardingStatus();
    }, []);

    const onBoardingStatus = async () => {
        await getMethod(urls.verifyOnboardStatus).then((response) => {
            if (response.status === 200) {
                setOnBoarding(response.data.status);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const getCurrentStatus = async () => {
        await getMethod(urls.onboardingProgress + "?of=product").then((response) => {
            window.$progessPercentage = response.data.status;
        }).catch((error) => {
            console.log(error);
        });
    };

    const debounce = (func, delay) => {
        return (...args) => {
            if (timeOutId) clearTimeout(timeOutId);
            timeOutId = setTimeout(() => {
                func.apply(null, args);
            }, delay);
        };
    };

    const listedItem = async () => {
        setLoading(true);
        let storeId = getStoreId();
        await getMethod(urls.listview + storeId).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setListviewData(response.data.results);
                setLoading(false);
            }
        }).catch((error) => {
            console.log("error", error);
        });
        setLoading(false);
    };

    const listedItemWithoutLoader = async () => {
        let storeId = getStoreId();
        await getMethod(urls.listview + storeId).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setListviewData(response.data.results);
            }
        }).catch((error) => {
            console.log("error", error);
        });
    };

    const handlePageChange = async (event, value) => {
        setLoading(true);
        setPage(value)
        let storeId = getStoreId();
        await getMethod(urls.listview + storeId + "&page=" + value).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setListviewData(response.data.results);
                setLoading(false);
            }
        });

        setLoading(false);
    }

    const goToDetails = async (productClicked, index) => {
        setProductId(productClicked.id);
        setAddProduct(true);
        setProductEditableFlag('editable')
        window.$productDetails = productClicked;
    };

    const goToDetailsProduct = async (productClicked, innerProduct, child) => {
        setProductId(productClicked.id);
        setAddProduct(true);
        setProductEditableFlag('editable')
        window.$productDetails = productClicked;
    };

    const deleteProduct = async (id) => {
        setConfirmation(false);
        setLoading(true);
        let storeId = getStoreId();
        await deleteMethodWithToken(
            urls.aboutStore + storeId + "/product/" + id + "/productdelete/"
        ).then((response) => {
            toast.success("Product Deleted Successfully.");
            listedItem();
            setLoading(false);
        });
        setLoading(false);
    };

    const myFun = (val) => {
        setHide(val);
    };

    const productStatusUpdate = async (status, id) => {
        let body = {
            is_status: status
        }

        await putMethodWithToken(urls.putProductStatus + id + "/", body).then((response) => {
            if (response.status === 201 || response.status === 200) {
                toast.success(response.data.response)
                listedItem();
            } else {
                toast.error(response.data.response);
            }
        });
    }

    const showProductList = () => {
        if ((loading && listviewData.length > 0) || loader) {
            return <SpinnerLoader />;
        } else if (!addProduct && listviewData.length > 0 && !cardViewList) {
            return (
                <>
                    <div className="col-12">
                        {count > 1 &&
                            <div className="shopPagination">
                                <Pagination
                                    count={count}
                                    page={page}
                                    showFirstButton
                                    showLastButton
                                    onChange={(event, value) => handlePageChange(event, value)}
                                />
                            </div>
                        }
                    </div>

                    <div className="col-12">
                        <div className="col-12">
                            {listViewCard()}
                        </div>
                    </div>

                    <div className="col-12">
                        {count > 1 &&
                            <div className="shopPagination mt-4 mb-5">
                                <Pagination
                                    count={count}
                                    page={page}
                                    showFirstButton
                                    showLastButton
                                    onChange={(event, value) => handlePageChange(event, value)}
                                />
                            </div>
                        }
                    </div>
                </>
            );
        } else if (!addProduct && listviewData.length > 0 && cardViewList) {
            return (
                <>
                    <div className="col-12">
                        {count > 1 &&
                            <div className="shopPagination mt-4">
                                <Pagination
                                    count={count}
                                    page={page}
                                    showFirstButton
                                    showLastButton
                                    onChange={(event, value) => handlePageChange(event, value)}
                                />
                            </div>
                        }
                    </div>

                    <div class="col-12 mt-4">
                        <ThumbNailView
                            cardViewData={listviewData}
                            goToProductDetails={(item) => goToDetails(item)}
                            myFun={(val) => myFun(val)}
                        />
                    </div>

                    <div className="col-12">
                        {count > 1 &&
                            <div className="shopPagination mt-4 mb-5">
                                <Pagination
                                    count={count}
                                    page={page}
                                    showFirstButton
                                    showLastButton
                                    onChange={(event, value) => handlePageChange(event, value)}
                                />
                            </div>
                        }
                    </div>
                </>
            );
        } else {
            return;
        }
    };

    const expandDropdown = (index) => {
        if (expandIndex === index) {
            setExpandIndex("");
        } else {
            setExpandIndex(index);
        }
    };

    const ToDelete = (id) => {
        setConfirmation(true);
        setIndexIdToDelete(id);
    };

    const changeToFloat = (value) => {
        let valueInFloat = value !== null ? value.toFixed(2) : "";
        return valueInFloat;
    };

    const listViewCard = () => {
        if (!noMatch) {
            return (
                <>
                    <div className="row listSectionAddproduct ft-14 mt-3">
                        <div className="col-1 "></div>
                        <div className="col-3 f-600">Product Name</div>
                        <div className="col-1 f-600">SKU</div>
                        <div className="col-1 f-600">Qty.</div>
                        <div className="col-1 f-600">Cost</div>
                        <div className="col-1 f-600">Sale Price</div>
                        <div className="col-1 f-600">List Price</div>
                        <div className="col-1 f-600">Inv Alert</div>
                        <div className="col-1 f-600">Status</div>
                        <div className="col-1 f-600">Actions</div>
                    </div>

                    {listviewData && listviewData.map((item, index) => {
                        return (
                            <>
                                <div key={index + 1} className="row ft-14 mt-3 pt-3 pb-3" style={{ background: "#ECECEC" }}>
                                    <div className="col-1 d-flex productImg" style={{ alignItems: "center", cursor: "pointer" }}>
                                        <div className="d-flex">
                                            {item.productNewStatus !== true ?
                                                <Tooltip classes={{ tooltip: classes.draftToolTip, arrow: classes.draftToolTipArrow }} title="Draft" placement="left" arrow>
                                                    <div className="prolisting-color" style={{ backgroundColor: "rgb(255, 127, 0)" }}></div>
                                                </Tooltip>
                                                : item.status === true ?
                                                    <Tooltip classes={{ tooltip: classes.activeToolTip, arrow: classes.activeToolTipArrow }} title="Active" placement="left" arrow>
                                                        <div className="prolisting-color" style={{ backgroundColor: "rgb(0, 180, 0)" }}></div>
                                                    </Tooltip>
                                                    :
                                                    <Tooltip classes={{ tooltip: classes.deactiveToolTip, arrow: classes.deactiveToolTipArrow }} title="Deactive" placement="left" arrow>
                                                        <div className="prolisting-color" style={{ backgroundColor: "rgb(255, 0, 0)" }}></div>
                                                    </Tooltip>
                                            }
                                        </div>
                                        <div className="d-flex ml-2" style={{ alignItems: "center" }}>
                                            {item.structure === "parent" && expandIndex !== index ? (
                                                <i style={{ marginRight: "10px", fontSize: "20px", verticalAlign: "middle" }} className="fa fa-angle-down iconColorArrow" onClick={() => expandDropdown(index)} aria-hidden="true"></i>
                                            ) : item.structure === "parent" && expandIndex === index ? (
                                                <i style={{ marginRight: "10px", fontSize: "20px", verticalAlign: "middle" }} className="fa fa-angle-up iconColorArrow" onClick={() => expandDropdown(index)} aria-hidden="true"></i>
                                            ) : null}

                                            {item.images && item.images.slice(0, 1).map((item, i) => {
                                                return (
                                                    <img
                                                        onClick={() => goToDetails(item)}
                                                        src={item.original}
                                                        alt=""
                                                        className="listedImg"
                                                    />
                                                );
                                            })}

                                            {typeof json_data !== "undefined" && item.images.length == 0 &&
                                                <img src={NoImage} alt="" className="listedImg" />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-3 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetails(item)}>
                                        {item.product_name}
                                    </div>
                                    <div className="col-1 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetails(item)}>
                                        {item.partner_sku}
                                    </div>
                                    <div className="col-1 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetails(item)}>
                                        {item.num_in_stock}
                                    </div>
                                    <div className="col-1 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetails(item)}>
                                        {item.invoice_cost}
                                    </div>
                                    <div className="col-1 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetails(item)}>
                                        {changeToFloat(item.price)}
                                    </div>
                                    <div className="col-1 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetails(item)}>
                                        {item.compare_at_price && changeToFloat(parseInt(item.compare_at_price))}
                                    </div>
                                    <div className="col-1 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetails(item)}>
                                        {item.low_stock_threshold}
                                    </div>
                                    <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                        <Switch
                                            checked={item.status}
                                            onColor="#00A739"
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            name="status"
                                            onChange={(event) => productStatusUpdate(event, item.id)}
                                        />
                                    </div>
                                    <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                        <div className="d-flex ml-2 ">
                                            <EditIcon fontSize="small" onClick={() => goToDetails(item)} style={{ cursor: "pointer", color: "#000000" }} />
                                        </div>
                                        <div className="d-flex ml-2 ">
                                            <DeleteIcon fontSize="small" onClick={() => ToDelete(item.id)} style={{ cursor: "pointer", color: "#eb2813" }} />
                                        </div>
                                    </div>
                                </div>

                                {expandIndex === index && listviewData[index].children.map((items, index) => {
                                    return (
                                        <div key={index + 1} className="row ft-14 mt-3 pt-3 pb-3" style={{ background: "#ECECEC" }}>
                                            <div className="col-1 d-flex productImg" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetailsProduct(item, items, "child")}>
                                                <div className="d-flex">
                                                    {item.status === true ?
                                                        <Tooltip classes={{ tooltip: classes.activeToolTip, arrow: classes.activeToolTipArrow }} title="Active" placement="left" arrow>
                                                            <div className="prolisting-color" style={{ backgroundColor: "rgb(0, 180, 0)" }}></div>
                                                        </Tooltip>
                                                        :
                                                        <Tooltip classes={{ tooltip: classes.deactiveToolTip, arrow: classes.deactiveToolTipArrow }} title="Deactive" placement="left" arrow>
                                                            <div className="prolisting-color" style={{ backgroundColor: "rgb(255, 0, 0)" }}></div>
                                                        </Tooltip>
                                                    }
                                                </div>
                                                <div className="d-flex ml-2">
                                                    {items.images && items.images.slice(0, 1).map((items, i) => {
                                                        return (
                                                            <img className="listedImg" src={items.original} alt="" />
                                                        );
                                                    })}

                                                    {typeof json_data !== "undefined" && items.images.length == 0 &&
                                                        <img className="listedImg" src={NoImage} alt="" />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-3 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetailsProduct(item, items, "child")}>
                                                {items.product_name}
                                            </div>
                                            <div className="col-1 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetailsProduct(item, items, "child")}>
                                                {items.partner_sku}
                                            </div>
                                            <div className="col-1 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetailsProduct(item, items, "child")}>
                                                {items.num_in_stock}
                                            </div>
                                            <div className="col-1 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetailsProduct(item, items, "child")}>
                                                {items.invoice_cost}
                                            </div>
                                            <div className="col-1 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetailsProduct(item, items, "child")}>
                                                {changeToFloat(items.price)}
                                            </div>
                                            <div className="col-1 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetailsProduct(item, items, "child")}>
                                                {items.compare_at_price && changeToFloat(parseInt(items.compare_at_price))}
                                            </div>
                                            <div className="col-1 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetailsProduct(item, items, "child")}>
                                                {items.low_stock_threshold}
                                            </div>
                                            <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                                <Switch
                                                    checked={items.status}
                                                    onColor="#00A739"
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    name="status"
                                                    onChange={(event) => productStatusUpdate(event, items.id)}
                                                />
                                            </div>
                                            <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                                <div className="d-flex ml-2">
                                                    <EditIcon onClick={() => goToDetailsProduct(item, items)} style={{ cursor: "pointer" }} />
                                                </div>
                                                <div className="d-flex ml-2">
                                                    <DeleteIcon onClick={() => ToDelete(items.id)} style={{ cursor: "pointer",color:"#eb2813" }} />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        );
                    })}
                </>
            );
        } else {
            return <div className="noMatch">No matching product found.</div>;
        }
    };

    const searchProduct = async (text) => {
        let storeId = getStoreId();
        if (text === "") {
            setNoMatch(false);
            listedItem();
        } else {
            await getMethod(urls.aboutStore + storeId + "/searchproduct/?product_name=" + text).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (response.data.results.data.length > 0) {
                        setNoMatch(false);
                        setCount(Math.ceil(response.data.count / itemsPerPage))
                        setPage(1)
                        setListviewData(response.data.results.data);
                    } else {
                        setNoMatch(true);
                    }
                }
            });
        }
    };

    const handleSearchInputChange = (e) => {
        setSearchInput(e.target.value);
        debounceCall(e.target.value);
    };

    const debounceCall = debounce(searchProduct, 500);

    return (
        <div className="w-100 screenLeftMargin">
            {!addProduct && hide &&
                <>
                    <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                        <div className="col-12 heading-title"> Store Listings</div>
                    </div>
                    <div className="row ml-3 mr-3 mt-2 mobileResponsive">
                        <div className="col-lg-9 col-md-8">
                            <div className="muiDescripationInfo">
                                Stock your online store with as many products as you can. Let your perspective customers discover what you have in store.
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 d-flex justify-content-end btn-mobile-margin-none" style={{ top: "-25px" }}>
                            <Button onClick={() => productPage()} className="btn btn-cancle" name="Add a product">
                                <img src={importIcon} />
                            </Button>
                        </div>
                    </div>
                </>
            }

            {!addProduct && hide && listviewData.length > 0 &&
                <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                    <div className="col-12 mb-2">
                        <div className="h6-label">Listed Item</div>
                    </div>
                    <div className="col-xl-4 col-lg-5 col-md-6">
                        <TextField
                            onChange={(text) => console.log(text)}
                            name="search"
                            label="Search Products"
                            variant="outlined"
                            value={searchInput}
                            onChange={(event) => handleSearchInputChange(event)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className="col-xl-8 col-lg-7 col-md-6">
                        <div className="d-flex justify-content-end">
                            <img
                                onClick={() => listViewData()}
                                className="paddingRight08"
                                src={ListViewOption}
                                alt=""
                                style={{ cursor: "pointer" }}
                            />
                            <img
                                className="paddingRight0"
                                onClick={() => cardView()}
                                src={GridIcon}
                                alt=""
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </div>
                </div>
            }

            <div className="row ml-3 mr-3 mobileResponsive">
                {addProduct &&
                    <TabBar backHandle={() => productPage()} generalDetails={listviewData} />
                }

                <div className="col-12">
                    {loading && addProduct === false ? (
                        <SpinnerLoader />
                    ) : loading === false && addProduct === false && listviewData.length === 0 ? (
                        <StoreListing />
                    ) : (
                        ""
                    )}
                </div>
            </div>

            <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                {showProductList()}
            </div>

            {onBoarding === false && addProduct === false &&
                <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                    <div className="col-12 d-flex justify-content-end">
                        <Button type="onSubmit" onClick={() => history.push("/financials")} name="Skip" className="btn btn-primary mb-5" />
                    </div>
                </div>
            }

            <div className="row ml-3 mr-3 mt-3">
                <div className="col-12">
                    <Modal isOpen={confirmation} style={customStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                        <div className="mt-4 mb-4">
                            <div className="row">
                                <div className="col-12 text-center">
                                    Are you sure you want to delete this product?
                                </div>
                                <div className="col-12 mt-4  d-flex justify-content-center">
                                    <Button type="onSubmit" onClick={() => setConfirmation(false)} name="Cancel" className="btn btn-sm btn-cancle mr-3" />
                                    <Button type="onSubmit" onClick={() => { deleteProduct(indexIdToDelete); }} name="Delete" className="btn btn-sm btn-primary" />
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    );
}
export default AddProduct;
