import React, { useEffect, useState } from "react";
import "./collection.scss";
import Button from "../../../../components/button/button";
import TabCollection from "./components/tabCollection/tabCollection";
import CollectionDB from "../../../../assets/images/collection.png";
import CollectionList from "./components/collectionList/CollectionList";

function Collection() {
    window.$addCollection = false;
    const [addCollection, setAddCollection] = useState(window.$addCollection);
    const [collectionDetail, setCollectionDetail] = useState("");
    const [show, setShow] = useState(false);

    const collectionPage = () => {
        setAddCollection(true);
        window.$addCollection = true;
        setShow(false);
        setCollectionDetail("");
    };

    const collectionDetails = (val) => {
        setCollectionDetail(val);
    }

    const showView = (val) => {
        setShow(val);
    }

    return (
        <div className="w-100 screenLeftMargin">

            {!addCollection &&
                <>
                    <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                        <div className="col-12 heading-title">Collections</div>
                    </div>
                    <div className="row ml-3 mr-3 mt-2 mobileResponsive">
                        <div className="col-lg-9 col-md-8">
                            <div className="muiDescripationInfo">
                                Collection can be used to manage your listings and Discounts.This information will not be seen by customers.                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 d-flex justify-content-end btn-mobile-margin-none" style={{ top: "-25px" }}>
                            <Button onClick={() => collectionPage()} className="btn btn-cancle btn-lg" name="Create Collection"></Button>
                        </div>
                    </div>
                </>
            }

            {addCollection ?
                <div className="row ml-3 mr-3 mobileResponsive">
                    <div className="col-12">
                        < TabCollection show={show} showListing={collectionDetail} setShowListing={(val) => setAddCollection(val)} />
                    </div>
                </div>
                : ""}

            {!addCollection &&
                <div className="row ml-3 mr-3 mobileResponsive">
                    <div className="col-12">
                        < CollectionList show={(val) => showView(val)} setShowListing={(val) => setAddCollection(val)} collectionDetails={(val) => collectionDetails(val)} />
                    </div>
                </div>
            }

        </div>
    )
}
export default Collection;