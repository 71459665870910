import React, { useState, useEffect } from 'react';
import './Approved.scss';
import { getMethod } from '../../../../../utils/services/api';
import urls from "../../../../../utils/services/apiUrl.json";
import { useHistory } from "react-router-dom";
import SpinnerLoader from "../../../../../components/loader/spinnerLoader";
import moment from "moment";
import CollectionDB from "../../../../../assets/images/collection.png";
import TablePagination from '@mui/material/TablePagination';
import LanguageIcon from '@material-ui/icons/Language';
import PhoneIcon from '@material-ui/icons/Phone';
import Pagination from '@mui/material/Pagination';

const Approved = () => {
    const itemsPerPage = 20;
    const [loading, setLoading] = useState(false);
    const [listOfInApprovedStores, setListOfInApprovedStores] = useState([]);
    const history = useHistory();
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    const createDate = (date) => {
        let now = new Date(date)
        var dateString = moment(now).format('DD MMM YYYY');
        return dateString
    };

    useEffect(() => {
        storeListing();
    }, []);

    const approveDetails = async (item) => {
        history.push({ pathname: `/stores/approved/${item.id}`});
    };

    const storeListing = async () => {
        setLoading(true);
        await getMethod(urls.getStoreApi + "approved").then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count/itemsPerPage))
                setListOfInApprovedStores(response.data.results.data);
                setLoading(false);
            }
        });
    };

    const handlePageChange = async (event, value) => {
        setLoading(true);
        setPage(value)

        await getMethod(urls.getStoreApi + "approved&page="+value).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count/itemsPerPage))
                setListOfInApprovedStores(response.data.results.data);
                setLoading(false);
            }
        });

        setLoading(false);
    }

    if (loading) {
        return <SpinnerLoader />;
    } else {
        return (
            <div>
                {count > 1 && 
                    <div className="shopPagination mt-3">
                        <Pagination 
                            count={count}
                            page={page}
                            showFirstButton
                            showLastButton 
                            onChange={(event, value) => handlePageChange(event, value)}
                        />
                    </div>
                }

                <div className="mt-3">
                    {listOfInApprovedStores.length !== 0 ? (
                        <>
                            <div className="row pt-3 pb-3 ml-0 mr-0">
                                <div className="col-2 f-600">Store Name</div>
                                <div className="col-2 f-600">App User</div>
                                <div className="col-3 f-600">Information</div>
                                <div className="col-3 f-600">Address</div>
                                <div className="col-2 f-600">Applied On</div>
                            </div>

                            {listOfInApprovedStores.length > 0 && listOfInApprovedStores.map((item, index) => {
                                return (
                                    <div className="row mt-3 pt-3 pb-3 ml-0 mr-0" style={{background: "#ECECEC", cursor: "pointer"}} onClick={() => approveDetails(item)}>
                                        <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                            {item.store_company_name}
                                        </div>
                                        <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                            {item.owner_name}
                                        </div>
                                        <div className="col-3" style={{alignItems: "center"}}>
                                            {item.phone_number !== "" && item.phone_number !== undefined && item.phone_number !== null &&
                                                <div>
                                                    <PhoneIcon fontSize="small" style={{color: "#808080", marginRight:"5Px", marginTop: "-1px"}}/>
                                                    {item.country_code + " " + item.phone_number}
                                                </div>
                                            }

                                            {item.email !== "" && item.email !== undefined && item.email !== null &&
                                                <div>
                                                    <LanguageIcon fontSize="small" style={{color: "#808080", marginRight:"5Px", marginTop: "-1px"}}/>
                                                    {item.email}
                                                </div>
                                            }
                                        </div>
                                        <div className="col-3 d-flex" style={{alignItems: "center"}}>
                                            {item.location}
                                        </div>
                                        <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                            {item.created_at !== undefined ? createDate(item.created_at) : ""}
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    ) : (
                        <div className='collection-img'>
                            <img src={CollectionDB} alt="" />
                        </div>
                    )}
                </div>

                {count > 1 && 
                    <div className="shopPagination mt-3 mb-5">
                        <Pagination 
                            count={count}
                            page={page}
                            showFirstButton
                            showLastButton 
                            onChange={(event, value) => handlePageChange(event, value)}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default Approved;
