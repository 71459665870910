import React, { useEffect, useState } from "react";
import "./OnlineEnquiry.scss";
import Button from "../../../components/button/button";
import { useHistory } from "react-router-dom";
import Phone from "../../../assets/images/Call_C.svg";
import NoImage from "../../../assets/images/storelisting.png";
import OnlineEnq from "../../../assets/images/onlineEnqM.svg";
import { getMethod } from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";
import SpinnerLoader from "../../../components/loader/spinnerLoader";
import TablePagination from "@mui/material/TablePagination";
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Pagination from '@mui/material/Pagination';
import moment from "moment";


const OnlineEnquiry = (props) => {
    const itemsPerPage = 20;
    const [storeRequestList, setStoreRequestList] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    const storeDetails = async (item) => {
        history.push({ pathname: "/onlineenquiry/storedetails", state: { item } });
    };

    const notification = async () => {
        history.push("/notifications");
    };

    useEffect(() => {
        getStoreRequests();
        //getProfileInfo();
    }, []);

    /*const getProfileInfo = async () => {
        await getMethod(urls.getCustomerStripeId).then((resp) => {
        });
    };*/

    const getStoreRequests = async () => {
        setLoading(true);
        
        await getMethod(urls.onlineEnquiry + "onlineenquiry").then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count/itemsPerPage))
                setStoreRequestList(response.data.results.data);
                setLoading(false);
            }
        });
        
        setLoading(false);
    };

    const handlePageChange = async (event, value) => {
        setLoading(true);
        setPage(value)

        await getMethod(urls.onlineEnquiry + "onlineenquiry&page="+value).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count/itemsPerPage))
                setStoreRequestList(response.data.results.data);
                setLoading(false);
            }
        });

        setLoading(false);
    }

    return (
        <div className={props.menu ? "w-100 screenLeftMargin" : "screenLeftMarginResponse w-100"}>
            {/* <div className="row mt-3 ml-3 mr-3 mobileResponsive">
                <div className="col-12 d-flex justify-content-end">
                    <div className="muiNotificationsIcon d-flex mr-3" onClick={() => notification()}>
                        <NotificationsIcon style={{color: "#fff", fontSize: "30px"}}/>
                    </div>
                    <div className="muiNotificationsIcon d-flex">
                        <EmailIcon style={{color: "#fff", fontSize: "30px"}}/>
                    </div>
                </div>
            </div> */}

            <div className="row mt-3 ml-3 mr-3 mobileResponsive">
                <div className="col-6">
                    <div className="enquiry-text">
                        <img className="headIcon" src={OnlineEnq} />
                        <span className="heading-title pl-2"> Online Enquiry</span>
                    </div>
                </div>

                {/*<div className="col-6 d-flex justify-content-end">
                    <Button onClick={() => ""} className="btn btn-cancle btn-lg" name="Archived Requests"></Button>
                </div>*/}
            </div>

            <div className="row mt-3 ml-3 mr-3 mobileResponsive">
                <div className="col-12">
                    {loading ? (
                        <SpinnerLoader />
                    ) : (
                        <div className="">
                            {count > 1 && 
                                <div className="shopPagination">
                                    <Pagination 
                                        count={count}
                                        page={page}
                                        showFirstButton
                                        showLastButton 
                                        onChange={(event, value) => handlePageChange(event, value)}
                                    />
                                </div>
                            }

                            <div className="col-12">
                                <div className="row mt-3 pb-3">
                                    <div className="col-3 f-600">Store Name</div>
                                    <div className="col-3 f-600">Owner's Name</div>
                                    <div className="col-4 f-600">Information</div>
                                    <div className="col-2 f-600">Date</div>
                                </div>

                                {storeRequestList.map((item, index) => {
                                    return (
                                        <div className="row mt-3 pt-3 pb-3" style={{background: "#ECECEC", cursor: "pointer"}} onClick={() => storeDetails(item)}>
                                            <div className="col-3 d-flex" style={{alignItems: "center"}}>
                                                {item.store_company_name}
                                            </div>
                                            <div className="col-3 d-flex" style={{alignItems: "center"}}>
                                                {item.owner_name}
                                            </div>
                                            <div className="col-4" style={{alignItems: "center"}}>
                                                <div>
                                                    <PhoneIcon fontSize="small" style={{color: "#b7b7b7", marginRight:"5Px", marginTop: "-1px"}}/>
                                                    {item.country_code + " " + item.phone_number}
                                                </div>
                                                <div>
                                                    <EmailIcon fontSize="small" style={{color: "#b7b7b7", marginRight:"5Px", marginTop: "-1px"}}/>
                                                    {item.email}
                                                </div>
                                            </div>
                                            <div className="col-2 d-flex" style={{alignItems: "center"}}>
                                                {moment(item.created_at).format('YYYY MMM DD HH:mm')}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            {count > 1 && 
                                <div className="shopPagination mt-3 mb-5">
                                    <Pagination 
                                        count={count}
                                        page={page}
                                        showFirstButton
                                        showLastButton 
                                        onChange={(event, value) => handlePageChange(event, value)}
                                    />
                                </div>
                            }
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OnlineEnquiry;
