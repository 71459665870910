import React, { useState } from 'react';
import './OpeningDays.scss';
import moment from "moment";

const OpeningDays = ({data}) => {

   
    return (
        <div className=''>
            <div className='FinancialBox mt-3'>
                <div className='h5-label pt-3 pl-4'>Opening Days</div>
                {data?.opening_days?.map((item,index)=>{
                    console.log(item)
                    return( 
                        <>
                        {item.startDateTime && item.endDateTime !== null && 
                        <>
                         <div className='mt-3'>
                             <span className="pl-4 h5 leadBox">{item.days.name}</span>
                        </div>
                        <div className='d-flex mt-3 ml-4'>
                            <div className='col-3'>
                                <div className='h5-label'>Start Time</div>
                                <div className='leadBox-details'>{moment(item.endDateTime).format('HH:mm')}</div>
                            </div>
                            <div className='col-3'>
                                <div className='h5-label'>End  Time</div>
                                <div className='leadBox-details'>{moment(item.endDateTime).format('HH:mm')}</div>
                            </div>
                            </div>
                            <hr className='mt-3' />
                            </>
                            }
                        </>
                    )

                })}
               
               
            </div>
        </div>
    )
}

export default OpeningDays;
