import React, { useState } from 'react';
import './BussinessCred.scss';
import DefaultIamge from "../../../../../../../../../assets/images/ShopezDefult.svg"
import PDF from "../../../../../../../../../assets/images/pdfHolder.svg"

const BussinessCred = ({data}) => {
    const checkFileExtension = (data) => {
        var fileExtension = data?.split(".").pop();
        return fileExtension === "pdf";
    };
    return (
        <div>
            <div className='BussinessBox mt-3'>
                <div className="row pt-3 pl-5">
                    
                        {/* <img 
                            alt=""
                            style={{width: "200px", height: "200px"}}
                            src={data?.business_creditionals?.[0]?.file ? data?.business_creditionals?.[0]?.file : DefaultIamge }
                        /> */}
                          {checkFileExtension(data?.business_creditionals?.[0]?.file) ? (
                             <a href={data?.business_creditionals?.[0]?.file} target="_blank">
                                 <img src={PDF} style={{width:"200px",height:"200px"}} alt="" />
                             </a>
                         ) : (
                        <img src={data?.business_creditionals?.[0]?.file ? data?.business_creditionals?.[0]?.file : DefaultIamge } style={{width:"200px",height:"200px"}} alt="" />
                         )}  
                </div>
            </div>
        </div>
    )
}

export default BussinessCred;
