import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import Switch from "react-switch";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import ListSubheader from "@material-ui/core/ListSubheader";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Modal from "react-modal";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import RoleDB from "../../../../assets/images/Territory_T.svg";
import Button from "../../../../components/button/button";
import {deleteMethodWithToken, getMethod, postMethodWithToken, putMethodWithToken, deleteMethodWithTokenAndData} from "../../../../utils/services/api";
import urls from "../../../../utils/services/apiUrl.json";
import "./territories.scss";

const checkTerritory = value => {    
    const emptyKeys = [
        'country_name',
        'state_id',
        'city_id',
    ];

    const emptyKeysErrors = [
        'Country Name',
        'State Name',
        'City Name',
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!value[emptyKeys[i]]) {
            return `Please fill in ${emptyKeysErrors[i]}`;
        }
    }

    return '';
};

const Territories = (props) => {
    const history = useHistory();

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const [modelOpen, setModelOpen] = useState(false);
    const [addTerritory, setAddTerritory] = useState(false);
    
    const [territoryList, setTerritoryList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [stateDataList, setStateDataList] = useState([]);
    const [cityDataList, setCityDataList] = useState([]);

    const [formValue,setFormValue] = useState({
        country_name:"",
        state_id:"",
        city_id:"",
    })

    const [stateConfirmation, setStateConfirmation] = useState(false);
    const [stateConfirmationValue, setStateConfirmationValue] = useState("");
    const [stateIdToDelete, setStateIdToDelete] = useState("");
    const [cityConfirmation, setCityConfirmation] = useState(false);
    const [cityConfirmationValue, setCityConfirmationValue] = useState("");
    const [cityIdToDelete, setCityIdToDelete] = useState("");
    
    const gotoBack = async () => {
        history.push('/masterdata')
    };

    useEffect(() => {
        getCountryList();
        getTerritoryList();
    }, []);

    const getTerritoryList = async () => {
        await getMethod(urls.getTerritoryList).then((response) => {
            if (response.status === 200) {
                setTerritoryList(response.data.response);
            }
        });
    };

    const getCountryList = async () => {
        await getMethod(urls.getCountry).then((response) => {
            if (response.status === 200) {
                setCountryList(response.data);
            }
        });
    };

    const getStateList = async (value) => {
        await getMethod(urls.getStates+'?country_name='+value).then((response) => {
            if (response.status === 200) {
                setStateList(response.data.response[0].data.states);
                setStateDataList(response.data.response[0].data.states);
            }
        });
    };

    const getCityList = async (value) => {
        await getMethod(urls.getStates+'?country_name='+formValue.country_name+'&state_id='+value).then((response) => {
            if (response.status === 200) {
                setCityList(response.data.response[0].data.cities);
                setCityDataList(response.data.response[0].data.cities);
            }
        });
    };

    const handleCountryChange = (event) => {
        setFormValue({...formValue, [event.target.name]:event.target.value});
        getStateList(event.target.value);
    };

    const handleStateChange = (event) => {
        if(event.target.value !== undefined) {
            setFormValue({...formValue, [event.target.name]:event.target.value});
            getCityList(event.target.value);
        }
    };

    const handleCityChange = (event) => {
        if(event.target.value !== undefined) {
            setFormValue({...formValue, [event.target.name]:event.target.value});
        }
    };

    const stateStatusUpdate = async (id) => {
        const body = {
            "state_id": id
        }

        await putMethodWithToken(urls.putStateStatusUpdate+id+'/', body).then(
            (response) => {
                if (response.status === 201 || response.status === 200) {
                    toast.success(response.data.response);
                    getTerritoryList();
                } else {
                    toast.error(response.data.response);
                }
            }
        );
    };

    const cityStatusUpdate = async (id) => {
        const body = {
            "cities_ids": [id]
        }

        await putMethodWithToken(urls.putCityStatusUpdate+id+'/', body).then((response) => {
            if (response.status === 201 || response.status === 200) {
                toast.success(response.data.response);
                getTerritoryList();
            } else {
                toast.error(response.data.response);
            }
        });
    };

    const openTerritory = () => {
        setModelOpen(true);
    };

    const closeTerritory = () => {
        setModelOpen(false);
        setFormValue({...formValue, ['country_name']:''});
        setFormValue({...formValue, ['state_id']:''});
        setFormValue({...formValue, ['city_id']:''});
    };

    const deleteStateTerritories = async (id) => {
        const body = {
            "state_id": id
        }

        await deleteMethodWithTokenAndData(urls.deleteTerritory, body).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.response);
                getTerritoryList();
                setStateConfirmation(false)
            } else {
                toast.error(response.data.response);
                setStateConfirmation(false)
            }
        });
    };

    const deleteCityTerritories = async (id) => {
        const body = {
            "city_id": id
        }

        await deleteMethodWithTokenAndData(urls.deleteTerritory, body).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.response);
                getTerritoryList();
                setCityConfirmation(false)
            } else {
                toast.error(response.data.response);
                setStateConfirmation(false)
            }
        });
    };

    const addNewTerritory = async () => {
        const error = checkTerritory(formValue);

        if(error) {
            toast.error(error);
        } else {
            await postMethodWithToken(urls.addTerritory, formValue).then((response) => {
                if (response.status === 201 || response.status === 200) {
                    toast.success(response.data.response);
                    setModelOpen(false);
                    getTerritoryList();
                    setFormValue({...formValue, ['country_name']:''});
                    setFormValue({...formValue, ['state_id']:''});
                    setFormValue({...formValue, ['city_id']:''});
                } else {
                    toast.error(response.data.response);
                }
            });
        }
    };

    const customStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 800 ? "40vw" : screenSize.width <= 1100 ? "28vw" : "25vw",
            height: screenSize.width <= 800 ? "15vh" : "18vh",
        },
    };

    const allStyless = {
        content: {
            top: '50%',
            left: '50%',
            transform: screenSize.width <= 800 ? 'translate(-32%, -50%)' : screenSize.width <= 1100 ? 'translate(-38%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            width: "70vw",
            height: screenSize.width <= 800 ? "32vh" : screenSize.width <= 1100 ? "40vh" : "35vh",
            outline: "none",
            padding: "15px",
        },
    };

    const stateFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = stateDataList.filter((item) => {
                return item.stateName.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setStateList(results);
        } else {
            setStateList(stateDataList);
        }
    };

    const cityFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = cityDataList.filter((item) => {
                return item.cityName.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setCityList(results);
        } else {
            setCityList(cityDataList);
        }
    };

    return (
        <div className={props.menu ? "w-100 screenLeftMargin" : "w-100 screenLeftMarginResponse"}>
            <div className="row ml-3 pl-3 mr-3 mt-3 mobileResponsive">
                <div  onClick={() => gotoBack()} style={{cursor: "pointer"}}>
                    <ArrowBackIcon /> <a style={{color: "#4472C4"}} className="ml-2">Master Data</a>
                </div>
            </div>
            <div className="row ml-3 mr-3 mt-2 mobileResponsive">
                <div className="col-7 d-flex">
                    <div className="d-flex mr-3">
                        <img width="25" src={RoleDB} />
                    </div>
                    <div className="d-flex" style={{alignItems: "center"}}>
                        <h1 className="heading-title d-flex">Territories</h1>
                    </div>
                </div>
                <div className="col-5" style={{textAlign: "right"}}>
                    <Button onClick={() => openTerritory()} className="btn btn-cancle btn-lg" name="Add Territory"></Button>
                </div>
            </div>
            <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                <h2 className="operational col-12">Operational Areas</h2>
            </div>

            <div className="row ml-3 mr-3 mt-2 mobileResponsive">
                <div className="col-12 isoTerritoriesList">
                    {territoryList.map((items, index) => {
                        return(
                            <Accordion className="isoTerritoriesItem">
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={"panel"+index+"-content"}
                                    id={"panel"+index+"-header"}
                                    className="isoTerritoriesTitle"
                                    >
                                    <Typography className="isoTerritoriesContent">
                                        <img src={items.images[0].file} width="50" alt="" /> {items.iso_3166_1_a3}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails className="isoTerritoriesDetails">
                                    <Typography>
                                        {items.state.map((state, sindex) => {
                                            return (
                                                <Accordion className="isoTerritoriesItem">
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls={"panel"+sindex+"-content"}
                                                        id={"panel"+sindex+"-header"}
                                                        className="isoTerritoriesTitle"
                                                        >
                                                        <Typography className="isoTerritoriesContent">
                                                            <div className="col-6">
                                                                {state.stateName}
                                                            </div>
                                                            <div className="col-3">
                                                                <Switch
                                                                    className="switch"
                                                                    checked={!state.is_status}
                                                                    onChange={() => {
                                                                        stateStatusUpdate(state.id)
                                                                    }}
                                                                />
                                                            </div>
                                                            <div style={{cursor: "pointer"}} className="col-3 d-flex justify-content-end">
                                                                <DeleteIcon fontSize="small" style={{color:"#eb2813"}} onClick={() => {
                                                                    setStateConfirmation(true)
                                                                    setStateConfirmationValue(state.stateName)
                                                                    setStateIdToDelete(state.id)
                                                                }}/>
                                                            </div>
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="isoTerritoriesDetails">
                                                        {state.cities.map((city, cindex) => {
                                                            return (
                                                                <>
                                                                    <div className="row p-0 m-0">
                                                                        <div className="col-6">
                                                                            {city.cityName}
                                                                        </div>
                                                                        <div className="col-3">
                                                                            <Switch
                                                                                className="switch"
                                                                                checked={!city.is_status}
                                                                                onChange={() => {
                                                                                    cityStatusUpdate(city.id)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div style={{cursor: "pointer"}} className="col-3 d-flex justify-content-end">
                                                                            <DeleteIcon fontSize="small" style={{color:"#eb2813"}} onClick={() => {
                                                                                setCityConfirmation(true)
                                                                                setCityConfirmationValue(city.cityName)
                                                                                setCityIdToDelete(city.id)
                                                                            }}/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                    </AccordionDetails>
                                                </Accordion>
                                            )
                                        })}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </div>
            </div>

            <div>
                <Modal
                    isOpen={modelOpen}
                    style={allStyless}
                    overlayClassName="myoverlay" 
                    closeTimeoutMS={500}
                    >
                    <div className="row mt-3">
                        <div className="h4-label text-center mt-3 mb-3 col-12">
                            Add Territory
                        </div>
                    </div>
                    <div className="row ml-4">
                        <div className="col-4">
                            <ValidatorForm>
                                <FormControl variant="outlined" className="mt-5">
                                    <InputLabel required id="country-select">
                                        Country
                                    </InputLabel>
                                    <Select
                                        labelId="country-select"
                                        id="country-select-dropdown"
                                        value={formValue.country_name}
                                        onChange={handleCountryChange}
                                        label="Country"
                                        name="country_name"
                                        >
                                        {countryList.map((item) => {
                                            return (
                                                <MenuItem value={item.iso_3166_1_a3}>
                                                    {item.printable_name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </ValidatorForm>
                        </div>
                        <div className="col-4">
                            <ValidatorForm>
                                <FormControl variant="outlined" className="mt-5">
                                    <InputLabel required id="state-select">
                                        State
                                    </InputLabel>
                                    <Select
                                        MenuProps={{ autoFocus: false }}
                                        labelId="state-select"
                                        id="state-select-dropdown"
                                        value={formValue.state_id}
                                        defaultValue={formValue.state_id}
                                        label="State"
                                        name="state_id"
                                        onChange={(e) => handleStateChange(e)}
                                        onClose={() => setStateList(stateDataList)}
                                        >
                                        <ListSubheader>
                                            <TextField
                                                size="small"
                                                autoFocus
                                                placeholder="Type to search..."
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onChange={stateFilter}
                                                onKeyDown={(e) => {
                                                    if (e.key !== "Escape") {
                                                        e.stopPropagation();
                                                    }
                                                }}
                                            />
                                        </ListSubheader>
                                        {stateList.length > 0 && stateList.map((item) => {
                                            return (
                                                <MenuItem value={item.id}>
                                                    {item.stateName}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </ValidatorForm>
                        </div>
                        <div className="col-4">
                            <ValidatorForm>
                                <FormControl variant="outlined" className="mt-5">
                                    <InputLabel required id="city-select">City</InputLabel>
                                    <Select
                                        MenuProps={{ autoFocus: false }}
                                        labelId="city-select"
                                        id="city-select-dropdown"
                                        value={formValue.city_id}
                                        defaultValue={formValue.city_id}
                                        label="City"
                                        name="city_id"
                                        onChange={(e) => handleCityChange(e)}
                                        onClose={() => setCityList(cityDataList)}
                                        >
                                        <ListSubheader>
                                            <TextField
                                                size="small"
                                                autoFocus
                                                placeholder="Type to search..."
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onChange={cityFilter}
                                                onKeyDown={(e) => {
                                                    if (e.key !== "Escape") {
                                                        e.stopPropagation();
                                                    }
                                                }}
                                            />
                                        </ListSubheader>
                                        {cityList.map((item) => {
                                            return (
                                                <MenuItem value={item.id}>
                                                    {item.cityName}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </ValidatorForm>
                        </div>
                    </div>
                    <div className="col-12 mt-5 d-flex justify-content-center">
                        <Button type="onSubmit" onClick={() => closeTerritory()} name="Cancel" className="btn btn-cancle mr-3" />
                        <Button onClick={() => addNewTerritory()} className="btn btn-primary" name="Save"></Button>
                    </div>
                </Modal>

                <Modal isOpen={stateConfirmation} style={customStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                    <div className="mt-4 mb-4">
                        <div className="row">
                            <div className="col-12 text-center">
                                Are you sure you want to delete {stateConfirmationValue}?
                            </div>
                            <div className="col-12 mt-4  d-flex justify-content-center">
                                <Button type="onSubmit" onClick={() => setStateConfirmation(false)} name="Cancel" className="btn btn-cancle btn-sm mr-3" />
                                <Button type="onSubmit" onClick={() => {deleteStateTerritories(stateIdToDelete);}} name="Delete" className="btn btn-primary btn-sm" />
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={cityConfirmation} style={customStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                    <div className="mt-4 mb-4">
                        <div className="row">
                             <div className="col-12 text-center">
                                Are you sure you want to delete {cityConfirmationValue}?
                            </div>
                            <div className="col-12 mt-4  d-flex justify-content-center">
                                <Button type="onSubmit" onClick={() => setCityConfirmation(false)} name="Cancel" className="btn btn-cancle btn-sm mr-3"/>
                                <Button type="onSubmit" onClick={() => {deleteCityTerritories(cityIdToDelete);}} name="Delete" className="btn btn-primary btn-sm"/>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default Territories;
