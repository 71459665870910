import React, { useEffect, useState } from "react";
import "./forgotPassword.scss";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import ShopezLogo from "../../../assets/images/shopez_logo.png";
import Button from "../../../components/button/button";
import { useHistory } from "react-router-dom";
import { postMethod } from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";
import { toast } from "react-toastify";

const ForgotPassword = () => {
    const history = useHistory();
    const [urlTokenId, setUrlTokenId] = useState();
    const [getPassword, setPassword] = useState({
        password: "",
        confirm_password: "",
    });

    let url_string = window.location.href;
    let url = new URL(url_string);
    let token = url.searchParams.get("token");
    let email = url.searchParams.get("email").replace(' ', '+');

    const gotoBack = async () => {
        history.push("/login");
    };

    useEffect(() => {
        validateToken();
    }, []);

    const validateToken = async () => {
        let body = {
            email: email,
            forget_token: token,
        };
        await postMethod(urls.forgotTokenValidate, body).then((response) => {
            if (response.status === 200) {
                setUrlTokenId(response.data.user_id);
            }else if(response.status === 400){
                toast.error(response.data.Response);
            } else {
                history.push("/invalidToken");
            }
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPassword((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const changePassword = async () => {
        let body = {
            user_id: urlTokenId,
            password: btoa(getPassword.password),
        };

        if (
            getPassword.password !== "" &&
            getPassword.confirm_password !== "" &&
            getPassword.password === getPassword.confirm_password
        ) {
            await postMethod(urls.setNewPassword, body).then((response) => {
                if (response.status === 200) {
                    history.push("/login");
                } else {
                    toast.error(response.error);
                }
            });
        }
    };

    return (
        <React.Fragment>
            <div className="container-fluid p-0 m-0">
                <div className="row p-0 m-0">

                    <div className="col-6 ">
                        <div className="forgotPasswordDeal container">
                            <div className="back" onClick={() => gotoBack()}>
                                <i className="fas fa-2x fa-arrow-left back_arrow"></i>
                            </div>
                            <img className="logo_forgot img-fluid" src={ShopezLogo} />
                            <div className="logo_text_wel">
                                <div>Welcome Back</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 pr-0">
                        <div className="loginForm">
                            <div id="create-card" className="forgot_card_container pt-5 pb-5">
                                <div className="container col-12">
                                    <h1 className="heading-title text-center mb-3">Welcome to Shopez Family</h1>
                                    <div className="h6-label text-center" style={{ color: "#808080" }}>Reset your password</div>

                                    <ValidatorForm>

                                        <div className="row mt-4">
                                            <div className="col-8 offset-2">
                                                <TextValidator
                                                    required
                                                    errorMessages={"Please enter the password"}
                                                    validators={[
                                                        "minStringLength:5",
                                                        "maxStringLength:30",
                                                        "required",
                                                    ]}
                                                    onChange={handleChange}
                                                    inputProps={{ maxLength: 20 }}
                                                    type="password"
                                                    name="password"
                                                    label="Password"
                                                    variant="outlined"
                                                    value={getPassword.password}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-8 offset-2">
                                                <TextValidator
                                                    required
                                                     errorMessages={"Please enter the confirm password"}
                                                    validators={[
                                                        "minStringLength:1",
                                                        "maxStringLength:30",
                                                        "required",
                                                    ]}
                                                    onChange={handleChange}
                                                    inputProps={{ maxLength: 20 }}
                                                    type="password"
                                                    name="confirm_password"
                                                    label="Confirm Password"
                                                    variant="outlined"
                                                    value={getPassword.confirm_password}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            {getPassword.password !== getPassword.confirm_password ? (
                                                <div style={{ marginLeft: "10vw", fontSize: "12px", color: "Red", paddingTop: "10px" }}>
                                                    Password and confirm password doesn't match!
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-8 offset-2">
                                                <Button onClick={() => changePassword()} className="btn btn-primary w-100 text-truncate" name="Continue"></Button>
                                            </div>
                                        </div>
                                    </ValidatorForm>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ForgotPassword;
