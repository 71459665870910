import React, { useState, useEffect, useRef, useCallback } from "react";
import InputMask from "react-input-mask";
import "./stores.scss";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import ListSubheader from "@material-ui/core/ListSubheader";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "../../../components/button/button";
import CollectionDB from "../../../assets/images/collection.png";
import NoDoc from "../../../assets/images/emptyCamera.svg";
import { getMethod, postMethodWithToken, putMethodWithToken, postMethodWithTokenProgress, deleteMethodWithToken } from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";
import { getStoreId, getUserRole } from "../../../utils/common";
import Modal from "react-modal";
import FormControl from "@material-ui/core/FormControl";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useDropzone } from "react-dropzone";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import SpinnerLoader from "../../../components/loader/spinnerLoader";
import NoImage from "../../../assets/images/storelisting.png";
import Camera from "../../../assets/images/camera.svg";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import Shortlisted from "./components/shortlisted/Shortlisted";
import InReview from "./components/inReview/InReview";
import Approved from "./components/approved/Approved";
import OnHold from "./components/onHold/onHold";
import Geosuggest from "react-geosuggest";
import LanguageIcon from '@material-ui/icons/Language';
import PhoneIcon from '@material-ui/icons/Phone';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from "@material-ui/icons/Search";
import UploadPhotoIcon from "../../../assets/images/add_a_photo.png";

const checkStoresError = stores => {

    const emptyKeys = [
        'store_icon',
        'store_logo',
        'location',
        'first_name',
        'last_name',
        'address',
        'country',
        'states_id',
        'cities_id',
        'postal_code',
        'contact_number',
        'store_info',
        'latitude',
        'longitude'
    ];

    const emptyKeysErrors = [
        'Store Icon',
        'Store Logo',
        'Location',
        'First Name',
        'Last Name',
        'Address',
        'Country',
        'State/Province',
        'City',
        'Zip Code/Postal Code',
        'Contact Number',
        'Store Info',
        'Latitude',
        'Longitude'
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!stores[emptyKeys[i]]) {
            return `Please fill in ${emptyKeysErrors[i]}`;
        }

        if (emptyKeys[i] === 'postal_code' && stores[emptyKeys[i]]) {
            if (stores['country'] === 'CAN') {
                let postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
                if (!postalCodeRegex.test(stores[emptyKeys[i]])) {
                    return `Please fill in valid ${emptyKeysErrors[i]}`;
                }
            } else {
                let postalCodeRegex = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;

                if (!postalCodeRegex.test(stores[emptyKeys[i]])) {
                    return `Please fill in valid ${emptyKeysErrors[i]}`;
                }
            }
        }

        if (emptyKeys[i] === 'store_icon' && stores[emptyKeys[i]].length === 0) {
            return `Please fill in ${emptyKeysErrors[i]}`;
        }
        if (emptyKeys[i] === 'store_logo' && stores[emptyKeys[i]].length === 0) {
            return `Please fill in ${emptyKeysErrors[i]}`;
        }
    }

    return '';
};

function Stores(props) {
    var panelOpen = window.$panelActive;

    const geosuggestEl = useRef("");
    const google = window.google;

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [currTab, setCurrTab] = React.useState(0);
    const [initialValue, setInitialValue] = useState([]);
    const [iconLogoId, setIconLogoId] = useState([]);
    const [storeDetail, setStoreDetail] = useState(false);
    const [listOfStores, setListOfStores] = useState([]);
    const [action, setAction] = useState("");
    const [uploadedDoc, setUploadedDoc] = useState([]);
    const [uploadedDocIcon, setUploadedDocIcon] = useState([]);
    const [uploadPercentage, setStateUploadPercentage] = useState(0);
    const [getStatePovince, setStatePovince] = useState("State");
    const [getPinZip, setGetPinZip] = useState("Postal code/Zip code");
    const [loading, setLoading] = useState(false);
    const [idClicked, setIdClicked] = useState();
    const [shortlistedList, setShortlistedList] = useState([]);
    const [aboutStoreCountry, setAboutStoreCountry] = useState("");

    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [stateDataList, setStateDataList] = useState([]);
    const [cityDataList, setCityDataList] = useState([]);

    const [countrySelected, setCountrySelected] = useState("");
    const [stateSelected, setStateSelected] = useState("");
    const [citySelected, setCitySelected] = useState("");

    const [stateName, setStateName] = useState("");
    const [cityName, setCityName] = useState("");
    const [indexIdToDelete, setIndexIdToDelete] = useState("");
    const [confirmationModal, setConfirmationModal] = useState(false);

    const [storeIconLogo, setStoreIconLogo] = useState(false);
    const [storeIconImage, setStoreIconImage] = useState(false);

    const [getStoreDetails, setStoreDetails] = useState({
        first_name: "",
        last_name: "",
        location: "",
        website: "",
        address: "",
        country: "",
        states_id: "",
        cities_id: "",
        unit: "",
        postal_code: "",
        contact_number: "",
        store_info: "",
        store_icon: [],
        store_logo: [],
        latitude: "",
        longitude: "",
    });

    //Call API of about store to get country to filter geo location
    useEffect(() => {
        getCountry();

        getCountryList();
    }, []);

    const getCountry = async () => {
        let storeId = getStoreId();

        setStoreDetails((prevState) => ({
            ...prevState,
            store_info: storeId,
        }));

        await getMethod(urls.aboutStore + storeId).then((response) => {
            if (response.status === 200) {
                setAboutStoreCountry(response.data.business_address && response.data.business_address.country !== undefined ? response.data.business_address.country : "");
            }
        });
    };

    const getCountryList = async () => {
        await getMethod(urls.getCountry).then((response) => {
            if (response.status === 200) {
                setCountryList(response.data);
            }
        });
    };

    const getStateList = async (value) => {
        await getMethod(urls.getStates + '?country_name=' + value).then((response) => {
            if (response.status === 200) {
                setStateList(response.data.response[0].data.states);
                setStateDataList(response.data.response[0].data.states);
            }
        });
    };

    const getCityList = async (value) => {
        await getMethod(urls.getStates + '?country_name=' + getStoreDetails.country + '&state_id=' + value).then((response) => {
            if (response.status === 200) {
                setCityList(response.data.response[0].data.cities);
                setCityDataList(response.data.response[0].data.cities);
            }
        });
    };

    const getCityListWithName = async (countryId, stateId, name) => {
        await getMethod(urls.getStates + '?country_name=' + countryId + '&state_id=' + stateId).then((response) => {
            if (response.status === 200) {
                let results = response.data.response[0].data.cities;
                setCityList(results);
                setCityDataList(results);

                if (name && results.length > 0) {
                    let indexCityID = '';

                    results.map((item) => {
                        if (item.cityName === name) {
                            indexCityID = item.id;
                        }
                    });

                    if (indexCityID) {
                        setCitySelected(indexCityID)

                        setStoreDetails((prevState) => ({
                            ...prevState,
                            cities_id: indexCityID,
                        }));
                    }
                }

            }
        });
    }

    function handleTabChange(event, newValue) {
        setCurrTab(newValue);
    }

    function handleChangeIndex(index) {
        setCurrTab(index);
    }

    const storeDetails = (Action) => {
        setAction(Action);
        setStoreDetail(true);
    };

    const closeStoreDetails = () => {
        setStoreDetail(false);
        clearFields();
    };

    /*const useStyles = makeStyles((theme) => ({
        selectEmpty: {
            marginTop: theme.spacing(0.5),
            fontSize: 18,
            fontWeight: 400,
            opacity: 0.5,
            color: "#233949",
        },
    }));*/

    /*const classes = useStyles();*/

    const handleCountryChange = (event) => {
        setCountrySelected(event.target.value);
        setStoreDetails({ ...getStoreDetails, [event.target.name]: event.target.value });

        setStoreDetails((prevState) => ({
            ...prevState,
            states_id: '',
            cities_id: '',
            postal_code: '',
        }));

        setStateSelected('')
        setCitySelected('');

        getStateList(event.target.value);

        if (event.target.value === "CAN") {
            setStatePovince("Province");
            setGetPinZip("Postal Code");
        } else {
            setStatePovince("State");
            setGetPinZip("ZIP Code");
        }
    };

    const handleStateChange = (event) => {
        if (event.target.value !== undefined) {
            setStateSelected(event.target.value);
            setStoreDetails({ ...getStoreDetails, [event.target.name]: event.target.value });

            setStoreDetails((prevState) => ({
                ...prevState,
                cities_id: '',
            }));
            setCitySelected('');

            getCityList(event.target.value);
        }
    };

    const handleCityChange = (event) => {
        if (event.target.value !== undefined) {
            setCitySelected(event.target.value);
            setStoreDetails({ ...getStoreDetails, [event.target.name]: event.target.value });
        }
    };

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setStoreDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleNameChange = (e) => {
        const { name, value } = e.target;
        const result = value.replace(/[^a-z]/gi, "");
        setStoreDetails((prevState) => ({
            ...prevState,
            [name]: result,
        }));
    };

    const onUploadProgress = (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setStateUploadPercentage(percent);
    };

    const onDrop = useCallback((acceptedFiles) => {
       
        if (storeIconLogo === true) {
            let formData = new FormData();
            acceptedFiles.map((file, index) => {
                formData.append("file", file);
            });

            postMethodWithTokenProgress(urls.fileUpload, formData, onUploadProgress).then((response) => {
                response.data.map((item, index) => {
                    let image = item.id;
                    let idObject = {
                        image_id: image.toString(),
                        order_image: initialValue.length > 0 ? initialValue.length + 1 : index + 1,
                    };

                    setInitialValue([idObject]);

                    let imageObject = {
                        file: [null],
                        id: [null],
                        order: [null],
                    };

                    imageObject.file = item.file;
                    imageObject.id = item.id;
                    imageObject.order = uploadedDoc.length > 0 ? uploadedDoc.length + 1 : index + 1;

                    setUploadedDoc([imageObject]);
                });
            }).catch((error) => {
                console.log("error", error);
            });

        } else if (storeIconImage === true) {
            
            let formData = new FormData();
            acceptedFiles.map((file, index) => {
                formData.append("file", file);
            });

            postMethodWithTokenProgress(urls.fileUpload, formData, onUploadProgress).then((response) => {
                response.data.map((item, index) => {
                    let image = item.id;
                    let idObject = {
                        image_id: image.toString(),
                        order_image: iconLogoId.length > 0 ? iconLogoId.length + 1 : index + 1,
                    };

                    setIconLogoId([idObject]);

                    let imageObject = {
                        file: [null],
                        id: [null],
                        order: [null],
                    };

                    imageObject.file = item.file;
                    imageObject.id = item.id;
                    imageObject.order = uploadedDocIcon.length > 0 ? uploadedDocIcon.length + 1 : index + 1;

                    setUploadedDocIcon([imageObject]);
                });
            }).catch((error) => {
                console.log("error", error);
            });

        }
    });


    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: "image/jpeg, image/jpg, image/png",
        onDrop,
    });

    const customStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 800 ? "40vw" : screenSize.width <= 1100 ? "28vw" : "25vw",
            height: screenSize.width <= 800 ? "15vh" : "18vh",
        },
    };

    const allStyles = {
        content: {
            top: '50%',
            left: '50%',
            transform: screenSize.width <= 800 ? 'translate(-32%, -55%)' : screenSize.width <= 1100 ? 'translate(-36%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            width: "70vw",
            height: screenSize.width <= 800 ? "65vh" : screenSize.width <= 1100 ? "75vh" : "62vh",
            outline: "none",
            padding: "15px",
        },
    };

    useEffect(() => {
        if (getUserRole() !== "super-admin") {
            storeListing();
        }
    }, []);

    const storeListing = async () => {
        setLoading(true);
        await getMethod(urls.getStore).then((response) => {
            if (response.status === 200) {
                setListOfStores(response.data);
                setLoading(false);
            }
        });
        setLoading(false);
    };

    const fillStoreDetails = async (Action, id) => {
        setAction(Action);
        setIdClicked(id);

        await getMethod(urls.getStoreDetail + id + "/").then((response) => {
            if (response.status === 200) {

                if (response.data.country === "Canada" || response.data.country === "CAN") {
                    setStatePovince("Province");
                    setGetPinZip("Postal Code");
                } else {
                    setStatePovince("State");
                    setGetPinZip("ZIP Code");
                }


                setStoreDetails((prevState) => ({
                    ...prevState,
                    store_info: response.data.store_info.id,
                }));

                let countryValue = response.data.country;
                let stateValue = response.data.state;
                let cityValue = response.data.city;
                let country_id = '';
                let state_id = '';

                if (countryValue) {
                    countryList.map((item) => {
                        if (item.printable_name === countryValue) {
                            country_id = item.iso_3166_1_a3;
                        }
                    });

                    if (country_id) {
                        getMethod(urls.getStates + '?country_name=' + country_id).then((response) => {
                            if (response.status === 200) {
                                let results = response.data.response[0].data.states

                                setStateList(results);
                                setStateDataList(results);

                                if (results.length > 0 && stateValue) {
                                    let indexStateID = '';

                                    results.map((item) => {
                                        if (item.stateName === stateValue) {
                                            indexStateID = item.id;
                                        }
                                    });

                                    if (indexStateID) {
                                        state_id = indexStateID;
                                        setStateSelected(indexStateID);

                                        setStoreDetails((prevState) => ({
                                            ...prevState,
                                            states_id: indexStateID,
                                        }));

                                        if (country_id && state_id) {
                                            getCityListWithName(country_id, state_id, cityValue)
                                        }
                                    }
                                }
                            }
                        });
                    }
                }

                setCountrySelected(response.data.country);

                setStoreDetails((prevState) => ({
                    ...prevState,
                    ["first_name"]: response.data.first_name,
                    ["last_name"]: response.data.last_name,
                    ["location"]: response.data.store_info.name,
                    ["address"]: response.data.address,
                    ["country"]: country_id,
                    ["unit"]: response.data.unit,
                    ["website"]: response.data.website,
                    ["contact_number"]: response.data.contact_number,
                    ["postal_code"]: response.data.postal_code,
                    ["latitude"]: response.data.latitude,
                    ["longitude"]: response.data.longitude,
                }));

                if (response.data.store_icon !== null) {
                    setUploadedDoc(response.data.store_icon !== undefined || response.data.store_icon !== null || response.data.store_icon !== [] ? response.data.store_icon : []);

                    let IdsImage = [];
                    response.data.store_icon !== undefined && response.data.store_icon !== null && response.data.store_icon.length > 0 && response.data.store_icon.map((item, index) => {
                        if (!IdsImage.includes(item.id.toString())) {
                            let idObject = {
                                image_id: item.id.toString(),
                                order_image: IdsImage.length > 0 ? IdsImage.length + 1 : index + 1,
                            };
                            IdsImage.push(idObject);
                        }
                        setInitialValue(IdsImage);
                    });
                }

                if (response.data.store_logo !== null) {
                    setUploadedDocIcon(response.data.store_logo !== undefined || response.data.store_logo !== null || response.data.store_logo !== [] ? response.data.store_logo : []);

                    let IdsImage = [];
                    response.data.store_logo !== undefined && response.data.store_logo !== null && response.data.store_logo.length > 0 && response.data.store_logo.map((item, index) => {
                        if (!IdsImage.includes(item.id.toString())) {
                            let idObject = {
                                image_id: item.id.toString(),
                                order_image: IdsImage.length > 0 ? IdsImage.length + 1 : index + 1,
                            };
                            IdsImage.push(idObject);
                        }
                        setIconLogoId(IdsImage);
                    });
                }

                setStoreDetail(true);
            }
        });
    };

    const clearFields = () => {
        setStoreDetails((prevState) => ({
            ...prevState,
            ["first_name"]: "",
            ["last_name"]: "",
            ["location"]: "",
            ["website"]: "",
            ["contact_number"]: "",
            ["address"]: "",
            ["country"]: "",
            ["states_id"]: "",
            ["cities_id"]: "",
            ["unit"]: "",
            ["postal_code"]: "",
            ["store_info"]: "",
            ["store_icon"]: [],
            ["store_logo"]: [],
            ["latitude"]: "",
            ["longitude"]: "",
        }));

        setCountrySelected("")
        setStateSelected("")
        setCitySelected("")

        setStateName("")
        setCityName("")

        setUploadedDoc([]);
        setUploadedDocIcon([]);
    };

    const deleteStore = async (id) => {
        setLoading(true);
        setConfirmationModal(false);
        await deleteMethodWithToken(urls.deleteStoreAddress + id).then((response) => {
            toast.success("Store Address Deleted Successfully.");
            storeListing();
            setLoading(false);
        });
        setLoading(false);
        
    };

    const ToDelete = (id) => {
        setConfirmationModal(true);
        setIndexIdToDelete(id);
    };

    const showStoreList = () => {
        if (loading) {
            return <SpinnerLoader />;
        } else {
            return (
                <div className="">
                    {listOfStores.length !== 0 ? (
                        <div className="col-12 listSectionAddproduct">
                            <div className="row">
                                <div className="col-1 f-600">S.No</div>
                                <div className="col-2 f-600">Location Name</div>
                                <div className="col-2 f-600">Owner's Name</div>
                                <div className="col-2 f-600">Stores's Name</div>
                                <div className="col-2 f-600">Info</div>
                                <div className="col-2 f-600">Address</div>
                                <div className="col-1 f-600">Actions</div>
                            </div>

                            {listOfStores && listOfStores.map((item, index) => {
                                return (
                                    <div className="row mt-3 pt-3 pb-3" style={{ background: "#ECECEC" }}>

                                        <div className="col-1 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => fillStoreDetails("update", item.id)}>
                                            {index + 1 + "."}
                                        </div>

                                        <div className="col-2 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => fillStoreDetails("update", item.id)}>
                                            <div className="d-flex">
                                                <div className="d-flex">
                                                    <img
                                                        src={item.store_icon !== null && item.store_icon.length > 0 ? item.store_icon[0].file : NoImage}
                                                        className="listedRadiusImg"
                                                    />
                                                </div>
                                                <div className="d-flex ml-2" style={{ alignItems: "center" }}>
                                                    {item.location}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-2 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => fillStoreDetails("update", item.id)}>
                                            {item.first_name + " " + item.last_name}
                                        </div>

                                        <div className="col-2 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => fillStoreDetails("update", item.id)}>
                                            {item.store_name}
                                        </div>

                                        <div className="col-2" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => fillStoreDetails("update", item.id)}>
                                            {item.contact_number !== "" && item.contact_number !== undefined && item.contact_number !== null &&
                                                <div>
                                                    <PhoneIcon fontSize="small" style={{ color: "#808080", marginRight: "5Px", marginTop: "-1px" }} />
                                                    {item.contact_number}
                                                </div>
                                            }
                                            {item.website !== "" && item.website !== undefined && item.website !== null &&
                                                <div>
                                                    <LanguageIcon fontSize="small" style={{ color: "#808080", marginRight: "5Px", marginTop: "-1px" }} />
                                                    {item.website}
                                                </div>
                                            }
                                        </div>

                                        <div className="col-2 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => fillStoreDetails("update", item.id)}>
                                            {item.address + ", " + item.city + ", " + item.state + ", " + item.postal_code}
                                        </div>

                                        <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                            <div className="d-flex">
                                                <EditIcon fontSize="small" onClick={() => fillStoreDetails("update", item.id)} style={{ cursor: "pointer", color: "#000000" }} />
                                            </div>
                                            <div className="d-flex ml-2">
                                                <DeleteIcon fontSize="small" onClick={() => ToDelete(item.id)} style={{ cursor: "pointer", color: "#eb2813" }} />
                                            </div>
                                        </div>

                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className="img-center-store">
                            <img className="collectionImage-store" src={CollectionDB} alt="" />
                        </div>
                    )}
                </div>
            );
        }
    };

    const onSuggestSelect = (suggest) => {

        if (suggest) {

            setCountrySelected('');
            setStateSelected('');
            setCitySelected('');

            setStoreDetails((prevState) => ({
                ...prevState,
                postal_code: "",
                country: "",
                states_id: "",
                cities_id: ""
            }));

            const components = suggest.gmaps.address_components;

            let state_id = '';
            let streetNumberValue = '';
            let routeValue = '';
            let cityValue = '';
            let postCodeValue = '';
            let stateValue = '';
            let stateAddressValue = '';
            let countryValue = '';
            let addressValue = '';

            for (let key of components) {
                if (key.types[0] === "locality" || key.types[1] === "sublocality") {
                    setCityName(key.long_name)
                    cityValue = key.long_name;
                }

                if (key.types[0] === "administrative_area_level_1") {
                    stateValue = key.long_name;
                    stateAddressValue = key.short_name;
                    setStateName(key.long_name)
                }

                if (key.types[0] === "country") {
                    if (key.short_name === 'US') {
                        setCountrySelected('USA')
                        countryValue = 'USA';

                        setStoreDetails((prevState) => ({
                            ...prevState,
                            country: 'USA',
                        }));

                        setStatePovince("State");
                        setGetPinZip("ZIP Code");

                    } else if (key.short_name === 'CA') {
                        setCountrySelected('CAN')
                        countryValue = 'CAN';

                        setStoreDetails((prevState) => ({
                            ...prevState,
                            country: 'CAN',
                        }));

                        setStatePovince("Province");
                        setGetPinZip("Postal Code");
                    } else {
                        setCountrySelected(key.short_name)
                        countryValue = key.short_name;

                        setStoreDetails((prevState) => ({
                            ...prevState,
                            country: key.short_name,
                        }));
                    }
                }

                if (key.types[0] === "postal_code") {
                    postCodeValue = key.long_name;
                    setStoreDetails((prevState) => ({
                        ...prevState,
                        postal_code: key.long_name,
                    }));
                }
                if (key.types[0] === "route") {
                    routeValue = key.short_name;
                }

                if (key.types[0] === "street_number") {
                    streetNumberValue = key.long_name;
                }
            }

            if (stateValue && countryValue) {
                getMethod(urls.getStates + '?country_name=' + countryValue).then((response) => {
                    if (response.status === 200) {
                        let results = response.data.response[0].data.states

                        setStateList(results);
                        setStateDataList(results);

                        if (results.length > 0) {
                            let indexStateID = '';

                            results.map((item) => {
                                if (item.stateName === stateValue) {
                                    indexStateID = item.id;
                                }
                            });

                            if (indexStateID) {
                                state_id = indexStateID;
                                setStateSelected(indexStateID);

                                setStoreDetails((prevState) => ({
                                    ...prevState,
                                    states_id: indexStateID,
                                }));

                                if (countryValue && state_id) {
                                    getCityListWithName(countryValue, state_id, cityValue)
                                }
                            }
                        }
                    }
                });
            }

            if (streetNumberValue !== "") {
                addressValue += streetNumberValue;
            }

            if (routeValue !== "" && streetNumberValue !== "") {
                addressValue += ", " + routeValue;
            } else if (routeValue !== "") {
                addressValue += routeValue;
            }

            /*if(cityValue !== "") {
                addressValue += ", " + cityValue;
            }

            if(stateValue !== "") {
                addressValue += ", " + stateAddressValue;
            }

            if(countryValue !== "") {
                addressValue += ", " + countryValue;
            }

            if(postCodeValue !== "") {
                addressValue += ", " + postCodeValue;
            }*/

            setStoreDetails((prevState) => ({
                ...prevState,
                ["address"]: addressValue,
                ["latitude"]: suggest.location.lat,
                ["longitude"]: suggest.location.lng,
            }));

            geosuggestEl.current.update(addressValue);
        }
    };

    const getUpperCase = (lowercase) => {
        if (getPinZip === "Postal Code" && lowercase !== "" && lowercase !== undefined) {
            return lowercase.toUpperCase();
        } else {
            return lowercase;
        }
    };

    const saveStoreDetails = async () => {

        getStoreDetails['store_icon'] = initialValue
        getStoreDetails['store_logo'] = iconLogoId

        const error = checkStoresError(getStoreDetails);

        if (error) {
            toast.error(error);
        } else {
            if (action === "create") {
                await postMethodWithToken(urls.createStore, getStoreDetails).then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        toast.success(response.message);

                        storeListing();
                        setStoreDetail(false);
                        clearFields();
                    }
                });
            } else if (action === "update") {
                await putMethodWithToken(urls.updateStoreDetail + idClicked + "/", getStoreDetails).then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        toast.success(response.message);
                        storeListing();
                        setStoreDetail(false);
                        clearFields();
                    }
                });
            }
        }
    };

    const storeTabs = () => {
        return (
            <div className="row">
                <div style={{ width: "100%" }}>
                    <div className="col-12" >
                        <Tabs
                            value={currTab}
                            onChange={handleTabChange}
                            textColor="inherit"
                            variant="standard"
                        >
                            <Tab
                                label="Shortlisted"
                            />
                            <Tab
                                label="In Review"
                            />
                            <Tab
                                label="Approved"
                            />
                            <Tab
                                label="On Hold"
                            />
                        </Tabs>
                    </div>
                </div>

                <div style={{ width: "100%" }}>
                    <div className="col-12">
                        <SwipeableViews
                            index={currTab}
                            onChangeIndex={handleChangeIndex}
                            enableMouseEvents={true}
                            className="p-0"
                        >
                            <div>
                                {currTab === 0 ? (
                                    <Shortlisted shortListedList={shortlistedList} />
                                ) : (
                                    console.log("")
                                )}
                            </div>
                            <div>{currTab === 1 ? <InReview /> : console.log("")}</div>
                            <div>{currTab === 2 ? <Approved /> : console.log("")}</div>
                            <div>{currTab === 3 ? <OnHold /> : console.log("")}</div>
                        </SwipeableViews>
                    </div>
                </div>
            </div>
        );
    };

    const stateFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = stateDataList.filter((item) => {
                return item.stateName.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setStateList(results);
        } else {
            setStateList(stateDataList);
        }
    };

    const cityFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = cityDataList.filter((item) => {
                return item.cityName.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setCityList(results);
        } else {
            setCityList(cityDataList);
        }
    };

    return (
        <>
        <div className={props.menu ? "w-100 screenLeftMargin mt-3" :"screenLeftMarginResponse w-100 mt-3"}>
            <div className="row ml-3 mr-3 mobileResponsive">
                <h1 className="heading-title col-6">Stores</h1>
                {getUserRole() !== "super-admin" ? (
                    <div className="col-6" style={{ textAlign: "right" }}>
                        <Button onClick={() => storeDetails("create")} className="btn btn-cancle" name="Add Store"></Button>
                    </div>
                ) : (
                    ""
                )}
            </div>

            <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                <div className="col-12">
                    {getUserRole() === "super-admin" ? storeTabs() : showStoreList()}
                </div>
            </div>

            <Modal isOpen={storeDetail} style={allStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="row">
                    <div className="col-10 offset-1">
                        <div className="row mt-3">
                            <div className="h4-label mt-3 mb-3 col-12">
                                Enter Store Details
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-6">
                                <div className="container storeImageBlock d-flex justify-content-end">
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <DragDropContext>
                                            <Droppable droppableId="uploadedImages" direction="horizontal">
                                                {(provided) => (
                                                    <div
                                                        style={{ display: "flex", alignItems: "center" }}
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                    
                                                        {uploadedDoc.length > 0 ? (
                                                            <>
                                                                {uploadedDoc && uploadedDoc.map((item, index) => {
                                                                    return (
                                                                        <Draggable
                                                                            key={item.id.toString()}
                                                                            draggableId={item.id.toString()}
                                                                        >
                                                                            {(provided) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                >
                                                                                    <img
                                                                                        src={item.original !== undefined ? item.original : item.file}
                                                                                        alt=""
                                                                                        className="imgTagBlock"
                                                                                        onClick={() => setStoreIconLogo(true)}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    );
                                                                })}
                                                            </>
                                                        ) : (
                                                            <div className="uploadPhoto">
                                                                <div style={{ cursor: "pointer" }} className="uploadInfo" onClick={() => (setStoreIconImage(false), setStoreIconLogo(true))}>
                                                                    <div className="uploadImage"><img width="22" src={UploadPhotoIcon} /></div>
                                                                    <div className="uploadText">Store Photo</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="container storeImageBlock d-flex justify-content-start">
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <DragDropContext>
                                            <Droppable droppableId="uploadedImages" direction="horizontal">
                                                {(provided) => (
                                                    <div
                                                        style={{ display: "flex", alignItems: "center" }}
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {uploadedDocIcon.length > 0 ? (
                                                            <>
                                                                {uploadedDocIcon && uploadedDocIcon.map((item, index) => {
                                                                    return (
                                                                        <Draggable
                                                                            key={item.id.toString()}
                                                                            draggableId={item.id.toString()}
                                                                        >
                                                                            {(provided) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                >
                                                                                    <img
                                                                                        src={item.original !== undefined ? item.original : item.file}
                                                                                        alt=""
                                                                                        className="imgTagBlock"
                                                                                        onClick={() => setStoreIconImage(true)}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    );
                                                                })}
                                                            </>
                                                        ) : (
                                                            <div className="uploadPhoto">
                                                                <div style={{ cursor: "pointer" }} className="uploadInfo" onClick={() => (setStoreIconLogo(false), setStoreIconImage(true))}>
                                                                    <div className="uploadImage"><img width="22" src={UploadPhotoIcon} /></div>
                                                                    <div className="uploadText">Store Icon</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div>
                            <ValidatorForm>
                                <div className="row pt-3">
                                    <div className="col-lg-6 col-md-12">
                                        <TextValidator
                                            validators={[
                                                "minStringLength:1",
                                                "maxStringLength:30",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter location name"
                                            ]}
                                            name="location"
                                            label="Store Name"
                                            variant="outlined"
                                            onChange={handleFieldChange}
                                            value={getStoreDetails.location}
                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-6 mobile-mt-3">
                                        <TextValidator
                                            validators={[
                                                "minStringLength:1",
                                                "maxStringLength:30",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter valid first name",
                                                "First name exceeds the maximum length",
                                                "Please enter first name"
                                            ]}
                                            name="first_name"
                                            label="First Name"
                                            variant="outlined"
                                            onChange={handleNameChange}
                                            value={getStoreDetails.first_name}
                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-6 mobile-mt-3">
                                        <TextValidator
                                            validators={[
                                                "minStringLength:1",
                                                "maxStringLength:30",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter valid last name",
                                                "Last name exceeds the maximum length",
                                                "Please enter last name"
                                            ]}
                                            name="last_name"
                                            label="Last Name"
                                            variant="outlined"
                                            onChange={handleNameChange}
                                            value={getStoreDetails.last_name}
                                        />
                                    </div>
                                </div>

                                <div className="row pt-3">
                                    <div className="col-6">
                                        <Geosuggest
                                            id="aboutstore"
                                            ref={geosuggestEl}
                                            placeholder="Address"
                                            name="address"
                                            fields={["address_components", "geometry"]}
                                            types={["address"]}
                                            //country={aboutStoreCountry === "USA" ? ["US"] : ["CA"]}
                                            country={["US", "CA"]}
                                            initialValue={getStoreDetails.address}
                                            onSuggestSelect={onSuggestSelect}
                                            location={new google.maps.LatLng(lat, lng)}
                                            radius="100"
                                            style={{ backgroundColor: "#ECECEC" }}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <TextValidator
                                            name="unit"
                                            label="Unit"
                                            variant="outlined"
                                            inputProps={{ min: 0 }}
                                            type="number"
                                            onChange={handleFieldChange}
                                            value={getStoreDetails.unit}
                                        />
                                    </div>
                                </div>

                                <div className="row pt-3">
                                    <div className="col-6">
                                        <FormControl variant="outlined">
                                            <InputLabel
                                                id="country-select"
                                                required
                                            >
                                                Country
                                            </InputLabel>
                                            <Select
                                                labelId="country-select"
                                                id="country-dropdown"
                                                onChange={handleCountryChange}
                                                value={getStoreDetails.country}
                                                label="Country"
                                                name="country"
                                                errorMessages={[
                                                    "Please select country"
                                                ]}
                                                validators={[
                                                    "required",
                                                ]}
                                            >
                                                {countryList.map((item) => {
                                                    return (
                                                        <MenuItem value={item.iso_3166_1_a3}> {item.printable_name} </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div className="col-6">
                                        <FormControl variant="outlined">
                                            <InputLabel
                                                required
                                                id="country-select"
                                            >
                                                {getStatePovince}
                                            </InputLabel>
                                            <Select
                                                MenuProps={{ autoFocus: false }}
                                                labelId="country-select"
                                                id="country-dropdown"
                                                value={getStoreDetails.states_id}
                                                defaultValue={getStoreDetails.states_id}
                                                label={getStatePovince}
                                                name="states_id"
                                                errorMessages={[
                                                    "Please select state"
                                                ]}
                                                validators={[
                                                    "required",
                                                ]}
                                                onChange={(e) => handleStateChange(e)}
                                                onClose={() => setStateList(stateDataList)}
                                            >
                                                <ListSubheader>
                                                    <TextField
                                                        size="small"
                                                        autoFocus
                                                        placeholder="Type to search..."
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        onChange={stateFilter}
                                                        onKeyDown={(e) => {
                                                            if (e.key !== "Escape") {
                                                                e.stopPropagation();
                                                            }
                                                        }}
                                                    />
                                                </ListSubheader>
                                                {stateList.length > 0 && stateList.map((item) => {
                                                    return (
                                                        <MenuItem value={item.id}> {item.stateName} </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row pt-3">
                                    <div className="col-6">
                                        <FormControl variant="outlined">
                                            <InputLabel
                                                required
                                                id="country-select"
                                            >
                                                City
                                            </InputLabel>
                                            <Select
                                                MenuProps={{ autoFocus: false }}
                                                labelId="country-select"
                                                id="country-dropdown"
                                                value={getStoreDetails.cities_id}
                                                defaultValue={getStoreDetails.cities_id}
                                                label="City"
                                                name="cities_id"
                                                errorMessages={[
                                                    "Please select city"
                                                ]}
                                                validators={[
                                                    "required",
                                                ]}
                                                onChange={(e) => handleCityChange(e)}
                                                onClose={() => setCityList(cityDataList)}
                                            >
                                                <ListSubheader>
                                                    <TextField
                                                        size="small"
                                                        autoFocus
                                                        placeholder="Type to search..."
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        onChange={cityFilter}
                                                        onKeyDown={(e) => {
                                                            if (e.key !== "Escape") {
                                                                e.stopPropagation();
                                                            }
                                                        }}
                                                    />
                                                </ListSubheader>
                                                {cityList.length > 0 && cityList.map((item) => {
                                                    return (
                                                        <MenuItem value={item.id}> {item.cityName} </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-6">
                                        {getPinZip === "ZIP Code" ?
                                            <InputMask
                                                mask={"99999"}
                                                value={getStoreDetails.postal_code}
                                                disabled={false}
                                                onChange={handleFieldChange}
                                            >
                                                {() => (
                                                    <TextValidator
                                                        required
                                                        errorMessages={[
                                                            "Please enter valid postal code/zip code"
                                                        ]}
                                                        validators={[
                                                            "required",
                                                        ]}
                                                        name="postal_code"
                                                        label={getPinZip}
                                                        variant="outlined"
                                                    />
                                                )}
                                            </InputMask>
                                            :
                                            <InputMask
                                                mask={"a9a 9a9"}
                                                value={getStoreDetails.postal_code}
                                                disabled={false}
                                                onChange={handleFieldChange}
                                            >
                                                {() => (
                                                    <TextValidator
                                                        required
                                                        errorMessages={[
                                                            "Please enter valid postal code/zip code"
                                                        ]}
                                                        validators={[
                                                            "required",
                                                        ]}
                                                        name="postal_code"
                                                        label={getPinZip}
                                                        variant="outlined"
                                                    />
                                                )}
                                            </InputMask>
                                        }
                                    </div>
                                </div>

                                <div className="row pt-3">
                                    <div className="col-6">
                                        <InputMask
                                            mask={"+1 (999) 999-9999"}
                                            maskChar="_"
                                            onChange={handleFieldChange}
                                            value={getStoreDetails.contact_number}
                                            disabled={false}
                                        >
                                            {() => (
                                                <TextValidator
                                                    required
                                                    errorMessages={[
                                                        "Please enter valid phone number"
                                                    ]}
                                                    validators={[
                                                        "required",
                                                    ]}
                                                    name="contact_number"
                                                    label="Phone Number"
                                                    variant="outlined"
                                                    onChange={handleFieldChange}
                                                    value={getStoreDetails.contact_number}
                                                />
                                            )}
                                        </InputMask>
                                    </div>

                                    <div className="col-6">
                                        <TextValidator
                                            type="text"
                                            name="website"
                                            label="Website"
                                            variant="outlined"
                                            onChange={handleFieldChange}
                                            value={getStoreDetails.website}
                                        />
                                    </div>
                                </div>
                            </ValidatorForm>
                        </div>
                        <div className={getUserRole()}>
                            <div className="row pb-5">
                                <div className="col-12 mt-3">
                                    <Button type="onSubmit" name="Save" onClick={() => { saveStoreDetails(); }} className="btn btn-primary ml-3 float-right" />
                                    <Button type="onSubmit" onClick={() => closeStoreDetails()} name="Cancel" className="btn btn-cancle float-right" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={confirmationModal} style={customStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="mt-4 mb-4">
                    <div className="row">
                        <div className="col-12 text-center">
                            Are you sure you want to delete this store?
                        </div>
                        <div className="col-12 mt-4  d-flex justify-content-center">
                            <Button type="onSubmit" onClick={() => setConfirmationModal(false)} name="Cancel" className="btn btn-sm btn-cancle mr-3" />
                            <Button type="onSubmit" onClick={() => { deleteStore(indexIdToDelete); }} name="Delete" className="btn btn-sm btn-primary" />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
        
        </>
    );
}
export default Stores;
