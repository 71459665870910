import React, { useEffect, useState } from "react";
import { getMethod } from "../../utils/services/api";
import urls from "../../utils/services/apiUrl.json";
import { toast } from "react-toastify";
import "./store.scss";

function StoreSeviceTypeList(props) {

    const [listData, setListData] = useState([]);
    const [icon, setIcon] = useState([]);
    const [selectList, setSelectList] = useState([]);

    const getStoreType = async () => {
        let url;
        if (props.type === "store") {
            url = urls.storeTypeList;
        } else if (props.type === "category") {
            url = urls.categoryTypeList;
        } else if (props.type === "payment") {
            url = urls.paymentMode;
        }

        await getMethod(url).then((response) => {
            if (response.data) {
                let tempArray = [];
                response.data.map((k, i) => {
                    if (icon.includes(k.name)) {
                    } else {
                        tempArray.push(k);
                    }
                })
                setListData(selectList.concat(tempArray));
            }
        }).catch((error) => {
            console.log("Error", error);
        });
    };

    const getSelectedElements = () => {

        if (props.activeStore) {
            let tempArray1 = [];
            let tempArray2 = [];
            props.activeStore.map((item) => {
                tempArray1.unshift(item.name);
                tempArray2.unshift(item);
            });
            setIcon(tempArray1);
            setSelectList(tempArray2);
        }
    };

    useEffect(() => {
        getSelectedElements();
    }, [props.activeStore]);

    useEffect(() => {
        getStoreType();
    }, [icon]);

    const changeIcon = (item, index) => {

        if (props.type === "payment") {
            setIcon([]);
            props.sendDataToFinancial(item);
            return;
        }
       
        if (!icon.includes(item.name)) {

            if (item.name === "Part Time (offline)") {
                if (icon.includes("Full Time (offline)")) {
                    toast.error("select only Part Time (offline) or Full Time (offline)")
                    return;
                }
            } else if (item.name === "Full Time (offline)") {
                if (icon.includes("Part Time (offline)")) {
                    toast.error("select only Part Time (offline) or Full Time (offline)")
                    return;
                }
            } else if (item.name === "Full Time (Online)") {
                if (icon.includes("Part Time (online)")) {
                    toast.error("select only Part Time (online) or Full Time (online)")
                    return;
                }
            } else if (item.name === "Part Time (online)") {
                if (icon.includes("Full Time (Online)")) {
                    toast.error("select only Part Time (online) or Full Time (online)")
                    return;
                }
            }
            setIcon([...icon, item.name]);
        } else {
            // icon.splice(icon.indexOf(item.name), 1);
        }

        if (props.type === "store") {
            props.sendDataToParent(item);
        } else if (props.type === "category") {
            props.sendDataToCategory(item);
        } else if (props.type === "payment") {
            props.sendDataToFinancial(item);
        }
    };

    return listData !== "" ? (
        <div className={props.isFullDataShow !== "yes" ? "row" : "row pl-4 pr-4"}>
            <div className={props.isFullDataShow !== "yes" ? "col-12 d-flex" : "col-12 d-contents"} >
                {listData !== "" && listData.map((item, index) => {
                    return (
                        <>
                            {props.isFullDataShow === "no" && index <= 5 ?
                                <div className="mr-3">
                                    {icon.includes(item.name) ? (
                                        <div key={index} onClick={() => { changeIcon(item, index); }} className={"frame storeType"}>
                                            <div className="selectedGrid"></div>
                                            <span className="helper"></span>
                                            <img src={item.active_image} />
                                            <div className="cardNameSelected">{item.name}</div>
                                        </div>
                                    ) : (
                                        <div key={index} onClick={() => { changeIcon(item, index); }} className={"frame storeType"}>
                                            <span className="helper"></span>
                                            <img src={item.inactive_image} />
                                            <div className="cardName">{item.name}</div>
                                        </div>
                                    )}

                                </div>
                                : props.isFullDataShow === "yes" &&
                                <div className="mr-3">
                                    {icon.includes(item.name) ? (
                                        <div key={index} onClick={() => { changeIcon(item, index); }} className={"frame storeType"}>
                                            <div className="selectedGrid"></div>
                                            <span className="helper"></span>
                                            <img src={item.active_image} />
                                            <div className="cardNameSelected">{item.name}</div>
                                        </div>
                                    ) : (
                                        <div key={index} onClick={() => { changeIcon(item, index); }} className={"frame storeType"}>
                                            <span className="helper"></span>
                                            <img src={item.inactive_image} />
                                            <div className="cardName">{item.name}</div>
                                        </div>
                                    )}

                                </div>
                            }
                        </>
                    );
                })}
            </div>
        </div>
    ) : (
        <div className="row">
            <div className="col-12">
                No data found...
            </div>
        </div>
    );
}
export default StoreSeviceTypeList;
