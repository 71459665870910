import React, { useState, useEffect } from "react";
import "./ShopDetails.scss";
import Button from "../../../../../../../components/button/button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import BussinessCred from "../inReviewTabs/components/bussinessCredential/BussinessCred";
import FinancialInfo from "../inReviewTabs/components/fanancialInformation/FinancialInfo";
import OpeningDays from "../inReviewTabs/components/openingDays/OpeningDays";
import Products from "../inReviewTabs/components/Products/Products";
import { useHistory,useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import urls from "../../../../../../../utils/services/apiUrl.json";
import {postMethodWithToken, getMethod, putMethodWithToken} from "../../../../../../../utils/services/api";
import {getStoreId, getUserRole} from "../../../../../../../utils/common";
import Modal from "react-modal";
import { toast } from "react-toastify";
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import StorefrontIcon from '@material-ui/icons/Storefront';

const ShopDetails = (props) => {
    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    var panelOpen = window.$panelActive;

    const [currTab, setCurrTab] = React.useState(0);
    const [reviewDetails, setReviewDetails] = useState();
    const [cancelDetails, setCancelDetails] = useState(false);
    const [inReviewDetail,setInReviewStoreDetail] = useState("")

    window.$ReviewStoreDetails = inReviewDetail;

    function handleTabChange(event, newValue) {
        setCurrTab(newValue);
    }

    function handleChangeIndex(index) {
        setCurrTab(index);
    }

    const gotoBack = async () => {
        history.goBack()
    };

    const approved = async () => {
        let body = {
            shop_id: inReviewDetail.id,
        };
        await postMethodWithToken(urls.approve + "in_approved", body).then((response) => {
            if (response.status === 200) {
                history.push("/stores");
            }
        });
    };

    const cancelRequest = async () => {
        setCancelDetails(false);
        await putMethodWithToken(urls.cancelInreviewStore + inReviewDetail.id + "/", "").then((response) => {
            if (response.status === 200) {
                history.push("/stores");
                toast.success(response.data.response);
            } else {
                toast.error("Something Went Wrong");
            }
        });
    };

    const customStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: "25vw",
            height: "20vh",
        },
    };

    useEffect(() => {
        if (getUserRole() !== "super-admin") {
            getInReviewDetails();
        }else{
            inReviewDetails()
        }
        
    }, []);

    const getInReviewDetails = async () => {
        let storeid = getStoreId();
        await getMethod(urls.vendorOnboarding + storeid).then((response) => {
            setReviewDetails(response.data);
        }).catch((error) => {
            console.log("error", error);
        });
    };

    const inReviewDetails = async() =>[
        await getMethod(urls.getDetails + params.id + "/?query_for=inreview").then((response)=>{
            if(response.status === 200 || response.status === 201){
                setInReviewStoreDetail(response.data.response)
            }
        })
    ]
    return (
        <div className={props.menu ? "w-100 screenLeftMargin" : "w-100 screenLeftMarginResponse"}>
            <div className="row mt-3 pl-3 ml-3 mr-3 mobileResponsive">
                <div  onClick={() => gotoBack()} style={{cursor: "pointer"}}>
                    <ArrowBackIcon /> <a style={{color: "#4472C4"}} className="ml-2">Listings</a>
                </div>
            </div>
            <div className="row mt-2 ml-3 mr-3 mobileResponsive">
                <div className="col-7 d-flex">
                    <h1 className="heading-title">
                        <StorefrontIcon fontSize="large"/> Stores
                    </h1>
                </div>
                <div className="col-5 d-flex justify-content-end">
                    <Button onClick={() => approved()} className="btn btn-primary mr-3" name="Approve"></Button>
                    <Button onClick={() => setCancelDetails(true)} className="btn btn-cancle" name="Cancel"></Button>
                </div>
            </div>

            <div className="row mt-3 ml-3 mr-3 mobileResponsive">
                <div className="col-12">
                    <h2 className="h6-label mb-2">In Review</h2>

                    <div className="reviewBox">
                        <div className="row ">
                            <div className="col-4 mt-3 pl-5">
                                <h3 className="h6-label">Shop Details</h3>
                                <div>
                                    <p className="h2-label mt-3">
                                        {inReviewDetail?.store_company_name}
                                    </p>
                                    <p className="h2-label mt-2">
                                        ID: <span>{inReviewDetail?.id}</span>
                                    </p>
                                </div>
                            </div>

                            <div className="col-4 mt-3">
                                <h3 className="h6-label">Owner Details</h3>
                                <p className="h2-label mt-3">
                                    {inReviewDetail?.owner_name}
                                </p>
                            </div>

                            <div className="col-4 mt-3 pr-5">
                                <h3 className="h6-label">Contact</h3>
                                <p className="mb-1 mt-3">
                                    <EmailIcon fontSize="small" style={{color: "#808080", marginRight:"5Px"}}/> {inReviewDetail?.email}
                                </p>
                                <p>
                                    <PhoneIcon fontSize="small" style={{color: "#808080", marginRight:"5Px"}}/>
                                    {inReviewDetail?.country_code}
                                    {inReviewDetail?.phone_number}
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4 mt-3">
                                <div className="ml-3 pl-3">
                                    <span>
                                        <p className="h5-label">Store Details</p>
                                    </span>
                                    <p className="h6-label mb-0">
                                        <b>Store Type</b>
                                        <span className="leadBox-details pl-4 ml-3">
                                            : {inReviewDetail?.store_types?.[0]?.name}
                                        </span>
                                    </p>
                                    <p className="h6-label mb-0">
                                        <b>Store Category</b>
                                        <span className="leadBox-details pl-2 ml-1">
                                            : {inReviewDetail?.strore_category?.[0]?.name}
                                        </span>
                                    </p>
                                    {inReviewDetail?.store_revenue?.slug && 
                                        <p className="h6-label mb-0">
                                            <b>Revenue</b>
                                            <span className="leadBox-details pl-5 ml-2">
                                                : {inReviewDetail?.store_revenue?.slug}
                                            </span>
                                        </p>
                                    }
                                </div>
                            </div>

                            <div className="col-4 mt-3">
                                <p className="h5-label mt-4">Address</p>
                                <p className="leadBox-details">
                                    {inReviewDetail?.store_address?.location}
                                </p>
                            </div>

                            <div className="col-4 mt-3">
                                <p className="h5-label mt-4">Manager Details</p>
                                <p className="mb-1 mt-3">
                                    {/* <b>{inReviewDetail.manager_detail[0].related_manager.name}</b> */}
                                </p>
                                <p className="mb-1">
                                    <EmailIcon fontSize="small" style={{color: "#808080", marginRight:"5Px"}}/> 
                                    {inReviewDetail?.manager_detail?.[0]?.related_manager?.email}
                                </p>
                                <p>
                                    <PhoneIcon fontSize="small" style={{color: "#808080", marginRight:"5Px"}}/>
                                    {inReviewDetail?.manager_detail?.[0]?.related_manager?.phone_number}
                                </p>
                            </div>
                        </div>

                        
                        <div className="w-100">
                            <Tabs
                                value={currTab}
                                onChange={handleTabChange}
                                textColor="inherit"
                                variant="standard"
                                >
                                <Tab
                                    label="Business Credentials"
                                />
                                <Tab
                                    label="Financial Information"
                                />
                                {inReviewDetail?.opening_days?.length > 0 && 
                                <Tab
                                    label="Opening Days"
                                />
                                }
                                <Tab
                                    label="Products"
                                />
                            </Tabs>
                        </div>
                    </div>

                    <div>
                         <div className="w-100">
                            <SwipeableViews index={currTab} onChangeIndex={(ind)=>handleChangeIndex(ind)} enableMouseEvents={true}>
                                <div>
                                    <BussinessCred data={inReviewDetail} />
                                </div>
                                <div>
                                    <FinancialInfo data={inReviewDetail} />
                                </div> 

                                {inReviewDetail?.opening_days?.length > 0 ?
                                <div>
                                    <OpeningDays data={inReviewDetail} />
                                </div>
                                :
                                <div>
                                    <Products data={inReviewDetail} />
                                </div>
                                 }
                                 <div>
                                    <Products data={inReviewDetail} />
                                </div>

                            </SwipeableViews>
                        </div> 
                    </div>
                </div>
            </div>

            <Modal isOpen={cancelDetails} style={customStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="mt-4 mb-4">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h4 className="h4-label">Attention!!</h4>
                        </div>
                        <div className="col-12 text-center mt-4">
                            Drag to Shortlisted
                        </div>
                        <div className="col-12 mt-4  d-flex justify-content-center">
                            <Button type="onSubmit" onClick={() => setCancelDetails(false)} name="Cancel" className="btn btn-cancle btn-sm mr-3" />
                            <Button type="onSubmit" onClick={() => {cancelRequest();}} name="Yes" className="btn btn-primary btn-sm" />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ShopDetails;
