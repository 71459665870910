import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import TextField from "@material-ui/core/TextField";
import "./generalSettings.scss";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "../../../../components/button/button";
import { getMethod, postMethodWithToken, postMethodWithTokenProgress, putMethodWithToken } from "../../../../utils/services/api";
import urls from "../../../../utils/services/apiUrl.json";
import { getStoreId } from "../../../../utils/common";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import StoreSeviceTypeList from "../../../../components/store/store";
import Modal from "react-modal";
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from "@material-ui/core/InputLabel";
import Geosuggest from "react-geosuggest";
import ListSubheader from "@material-ui/core/ListSubheader";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import InputMask from "react-input-mask";
import { useDropzone } from "react-dropzone";
import UploadPhotoIcon from "../../../../assets/images/add_a_photo.png";
import PDF from "../../../../assets/images/pdfHolder.svg";

let latitude = "";
let longitude = "";

function GeneralSettings() {

    const editorRef = useRef(null);
    const google = window.google;
    const geosuggestEl = useRef("");
    const dragItem = useRef();
    const dragOverItem = useRef();

    const allStyles = {
        content: {
            top: "15vw",
            left: "50vw",
            right: "auto",
            transform: "translate(-50%, -5%)",
            backgroundColor: "#fff",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            width: "40vw",
            height: "60vh",
            outline: "none",
            padding: "20px",
        },
    };

    function getPaymentConfig(props) {
        let config = {
            readonly: false,
            placeholder: "Payment",
        }
        return config;
    }

    const paymentConfig = useMemo(() => getPaymentConfig());

    function getShippingConfig(props) {
        let config = {
            readonly: false,
            placeholder: "Shipping",
        }
        return config;
    }

    const shippingConfig = useMemo(() => getShippingConfig());

    const [reviewStoreData, setReviewStoreData] = useState("");
    const [getStatePovince, setStatePovince] = useState("State");
    const [getPinZip, setGetPinZip] = useState("ZIP Code");
    const [storeTiming, setStoreTiming] = useState([
        {
            "days": "monday",
            "startTime": null,
            "endTime": null,
            "is_holiday": true
        },
        {
            "days": "tuesday",
            "startTime": null,
            "endTime": null,
            "is_holiday": true
        },
        {
            "days": "wednesday",
            "startTime": null,
            "endTime": null,
            "is_holiday": true
        },
        {
            "days": "thursday",
            "startTime": null,
            "endTime": null,
            "is_holiday": true
        },
        {
            "days": "friday",
            "startTime": null,
            "endTime": null,
            "is_holiday": true
        },
        {
            "days": "saturday",
            "startTime": null,
            "endTime": null,
            "is_holiday": true
        },
        {
            "days": "sunday",
            "startTime": null,
            "endTime": null,
            "is_holiday": true
        },
    ]);
    const [typeOfStore, setTypeOfStore] = useState([]);
    const [openStoreCategories, setOpenStoreCategories] = useState([]);
    const [storeIsOpen, setStoreIsOpen] = useState(false);
    const [storeCategory, setStoreCategory] = useState([]);
    const [categoryIsOpen, setCategoryIsOpen] = useState(false);
    const [openCategory, setOpenCategory] = useState([]);
    const [revenueOptions, setRevenueOptions] = useState([]);
    const [docsImagesList, setDocsImagesList] = useState([]);
    const [storeIconImage, setStoreIconImage] = useState(false);
    const [storeIconLogo, setStoreIconLogo] = useState(false);

    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [stateDataList, setStateDataList] = useState([]);
    const [cityDataList, setCityDataList] = useState([]);
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);

    const [formValue, setFormValue] = useState({
        merchant_shipping: "",
        merchant_payment: "",
        store_info: getStoreId(),
        current_annual_revenue: "",
        store_name: "",
        first_name: "",
        last_name: "",
        address: "",
        unit: "",
        postal_code: "",
        city: "",
        country: "",
        state: "",
        contact_number: "",
        website: "",
        province: "",
        doc_ids: [],
        store_icon: [],
        store_logo: [],
        latitude: "",
        longitude: "",
    });


    useEffect(() => {
        getCountryList();
        getAnualRevenueList();
        getStoreDetails();
        getCurrentStatus();
        getGeneralStore();
    }, []);

    const getAnualRevenueList = async () => {
        await getMethod(urls.storeAnnualRevenue).then((response) => {
            if (response.status === 200) {
                setRevenueOptions(response.data);
            }
        });
    };

    const getCurrentStatus = async () => {
        await getMethod(urls.onboardingProgress + "?of=review").then((response) => {
            window.$progessPercentage = response.data.status;
        }).catch((error) => {
            console.log(error);
        });
    };

    const getGeneralStore = async () => {
        const result = await getMethod(urls.aboutStore + getStoreId() + "/");
        {
            result.data.store_timing.map((k, i) => {
                if (k.startDateTime !== null) {
                    storeTiming[i].startTime = moment(k.startDateTime).format('DD-MMM-YYYY hh:mm')
                }
                if (k.endDateTime !== null) {
                    storeTiming[i].endTime = moment(k.endDateTime).format('DD-MMM-YYYY hh:mm')
                }
                setStoreTiming(storeTiming)
            })
        }

        let countryValue = result.data.business_address.country;
        let stateValue = result.data.business_address.state;
        let cityValue = result.data.business_address.city;
        let state_id = '';

        if (countryValue) {
            getMethod(urls.getStates + '?country_name=' + countryValue).then((response) => {
                if (response.status === 200) {
                    let results = response.data.response[0].data.states

                    setStateList(results);
                    setStateDataList(results);

                    if (results.length > 0 && stateValue) {
                        let indexStateID = '';

                        results.map((item) => {
                            if (item.stateName === stateValue) {
                                indexStateID = item.id;
                            }
                        });

                        if (indexStateID) {
                            state_id = indexStateID;

                            setFormValue((prevState) => ({
                                ...prevState,
                                state: indexStateID,
                            }));

                            if (countryValue && state_id) {
                                getCityListWithName(countryValue, state_id, cityValue)
                            }
                        }
                    }
                }
            });
        }

        setFormValue((prevState) => ({
            ...prevState,
            ['merchant_shipping']: result.data.merchantShipping,
            ['merchant_payment']: result.data.merchantPayment,
            ["current_annual_revenue"]: result.data.current_annual_revenue.slug,
            ["store_name"]: result.data.name,
            ["first_name"]: result.data.business_address.first_name,
            ["last_name"]: result.data.business_address.last_name,
            ["address"]: result.data.business_address.address,
            ["unit"]: result.data.business_address.unit,
            ["country"]: result.data.business_address.country,
            ["state"]: result.data.business_address.state,
            ["city"]: result.data.business_address.city,
            ["postal_code"]: result.data.business_address.postal_code,
            ["contact_number"]: result.data.business_address.contact_number,
            ["website"]: result.data.business_address.website,
        }));
        setOpenStoreCategories(result.data.type_of_store);
        setOpenCategory(result.data.category);
        { result.data && result.data.type_of_store.length > 0 && result.data.type_of_store.map((k) => typeOfStore.push(k.slug)) }
        { result.data && result.data.category.length > 0 && result.data.category.map((k) => storeCategory.push(k.slug)) }

        if (result.data.business_address.store_icon.length > 0) {
            let idObject = {
                image_id: result.data.business_address.store_icon[0].id,
                order_image: result.data.business_address.store_icon[0].order_image,
                file: result.data.business_address.store_icon[0].file
            };
            setFormValue((prevState) => ({
                ...prevState,
                ['store_icon']: [idObject],
            }));
        }

        if (result.data.business_address.store_logo && result.data.business_address.store_logo.length > 0) {
            let idObject = {
                image_id: result.data.business_address.store_logo[0].id,
                order_image: result.data.business_address.store_logo[0].order_image,
                file: result.data.business_address.store_logo[0].file
            };

            setFormValue((prevState) => ({
                ...prevState,
                ['store_logo']: [idObject],
            }));
        }

        if (result.data.business_address.business_doc.length > 0) {
            let storeDocsList = [];
            result.data.business_address.business_doc.forEach((x, i) => {
                let idObject = {
                    image_id: x.id,
                    order_image: x.order_image,
                    file: x.file
                };
                storeDocsList.push(idObject);
            });
            setDocsImagesList(storeDocsList);
        }

    }

    const getStoreDetails = async () => {
        let storeid = getStoreId();
        await getMethod(urls.vendorOnboarding + storeid).then((response) => {
            if (response.status === 200) {
                setReviewStoreData(response.data);
                if (response.data.business_address.country === 'USA') {
                    setStatePovince("State");
                    setGetPinZip("ZIP Code");
                } else {
                    setStatePovince("Province");
                    setGetPinZip("Postal Code");
                }
            }
        }).catch((error) => {
            console.log("error", error);
        });
    };

    const handleStoreHoliday = (event, index) => {
        const listArray = [...storeTiming]
        listArray[index].is_holiday = !listArray[index].is_holiday;
        setStoreTiming(listArray);
    }

    const handleStartDateChange = (date, index) => {
        const listArray = [...storeTiming]
        listArray[index].startTime = moment(date).format('DD-MMM-YYYY hh:mm');
        setStoreTiming(listArray);
    }

    const handleEndDateChange = (date, index) => {
        const listArray = [...storeTiming]
        listArray[index].endTime = moment(date).format('DD-MMM-YYYY hh:mm');
        setStoreTiming(listArray);
    }

    const handleShippingChange = (e) => {
        setFormValue((prevState) => ({
            ...prevState,
            ['merchant_shipping']: e,
        }));
    };

    const handlePaymentChange = (e) => {
        setFormValue((prevState) => ({
            ...prevState,
            ['merchant_payment']: e,
        }));
    };

    const submitData = async () => {
        formValue['store_timing'] = storeTiming;
        formValue['type_of_store'] = typeOfStore;
        formValue['category'] = storeCategory;
        formValue['doc_ids'] = docsImagesList;

        await putMethodWithToken(urls.aboutStore + getStoreId() + "/", formValue).then((response) => {
            if (response.status === 201 || response.status === 200) {
                toast.success(response.data.response);
            } else {
                toast.error(response.data.response);
            }
        });
    }

    const handleChange = (e) => {
        setFormValue((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const viewAllStoreType = () => {
        setStoreIsOpen(true);
    };

    const closeAllStoreType = () => {
        setStoreIsOpen(false);
    };

    const viewAllCategoryType = () => {
        setCategoryIsOpen(true);
    };

    const closeAllCategoryType = () => {
        setCategoryIsOpen(false);
    };

    const sendDataToParent = (item) => {
        const list = [...typeOfStore];
        const openList = [...openStoreCategories];

        if (list.includes(item.slug) === false) {
            list.push(item.slug);
            openList.push(item);
        } else {
            const index = typeOfStore.indexOf(item.slug);

            if (index > -1) {
                list.splice(index, 1);
                openList.splice(index, 1);
            }
        }
        setTypeOfStore(list);
        setOpenStoreCategories(openList);
    };

    const sendDataToCategory = (item) => {
        const list = [...storeCategory];
        const openList = [...openCategory];

        if (list.includes(item.slug) === false) {
            list.push(item.slug);
            openList.push(item);
        } else {
            const index = storeCategory.indexOf(item.slug);

            if (index > -1) {
                list.splice(index, 1);
                openList.splice(index, 1);
            }
        }
        setStoreCategory(list);
        setOpenCategory(openList);
    };

    const onSuggestSelect = (suggest) => {

        if (suggest) {

            setFormValue((prevState) => ({
                ...prevState,
                postal_code: "",
                country: "",
                state: "",
                city: ""
            }));

            const components = suggest.gmaps.address_components;

            let state_id = '';
            let streetNumberValue = '';
            let routeValue = '';
            let cityValue = '';
            let postCodeValue = '';
            let stateValue = '';
            let stateAddressValue = '';
            let countryValue = '';
            let addressValue = '';

            for (let key of components) {
                if (key.types[0] === "locality" || key.types[1] === "sublocality") {
                    cityValue = key.long_name;
                }

                if (key.types[0] === "administrative_area_level_1") {
                    stateValue = key.long_name;
                    stateAddressValue = key.short_name;
                }

                if (key.types[0] === "country") {
                    if (key.short_name === 'US') {
                        countryValue = 'USA';

                        setFormValue((prevState) => ({
                            ...prevState,
                            country: 'USA',
                        }));

                    } else if (key.short_name === 'CA') {
                        countryValue = 'CAN';

                        setFormValue((prevState) => ({
                            ...prevState,
                            country: 'CAN',
                        }));
                    } else {
                        countryValue = key.short_name;

                        setFormValue((prevState) => ({
                            ...prevState,
                            country: key.short_name,
                        }));
                    }
                }

                if (key.types[0] === "postal_code") {
                    postCodeValue = key.long_name;
                    setFormValue((prevState) => ({
                        ...prevState,
                        postal_code: key.long_name,
                    }));
                }
                if (key.types[0] === "route") {
                    routeValue = key.short_name;
                }

                if (key.types[0] === "street_number") {
                    streetNumberValue = key.long_name;
                }
            }

            if (stateValue && countryValue) {
                getMethod(urls.getStates + '?country_name=' + countryValue).then((response) => {
                    if (response.status === 200) {
                        let results = response.data.response[0].data.states

                        setStateList(results);
                        setStateDataList(results);

                        if (results.length > 0) {
                            let indexStateID = '';

                            results.map((item) => {
                                if (item.stateName === stateValue) {
                                    indexStateID = item.id;
                                }
                            });

                            if (indexStateID) {
                                state_id = indexStateID;

                                setFormValue((prevState) => ({
                                    ...prevState,
                                    state: indexStateID,
                                }));

                                if (countryValue && state_id) {
                                    getCityListWithName(countryValue, state_id, cityValue)
                                }
                            }
                        }
                    }
                });
            }

            if (streetNumberValue !== "") {
                addressValue += streetNumberValue;
            }

            if (routeValue !== "" && streetNumberValue !== "") {
                addressValue += ", " + routeValue;
            } else if (routeValue !== "") {
                addressValue += routeValue;
            }

            latitude = suggest.location.lat;
            longitude = suggest.location.lng;

            setFormValue((prevState) => ({
                ...prevState,
                ["location"]: addressValue,
                ["address"]: addressValue,
                ["latitude"]: latitude,
                ["longitude"]: longitude,
            }));

            geosuggestEl.current.update(addressValue);
        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        if (storeIconLogo === true) {

            let formData = new FormData();
            acceptedFiles.map((file, index) => {
                formData.append("file", file);
            });
            postMethodWithTokenProgress(urls.fileUpload, formData).then((response) => {
                if (response.status === 200) {
                    let idObject = {
                        image_id: response.data[0].id,
                        order_image: 1,
                        file: response.data[0].file
                    };

                    setFormValue((prevState) => ({
                        ...prevState,
                        ['store_logo']: [idObject],
                    }));
                } else {
                    toast.error(response.error)
                }
            }).catch((error) => {
                console.log("error", error);
            });

            setStoreIconLogo(false)

        } else if (storeIconImage === true) {

            let formData = new FormData();
            acceptedFiles.map((file, index) => {
                formData.append("file", file);
            });
            postMethodWithTokenProgress(urls.fileUpload, formData).then((response) => {
                if (response.status === 200) {
                    let idObject = {
                        image_id: response.data[0].id,
                        order_image: 1,
                        file: response.data[0].file
                    };

                    setFormValue((prevState) => ({
                        ...prevState,
                        ['store_icon']: [idObject],
                    }));
                } else {
                    toast.error(response.error)
                }
            }).catch((error) => {
                console.log("error", error);
            });

            setStoreIconImage(false)
        } else {
            let formData = new FormData();
            acceptedFiles.map((file, index) => {
                formData.append("file", file);
            });

            postMethodWithTokenProgress(urls.fileUpload, formData).then((response) => {
                if (response.status === 200) {
                    let storeDocsList = [...docsImagesList];
                    response.data.map((item, index) => {
                        let idObject = {
                            image_id: item.id,
                            order_image: storeDocsList.length > 0 ? storeDocsList.length + 1 : index + 1,
                            file: item.file
                        };

                        storeDocsList.push(idObject);
                    });
                    setDocsImagesList(storeDocsList);
                } else {
                    toast.error(response.error)
                }
            }).catch((error) => {
                console.log("error", error);
            });
        }
    });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const dragUploadStart = (e, position) => {
        dragItem.current = position;
    }

    const dragUploadEnter = (e, position) => {
        dragOverItem.current = position;
    }

    const dropUploadEnd = (e) => {
        const list = [...docsImagesList];

        const dragItemWithFile = list[dragItem.current];
        const dragOverItemWithFile = list[dragOverItem.current];

        list.splice(dragItem.current, 1);
        list.splice(dragOverItem.current, 0, dragItemWithFile);

        dragItem.current = null;
        dragOverItem.current = null;

        list.forEach((x, i) => {
            list[i]['order_image'] = i + 1;
        })

        setDocsImagesList(list)
    }

    const handleDeleteImage = (index) => {
        const list = [...docsImagesList];
        list.splice(index, 1);

        if (list.length > 0) {
            list.forEach((x, i) => {
                if (list.order_image !== 1) {
                    list.order_image = list.order_image - 1;
                }
            });
        }

        setDocsImagesList(list)
    };

    const getCountryList = async () => {
        await getMethod(urls.getCountry).then((response) => {
            if (response.status === 200) {
                setCountryList(response.data);
            }
        });
    };

    const getStateList = async (value) => {
        await getMethod(urls.getStates + '?country_name=' + value).then((response) => {
            if (response.status === 200) {
                setStateList(response.data.response[0].data.states);
                setStateDataList(response.data.response[0].data.states);
            }
        });
    };

    const getCityList = async (value) => {
        await getMethod(urls.getStates + '?country_name=' + formValue.country + '&state_id=' + value).then((response) => {
            if (response.status === 200) {
                setCityList(response.data.response[0].data.cities);
                setCityDataList(response.data.response[0].data.cities);
            }
        });
    };

    const getCityListWithName = async (countryId, stateId, name) => {
        await getMethod(urls.getStates + '?country_name=' + countryId + '&state_id=' + stateId).then((response) => {
            if (response.status === 200) {
                let results = response.data.response[0].data.cities;
                setCityList(results);
                setCityDataList(results);

                if (name && results.length > 0) {
                    let indexCityID = '';

                    results.map((item) => {
                        if (item.cityName === name) {
                            indexCityID = item.id;
                        }
                    });

                    if (indexCityID) {
                        setFormValue((prevState) => ({
                            ...prevState,
                            city: indexCityID,
                        }));
                    }
                }

            }
        });
    }

    const stateFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = stateDataList.filter((item) => {
                return item.stateName.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setStateList(results);
        } else {
            setStateList(stateDataList);
        }
    };

    const cityFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = cityDataList.filter((item) => {
                return item.cityName.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setCityList(results);
        } else {
            setCityList(cityDataList);
        }
    };

    const handleCountryChange = (event) => {
        setFormValue({ ...formValue, [event.target.name]: event.target.value });

        setFormValue((prevState) => ({
            ...prevState,
            state: '',
            city: '',
            postal_code: '',
        }));

        getStateList(event.target.value);
    };

    const handleStateChange = (event) => {
        if (event.target.value !== undefined) {
            setFormValue({ ...formValue, [event.target.name]: event.target.value });

            setFormValue((prevState) => ({
                ...prevState,
                city: '',
            }));

            getCityList(event.target.value);
        }
    };

    const handleCityChange = (event) => {
        if (event.target.value !== undefined) {
            setFormValue({ ...formValue, [event.target.name]: event.target.value });
        }
    };

    const checkFileExtension = (item) => {
        var fileExtension = item.file.split(".").pop();
        return fileExtension === "pdf";
    };

    return (
        <div className="w-100 screenLeftMargin">
            <div className="row ml-3 mr-3 mt-3">
                <ValidatorForm>
                    <div className="col-12">
                        <h1 className="heading-title mb-3">General</h1>
                        {/* <div className="p-3" style={{ background: "#ECECEC" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className="h3-label">Woodland</div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div className="h5-label">Store Theme</div>
                                    <div className="themeColor"></div>
                                </div>
                            </div>
                            <p className="muiDescripationInfo">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac ut amet,
                                odio elementum lacinia ut bibendum non aliquam. Risus ac at
                                sollicitudin elit nullam vitae dui. Gravida integer dictum neque
                                malesuada enim.
                            </p>
                        </div> */}
                    </div>

                    <div className="col-12 mt-3">
                        <div className="row">

                            <div className="col-lg-6 col-md-12">
                                <div className="row">

                                    <div className="col-12 h4-label mt-2">
                                        About Store
                                    </div>

                                    <div className="col-2">
                                        <div className="row">
                                            <div className="col-12 mt-2">
                                                <div className="tellUsLabel">Store Icon</div>
                                            </div>

                                            <div className="col-12 mt-2">
                                                <div className="storeImageBlock">
                                                    <div className="imageUpload" {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        {isDragActive ? (
                                                            <p>Drop the files here ...</p>
                                                        ) : (
                                                            <>
                                                                {formValue.store_logo.length > 0 ? (
                                                                    <>
                                                                        {formValue.store_logo.map((item) => {
                                                                            return (
                                                                                <div>
                                                                                    <img
                                                                                        onClick={() => setStoreIconLogo(true)}
                                                                                        className="imgTagBlock"
                                                                                        src={item.original !== undefined ? item.original : item.file}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </>
                                                                ) : (
                                                                    <div className="uploadPhoto">
                                                                        <div style={{ cursor: "pointer" }} onClick={() => setStoreIconLogo(true)} className="uploadInfo">
                                                                            <div className="uploadImage"><img width="22" src={UploadPhotoIcon} /></div>
                                                                            <div className="uploadText">Store Icon</div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-10">
                                        <div className="row">
                                            <div className="col-12 mt-2">
                                                <div className="tellUsLabel">Store Front</div>
                                            </div>

                                            <div className="col-12 mt-2">
                                                <div className="storeImageBlock">
                                                    <div className="imageUpload" {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        {isDragActive ? (
                                                            <p>Drop the files here ...</p>
                                                        ) : (
                                                            <>
                                                                {formValue.store_icon.length > 0 ? (
                                                                    <>
                                                                        {formValue.store_icon.map((item) => {
                                                                            return (
                                                                                <div>
                                                                                    <img
                                                                                        onClick={() => setStoreIconImage(true)}
                                                                                        className="imgTagBlock"
                                                                                        src={item.original !== undefined ? item.original : item.file}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </>
                                                                ) : (
                                                                    <div className="uploadPhoto">
                                                                        <div style={{ cursor: "pointer" }} onClick={() => setStoreIconImage(true)} className="uploadInfo">
                                                                            <div className="uploadImage"><img width="22" src={UploadPhotoIcon} /></div>
                                                                            <div className="uploadText">Store Front</div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 mt-2">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <div className="h6-label d-flex">
                                                    What describes you the best as a store?
                                                </div>
                                                {/*<div className="d-flex ml-3">
                                                    <div style={{ cursor: "pointer" }} onClick={() => viewAllStoreType()} className="viewAll">
                                                        View all
                                                    </div>
                                                </div>*/}
                                            </div>
                                        </div>

                                        <div className="row mt-1">
                                            <div className="col-12">
                                                <StoreSeviceTypeList
                                                    sendDataToParent={(item) => {
                                                        sendDataToParent(item);
                                                    }}
                                                    activeStore={openStoreCategories}
                                                    type="store"
                                                    isFullDataShow="no"
                                                />
                                            </div>
                                        </div>

                                        {/*<Modal isOpen={storeIsOpen} style={allStyles} overlayClassName="myoverlay">
                                            <div style={{ cursor: "pointer" }} onClick={() => closeAllStoreType()} className="x mb-2">
                                                <CloseIcon />
                                            </div>

                                            <StoreSeviceTypeList
                                                sendDataToParent={(item) => {
                                                    sendDataToParent(item);
                                                }}
                                                activeStore={openStoreCategories}
                                                type="store"
                                            />
                                        </Modal>*/}
                                    </div>

                                    <div className="col-12 mt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <div className="h6-label d-flex">
                                                    How would you categorize your store?
                                                </div>
                                                <div className="d-flex ml-3">
                                                    <div style={{ cursor: "pointer" }} onClick={() => viewAllCategoryType()} className="viewAll">
                                                        View all
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <Modal isOpen={categoryIsOpen} style={allStyles} overlayClassName="myoverlay">
                                            <div>
                                                <div style={{ cursor: "pointer" }} onClick={() => closeAllCategoryType()} className="x mb-2">
                                                    <CloseIcon />
                                                </div>
                                            </div>

                                            <StoreSeviceTypeList
                                                sendDataToCategory={(item) => {
                                                    sendDataToCategory(item);
                                                }}
                                                activeStore={openCategory}
                                                type="category"
                                                isFullDataShow="yes"
                                            />

                                            <div>
                                                <div style={{ cursor: "pointer" }} onClick={() => closeAllCategoryType()} className="x mb-2">
                                                    <Button name="OK" className="btn btn-primary" />
                                                </div>
                                            </div>
                                        </Modal>

                                        <div className="row mt-1">
                                            <div className="col-12">
                                                <StoreSeviceTypeList
                                                    sendDataToCategory={(item) => {
                                                        sendDataToCategory(item);
                                                    }}
                                                    activeStore={openCategory}
                                                    type="category"
                                                    isFullDataShow="no"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 mt-3">
                                        <div className="h6-label">
                                            What is your current annual revenue?
                                        </div>
                                    </div>

                                    <div className="col-12 mt-2">
                                        <FormControl variant="outlined">
                                            <InputLabel
                                                id="revenue-select"
                                            >
                                                Revenue
                                            </InputLabel>

                                            <Select
                                                MenuProps={{ autoFocus: false }}
                                                labelId="revenue-select"
                                                id="revenue-dropdown"
                                                value={formValue.current_annual_revenue}
                                                defaultValue={formValue.current_annual_revenue}
                                                label="Revenue"
                                                name="current_annual_revenue"
                                                onChange={handleChange}
                                            >
                                                {revenueOptions.length > 0 && revenueOptions.map((item) => {
                                                    return (
                                                        <MenuItem value={item.slug}> {item.cost} </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>

                            {/* Business address */}
                            <div className="col-lg-6 col-md-12">
                                <div className="row">
                                    <h5 className="col-12 mb-2 h5-label">Your business address</h5>

                                    <div className="col-12 mb-3">
                                        <div className="row">
                                            <div className="col-6">
                                                <TextField
                                                    value={formValue.store_name}
                                                    name="store_name"
                                                    label="Store Name"
                                                    variant="outlined"
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 mb-3">
                                        <div className="row">
                                            <div className="col-6">
                                                <TextField
                                                    value={formValue.first_name}
                                                    name="first_name"
                                                    label="First Name"
                                                    variant="outlined"
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <TextField
                                                    value={formValue.last_name}
                                                    name="last_name"
                                                    label="Last Name"
                                                    variant="outlined"
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 mb-3">
                                        <div className="row">
                                            <div className="col-8">
                                                <Geosuggest
                                                    id="aboutstore"
                                                    ref={geosuggestEl}
                                                    required
                                                    className="Aboutstore_textfield"
                                                    placeholder="Address"
                                                    name="address"
                                                    fields={["address_components", "geometry"]}
                                                    types={["address"]}
                                                    country={["US", "CA"]}
                                                    initialValue={formValue.address}
                                                    onSuggestSelect={onSuggestSelect}
                                                    location={new google.maps.LatLng(lat, lng)}
                                                    radius="100"
                                                />
                                            </div>
                                            <div className="col-4">
                                                <TextField
                                                    value={formValue.unit}
                                                    type={"number"}
                                                    name="unit"
                                                    label="Unit"
                                                    variant="outlined"
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 mb-3">
                                        <div className="row">
                                            <div className="col-6">
                                                <FormControl variant="outlined">
                                                    <InputLabel
                                                        required
                                                        id="country-select"
                                                    >
                                                        Country
                                                    </InputLabel>
                                                    <Select
                                                        labelId="country-select"
                                                        id="country-dropdown"
                                                        value={formValue.country}
                                                        onChange={handleCountryChange}
                                                        label="Country"
                                                        name="country"
                                                        errorMessages={["Please select country"]}
                                                        validators={[
                                                            "required",
                                                        ]}
                                                    >
                                                        {countryList.map((item) => {
                                                            return (
                                                                <MenuItem value={item.iso_3166_1_a3}> {item.printable_name} </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="col-6">
                                                <FormControl variant="outlined">
                                                    <InputLabel
                                                        required
                                                        id="country-select"
                                                    >
                                                        {formValue.country === 'CAN' ? 'Province' : 'State'}
                                                    </InputLabel>
                                                    <Select
                                                        MenuProps={{ autoFocus: false }}
                                                        labelId="country-select"
                                                        id="country-dropdown"
                                                        value={formValue.state}
                                                        defaultValue={formValue.state}
                                                        label={formValue.country === 'CAN' ? 'Province' : 'State'}
                                                        name="state"
                                                        errorMessages={["Please select state"]}
                                                        validators={[
                                                            "required",
                                                        ]}
                                                        onChange={(e) => handleStateChange(e)}
                                                        onClose={() => setStateList(stateDataList)}
                                                    >
                                                        <ListSubheader>
                                                            <TextField
                                                                size="small"
                                                                autoFocus
                                                                placeholder="Type to search..."
                                                                fullWidth
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <SearchIcon />
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                onChange={stateFilter}
                                                                onKeyDown={(e) => {
                                                                    if (e.key !== "Escape") {
                                                                        e.stopPropagation();
                                                                    }
                                                                }}
                                                            />
                                                        </ListSubheader>
                                                        {stateList.length > 0 && stateList.map((item) => {
                                                            return (
                                                                <MenuItem value={item.id}> {item.stateName} </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 mb-3">
                                        <div className="row">
                                            <div className="col-6">
                                                <FormControl variant="outlined">
                                                    <InputLabel
                                                        required
                                                        id="country-select"
                                                    >
                                                        City
                                                    </InputLabel>
                                                    <Select
                                                        MenuProps={{ autoFocus: false }}
                                                        labelId="country-select"
                                                        id="country-dropdown"
                                                        value={formValue.city}
                                                        defaultValue={formValue.city}
                                                        label="City"
                                                        name="city"
                                                        errorMessages={["Please select city"]}
                                                        validators={[
                                                            "required",
                                                        ]}
                                                        onChange={(e) => handleCityChange(e)}
                                                        onClose={() => setCityList(cityDataList)}
                                                    >
                                                        <ListSubheader>
                                                            <TextField
                                                                size="small"
                                                                autoFocus
                                                                placeholder="Type to search..."
                                                                fullWidth
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <SearchIcon />
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                onChange={cityFilter}
                                                                onKeyDown={(e) => {
                                                                    if (e.key !== "Escape") {
                                                                        e.stopPropagation();
                                                                    }
                                                                }}
                                                            />
                                                        </ListSubheader>
                                                        {cityList.length > 0 && cityList.map((item) => {
                                                            return (
                                                                <MenuItem value={item.id}> {item.cityName} </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="col-6">
                                                {formValue.country === "CAN" ?
                                                    <InputMask
                                                        mask={"a9a 9a9"}
                                                        value={formValue.postal_code}
                                                        disabled={false}
                                                        onChange={handleChange}
                                                    >
                                                        {() => (
                                                            <TextValidator
                                                                required
                                                                errorMessages={[
                                                                    "Please enter valid Postal Code"
                                                                ]}
                                                                validators={[
                                                                    "required",
                                                                ]}
                                                                className="w-100"
                                                                name="postal_code"
                                                                label={'Postal Code'}
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    </InputMask>
                                                    :
                                                    <InputMask
                                                        mask={"99999"}
                                                        value={formValue.postal_code}
                                                        disabled={false}
                                                        onChange={handleChange}
                                                    >
                                                        {() => (
                                                            <TextValidator
                                                                required
                                                                errorMessages={[
                                                                    "Please enter valid ZIP Code"
                                                                ]}
                                                                validators={[
                                                                    "required",
                                                                ]}
                                                                className="w-100"
                                                                name="postal_code"
                                                                label={'ZIP Code'}
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    </InputMask>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 mb-3">
                                        <div className="row">
                                            <div className="col-6">
                                                <TextField
                                                    value={formValue.contact_number}
                                                    name="contact_number"
                                                    label="Phone Number"
                                                    variant="outlined"
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <TextField
                                                    value={formValue.website}
                                                    name="website"
                                                    label="Website"
                                                    variant="outlined"
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <h5 className="h5-label">Add business credentials</h5>
                                        <div className="muiDescripationInfo">
                                            Your business registration document (such as a incorporation certificate) and a
                                            government issued ID is required to verify and protect identity of your business.
                                            You may launch your store now but it will only be activated after proper verification
                                            of your business credentials.
                                        </div>
                                    </div>

                                    <div className="col-12 mt-2 d-flex">
                                        {docsImagesList.length > 0 && docsImagesList.map((item, index) => {
                                            return (
                                                <div className="storeImageBlock d-flex mr-3"
                                                    data-key={index}
                                                    onDragStart={(e) => dragUploadStart(e, index)}
                                                    onDragEnter={(e) => dragUploadEnter(e, index)}
                                                    onDragEnd={dropUploadEnd}
                                                    key={index}
                                                    draggable>
                                                    <div className="ml-2 mr-3">
                                                        <div className="deleteUploadedImage text-center" onClick={() => handleDeleteImage(index)}>
                                                            <CloseIcon style={{ fontSize: "18px", cursor: "pointer" }} />
                                                        </div>
                                                        {checkFileExtension(item) ? (
                                                            <a href={item.file} target="_blank">
                                                                <img className="imgTagBlock" src={PDF} alt="" />
                                                            </a>
                                                        ) : (
                                                            <img className="imgTagBlock" src={item.file} alt="" />
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}

                                        <div className="storeImageBlock d-flex">
                                            <div className="imageUpload" {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                {isDragActive ? (
                                                    <p>Drop the files here ...</p>
                                                ) : (
                                                    <div className="uploadPhoto">
                                                        <div style={{ cursor: "pointer" }} className="uploadInfo">
                                                            <div className="uploadImage"><img width="22" src={UploadPhotoIcon} /></div>
                                                            <div className="uploadText">Store Photo</div>
                                                        </div>
                                                    </div>

                                                )}
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mt-4">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <h5 className="h5-label mb-2">Store Timings</h5>
                                <div className="col-12">
                                    <div className="row mb-2 pl-3 pr-3">
                                        <div className="col-6"></div>
                                        <div className="col-3 f-600">Start time</div>
                                        <div className="col-3 f-600">End time</div>
                                    </div>

                                    {storeTiming.map((item, index) => {
                                        return (
                                            <div className="row pt-3 pb-3 mb-2" style={{ backgroundColor: "#ECECEC" }}>
                                                <div className="col-6 d-flex" style={{ alignItems: "center" }}>
                                                    <Checkbox
                                                        defaultChecked
                                                        color="primary"
                                                        checked={item.is_holiday}
                                                        onChange={(e) => handleStoreHoliday(e, index)}
                                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                                    />
                                                    {item.days.charAt(0).toUpperCase() + item.days.slice(1)}
                                                </div>
                                                <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardTimePicker
                                                            id="time-picker"
                                                            label="Start Time"
                                                            inputVariant="outlined"
                                                            mask="__:__ __M"
                                                            value={item.startTime}
                                                            onChange={date => handleStartDateChange(date, index)}
                                                            InputProps={{ disableUnderline: true }}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change time',
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                                <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardTimePicker
                                                            id="time-picker"
                                                            label="End Time"
                                                            inputVariant="outlined"
                                                            mask="__:__ _M"
                                                            value={item.endTime}
                                                            onChange={date => handleEndDateChange(date, index)}
                                                            InputProps={{ disableUnderline: true }}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change time',
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="row">
                                    <h5 className="col-12 h5-label">Shipping</h5>
                                    <div className="col-12 mt-2">
                                        <div className={"joditEdit"}>
                                            <JoditEditor
                                                ref={editorRef}
                                                value={formValue.merchant_shipping}
                                                config={shippingConfig}
                                                onBlur={newContent => handleShippingChange(newContent)}
                                                onChange={newContent => { }}
                                            />
                                        </div>
                                    </div>
                                    <h5 className="col-12 h5-label mt-3">Payment</h5>
                                    <div className="col-12 mt-2">
                                        <div className={"joditEdit"}>
                                            <JoditEditor
                                                ref={editorRef}
                                                value={formValue.merchant_payment}
                                                config={paymentConfig}
                                                onBlur={newContent => handlePaymentChange(newContent)}
                                                onChange={newContent => { }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mb-5">
                        <div className="row">
                            <div className="col-12" style={{ textAlign: "right" }}>
                                <Button className="btn btn-primary mt-3 mb-3" onClick={() => submitData()} name="Update"></Button>
                            </div>
                        </div>
                    </div>

                </ValidatorForm>
            </div>
        </div >
    );
}
export default GeneralSettings;
